import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'
import TextField from '@mui/material/TextField'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import Header from 'Components/LayoutComponents/Header'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { BlueColorMainLogo } from 'Assets/Icons'
import { SupportNewSvg } from 'Assets/Images'
import { REG_EX, STATIC_ROUTE_STRINGS } from 'Utils/Constants'
import { coffeewebStorageKeys, coffeewebGetLocal } from 'Utils/LocalStorage_Handler'
import FreeMenubar from 'Components/LayoutComponents/FreeUserMenubar'
import ApiResponseHandler from 'Components/ApiResponseHandler'

function SupportRequest({ handleApiResponse }) {
  const { i18n, t } = useTranslation()
  const { EMAIL_REG_EX, PHONE_REG_EX } = REG_EX
  const history = useHistory()

  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const AuthToken = coffeewebGetLocal(coffeewebStorageKeys.authToken)

  const isLoggedIn = coffeewebGetLocal(coffeewebStorageKeys.isLoggedIn)

  const [inputValues, setInputValue] = useState({
    name: '',
    email: isLoggedIn ? userDetails?.email : '',
    subject: '',
    phone: '',
    message: '',
    termsAndCondition: false
  })
  const [authToken, setAuthToken] = useState([])
  const [allError, setAllError] = useState(false)
  const [termsAndCondition, setTermsAndCondition] = useState(false)
  const [errorMessageForEmail, setErrorMessageForEmail] = useState(false)
  const [errorMessageForSubject, setErrorMessageForSubject] = useState(false)
  const [errorMessageForMessage, setErrorMessageForMessage] = useState(false)

  /* eslint-disable no-unused-vars */
  /* eslint-disable no-unused-vars */

  useEffect(() => {
    if (!isLoggedIn) {
      generateAuthToken()
    }
  }, [])

  const checkInputBox = () => {
    !termsAndCondition ? setTermsAndCondition(true) : setTermsAndCondition(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'name') {
      const sanitizedValue = value.replace(/[0-9!@#$%^&*(),.?":{}|<>]/g, '')

      setInputValue({ ...inputValues, name: sanitizedValue })
    } else if (name === 'email') {
      setInputValue({ ...inputValues, email: value })
      setErrorMessageForEmail(allError && value && !EMAIL_REG_EX.test(value.trim()))
      value === null || value === '' ? setErrorMessageForEmail(true) : setErrorMessageForEmail(false)
    } else if (name === 'phone') {
      setInputValue({ ...inputValues, phone: value })
      setErrorMessageForEmail(allError && value && !PHONE_REG_EX.test(value.trim()))
    } else if (name === 'subject') {
      setInputValue({ ...inputValues, subject: value })
      value === null || value === '' ? setErrorMessageForSubject(true) : setErrorMessageForSubject(false)
    } else if (name === 'message') {
      setInputValue({ ...inputValues, message: value })
      value === null || value === '' ? setErrorMessageForMessage(true) : setErrorMessageForMessage(false)
    }
  }

  const handleSubmit = (e) => {
    const terms = document.getElementsByClassName('TermsandCondition')

    if (isLoggedIn) {
      if (!inputValues.email || !inputValues.subject || !inputValues.message) {
        setAllError(true)

        return
      }
    } else if (!inputValues.name || !inputValues.email || !inputValues.phone || !inputValues.subject || !inputValues.message || !terms[0].checked) {
      setAllError(true)

      return
    }

    // validations
    if (!EMAIL_REG_EX.test(inputValues.email.trim())) {
      setErrorMessageForEmail(true)

      return
    }
    if (inputValues.subject.trim().length < 10 && inputValues.message.trim().length < 100) {
      setErrorMessageForSubject(true)
      setErrorMessageForMessage(true)

      return
    }
    if (inputValues.subject.trim().length < 10) {
      setErrorMessageForSubject(true)

      return
    }
    if (inputValues.message.trim().length < 100) {
      setErrorMessageForMessage(true)

      return
    }
    postData()
  }

  const generateAuthToken = async () => {
    await apiAdapterCoffeeWeb
      .generateAuthToken()
      .then((response) => {
        setAuthToken(response.data.token)
      })
      .catch((error) => {
        handleApiResponse({ status: error.response.status, message: 'FAILED_TO_GENERATE_AUTH_TOKEN', onRetry: generateAuthToken })
      })
  }

  const postData = async () => {
    const token = isLoggedIn ? AuthToken : authToken
    const apiEndPoint = isLoggedIn ? apiAdapterCoffeeWeb.sendSupportEmail : apiAdapterCoffeeWeb.requestForSupport
    const commonData = {
      subject: inputValues.subject,
      message: inputValues.message
    }
    /* eslint-disable indent */
    const additionalData = isLoggedIn
      ? {
          userId: userDetails.id,
          email: inputValues.email,
          mode: userDetails.isEmailVerified,
          phone: userDetails.phone
        }
      : {
          name: inputValues.name,
          phone: inputValues.phone,
          emailId: inputValues.email
        }
    /* eslint-disable indent */
    const data = { ...commonData, ...additionalData }

    await apiEndPoint(token, data)
      .then(() => {
        swal({
          title: t('SUCCESS'),
          text: t('WE_HAVE_RECEIVED_YOUR_EMAIL'),
          icon: 'success'
        }).then((value) => {
          setAllError(false)
          if (!isLoggedIn && value) {
            history.push('/')
          }
          setInputValue({
            name: '',
            email: isLoggedIn ? userDetails?.email : '',
            subject: '',
            phone: '',
            message: '',
            termsAndCondition: false
          })
        })
      })
      .catch((error) => {
        handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_SEND_MESSAGE', onRetry: postData })
      })
  }

  const handleClickBackButton = () => {
    history.goBack()
  }

  return (
    <>
      {!isLoggedIn && <Header />}
      {!isLoggedIn && <FreeMenubar />}
      <div className="user">
        <div className="supportRequest">
          <div className="row mainDiv">
            <div className="col-lg-6 col-md-6 col-sm-6 d-none d-sm-block d-flex m-auto justify-content-center">
              <div>
                <div>
                  {!isLoggedIn && (
                    <div className="support_image_container">
                      <div className="support_image_sub_container">
                        <BlueColorMainLogo fill="#50b3f6" strokeWidth="1" className="d-inline-block align-top forLogo" />
                      </div>
                      <div className="support_image">
                        <SupportNewSvg />
                      </div>
                    </div>
                  )}
                </div>
                {isLoggedIn && (
                  <div className="logoSvg">
                    <SupportNewSvg />
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 formDiv">
              <h4>{t('SUPPORT')}</h4>
              <>
                {!isLoggedIn && (
                  <>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      label={
                        <span className="support_textfield_label">
                          {t('ENTER_YOUR_NAME')}
                          <span className="support_textfield_span">*</span>
                        </span>
                      }
                      name="name"
                      value={inputValues.name}
                      variant="outlined"
                      onChange={(e) => handleChange(e)}
                    />
                    {allError && !inputValues.name && <span>{t('PLEASE_ENTER_NAME')}</span>}
                  </>
                )}

                <TextField
                  id="outlined-basic"
                  label={
                    <span className="support_textfield_label">
                      {!isLoggedIn ? t('ENTER_YOUR_EMAIL') : t('EMAIL')} <span className="support_textfield_span">*</span>
                    </span>
                  }
                  value={inputValues?.email || ''}
                  disabled={!!(isLoggedIn && userDetails?.email)}
                  name="email"
                  variant="outlined"
                  onChange={(e) => handleChange(e)}
                />
                {allError && !inputValues.email ? <span>{t('PLEASE_FILL_IN_THE_EMAIL')}</span> : errorMessageForEmail && <span>{t('ENTER_VALID_EMAIL')}</span>}

                {!isLoggedIn && (
                  <>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      label={
                        <span className="support_textfield_label">
                          {t('ENTER_YOUR_PHONE')} <span className="support_textfield_span">*</span>
                        </span>
                      }
                      name="phone"
                      type="number"
                      value={inputValues?.phone}
                      variant="outlined"
                      onChange={(e) => handleChange(e)}
                    />
                    {allError && !inputValues.phone && <span>{t('ENTER_YOUR_PHONE')}</span>}
                  </>
                )}

                <TextField
                  id="outlined-basic"
                  value={inputValues.subject}
                  label={
                    <span className="support_textfield_label">
                      {t('SUBJECT')} <span className="support_textfield_span">*</span>
                    </span>
                  }
                  name="subject"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e)}
                />
                {allError && !inputValues.subject ? <span>{t('PLEASE_ENTER_SUBJECT')}</span> : errorMessageForSubject && <span>{t('SUBJECT_ERROR_STATE')}</span>}
                <TextField
                  id="outlined-multiline-static"
                  label={
                    <span className="support_textfield_label">
                      {t('MESSAGE')} <span className="support_textfield_span">*</span>
                    </span>
                  }
                  multiline
                  rows={4}
                  value={inputValues.message}
                  name="message"
                  onChange={(e) => handleChange(e)}
                />
                {allError && !inputValues.message ? <span>{t('PLEASE_FILL_THIS')}</span> : errorMessageForMessage && <span>{t('MESSAGE_ERROR_STATE')}</span>}
                {!isLoggedIn && (
                  <>
                    <div className="support_terms_and_condintions_container">
                      <input type="checkbox" onChange={() => checkInputBox()} className="TermsandCondition support_checkbox" id="" /> {t('I_AGREE_TO_THE')}
                      <a href={STATIC_ROUTE_STRINGS.termsAndCondition} target="_blank" rel="noreferrer">
                        {t('TERMS_AND_CONDITIONS')}
                      </a>
                      {t('AND')}
                      <a href={STATIC_ROUTE_STRINGS.privacyPolicy} target="_blank" rel="noreferrer">
                        {t('PRIVACY_POLICY')}
                      </a>
                    </div>
                    <div>{allError && !termsAndCondition && <div className="support_checkbox_err_text">{t('AGREE_TERMS_AND_CONDITIONS')}</div>}</div>
                  </>
                )}

                <button className="submitBtn" variant="contained" onClick={handleSubmit}>
                  {isLoggedIn ? t('SUBMIT') : t('SUBMIT_BUTTON')}
                </button>
              </>
            </div>
          </div>
          {isLoggedIn && (
            <div className="backBtnInSupportScreen" onClick={handleClickBackButton}>
              <div className="back_button_div">
                <ArrowBackIcon className="arrow_icon" />
              </div>
              <div className="go_back_text">{t('GO_BACK')}</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ApiResponseHandler(SupportRequest)
