import './styles.scss'
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import ReactCustomFlagSelect from 'react-custom-flag-select'
import 'react-custom-flag-select/lib/react-custom-flag-select.css'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import OtpField from 'react-otp-field'
import Loader from 'Components/LayoutComponents/Loader'
import { doc, setDoc } from 'firebase/firestore'
import { coffeewebStorageKeys, coffeewebSetLocal, coffeewebGetLocal } from 'Utils/LocalStorage_Handler'
import { db } from 'firebase.js'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { Closebtn, EyeFilled, EyeFilledOff } from 'Assets/Icons'
import { ROUTE_STRINGS, STATIC_ROUTE_STRINGS } from 'Utils/Constants'
import Validation from 'Utils/Validation.js'
import ApiResponseHandler from 'Components/ApiResponseHandler'

const SignupComponent = ({ setLoading, handleTabSelection, generatedToken, singleDeviceId, setNewsDataOnClick, handleApiResponse }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const params = new URLSearchParams()
  const dropdownRef = useRef(null)
  const sharedNewsId = history.location.state?.encryptedId || coffeewebGetLocal(coffeewebStorageKeys.sharednewsid)
  const newsForSubscription = history.location.state?.newsForSubscription || coffeewebGetLocal(coffeewebStorageKeys.newsForSubscription)
  const newsId = history.location.state?.newsId
  const forGuestUser = history.location.state?.forGuestUser

  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const accessToken = coffeewebGetLocal(coffeewebStorageKeys.authToken)

  let displayNameForChat = ''
  let emailForChat = ''
  let userChatId = ''

  const [countryApi, setCountryApi] = useState([])
  const [otpRequest, setOtpRequest] = useState('')
  const [selectedCountryCode, setSelectedCountryCode] = useState('-')
  const [minutes, setMinutes] = useState()
  const [seconds, setSeconds] = useState()
  const [currentItem, setCurrentItem] = useState(null)
  const [showPassword, getShowPassword] = useState(false)
  const [showOtpModal, setShowOtpModal] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [userInputValues, setUserInputValue] = useState({
    email: '',
    phoneNo: '',
    password: '',
    confirmPassword: '',
    otp: '',
    sessionId: ''
  })
  const [inputValues, setInputValues] = useState({
    firstName: '',
    lastName: '',
    phoneNo: '',
    email: '',
    otp: '',
    occupationId: 0,
    occupationName: 'NA',
    password: '',
    confirmPassword: '',
    idState: 0,
    countryId: '',
    countryPhoneCode: '',
    idtimezone: 0,
    termsAndCondition: false
  })
  const [errorState, setErrorState] = useState({
    firstName: false,
    lastName: false,
    country: false,
    phoneNo: false,
    phoneNoAlreadyExist: false,
    phoneNoInvalid: false,
    email: false,
    emailAlreadyExist: false,
    occupationId: false,
    occupationName: false,
    password: false,
    confirmPassword: false,
    timeZone: false,
    idState: false,
    termsAndCondition: false,
    countryListError: false,
    invalidPhoneNoError: false,
    unableToSendOtp: false,
    otpMisMatch: false,
    PhoneNoFetchErrorState: false,
    verifyOtpError: false
  })

  if (userDetails && accessToken) {
    coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, true)
    if (userDetails?.userLandingPageUrl) {
      history.push(`${ROUTE_STRINGS.dashboard}/${userDetails?.userLandingPageUrl}`)
    } else {
      history.push(ROUTE_STRINGS.coffeeNewsFeeds)
    }
  }

  useEffect(() => {
    init()
    setNewsDataOnClick((previousState) => ({ ...previousState, encryptedId: sharedNewsId, newsForSubscription, newsId, forGuestUser }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const init = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getAllCountries()

      if (response.data.length) {
        setErrorState({ ...errorState, countryListError: false })

        const newData = response.data.map((ele) => ({
          ...ele,
          flag: ele.flagsUrl,
          id: ele.id.toString(),
          name: ele.nicename
        }))

        setCountryApi(newData)

        const responseData = await fetch('https://ipapi.co/json/')

        const countryCodeResponse = await responseData.json()

        response.data.forEach((ele) => {
          if (`+${ele.phonecode}` === countryCodeResponse.country_calling_code) {
            handleSelect(ele.id)
          }
        })
      } else {
        setErrorState({ ...errorState, countryListError: true })
      }
    } catch {
      setErrorState({ ...errorState, countryListError: true })
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  const validateAndSubmitDetails = async () => {
    const { firstName, countryId, phoneNo, password, countryPhoneCode } = inputValues

    if (Validation.checkAllInputFieldsNotEmpty(firstName, countryId, phoneNo, password) && !errorState.firstName) {
      const isPhoneNumberExists = await validateAndCheckNumber(countryPhoneCode)

      if (!isPhoneNumberExists) {
        sendOtpAndShowModal(phoneNo)
      }
    } else {
      if (!firstName) {
        setErrorState((previousState) => ({ ...previousState, firstName: true }))
      }
      if (!password) {
        setErrorState((previousState) => ({ ...previousState, password: true }))
      }
      if (!countryId) {
        setErrorState((previousState) => ({ ...previousState, country: true }))
      }
      if (!phoneNo) {
        setErrorState((previousState) => ({ ...previousState, phoneNo: true }))
      }
    }
  }

  const sendOtpAndShowModal = async (number) => {
    setOtpRequest('')
    setLoading(true)

    const concatenatedPhoneNumber = selectedCountryCode + number

    try {
      const response = await apiAdapterCoffeeWeb.sendOtpWithTemplate(concatenatedPhoneNumber)
      const {
        status: responseStatus,
        data: { status, details }
      } = response

      if (responseStatus === 200 && status === 'Error') {
        setErrorState({ ...errorState, unableToSendOtp: true })
        setLoading(false)
      } else if (responseStatus === 200) {
        setUserInputValue({
          ...userInputValues,
          sessionId: details
        })
        setShowOtpModal(true)
        setLoading(false)
        handleOtpTimer()
      }
    } catch (error) {
      setErrorState({ ...errorState, unableToSendOtp: true })
      setLoading(false)
    }
  }

  const handleOtpSubmit = () => {
    const { sessionId } = userInputValues

    if (!Validation.isInputFieldEmpty(otpRequest) && !Validation.isInputFieldEmpty(sessionId)) {
      handleOtpVerification(sessionId, otpRequest)
    }
  }

  const handleOtpVerification = async (sessionId, otp) => {
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.verifyOtp({ sessionId, otp })
      const {
        status: responseStatus,
        data: { status }
      } = response

      if (responseStatus === 200) {
        if (status === 'Success') {
          addUserInDatabase()
          setErrorState({ ...errorState, otpMisMatch: false })
        } else if (status === 'Error') {
          setErrorState({ ...errorState, otpMisMatch: true })
          setLoading(false)
        }
      }
    } catch (error) {
      setErrorState({ ...errorState, verifyOtpError: true })
      setLoading(false)
    }
  }

  const addUserInDatabase = async () => {
    const { firstName, lastName, email, phoneNo, occupationId, occupationName, countryId, password, idtimezone, idState } = inputValues
    const data = {
      userType: 1,
      firstName,
      lastName,
      email,
      phone: phoneNo,
      occupationId,
      occupationName,
      countryId,
      password,
      createdBy: 0,
      includeMenu: true,
      countryPhoneCode: selectedCountryCode,
      idtimezone,
      idState,
      showMobileNo: true,
      showEmailId: true,
      isAllowedToConnect: true,
      userRole: 0,
      registerSource: 2,
      deviceId: singleDeviceId,
      fbToken: singleDeviceId,
      registerDevice: 'Browser'
    }

    try {
      const response = await apiAdapterCoffeeWeb.createUser(generatedToken, data)
      const {
        status: responseStatus,
        data: { firstName, email, userFbregistrationId }
      } = response

      if (responseStatus === 200) {
        displayNameForChat = firstName
        emailForChat = email
        userChatId = userFbregistrationId
        addUserInFirebase()
      }
    } catch (error) {
      setLoading(false)
      handleApiResponse({ status: error?.response?.status, message: 'SORRY_COULD_NOT_CREATE_YOUR_ACCOUNT_PLEASE_TRY_AGAIN_LATER', onRetry: addUserInDatabase })
    }
  }

  const addUserInFirebase = async () => {
    const setUser = setDoc(doc(db, 'users', userChatId), {
      uid: userChatId,
      displayNameForChat,
      emailForChat
    })
    const setUserChats = setDoc(doc(db, 'userChats', userChatId), {})

    try {
      await Promise.all([setUser, setUserChats])
      authenticateForDashboard()
    } catch (err) {
      setLoading(false)
    }
  }

  const authenticateForDashboard = async () => {
    const { phoneNo, password } = inputValues
    const postData = {
      username: phoneNo,
      password,
      includeMenu: true,
      isMobile: false,
      deviceId: singleDeviceId,
      fbToken: singleDeviceId,
      isAndroidDevice: false,
      byPassOldDevice: true
    }

    try {
      const response = await apiAdapterCoffeeWeb.authenticateWithFB(postData)

      if (response.status === 200) {
        const {
          token,
          languageId,
          sub: { subType }
        } = response.data

        coffeewebSetLocal(coffeewebStorageKeys.userDetails, response.data)
        coffeewebSetLocal(coffeewebStorageKeys.authToken, token)
        coffeewebSetLocal(coffeewebStorageKeys.languageIdForNews, languageId)
        coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, true)
        setLoading(false)

        const newsSubscriptionType = newsForSubscription?.split(',')?.map((item) => item?.trim())
        const isUserSubscribed = newsSubscriptionType?.includes(subType.toString())

        if (newsId && forGuestUser) {
          if (isUserSubscribed) {
            params.set('newsId', newsId)
            history.push({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
          } else {
            history.push(ROUTE_STRINGS.subscription)
          }
        } else if (sharedNewsId) {
          if (isUserSubscribed) {
            history.push({ pathname: ROUTE_STRINGS.opensharednews, state: { encryptedId: sharedNewsId } })
          } else {
            history.push(ROUTE_STRINGS.subscription)
          }
        } else if (response.data?.userLandingPageUrl) {
          history.push(`${ROUTE_STRINGS.dashboard}/${response?.data?.userLandingPageUrl}`)
        } else {
          history.push(ROUTE_STRINGS.coffeeNewsFeeds)
        }
      }
    } catch (error) {
      handleApiResponse({ status: error?.response?.status, message: 'COULD_NOT_BE_AUTHENTICATED_PLEASE_TRY_AGAIN_LATER', onRetry: authenticateForDashboard })

      setLoading(false)
    }
  }

  const validateAndCheckNumber = async (countryPhoneCode) => {
    const { phoneNo } = inputValues

    if (Validation.isInputFieldEmpty(countryPhoneCode) || countryPhoneCode === '---Select---') {
      setErrorState((previousState) => ({ ...previousState, country: true }))
    } else {
      try {
        const response = await apiAdapterCoffeeWeb.isPhoneExist({ phoneNo, countryPhoneCode })
        const { data } = response

        if (data === true) {
          setErrorState((previousState) => ({ ...previousState, phoneNoAlreadyExist: true, PhoneNoFetchErrorState: false }))

          return true
        }
        checkForValidMobileNumber(generatedToken, phoneNo)
      } catch (error) {
        setErrorState({ ...errorState, PhoneNoFetchErrorState: true })
      } finally {
        setLoading(false)
      }
    }
  }

  const checkForValidMobileNumber = async (generatedToken, phoneNo) => {
    try {
      const response = await apiAdapterCoffeeWeb.checkMobileNumber(generatedToken, phoneNo)
      const { data } = response

      if (data?.statusCode === 200) {
        setErrorState((...previousState) => ({ ...previousState, phoneNoInvalid: true }))
      }
    } catch (error) {
      setErrorState((...previousState) => ({ ...previousState, invalidPhoneNoError: true }))
    }
  }

  const countryCallingCodeUpdate = async (internationalCallingCode) => {
    await apiAdapterCoffeeWeb
      .getCountryDetailsByCountryId(internationalCallingCode)

      .then((response) => {
        if (response.data) {
          setErrorState({ ...errorState, countryListError: false })
          const modifiedResponse = { ...response.data, flag: response.data.flagsUrl }

          setCurrentItem(modifiedResponse)
          setSelectedCountryCode(response.data.phonecode)
          setInputValues({ ...inputValues, countryId: response.data.id, countryPhoneCode: response.data.phonecode, idState: 0, idtimezone: 0 })
          if (inputValues.phoneNo !== '') {
            validateAndCheckNumber(response.data.phonecode)
          }
        } else {
          setErrorState({ ...errorState, countryListError: true })
        }
      })
      .catch(() => {
        setErrorState({ ...errorState, countryListError: true })

        setInputValues({ ...inputValues, countryId: '', countryPhoneCode: '' })
      })
  }

  const handleOtpTimer = () => {
    setMinutes(4)
    setSeconds(59)
    setTimeout(() => {
      setUserInputValue({
        ...userInputValues,
        sessionId: ''
      })
    }, 299000)
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'firstName') {
      setInputValues({
        ...inputValues,
        firstName: value
      })
      setErrorState((prevState) => ({
        ...prevState,
        firstName: !Validation.nameValidation(value)
      }))
    } else if (name === 'phoneNo') {
      const isPhoneNumberEmpty = Validation.isInputFieldEmpty(value)
      const isValidPhoneNumber = !isPhoneNumberEmpty && Validation.phoneNumberValidation(value)

      setErrorState((previousState) => ({ ...previousState, phoneNo: false, phoneNoAlreadyExist: false, phoneNoInvalid: false, unableToSendOtp: false }))

      setErrorState((prevState) => ({
        ...prevState,
        phoneNo: isPhoneNumberEmpty,
        phoneNoInvalid: !isPhoneNumberEmpty && value.length < 6,
        phoneNoAlreadyExist: false
      }))

      if (isValidPhoneNumber || isPhoneNumberEmpty || value === '-') {
        setInputValues({
          ...inputValues,
          phoneNo: value.trim()
        })
      }
    } else if (name === 'password') {
      setInputValues({
        ...inputValues,
        password: value
      })
      setErrorState((prevState) => ({
        ...prevState,
        password: !Validation.passwordValidation(value)
      }))
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    if (name === 'firstName') {
      if (!Validation.nameValidation(value) || value === '-' || value === ' ' || Validation.isInputFieldEmpty(value)) {
        setErrorState({
          ...errorState,
          firstName: true
        })
      } else {
        setErrorState({
          ...errorState,
          firstName: false
        })
      }
    }

    if (name === 'phoneNo') {
      const { countryPhoneCode } = inputValues

      if (!Validation.isInputFieldEmpty(value)) {
        const phoneNumberLength = value.length

        if (phoneNumberLength <= 5) {
          setErrorState((previousState) => ({ ...previousState, phoneNoInvalid: true }))
        } else {
          validateAndCheckNumber(countryPhoneCode)
        }
      } else {
        setErrorState((previousState) => ({ ...previousState, phoneNo: true }))
      }
    }
    if (name === 'password') {
      if (Validation.isInputFieldEmpty(value)) {
        setErrorState({
          ...errorState,
          password: true
        })
      } else {
        setErrorState({
          ...errorState,
          password: false
        })
      }
    }
  }

  const handleSelect = (internationalCallingCode) => {
    countryCallingCodeUpdate(internationalCallingCode)
    setErrorState((previousState) => ({ ...previousState, country: false, phoneNoAlreadyExist: false }))
  }

  const togglePassword = () => {
    getShowPassword(!showPassword)
  }

  const handleClose = () => {
    setOtpError(false)
    setShowOtpModal(false)
    setErrorState({ ...errorState, otpMisMatch: false })
  }

  const handleChangeOtp = (otpValue) => {
    setOtpRequest(otpValue)
    setOtpError(false)

    setErrorState({ ...errorState, otpMisMatch: false })
  }

  const handleOtpBlur = () => {
    setOtpError(true)
  }

  const handleFocus = () => {
    setOtpError(false)
  }

  return (
    <>
      <div className="signUpTabScreen">
        <div className="frmsignup">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <label htmlFor="txtFirstName" className="form-label">
                {t('NAME')}
                <span className="mandate">*</span>
              </label>
              <input type="text" name="firstName" value={inputValues.firstName} onChange={handleChange} onBlur={handleBlur} className="signup_form-control" id="txtFirstName" placeholder={t('NAME')} />
              {inputValues.firstName && errorState.firstName && <p className="text-danger">{t('PLEASE_ENTER_ONLY_LETTERS')}</p>}
              {!inputValues.firstName && errorState.firstName && <p className="text-danger">{t('NAME_IS_REQUIRED')}</p>}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 countryDropDownForSignUp">
              <label htmlFor="ddlCountyotp" className="form-label">
                {t('COUNTRY')}
                <span className="mandate">*</span>
              </label>

              {currentItem && countryApi.length && (
                <ReactCustomFlagSelect
                  ref={dropdownRef}
                  attributesWrapper={{ id: 'areaCodeWrapper', tabIndex: '1' }}
                  attributesButton={{ id: 'areaCodeButton' }}
                  attributesInput={{ id: 'areaCode', name: 'areaCode' }}
                  value={currentItem?.id ? currentItem?.id : ''}
                  disabled={false}
                  showSearch={true}
                  fields={['currencyName', 'currency', 'countryTag', 'nicename']}
                  showArrow={true}
                  animate={true}
                  optionList={countryApi}
                  selectHtml={
                    <div style={{ color: 'black', display: 'flex', justifyContent: 'start', width: '100%', gap: '8px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <img src={currentItem?.flag} style={{ borderRadius: '3px', border: '1px solid rgba(0, 17, 51, 0.15)' }} alt="Jhanda" />
                      </div>
                      <div style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>{`${currentItem?.nicename}`}</div>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}></div>
                    </div>
                  }
                  customStyleContainer={{ border: 'none', fontSize: '12px' }}
                  customStyleSelect={{ height: '40px', border: '1px solid #e4e4e4' }}
                  customStyleOptionListItem={{ display: 'flex', justifyContent: 'space-around' }}
                  customStyleOptionListContainer={{ maxHeight: '240px', overflow: 'auto' }}
                  onChange={(areaCode) => handleSelect(areaCode)}
                />
              )}
              {!currentItem && <div style={{ border: '1px solid #50b3f6', height: '40px', borderRadius: '4px', display: 'flex', alignItems: 'center', color: 'gray', padding: '10px' }}>Loading...</div>}
              {errorState.country && !inputValues.countryId && <p className="text-danger">{t('SELECT_COUNTRY')}</p>}
            </div>
            {errorState?.countryListError && <p className="error-country-dropdown">{t('COUNTRY_LIST_DATA_NOT_AVAILABLE')}</p>}
            <div className="col-lg-12 col-md-12 col-sm-12">
              <label htmlFor="txtPhoneOtp" className="form-label">
                {t('PHONE')}
                <span className="mandate">*</span>
              </label>
              <div className="input-group phone_group">
                <div className="input-group-prepend countrycodeotp">
                  <span className="input-group-text countrycodeotpprepend">{inputValues.countryId === '' ? '-' : `+${selectedCountryCode}`}</span>
                </div>
                <input type="number" name={'phoneNo'} value={inputValues.phoneNo} onChange={handleChange} onBlur={handleBlur} maxLength={15} className="codes_form-control form-control signup_form-control signup_phone_field" autoComplete="off" placeholder={t('PHONE')} />
              </div>
              {errorState?.countryListError && <p className="error-message">{t('PHONE_CODE_NOT_AVAILABLE')}</p>}
              {errorState.phoneNoAlreadyExist && <p className="text-danger">{t('PHONE_NUMBER_ALREADY_EXISTS')}</p>}
              {errorState.phoneNoInvalid && !errorState.phoneNo && <p className="text-danger">{t('INVALID_PHONE_NUMBER')}</p>}
              {errorState.invalidPhoneNoError && <p className="text-danger">{t('FAILED_TO_VALID_PHONE_NUMBER')}</p>}
              {errorState.PhoneNoFetchErrorState && !errorState.phoneNoInvalid && !errorState.phoneNo && <p className="error-message">{t('ERROR_VERIFYING_PHONE_PLEASE_TRY_AGAIN')} </p>}
              {errorState.phoneNo && !inputValues.phoneNo && <p className="text-danger">{t('PHONE_NUMBER_IS_REQUIRED')}</p>}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <label htmlFor="txtPassword" className="form-label">
                {t('PASSWORD')}
                <span className="mandate">*</span>
              </label>
              <div className="pass-wrapper">
                <input type={showPassword ? 'text' : 'password'} name="password" value={inputValues.password} onChange={handleChange} onBlur={handleBlur} className="signup_form-control pass_flow" style={{ width: '417px' }} placeholder={t('PASSWORD')} autoComplete="new-password" />
                <span onClick={togglePassword} className="eye_icon">
                  {showPassword ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" />}
                </span>
              </div>
              {errorState.password && !inputValues.password && <p className="text-danger">{t('PASSWORD_IS_REQUIRED')}</p>}
            </div>
          </div>
          {errorState.unableToSendOtp && <p className="error-message">{t('FAILED_TO_SEND_OTP')}</p>}
          <div className="termsAndPolicyFooterStyle">
            <div>
              {t('BY_CLICKING_ON_CONTINUE_YOU_AGREE_OUR')}
              <a href={STATIC_ROUTE_STRINGS.termsAndCondition} target="_blank" rel="noreferrer">
                {t('TERMS_CONDITIONS')}
              </a>
              {t('AND')}
              <a href={STATIC_ROUTE_STRINGS.privacyPolicy} target="_blank" rel="noreferrer">
                {t('PRIVACY_POLICY')}
              </a>
            </div>
          </div>

          <div className="continueButton">
            <button className="continueButtonStyle" onClick={validateAndSubmitDetails}>
              {t('CONTINUE')}
            </button>

            <Modal show={showOtpModal} size="md" aria-labelledby="contained-modal-title-vcenter" style={{ marginTop: '100px' }} backdrop="static" keyboard={false}>
              <Modal.Header style={{ color: 'white', backgroundColor: '#50b3f6', display: 'flex', justifyContent: 'center' }}>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h4 style={{ marginLeft: '10px' }}>{t('VERIFY_MOBILE_NUMBER')}</h4>
                </Modal.Title>
                <Closebtn style={{ float: 'end', cursor: 'pointer' }} onClick={() => setShowOtpModal(false)} />
              </Modal.Header>
              <Modal.Body>
                <h5>{`${t('ENTER_THE_OTP_SENT_TO')} ${inputValues.phoneNo}`}</h5>
                <div>
                  <OtpField value={otpRequest} onChange={handleChangeOtp} onBlur={handleOtpBlur} onFocus={handleFocus} numInputs={6} onChangeRegex={/^([0-9]{0,})$/} autoFocus isTypeNumber inputProps={{ className: 'otp-field__input', disabled: false }} />
                </div>

                <div className={`${(otpError && otpRequest.length !== 6) || errorState?.otpMisMatch ? 'otp-mismatch-error' : 'timer-container'}`}>
                  {otpError && otpRequest.length !== 6 && <p className="text-danger">{t('OTP_IS_REQUIRED')}</p>}
                  {errorState?.otpMisMatch && <div className="error-message">{t('OTP_MISMATCH')}</div>}
                  {errorState?.verifyOtpError && !errorState?.otpMisMatch && <div className="error-message">{t('FAILED_TO_VERIFY_OTP')}</div>}
                  {seconds > 0 || minutes > 0 ? (
                    <p style={{ color: '#50b3f6', backgroundColor: 'white', margin: '3px 12px', textDecoration: 'underline #50b3f6' }}>
                      {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <button style={{ color: '#50b3f6', backgroundColor: 'white', border: '1px solid #50b3f6', cursor: 'pointer', padding: '4px 12px', fontSize: '14px', margin: '3px 12px' }} onClick={() => sendOtpAndShowModal(inputValues.phoneNo)}>
                      {t('RESEND_OTP')}
                    </button>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button style={{ backgroundColor: 'white', padding: '5px 13px', color: '#50b3f6', border: '1px solid #50b3f6' }} onClick={handleClose}>
                  {t('CLOSE')}
                </button>

                <div className="verify-create-account">
                  <button
                    onClick={() => {
                      handleOtpSubmit()
                    }}
                    style={{ backgroundColor: '#50b3f6', padding: '5px 13px', border: '1px solid #50b3f6' }}
                  >
                    {t('VERIFY_CREATE_ACCOUNT')}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>

          <div className="login_links redirect_to_login_container">
            <span lang="en-us">{t('ALREADY_HAVE_AN_ACCOUNT')}</span>
            <button className="btn btn-sm coffe-hard sign_up_btn" onClick={() => handleTabSelection('login')}>
              {t('LOGIN_HERE')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Loader(ApiResponseHandler(SignupComponent))
