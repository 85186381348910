import './styles.scss'
import 'react-image-lightbox/style.css'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import Lightbox from 'react-image-lightbox'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ShareIcon from '@mui/icons-material/Share'
import Carousel from 'framer-motion-carousel'
import { ClockIcon, EyeFilled, FilledHeartIcon, UnFilledHeartIcon } from 'Assets/Icons'
import { coffeewebGetLocal, coffeewebSetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'
import { addBlankTargetToAnchors } from 'Utils/HtmlUtils'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Loader from 'Components/LayoutComponents/Loader'
import ShareNewsfeed from 'Screens/CoffeeNewsFeeds/Components/ShareNewsfeed'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import { NoData } from 'Assets/Images'

const OpenSharedNews = ({ setLoading, handleApiResponse }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const scrollableDivRef = useRef(null)
  const appSettings = coffeewebGetLocal(coffeewebStorageKeys.appSettings)
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const sharedNewsId = history.location.state?.encryptedId || coffeewebGetLocal(coffeewebStorageKeys.sharednewsid)

  const [coffeeNewsData, setCoffeeNewsData] = useState([])
  const [images, setImages] = useState([])
  const [like, setLike] = useState(false)
  const [shareButton, setShareButton] = useState(false)
  const [openLogoutModal, setOpenLogoutModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [imgIndex, setImgIndex] = useState(0)
  const [newsId, setNewsId] = useState('')
  const [scrollDirection, setScrollDirection] = useState('up')
  const [previousScrollTop, setPreviousScrollTop] = useState(0)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  useEffect(() => {
    getCoffeeNewsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setErrorMessage(false)
    }, 5000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [errorMessage])

  const getCoffeeNewsData = async () => {
    if (sharedNewsId) {
      setLoading(true)
      await apiAdapterCoffeeWeb
        .getNewsByEncryptedUserId({ newsId: sharedNewsId, userid: userDetails.id, MarkAsNotificationRead: true })
        .then((response) => {
          const newsData = response.data.returnLst[0]

          if (response.data.returnLst) {
            const { newsForLock, newsForSubscription, id: newsId, showFullfreeNews } = newsData

            const isSubscriptionValid = newsForSubscription.includes(userDetails?.sub?.subType) && !newsForLock.includes(userDetails?.sub?.subType)

            if (isSubscriptionValid || showFullfreeNews) {
              const data = addBlankTargetToAnchors(newsData.newsHtml)
              const newData = [newsData]?.map((ele) => ({ ...ele, newsHtml: data }))

              const imageMatches = newsData?.nwsFeedMedia.filter((item) => item.typeMedia === 'image')

              if (newsData.newsFavourite) {
                setLike((prevLike) => !prevLike)
              }

              setCoffeeNewsData(newData)
              setImages(imageMatches)
              updateViewCount(newsData?.id)
            } else {
              history.replace(ROUTE_STRINGS.subscription)
              coffeewebSetLocal(coffeewebStorageKeys.subscriptionNewsId, newsId)
            }
            setIsError(false)
            setErrorMessage(false)
          } else {
            setIsError(true)
            setErrorMessage(true)
          }
        })
        .catch((error) => {
          handleApiResponse({ status: error?.response?.error, message: 'FAILED_TO_FETCH_NEWS', onRetry: getCoffeeNewsData })
          setIsError(true)
          setErrorMessage(true)
        })
      setLoading(false)
    } else {
      history.push(ROUTE_STRINGS.coffeeNewsFeeds)
    }
  }

  const getFavouriteNewsId = async (item) => {
    await apiAdapterCoffeeWeb
      .getFavoriteNewsById({ userId: userDetails.id, newsId: item.id })
      .then((response) => {
        removeFromFavorites(response.data.returnLst[0].favouriteNewsId)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateViewCount = async (newsId) => {
    await apiAdapterCoffeeWeb.updateNewsViewCountOnUser({ NewsId: newsId, userid: userDetails.id })
  }

  const addToFavorites = async (selectedNewsData) => {
    const postData = {
      id: 0,
      idUser: userDetails.id,
      idNews: selectedNewsData.id,
      isFavourite: true
    }

    await apiAdapterCoffeeWeb
      .postNewsUsersFavorite(postData)
      .then(() => {
        setLike(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const removeFromFavorites = async (favouriteNewsId) => {
    await apiAdapterCoffeeWeb
      .makeNewsUnfavorite(favouriteNewsId)
      .then(() => {
        setLike(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getNewsId = (newsid) => {
    setNewsId(newsid?.id)
    setShareButton(true)
    setOpenLogoutModal(true)

    if (shareButton === true) {
      setShareButton(false)
    }
  }

  const handleImageModal = (ele) => {
    images.filter((item) => ele.idNewsFeedMedia === item.idNewsFeedMedia)
    const indexValue = images.map((e) => e.idNewsFeedMedia).indexOf(ele.idNewsFeedMedia)

    setIsOpen(true)
    setImgIndex(indexValue)
  }

  const handleClickBackButton = () => {
    history.replace(ROUTE_STRINGS.coffeeNewsFeeds)
  }

  const handleViewDocument = (fileName) => {
    window.open(fileName.filePath, '_blank')
  }

  const handleScroll = () => {
    const scrollableDiv = document.getElementById('scrollable-div')
    const width = window.innerWidth

    if (width < 767) {
      const { scrollTop: currentScrollPos, scrollHeight: currentScrollPoss, clientHeight: currentScrollPosss } = scrollableDiv
      const minValue = currentScrollPoss - 7

      if (currentScrollPos > previousScrollTop) {
        setPreviousScrollTop(currentScrollPos)
        setScrollDirection('down')
        if (currentScrollPos + currentScrollPosss > minValue) {
          setScrollDirection('up')
        }
      } else if (currentScrollPos < previousScrollTop) {
        setPreviousScrollTop(currentScrollPos)
        setScrollDirection('up')
      }
      setPreviousScrollTop(currentScrollPos)
    }
  }

  return (
    <>
      {isError && (
        <>
          <div className="no-data-found-container">
            <div className="no_data_found_image">
              <img src={NoData} alt="NoDataFound" className="no_data_image" />
              {errorMessage && <div className="error-share-news">{t('FAILED_TO_FETCH_SHARED_NEWS')}</div>}
            </div>
          </div>
        </>
      )}
      {coffeeNewsData && (
        <div className="news_read_more_screen_main_div">
          <div>
            <div className="news_read_more ">
              <div className="main-card">
                {coffeeNewsData.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="image_div">
                      <div className="sticky_button">
                        <div className="back_button" onClick={handleClickBackButton}>
                          <div className="arrow_btn_container">
                            <ArrowBackIcon className="arrow_share_icons" />
                          </div>
                          <div className="go_back_text">{t('GO_BACK')}</div>
                        </div>
                        <div className="share_news_button" onClick={() => getNewsId(item)}>
                          <div className="share_icon_container">
                            <ShareIcon className="share_icon" />
                          </div>
                          <div className="share_text">{t('SHARE')}</div>
                        </div>
                      </div>

                      {item.nwsFeedMedia?.length > 0 ? (
                        <div className="img_container">
                          {item.nwsFeedMedia?.map((ele, index) => (
                            <div key={index}>{ele.typeMedia === 'image' ? <img alt={ele?.nameOfMedia} src={ele.pathOfMedia} className="assets" onClick={() => handleImageModal(ele, index)} /> : <video src={ele.pathOfMedia} className="assets" controls />}</div>
                          ))}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="content_div" id="scrollable-div" ref={scrollableDivRef} onScroll={handleScroll}>
                      <div className={`sticky_button scrollBackBtn${scrollDirection === 'down' ? ' slide-down' : ' down'}`}>
                        <div className="back_button" onClick={handleClickBackButton}>
                          <div className="arrow_btn_container">
                            <ArrowBackIcon className="arrow_share_icons" />
                          </div>
                          <div className="go_back_share_text">{t('GO_BACK')}</div>
                        </div>
                        <div className="share_news_button" onClick={() => getNewsId(item)}>
                          <div className="go_back_text">
                            <ShareIcon className="arrow_share_icons" />
                          </div>
                          <div className="go_back_share_text">{t('SHARE')}</div>
                        </div>
                      </div>
                      <div className="sticky_div">
                        {scrollDirection === 'up' && (
                          <div className="sub_sticky_div">
                            <div className="sticky_icons_container">
                              <CalendarMonthIcon className="social_feed_icons calendar_clock_icons" />
                              {item.customdate}
                            </div>
                            <div className="sticky_icons_container">
                              <ClockIcon fill={'#50b3f6'} className="social_feed_icons calendar_clock_icons" />
                              {item.customtime}
                            </div>
                          </div>
                        )}

                        <div className="banner_image">{ReactHtmlParser(item.subject)}</div>
                        <div className="sticky_box_bottom_part"></div>
                        {scrollDirection === 'up' && (
                          <div className="sub_sticky_div">
                            <div className="account_icon_container">
                              <AccountCircleIcon fill={'#50b3f6'} className="social_feed_icons account_icon" />
                              {item.author}
                            </div>
                            <div className="right_part_icon_container">
                              <div className="eyefilled_container">
                                <EyeFilled fill={'#50b3f6'} className="social_feed_icons eyefilled_icon" /> {item.views}
                              </div>
                              {like === false ? (
                                <div onClick={() => addToFavorites(item)} className="heart_icon">
                                  <UnFilledHeartIcon fill={'red'} className="heartLogoBlob" />
                                </div>
                              ) : (
                                <div onClick={() => removeFromFavorites(item)} className="heart_icon">
                                  <FilledHeartIcon />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        {item.nwsFeedMedia.length > 1 && (
                          <>
                            <div className="carousel_for_news hide_carousel">
                              <Carousel interval={appSettings?.imageScrollingInterval !== undefined && appSettings.imageScrollingInterval !== null ? appSettings.imageScrollingInterval * 1000 : 8000}>
                                {' '}
                                {item?.nwsFeedMedia?.map((ele, i) => (
                                  <div key={i} className="carousel_container">
                                    {ele.typeMedia === 'image' ? <img alt="media-img" className="carousel_img" src={ele.pathOfMedia} onClick={() => handleImageModal(ele, i)} /> : <video className="carousel_img" src={ele.pathOfMedia} controls />}
                                  </div>
                                ))}
                              </Carousel>
                            </div>
                          </>
                        )}
                      </div>
                      <div>
                        {item.nwsFeedMedia.length === 1 && (
                          <>
                            <div className="carousel_for_news">
                              <div>
                                {item?.nwsFeedMedia?.map((ele, i) => (
                                  <div key={i} className="carousel_container">
                                    {ele.typeMedia === 'image' ? <img alt={ele.nameOfMedia} className="carousel_img" src={ele.pathOfMedia} onClick={() => handleImageModal(ele, i)} /> : <video className="carousel_img" src={ele.pathOfMedia} controls />}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {item.fileName && (
                        <div className="view_document_and_file_container">
                          <button className="view_doc_btn" onClick={() => handleViewDocument(item)}>
                            {t('VIEW_DOCUMENT')}
                          </button>
                        </div>
                      )}

                      <div>
                        <div className="news_html">{ReactHtmlParser(item.newsHtml)}</div>
                      </div>
                      <div className="news_html_fullstop">.</div>
                    </div>
                  </React.Fragment>
                ))}

                {images.length === 1 ? <> {isOpen && <Lightbox mainSrc={images[imgIndex].pathOfMedia} onCloseRequest={() => setIsOpen(false)} onMovePrevRequest={() => setImgIndex((imgIndex + images.length - 1) % images.length)} onMoveNextRequest={() => setImgIndex((imgIndex + 1) % images.length)} />}</> : <> {isOpen && <Lightbox mainSrc={images[imgIndex].pathOfMedia} nextSrc={images[(imgIndex + 1) % images.length].pathOfMedia} prevSrc={images[(imgIndex + images.length - 1) % images.length].pathOfMedia} onCloseRequest={() => setIsOpen(false)} onMovePrevRequest={() => setImgIndex((imgIndex + images.length - 1) % images.length)} onMoveNextRequest={() => setImgIndex((imgIndex + 1) % images.length)} />}</>}

                <div className="modal_body_share_news">{openLogoutModal && <ShareNewsfeed newsData={coffeeNewsData[0]} closeModal={() => setOpenLogoutModal(false)} newsidforselectred={newsId} />}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Loader(ApiResponseHandler(OpenSharedNews))
