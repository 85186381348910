/* eslint-disable */
import './styles.scss'
import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Container, Navbar } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { coffeewebGetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'
import { Logo, devEnvLogo } from 'Assets/Icons'
import LockIcon from '@mui/icons-material/Lock'
import { useTranslation } from 'react-i18next'
import { RouteStrings } from 'Utils/Routes/RouteStrings'

const Menubar = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const appSettings = coffeewebGetLocal(coffeewebStorageKeys.appSettings)

  useEffect(() => {
    if (appSettings?.showMenuBold === true) {
      document.documentElement.style.setProperty('--setFontWeight', 'bold')
    }
  }, [])

  const checkForMenuLock = (ele) => {
    if (ele.menulock) {
      setTimeout(() => {
        history.push({ pathname: `${ROUTE_STRINGS.subscription}`, state: { subscriptionId: 2 } })
      }, 0)
    }
  }

  return (
    <>
      {userDetails.userRole !== 5 && (
        <div className="post_header">
          <Navbar className="navbar-top navbar-dark" id="navbar-main">
            <Container fluid>
              <Navbar aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <ul className="navbar-nav ">
                  {userDetails?.menus !== null &&
                    userDetails?.menus?.map((ele, index) => (
                      <div key={index}>
                        {ele.isWebDisplayEnable === true && ele.isLaunch === true && ele.menuUrl !== null && (
                          <li className="nav-item1 menucolor1" id="navitem" style={{ margin: '0px 1px' }}>
                            <NavLink activeClassName="active_nav-link1" exact to={!ele.menulock && ele.menuUrl} onClick={() => checkForMenuLock(ele)} className="nav-link1 nav-link-ltr">
                              <div style={{ padding: '0px 5px' }}>
                                {ele.menulock && <LockIcon sx={{ marginRight: '2px', marginTop: '-4px', width: '17px', height: '17px' }} />}
                                {ele.menuName}
                              </div>
                            </NavLink>
                          </li>
                        )}
                      </div>
                    ))}
                </ul>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      )}
      {/* TO-DO */}
      {/* <Modal show={subscriptionAlertPopup} size="" className="subsciption-alert-popup">
        <Modal.Header className="subsciption-alert-header">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>
        <Modal.Body>
          <div className="subsciption-alert-body">
            <div className="alert-title">{t('UPGRADE_YOUR_PLAN')}</div>
            <div className="alert-message">
              {t('TO_ACCESS')} {alertScreenName},
            </div>
            <div className="alert-message">{t('PLEASE_UPGRADE_YOUR_SUBSCRIPTION')}</div>
          </div>
        </Modal.Body>
        <Modal.Footer className="subsciption-alert-footer">
          <button className="soft-active-button" onClick={() => setSubscriptionAlertPopup(false)}>
            {t('NOT_NOW')}
          </button>
          <button className="hard-active-button" onClick={() => navigateToSubscription()}>
            {t('UPGRADE')}
          </button>
        </Modal.Footer>
      </Modal> */}
    </>
  )
}

export default Menubar
