/* eslint-disable */
// https://translate.google.co.in/?sl=auto&tl=hi&text=Password%20Is%20Required&op=translate
const EN_LANGUAGES = {
  // AUTH Pages
  OK: 'OK',
  NO: 'No',
  YES: 'Yes',
  CONTINUE: 'Continue',
  CANCEL: 'Cancel',
  ALREADY_LOGGED_IN: 'You already logged in from another device.',
  CONFIRM_LOGOUT: 'Do you want to logout?',
  DONT_HAVE_AN_ACCOUNT: 'Don’t have an account?',
  CREATE_ACCOUNT_LABEL: 'CREATE ACCOUNT',
  CREATE_ACCOUNT_BUTTON_LABEL: 'Create Account',
  SUPPORT_LABEL: 'Support?',
  FORGET_PASSWORD_LABEL: 'Forgot password?',
  LOGIN: 'LOGIN',
  LOGIN_BUTTON: 'Login',
  SINGLE_DEVICE_CONTINUE_LOGIN: 'You already logged in from another device, would you like to continue from this device?',
  SINGLE_DEVICE_LOGOUT_NOTE: 'Note: If you continue from this device you will be logged out from other device!',
  INVALID_CREDENTIALS: 'Invalid Credentials!',
  PHONE_IS_REQUIRED: 'Phone Number Is Required',
  PASSWORD_IS_REQUIRED: 'Password Is Required',
  PHONE: 'Phone',
  PASSWORD: 'Password',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  LOGIN_HERE: 'Login Here',
  CONTINUE_WITH_GOOGLE: 'Continue with Google',
  COMING_SOON: 'Coming Soon...',
  SIGN_UP_WITH_GOOGLE: 'Sign up with Google',
  PASSWORD_FOR_COFFEEWEB: 'Password for CoffeeWeb',
  VERIFY_CREATE_ACCOUNT: 'Verify & Create Account',
  CLOSE: 'Close',
  RESEND_OTP: 'Resend OTP',
  OTP_IS_REQUIRED: 'OTP Is Required',
  VERIFY_MOBILE_NUMBER: 'Verify Mobile Number',
  ENTER_THE_OTP_SENT_TO: 'Enter the OTP sent to ',
  PHONE_NUMBER_ALREADY_EXISTS: 'Phone Number Already Exists',
  INVALID_PHONE_NUMBER: 'Invalid Phone Number',
  SELECT_COUNTRY: 'Select Country',
  COUNTRY: 'Country',
  NAME: 'Name',
  PLEASE_ENTER_ONLY_LETTERS: 'Please Enter Only Letters',
  NAME_IS_REQUIRED: 'Name Is Required',
  BY_CLICKING_ON_CONTINUE_YOU_AGREE_OUR: 'By clicking on continue you agree our',
  TERMS_CONDITIONS: ' Terms & Conditions ',
  AND: 'and',
  PRIVACY_POLICY: ' Privacy Policy',
  VALID_PHONE_NUMBER_IS_REQUIRED: 'Phone Number Is Required ',
  SUBMIT: 'Submit',
  OTP: 'OTP',
  RESEND: 'Resend',
  CONFIRM_PASSWORD: 'Confirm Password',
  PASSWORD_CONFIRMATION_IS_REQUIRED: 'Password Confirmation Is Required',
  ENTER_OTP: 'Enter OTP',
  MOBILE_NUMBER_NOT_REGISTERED: 'Mobile Number Not Registered',
  ENTER_ANOTHER_NUMBER: 'Enter another Number!',
  ERROR: 'Error',
  SOMETHING_WENT_WRONG: 'Something Went Wrong',
  PLEASE_FILL_ALL_THE_DETAILS: 'Please fill all the details',
  FAILURE: 'Failure !',
  FAILED_TO_SEND_OTP: 'Failed To Send OTP',
  SUCCESS: 'Success !',
  NEW_PASSWORD_UPDATED_SUCCESSFULLY: 'New Password Updated Successfully!',
  FAILED_TO_UPDATE_NEW_PASSWORD: 'Failed to Update New Password!',
  PLEASE_TRY_AFTER_SOMETIME: 'Please try after sometime...',
  DOWNLOAD_ON_THE: 'DOWNLOAD ON THE',
  APP_STORE: 'App Store',
  GET_IT_ON: 'GET IT ON',
  GOOGLE_PLAY: 'Google Play',
  VISIT_OUR_WEBSITE: 'Visit our Website',
  DOWNLOAD_ON_APP_STORE: 'Download on App Store',
  DOWNLOAD_ON_PLAY_STORE: 'Download on Play Store',
  YOU_WILL_NOT_BE_ABLE_TO_REVERT_THIS: 'You will not be able to revert this!',
  LOGOUT: 'Logout',
  SETTINGS: 'Settings',
  LOGIN_AND_CREATE_ACCOUNT: 'Login/Create Account',
  COUNTRY_LIST_UNAVAILABLE: 'Country List Not Available',
  PHONE_CODE_UNAVAILABLE: 'Phone Code Not Available',
  OTP_MISMATCH: 'OTP Mismatch',
  OTP_EXPIRED: 'OTP Expired',
  FAILED_TO_VERIFY_USER: 'Failed to Verify User',
  FAILED_TO_UPDATE_PASSWORD: 'Failed to Update Password',
  FAILED_TO_VERIFY_OTP: 'Failed to Verify OTP',
  FAILED_TO_VALIDATE_PHONE_NO: 'Failed to Validate Phone Number',
  NO_DATA: 'No Data',
  SORRY_COULD_NOT_SEND_THE_OTP_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Send The OTP. Please Try Again Later.',
  FAILED_TO_VALID_PHONE_NUMBER: 'Failed To Validate Phone Number',
  COUNTRY_LIST_DATA_NOT_AVAILABLE: 'Country List Data Not Available',
  SORRY_COULD_NOT_CREATE_YOUR_ACCOUNT_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Create Account. Please Try Again Later.',
  SORRY_COULD_NOT_FETCH_PHONE_CODE_PLEASE_TRY_AGAIN_LATER: 'Sorry, Could Not Fetch Phone Code. Please Try Again Later.',
  ERROR_VERIFYING_PHONE_PLEASE_TRY_AGAIN: 'Failed to Verify Phone Number',
  COULD_NOT_BE_AUTHENTICATED_PLEASE_TRY_AGAIN_LATER: 'Sorry, User Details Could Not be Authenticated. Please Try Again Later',
  PHONE_CODE_NOT_AVAILABLE: 'Phone Code Not Available',

  PLEASE_ENTER_ONLY_NUMBERS: 'Please Enter Only Numbers',
  // SUPPORT PAGES
  SUPPORT: 'Support',
  ENTER_YOUR_NAME: 'Enter your name  ',
  PLEASE_ENTER_NAME: 'Please enter name ',
  ENTER_YOUR_EMAIL: 'Enter your email  ',
  PLEASE_FILL_IN_THE_EMAIL: 'Please enter email',
  ENTER_VALID_EMAIL: 'Enter valid email  ',
  ENTER_YOUR_PHONE: 'Please enter phone number',
  SUBJECT: 'Subject',
  PLEASE_ENTER_SUBJECT: 'Please enter subject',
  SUBJECT_ERROR_STATE: 'Subject should be at least 10 characters',
  MESSAGE: 'Message',
  PLEASE_FILL_THIS: 'Please enter message',
  MESSAGE_ERROR_STATE: 'Message Should be at least 100 characters',
  I_AGREE_TO_THE: 'I agree to the ',
  TERMS_AND_CONDITIONS: 'Terms and Conditions ',
  AGREE_TERMS_AND_CONDITIONS: 'Please agree to the terms and conditions',
  WE_HAVE_RECEIVED_YOUR_EMAIL: 'We have received your email, we will get back.',
  FAILED_TO_SEND_MESSAGE: 'Failed to Send Message!',
  SUBMIT_BUTTON: 'SUBMIT',
  FAILED_TO_GENERATE_AUTH_TOKEN: 'Failed To Generate Auth Token, Please Try Again Later.',

  // Subscription Page
  ORDER_SUMMARY: 'Order Summary',
  DISCOUNTED_AMOUNT: 'Discounted Amount',
  ACTUAL_AMOUNT: 'Actual Amount',
  CGST_AND_FEES: 'CGST and Fees',
  SGST_AND_FEES: 'SGST and Fees',
  IGST_AND_FEES: 'IGST and Fees',
  TOTAL: 'Total',
  PAYMENT_METHOD: 'Payment Method',
  NO_PAYMENT_METHOD_AVAILABLE: 'NO PAYMENT METHOD AVAILABLE',
  ROUND_OF_TO_NEARBY: 'Round of to nearby',
  BILLING_INFORMATION: 'Billing Information',
  NOTE_INVOICE: 'Note : If you want invoice copy kindly update your E-mail',
  SAVE_DETAILS: 'SAVE DETAILS',
  UPDATE_FAILED: 'Update Failed',
  FREE: 'Free',
  PER_MONTH: 'Per Month ',
  YOU_CAN_UPGRADE: 'You Can Upgrade !',
  NOT_NOW: 'Not now',
  UPGRADE: 'Upgrade',
  UPGRADE_YOUR_PLAN: 'Upgrade your plan',
  TO_ACCESS: 'To access',
  PLEASE_UPGRADE_YOUR_SUBSCRIPTION: 'Please upgrade your subscription',
  RENEW: 'RENEW',
  BUY_NOW: 'Buy now',
  WE_ARE_UNABLE_TO_PROCEED_WITH_THE_SELECTED_PLAN: 'We Are Unable To Proceed With The Selected Plan.',
  PLEASE_TRY_AGAIN_OR_CHOOSE_ANOTHER_ONE: 'Please Try Again Or Choose Another One.',

  // Profile Modal
  MY_INFO: 'My Info',
  PROFILE_SETTINGS: 'Profile Settings',
  CHANGE_PASSWORD: 'Change Password',
  ACCOUNT_ACTIVITY: 'Account Activity',
  FIRST_NAME: 'First Name',
  FIRST_NAME_IS_REQUIRED: 'FirstName is Required',
  LAST_NAME: 'Last Name',
  LAST_NAME_IS_REQUIRED: 'LastName is Required',
  EMAIL: 'Email',
  EMAIL_IS_REQUIRED: 'Email is Required',
  PHONE_NUMBER_IS_REQUIRED: 'Phone Number Is Required',
  COUNTRY_IS_REQUIRED: 'Country is Required',
  STATE_LIST: 'State List',
  SELECT: 'Select',
  SELECT_STATE: 'Select State',
  OCCUPATION: 'Occupation',
  OCCUPATION_IS_REQUIRED: 'Occupation is Required',
  DATE_OF_BIRTH: 'Date Of Birth',
  DATE_OF_BIRTH_IS_REQUIRED: 'Date Of Birth is Required',
  SAVE: 'Save',
  DATE_FORMAT: 'Date Format',
  DATE_FORMAT_IS_REQUIRED: 'dateFormat is required',
  TIME_FORMAT: 'Time Format',
  TIME_FORMAT_IS_REQUIRED: 'timeFormat is required',
  LANGUAGE: 'Language',
  LANDING_PAGE: 'Landing Page',
  LANDING_PAGE_IS_REQUIRED: 'landingPage is required',
  TIME_ZONE: 'Time Zone',
  TIME_ZONE_IS_REQUIRED: 'Time Zone is required',
  SHOW_WEB_NOTIFICATION: 'Show Web Notification',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_NEW_PASSWORD: 'Confirm New Password',
  DEACTIVATE_ACCOUNT: 'Deactivate Account',
  DEACTIVATE_YOUR_ACCOUNT: 'Temporarily deactivate your account. You can later activate it and can connect with CoffeeWeb.',
  YES_DEACTIVATE: ' Yes, Deactivate',
  DELETE_ACCOUNT: 'Delete Account',
  DELETE_YOUR_ACCOUNT: 'If you delete account, there is no getting it back. Make sure you want to do this.',
  YES_DELETE: 'Yes, Delete',
  ON: 'ON',
  OFF: 'OFF',
  PASSWORD_AND_CONFIRM_PASSWORD_SHOULD_BE_SAME: 'Password And Confirm Password Should Be Same',
  PASSWORD_CHANGED_SUCCESSFULLY: 'Password Changed Successfully!',
  UPDATED_SUCCESSFULLY: 'Updated successfully',
  EMAIL_ALREADY_EXISTS: 'Email already Exists',
  ENTER_ANOTHER_EMAIL: 'Enter another Email',
  DATA_UPDATED_SUCCESSFULLY: 'Data updated successfully',
  FAILED: 'Failed',
  ARE_YOU_SURE: 'Are you sure?',
  IF_YOU_DELETE_ACCOUNT: 'If you delete account, there is no getting it back.Make sure you want to do this.',
  ACCOUNT_DELETED_SUCCESSFULLY: 'Account Deleted Successfully',
  TRY_AGAIN_AFTER_SOMETIME: 'Try again after sometime!',
  ACCOUNT_DEACTIVATED: 'Account Deactivated',
  OTP_IS_EXPIRED: 'OTP  is Expired',
  CLICK_ON_RESEND_TO_GET_NEW_OTP: 'Click on Resend to get new OTP',
  REQUEST_FOR_OTP: 'Request For OTP',

  // Coffee News Feed
  COFFEE_NEWS_FEEDS: 'Coffee News Feeds',
  BUY_SUBSCRIPTION: 'Buy Subscription',
  DAILY_FEED: 'Daily Feed',
  MY_FAVOURITE: ' My Favourite',
  SEARCH: 'Search',
  SEARCH_FOR_NEWS: 'Search For News',
  READ_MORE: ' Read More',
  LOADING: 'Loading...',
  SELECT_LANGUAGE: 'Select Language',
  ARE_YOU_SURE_YOU_WANT_TO_DISLIKE_THIS_NEWS: 'Are you sure you want to dislike this news?',
  NO_DATA_FOUND: 'No Data Found',
  SEE_MORE: 'See More',
  NEWS_FEED_API_FAILED: 'Unable to fetch feed, Please try again later.',
  NEWS_FEED_NO_DATA: 'No news feed is available',
  NEWS_FEED_ALL_NEWS_FETCHED: 'You have caught up with all the news.',
  NO_RESULTS_FOR_SEARCH: 'No results for your Search',
  FAILED_TO_LOAD_NEWS: 'Sorry, Could Not Fetch News. Please Try Again Later',
  FAILED_TO_SHOW_SEARCH_RESULTS: 'Sorry, No Results For Your Search. Please Try Again Later',
  CURRENTLY_NEWS_FEED_NO_DATA: 'Currently No News Feed Available',
  FAILED_FETCHING_NEWS_SHARING_OPTIONS: 'Currently, No News Sharing Options Available. Please Try Again Later',

  // News Read More
  GO_BACK: 'Go Back',
  SHARE: 'Share',
  COFFEE_NEWS_DATA_DOWNLOAD: 'coffeeNewsData Download ',
  DOWNLOAD_DOCUMENT: 'Download Document',
  VIEW_DOCUMENT: 'View Document',
  DUMMY_NEWS_TITLE: 'Welcome to CoffeeWeb',
  DUMMY_NEWS_TIME: '00:00',
  DUMMY_NEWS_DATE: 'Jan 01 2024',
  DUMMY_NEWS_AUTHOR: 'CoffeeWeb',

  // Share News Feed
  COPY: 'Copy',
  WHATS_APP: 'WhatsApp',
  FACEBOOK: 'Facebook',
  LINKED_IN: 'LinkedIn',
  TWITTER: 'Twitter',
  TELEGRAM: 'Telegram',
  MAIL: 'Mail',

  // Guest News Feed
  CONTENT_FORBIDDEN_MESSAGE: 'To access this page, you need to Login First',
  CONTENT_FORBIDDEN_MESSAGE_CONFIRMATION: 'Do you wish to proceed?',

  // Coffee Quotes
  VOL: 'Volume',
  HIGH: 'High',
  LOW: 'Low',
  OPEN: 'Open',
  BID: 'Bid',
  ASK: 'Ask',
  OPEN_INT: 'Open Int',
  NET_HIGH: 'Net-High',
  NET_LOW: 'Net-Low',
  PRE_CLOSE: 'Pre Close',
  BSIZE: 'B-size',
  ASIZE: 'A-size',
  OPTION_EXPIRY: 'Option Expiry',
  FIRST_NOTICE_DAY: 'First Notice Day',
  LET_S_HELP: 'Let’s help',
  LAST: 'Last',
  CHG: 'Chg',
  PLEASE_FILL_DIVIDER_NAME: 'Please fill Divider Name',
  ROBUSTA_DIVIDER: 'Robusta Divider',
  ARABICA_DIVIDER: 'Arabica Divider',
  ROBUSTA: 'Robusta',
  ADD: 'Add',
  GRADE: 'Grade',
  NET_PRICE: 'Net Price',
  OUT_RATE: 'Out Rate',
  DIFFERENTIAL: 'Differential',
  TIME: 'Time',
  DATE: 'Date',
  TRENDING_NEWS: 'News',
  ROBUSTA_CONTRACTS: 'Robusta Contracts',
  ARABICA_CONTRACTS: 'Arabica Contracts',
  FX: 'FX',
  FX_PRE_DIS: 'FX-Pre/Dis',
  FX_NET: 'FX Net',
  GROSS_PRICE: 'Gross Price',
  PROFIT: 'Profit/Exp',
  DELETE: 'Delete',
  CONVTO: 'Conv To',
  ROBUSTA_WORKBOOK: 'Robusta Workbooks',
  ARABICA_WORKBOOK: 'Arabica Workbooks',
  CHANGES_UPDATED_SUCCESSFULLY: 'Changes Updated successfully!',
  YES_DELETE_IT: 'Yes, delete it!',
  WORKBOOK_DELETED_SUCCESSFULLY: 'Workbook Deleted Successfully!',
  YOUR_MARKET_WORKBOOK_HAS_BEEN_REMAINS_SAFE: 'Your MarketWorkbook has been remains safe!',
  DELETE_FAILURE: 'Delete Failure!',
  FAILED_TO_GET_DATA: 'Failed to get data!',
  SAVE_ALL_CHANGES: 'Save All Changes',
  SELECT_ALL_ROBUSTA_WORKBOOK: 'Select All Robusta Workbooks',
  SELECT_ALL_ARABICA_WORKBOOK: 'Select All Arabica Workbooks',
  DELETE_SELECTED_WORKBOOK_DATA: 'Delete Selected Workbook Data',
  TRENDING_NEWS_IS_NOT_AVAILABLE: 'Trending News Is Not Available.',
  FAILED_TO_FETCH_COFFEE_QUOTES_CURRENCY: 'Failed To Fetch Coffee Quotes Currency.',
  FAILED_TO_FETCH_GET_TERMINAL_DETAILS: 'Failed To Fetch Terminal Details.',
  FAILED_TO_ADD_WORKBOOK: 'Failed To Add Workbook Data.',
  FAILED_TO_DELETE_WORKBOOK_DATA: 'Failed To Delete Workbook Data.',
  FAILED_TO_FETCH_WORKBOOK_DATA: 'Failed To Fetch Workbook Data.',
  FAILED_TO_FETCH_TERMINAL_DATA: 'Could Not Fetch Terminal Data.',
  UNABLE_TO_CHANGE_TIME_FORMAT: 'Unable To Change Time Format. Please Try Again Later.',

  // Global Differentials
  GLOBAL_DIFFERENTIALS: 'Global Differentials',
  SELECT_ORIGIN: 'Select Origin',
  CROP_YEAR: 'Crop Year',
  UPDATED_ON: 'Updated On',
  PRICES_BASED_ON_FOB: 'Prices Based On FOB',
  QUALITY: 'Quality',
  DIFFERENTIALS: 'Differentials',
  QUALITY_STANDARDS: 'Quality Standards',
  REMARKS: 'Remarks',
  DELIVERY_PORT: 'Delivery Port',
  FOREX_LEVEL: 'Forex Level',
  SIZE: 'Size',
  DIFFERENTIALS_BASED_ON_FOB: 'Differentials based on FOB',
  SL: 'SL',
  SCREEN: 'Screen',
  BETWEEN: 'Between',
  MOISTURE: 'Moisture',
  SCREEN_RETENTION: 'Screen Retention',
  DEFECTS: 'Defects',
  NO_DATA_FOUND_FOR_THIS_COUNTRY: 'No Data Found For This Country!',
  RANGE_STARTS: 'Range Starts',
  RANGE_ENDS: 'Range Ends',
  QUALITY_NAME: 'Quality Name',
  HISTORICAL_DATA: 'Historical Data',
  TERMINAL: 'Terminal',
  TERMINAL_LEVEL: 'Terminal Level',
  TO_ACCESS_THIS_INFORMATION_FOR_GLOBAL_DIFFERENTIAL: 'To access this information, Please Create an account or Log in. This data is available exclusively for Platinum user',
  FAILED_TO_FETCH_GLOBAL_DIFFERENTIAL_HISTORY_DATA: 'Failed To Fetch Global Differential History Data.',
  FAILED_TO_FETCH_GLOBAL_DIFFERENTIAL_DATA: 'Failed To Fetch Global Differential Data.',

  // Notification
  NO_NOTIFICATIONS: 'No Notifications',
  NO_MORE_NOTIFICATIONS: 'No more notifications.',
  NOTIFICATIONS: 'Notifications',
  FAILED_TO_FETCH_NOTIFICATION: 'Could Not Load Notification.',
  FAILED_TO_FETCH_NEWS: 'Sorry, Could Not Fetch News. Please Try Again.',
  MARK_ALL_AS_READ: 'Mark All As Read',
  SEE_ALL_NOTIFICATIONS: 'See All Notifications',
  CONFORMATION_NOTIFICATION: 'Are you sure you want to mark all notification as read?',
  MARK_AS_READ: 'Mark as Read',
  UNSELECT_ALL: 'Unselect All',
  SELECT_ALL: 'Select All',

  // Error Boundary
  YOUR_SESSION_HAS_EXPIRED: 'Your Session Has Expired',
  PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING_THE_APP: 'Please Log In Again To Continue Using The App',
  RE_LOGIN: 'Re-Login',
  TRY_AGAIN: 'Try Again',

  // Footer
  COPYRIGHT: '© Copyright ',
  COFFEE_WEB: 'CoffeeWeb Technologies Private Limited.',
  ALL_RIGHTS_RESERVED: 'All Rights Reserved ',

  // About Us
  ABOUT_US: 'About Us',
  ABOUT_GLOBAL_COFFEE_INDUSTRY: 'This Platform provides end-to-end information about the Global Coffee Industry.',
  COFFEE: 'Coffee',
  ABOUT_COFFEE: 'The second most traded commodity globally involving nearly 195 countries!',
  COUNTRIES_ABOUT_COFFEE: `Coffee has a rich history spanning centuries, and it's enjoyed in 195 nations worldwide, these countries engage both in it's consumption and production.`,
  COUNTRIES_ARE_INVOLVED: 'these countries are involved as consumers and producers.',
  GLOBAL_MARKET: 'This thriving global market underscores the universal appeal and economic significance of coffee on a global scale.',
  COFFEEWEB: 'CoffeeWeb',
  ABOUT_COFFEE_SOURCE: 'Your Exclusive Source for Coffee Insights!',
  ABOUT_COFFEE_PREMIER_SOURCE: 'CoffeeWeb stands as your premier source for exclusive insights into the ever-evolving coffee industry.',
  ABOUT_COFFEE_WEB_SITE_FEATURE: 'From production and consumption data to coffee prices, weather updates, differentials, and freight rates,',
  ABOUT_COFFEE_WEB_SITE_INFORMATION: 'we offer a comprehensive platform to keep you informed. Stay ahead of the curve with the latest news and trends in coffee-producing and consuming nations, making CoffeeWeb your go-to hub for all things coffee.',
  BENEFICIAL_FOR_ALL_COFFEE_INDUSTRY_STAKEHOLDERS: 'Beneficial for All Coffee Industry Stakeholders!',
  BENEFICIAL_FOR_ALL_COFFEE: 'CoffeeWeb caters to the diverse needs of coffee industry professionals, including exporters, importers, roasters, processors, traders, growers, and anyone involved in the coffee sector. Our comprehensive platform provides valuable information and resources tailored to your specific interests and requirements.',
  VISION_AND_MISSION: 'Vision and Mission',
  VISION: 'Vision',
  MISSION: 'Mission',
  ABOUT_VISION: '"At CoffeeWeb, we envision a global community connected through the shared love for coffee. Our vision is to be the unparalleled source of knowledge, fostering transparency and collaboration in the dynamic and ever-evolving coffee industry."',
  ABOUT_MISSION: '"Our mission at CoffeeWeb is to provide end-to-end information, becoming the go-to platform for comprehensive insights into the world of coffee. We are dedicated to delivering accurate, timely, and valuable data, empowering stakeholders across the coffee supply chain to make informed decisions and drive positive change in the industry."',
  COFFEE_MAP: 'Coffee Map',
  ANALOG_CLOCK: 'Analog Clock',
  DIGITAL_CLOCK: 'Digital Clock',
  NO_RESULT_FOUND: ' No results found',
  SEARCH_COUNTRY: 'Search Country',
  SET_FAV: 'Set Favourite',
  AVAILABLE_COUNTRIES: 'Available Countries',
  SELECTED_COUNTRIES: 'Selected Countries',
  SEARCH_BY_NAME: 'search by country name',
  CLOCK_SETTINGS: 'Clock Settings',
  FAVORITE_CLOCK: 'Make Clock Favorite',
  SELECT_TYPE: 'Clock Display Type',
  ERROR_MESSAGE: 'Please Select At Least One Country',
  SELECT_TIME_FORMAT: 'Select Time Format',
  FETCHING: 'Fetching Data...',

  // Payment History
  PAYMENT_HISTORY: 'Payment History',
  PURCHASE_DATE: 'Purchase Date',
  SUBSCRIPTION_TYPE: 'Subscription Type',
  EXPIRY_DATE: 'Expiry Date',
  AMOUNT: 'Amount',
  DO_YOU_WANT_TO_CHANGE_THE_TIME_FORMAT_PERMANENTLY: 'Do you want to change the time format permanently?',
  // Payment Status
  PAYMENT_SUCCESS: ' Payment Done Successfully',
  PLEASE_TRY_AGAIN: 'Please Try Again!',
  PAYMENT_FAILED: ' Payment Failed',
  PAYMENT_CANCELED: 'Payment has been cancelled!',

  // Dialog Modal - Error Handler
  NO_INTERNET_CONNECTION: 'No Internet Connection',
  PLEASE_CHECK_YOUR_CONNECTION: 'Please Check Your Connection And Try Again',
  SESSION_EXPIRED: 'Your Session Has Expired',
  PLEASE_LOGIN: 'Please Login Again',
  SERVER_ERROR: 'Server Error',
  DATA_ALREADY_EXISTS: 'Data Already Exists',
  PLEASE_ENTER_UNIQUE_DATA: 'Please Enter Unique Data',
  FAILED_TO_UPDATE_DETAILS: 'Failed To Update Details',
  SORRY_FAILED_TO_CREATE_PAYMENT: 'Sorry Failed To Create Payment Request. Please Try Again Later',
  SORRY_FAILED_TO_UPDATE_PAYMENT: 'Sorry Failed To Update Payment Details',
  FAILED_TO_GET_GST_DETAILS: 'Failed To Fetch GST Details',
  SORRY_UNABLE_TO_LOGIN: 'Sorry, Unable To Login Please Try Again Later',
  FAILED_TO_GENERATE_AUTH_TOKEN_PLEASE_TRY_AGAIN: 'Failed To Generate AuthToken ,Please Try Again Later',
  FAILED_TO_FETCH_APP_SETTINGS: 'Failed To Fetch Application Settings',
  // News Feed Link
  FAILED_TO_FETCH_SHARED_NEWS: 'Failed To Fetch Shared News.',
  // Profile Modal
  FAIL_TO_UPDATE_PROFILE_SETTINGS: 'Failed To Update Profile Settings',
  FAILED_TO_UPDATE_EMAIL: 'Failed To Update Email',
  FAILED_TO_UPDATE_USER_DATA: 'Failed To Update User Data',
  UNABLE_TO_DELETE_ACCOUNT: 'Unable To Delete The Account',
  UNABLE_TO_DEACTIVATE_ACCOUNT: 'Unable To Deactivate The Account',
  UNABLE_FETCH_STATE_LIST: 'Unable To Fetch The State List',
  UNABLE_TO_FETCH_USER_PROFILE_DETAILS: 'Unable To Fetch User Profile Details',
  SORRY_UNABLE_TO_LOGOUT_THE_USER: 'Sorry, Unable To Logout Please Try Again Later',
  FAILED_TO_UPDATE_THE_PASSWORD: 'Failed To Change The Password'
}

export default EN_LANGUAGES
