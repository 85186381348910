import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'primereact/checkbox'
import { coffeewebStorageKeys, coffeewebGetLocal, coffeewebSetLocal } from 'Utils/LocalStorage_Handler'
import Loader from 'Components/LayoutComponents/Loader'
import { NoData } from 'Assets/Images'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import { db } from 'firebase.js'
import { doc, onSnapshot } from 'firebase/firestore'

const Notification = ({ setLoading, handleApiResponse }) => {
  const { t } = useTranslation()
  const params = new URLSearchParams()
  const history = useHistory()

  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const [userNotificationForUser, setUserNotificationForUser] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(20) // eslint-disable-line
  const [hasMore, setHasMore] = useState(true)
  const [notificationLoader, setNotificationLoader] = useState(false)
  const [noNotification, setNoNotification] = useState(false)
  const [showAllLoadedMessage, setShowAllLoadedMessage] = useState(false)
  const [showCheckBox, setShowCheckBox] = useState(false)
  const [selectedNotificationsForRead, setSelectedNotificationsForRead] = useState([])
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const [styleForNotificationHeader, setStyleForNotificationHeader] = useState(null)
  const [styleForNotificationContainer, setStyleForNotificationContainer] = useState(null)

  useEffect(() => {
    getNotificationForUser()
    init()
  }, [])

  useEffect(() => {
    if (showAllLoadedMessage) {
      const body = document.querySelector('body')

      body.style.overflow = 'auto'

      const timeoutId = setTimeout(() => {
        setShowAllLoadedMessage(false)
      }, 5000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [showAllLoadedMessage])

  useEffect(() => {
    const handleResize = () => {
      setStyleForNotificationHeader(getDynamicHeight(90, 150, 'top'))
      setStyleForNotificationContainer(getDynamicHeight(90, 140, 'marginTop'))
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [selectedNotificationsForRead])

  useEffect(() => {
    if (userNotificationForUser?.length > 0 && unreadNotificationCount === 0) {
      setUserNotificationForUser(userNotificationForUser?.map((ele) => ({ ...ele, isRead: true })))
    }
  }, [unreadNotificationCount])

  const init = () => {
    let mounted = false

    if (userDetails?.phone) {
      const firebaseRequest = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', userDetails?.phone), (doc) => {
        const firebaseResponse = doc.data()

        if (mounted) {
          if (firebaseResponse?.UserNotifications?.UnreadCount >= 0) {
            setUnreadNotificationCount(firebaseResponse?.UserNotifications?.UnreadCount)
          }
        } else {
          mounted = true
        }
      })

      return () => {
        firebaseRequest()
      }
    }
  }

  const getNotificationForUser = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getUsersNotificationForUser({ userId: userDetails?.id, pageNumber, pageSize })

      setUnreadNotificationCount(response?.data?.returnLst?.notificationCount?.unreadNotification)
      if (response?.data?.returnLst?.appNotificationDTO.length > 0) {
        setUserNotificationForUser(response?.data?.returnLst?.appNotificationDTO)
        setPageNumber(pageNumber + 1)
      } else {
        setHasMore(false)
        setNoNotification(true)
      }
    } catch (error) {
      setNoNotification(true)
      handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_NOTIFICATION', onRetry: getNotificationForUser })
    } finally {
      setLoading(false)
    }
  }

  const getNotificationForUserScroll = async () => {
    setNotificationLoader(true)

    try {
      const response = await apiAdapterCoffeeWeb.getUsersNotificationForUser({ userId: userDetails.id, pageNumber, pageSize })

      const { appNotificationDTO } = response.data.returnLst

      setUserNotificationForUser((userNotificationForUser) => [...userNotificationForUser, ...appNotificationDTO])
      setPageNumber(pageNumber + 1)
    } catch (error) {
      handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_NOTIFICATION', onRetry: getNotificationForUserScroll })
      setShowAllLoadedMessage(true)
      setHasMore(false)
    } finally {
      setLoading(false)
      setNotificationLoader(false)
    }
  }

  const markNewsAsRead = async () => {
    setLoading(true)

    const updateData = {
      userId: userDetails.id,
      newsId: checkAllAreChecked ? [] : selectedNotificationsForRead
    }
    const { newsId } = updateData

    try {
      const { data } = await apiAdapterCoffeeWeb.updateNotificationReadAll(updateData)

      coffeewebSetLocal(coffeewebStorageKeys.latestNewsReadNotification, selectedNotificationsForRead)

      if (data) {
        const updateNewsRead = userNotificationForUser.map((ele) => {
          const isSelected = selectedNotificationsForRead.includes(ele.sourceId)

          return {
            ...ele,
            isChecked: false,
            isRead: isSelected ? true : ele.isRead
          }
        })

        setUserNotificationForUser(updateNewsRead)
        setUnreadNotificationCount(newsId.length)
        setSelectedNotificationsForRead([])
        setShowCheckBox(false)
      }
    } catch (error) {
      console.log(error.response)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenNotification = (notification) => {
    const { sourceId } = notification

    params.set('newsId', sourceId)

    history.push({
      search: params.toString(),
      pathname: ROUTE_STRINGS.opennewsnotification
    })
    coffeewebSetLocal(coffeewebStorageKeys.latestNewsReadNotification, sourceId)
  }

  const showCheckBoxToSelect = () => {
    setShowCheckBox(true)
  }

  const selectedNotifications = ({ value, checked }) => {
    const selectedNotificationsByUser = userNotificationForUser.map((ele) => (ele.sourceId === value.sourceId ? { ...ele, isChecked: checked } : ele))
    const sleetedNotificationIds = selectedNotificationsByUser.filter((ele) => ele.isChecked).map((ele) => ele.sourceId)

    setUserNotificationForUser(selectedNotificationsByUser)
    setSelectedNotificationsForRead(sleetedNotificationIds)
  }

  const checkAllAreChecked = userNotificationForUser.filter((ele) => !ele.isRead).every((ele) => ele.isChecked)

  const selectAllNotification = () => {
    const selectedNotificationsByUser = userNotificationForUser.map((ele) => ({ ...ele, isChecked: !checkAllAreChecked }))
    const sleetedNotificationIds = selectedNotificationsByUser.filter((ele) => ele.isChecked).map((ele) => ele.sourceId)

    setUserNotificationForUser(selectedNotificationsByUser.map((ele) => ({ ...ele, isChecked: !checkAllAreChecked })))
    setSelectedNotificationsForRead(sleetedNotificationIds)
  }

  const getDynamicHeight = (initialHeight, height, property) => {
    const isMobile = window.innerWidth < 767

    if (selectedNotificationsForRead.length > 0) {
      return { [property]: isMobile ? height - 40 : height }
    }

    return { [property]: isMobile ? initialHeight - 40 : initialHeight }
  }

  return (
    <>
      <div className="notification_screen_container" style={styleForNotificationContainer}>
        {selectedNotificationsForRead.length > 0 && (
          <div className="mark-as-read-wrapper">
            <div className="mark-as-read-button" onClick={markNewsAsRead}>
              {t('MARK_ALL_AS_READ')}
            </div>
          </div>
        )}

        <div className="notification_screen">
          <div className="screen_heading" style={styleForNotificationHeader}>
            <div>{t('NOTIFICATIONS')}</div>
            <div className="notification-mark-as-read">
              {unreadNotificationCount > 0 && <div onClick={showCheckBoxToSelect}>{!showCheckBox && t('MARK_AS_READ')}</div>}
              {showCheckBox && <div onClick={selectAllNotification}> {checkAllAreChecked ? t('UNSELECT_ALL') : t('SELECT_ALL')}</div>}
            </div>
          </div>
          <InfiniteScroll dataLength={userNotificationForUser.length} next={userNotificationForUser.length > 15 && getNotificationForUserScroll} hasMore={hasMore} scrollThreshold="80px">
            <div className="notification_message_store">
              {userNotificationForUser ? (
                userNotificationForUser?.map((ele, index) => (
                  <div key={index}>
                    <div className="each_message_highlight" id={!ele.isRead && 'unread'}>
                      <div onClick={() => handleOpenNotification(ele)}>
                        <div className="message_title">{ReactHtmlParser(ele.notificationMessage)}</div>
                        <div className="message_arrived_time">{ele.timeOnly}</div>
                      </div>
                      <div>{showCheckBox && !ele.isRead && <Checkbox value={ele} onClick={(ele) => selectedNotifications(ele)} checked={ele?.isChecked} />}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="notification_not_found">
                  <img src={NoData} alt="NoDataFound" className="no_image_found" />
                </div>
              )}
            </div>
          </InfiniteScroll>
          <div>
            {notificationLoader && (
              <div className="custom-spinner-loader">
                <div className="loader-spinner"></div>
              </div>
            )}
          </div>
          {noNotification && !userNotificationForUser.length && <div className="no-notification">{t('NO_NOTIFICATIONS')}</div>}
        </div>
      </div>
      {showAllLoadedMessage && <div className="no-more-notifications-message">{t('NO_MORE_NOTIFICATIONS')}</div>}
    </>
  )
}

export default Loader(ApiResponseHandler(Notification))
