import './styles.scss'
import React, { useState, useEffect } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ReactCustomFlagSelect from 'react-custom-flag-select'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import Loader from 'Components/LayoutComponents/Loader'
import ApiResponseHandler from 'Components/ApiResponseHandler'

const CountrySelector = ({ setLoading, ...props }) => {
  const { handleSelectCountry, filterType, handleApiResponse } = props
  const [currentItem, setCurrentItem] = useState(null)
  const [countryList, setCountryList] = useState([])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setLoading(true)
    await apiAdapterCoffeeWeb
      .getAllCountries()

      .then((response) => {
        const modifiedList = response.data
          ?.filter((ele) => (filterType ? ele[filterType] : ele))

          .map((ele) => ({
            ...ele,
            flag: ele.flagsUrl,
            id: ele.id.toString(),
            name: ele.nicename
          }))

        if (filterType === 'isCoffeeProducingCountry') {
          modifiedList?.sort((a, b) => a.coffeeProducingOrderBy - b.coffeeProducingOrderBy)
        }

        setCountryList(modifiedList)
        const defaultCountry = modifiedList?.find((ele) => ele.isDefault)

        if (defaultCountry) {
          setCurrentItem(defaultCountry)
          handleSelectCountry(defaultCountry)
        } else {
          const defaultCountry = modifiedList?.find((ele) => ele.phonecode === '91')

          setCurrentItem(defaultCountry)
          handleSelectCountry(defaultCountry)
        }
      })
      .catch((error) => {
        handleApiResponse({ status: error?.response?.status, message: 'COUNTRY_LIST_DATA_NOT_AVAILABLE', onRetry: init })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSelect = (countryId) => {
    const selectedCountry = countryList.find((ele) => ele.id === countryId)

    setCurrentItem(selectedCountry)
    handleSelectCountry(selectedCountry)
  }

  return (
    <div className="country-selector">
      {currentItem && countryList.length && (
        <ReactCustomFlagSelect
          attributesWrapper={{ id: 'areaCodeWrapper', tabIndex: '1' }}
          attributesButton={{ id: 'areaCodeButton' }}
          attributesInput={{ id: 'areaCode', name: 'areaCode' }}
          value={currentItem?.id ? currentItem?.id : ''}
          disabled={false}
          showSearch={true}
          fields={['currencyName', 'currency', 'countryTag', 'nicename']}
          showArrow={true}
          animate={true}
          optionList={countryList}
          selectHtml={
            <div style={{ color: 'black', display: 'flex', justifyContent: 'start', width: '100%', gap: '8px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <img src={currentItem?.flag} style={{ borderRadius: '3px', border: '1px solid rgba(0, 17, 51, 0.15)' }} alt="" />
              </div>
              <div style={{ fontSize: '17px', display: 'flex', alignItems: 'center' }}>{`${currentItem?.nicename}`}</div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <ArrowDropDownIcon className="arrow-icon" />
              </div>
            </div>
          }
          customStyleContainer={{ border: 'none', fontSize: '12px' }}
          customStyleSelect={{ height: '40px', border: '1px solid #e4e4e4' }}
          customStyleOptionListItem={{ display: 'flex', justifyContent: 'space-around' }}
          customStyleOptionListContainer={{ maxHeight: '350px', overflow: 'auto' }}
          onChange={(item) => handleSelect(item)}
        />
      )}
    </div>
  )
}

export default Loader(ApiResponseHandler(CountrySelector))
