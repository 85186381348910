import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import moment from 'moment'
import LanguageIcon from '@mui/icons-material/Language'
import Header from 'Components/LayoutComponents/Header'
import { GooglePlay, AppleIcon } from 'Assets/Icons'
import Loader from 'Components/LayoutComponents/Loader'
import { STATIC_ROUTE_STRINGS, ROUTE_STRINGS } from 'Utils/Constants'
import { coffeewebStorageKeys, coffeewebGetLocal, coffeewebSetLocal } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import NewsReadMore from 'Screens/CoffeeNewsFeeds/Components/NewsReadMore'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import { NoData } from 'Assets/Images'

const NewsFeedLink = ({ setLoading, handleApiResponse }) => {
  const { id } = useParams()
  const history = useHistory()
  const params = new URLSearchParams()
  const location = useLocation()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const accessToken = coffeewebGetLocal(coffeewebStorageKeys.authToken)
  const { t } = useTranslation()

  const [coffeeNewsData, setCoffeeNewsData] = useState([])
  const [coffeeNewsDataForImage, setCoffeeNewsDataForImage] = useState([])
  const [hideNewsFeed, setHideNewsFeed] = useState(false)
  const [generatedToken, setGeneratedToken] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  useEffect(() => {
    generateAuthToken()
    if (!userDetails) coffeewebSetLocal(coffeewebStorageKeys.sharednewsid, null)
    redirectToMobileApp()
  }, [])

  useEffect(() => {
    if (!location.search) {
      setBodyOverflowToAuto()
    }
  }, [location.search])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setErrorMessage(false)
    }, 5000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [errorMessage])

  const setBodyOverflowToAuto = () => {
    const body = document.querySelector('body')

    body.style.overflow = 'auto'
  }

  const generateAuthToken = async () => {
    await apiAdapterCoffeeWeb
      .generateAuthToken()
      .then((response) => {
        setGeneratedToken(response.data.token)
        handleSharedNewsLink(response.data.token)
      })
      .catch((error) => {
        handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_GENERATE_AUTH_TOKEN', onRetry: generateAuthToken })
      })
  }

  function redirectToMobileApp() {
    const originalUrl = window.location.href
    const removedPart = '/share'

    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
      if (/SamsungBrowser/i.test(userAgent)) {
        window.location.href = originalUrl.replace(removedPart, '')
      } else if (/Opera/i.test(userAgent)) {
        window.location.href = originalUrl.replace(removedPart, '')
      } else {
        window.location.href = originalUrl.replace(removedPart, '')
      }
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = originalUrl.replace(removedPart, '')
    }
  }

  const handleSharedNewsLink = async (token) => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getNewsByEncryptedId(id, token)
      const newsData = response.data.returnLst

      if (newsData.length > 0) {
        setCoffeeNewsData(newsData)
        coffeewebSetLocal(coffeewebStorageKeys.newsForSubscription, newsData[0]?.newsForSubscription)
        setCoffeeNewsDataForImage(newsData[0].nwsFeedMedia[0]?.pathOfMedia || '')
        setIsError(false)
        setErrorMessage(false)
        setLoading(false)
      } else {
        setIsError(true)
        setErrorMessage(true)
      }
    } catch (error) {
      handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_NEWS', onRetry: () => handleSharedNewsLink(token) })
      setIsError(true)
      setErrorMessage(true)
    } finally {
      setLoading(false)
    }
  }

  const handleSharedNewsReadMore = ({ id: newsId, newsForSubscription }) => {
    const isFullFreeNews = coffeeNewsData.length > 0 && coffeeNewsData[0].showFullfreeNews

    if (isFullFreeNews && !userDetails && !accessToken) {
      params.set('share', true)
      params.set('newsId', newsId)
      history.push({ pathname: ROUTE_STRINGS.guestNewsFeed, search: params.toString() })
    } else if (userDetails && accessToken) {
      const newsSubscriptionType = newsForSubscription?.split(',')?.map((item) => item?.trim())
      const isUserSubscribed = newsSubscriptionType.includes(userDetails?.sub?.subType.toString())

      if (isUserSubscribed) {
        coffeewebSetLocal(coffeewebStorageKeys.sharednewsid, id)
        history.push(ROUTE_STRINGS.opensharednews)
      } else {
        history.push(ROUTE_STRINGS.subscription)
      }
    } else {
      setHideNewsFeed(true)
      coffeewebSetLocal(coffeewebStorageKeys.sharednewsid, id)
    }
  }

  return (
    <>
      <Header isLoggedIn={false} />
      <div>
        <div className="coffee_news_feeds_forShare content-wrapper restrictEverything">
          {isError && (
            <>
              <div className="no-data-found-container">
                <div className="no_data_found_image">
                  <img src={NoData} alt="NoDataFound" className="no_data_image" />
                  {errorMessage && <div className="error-share-news">{t('FAILED_TO_FETCH_SHARED_NEWS')}</div>}
                </div>
              </div>
            </>
          )}
          {!hideNewsFeed && !isError ? (
            <div className="feeds_container">
              <div className="news_feeds_card">
                <div className=" outer-card">
                  <div>
                    {coffeeNewsData.length > 0 && (
                      <div className="paginated">
                        {coffeeNewsData.map((item, index) => (
                          <>
                            <div className="design" key={index}>
                              <div className="subSecForImage">
                                <div className="forMobileDeviceIndetail" style={{ padding: '4px 9px 2px' }}>
                                  <div>
                                    <CalendarMonthIcon className="muiLogo calendar_icon" />
                                    {moment(item?.newsDate).format('D MMM YYYY')}
                                  </div>
                                  <div className="access_time_icon_div">
                                    <AccessTimeFilledIcon className="muiLogo" />
                                    {moment(item?.saApproveDtm).format(' hh:mm a')}
                                  </div>
                                </div>

                                {item.nwsFeedMedia.length > 0 ? (
                                  <div className="news_feeds_div">
                                    <img alt="" src={coffeeNewsDataForImage} className="news_feeds_img " />
                                  </div>
                                ) : (
                                  <div className="news_feeds_div">
                                    <img alt="" src={item?.coffeeNewsDefaultImage} className="news_feeds_img_logo" />
                                  </div>
                                )}
                                <div className="forMobileDeviceIndetail" style={{ padding: '4px 9px 2px' }}>
                                  <div className="account_circle_div">
                                    <AccountCircleIcon className="muiLogo" /> {item?.author}
                                  </div>

                                  <div className="visibility_icon_div">
                                    <div>
                                      <VisibilityIcon className="muiLogo" /> {item?.views}
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                              <div className="subSecForNews">
                                <div>
                                  <div className="d-flex justify-content-between ">
                                    <div className="newsHeading" onClick={() => handleSharedNewsReadMore(item)}>
                                      {ReactHtmlParser(item.subject)}
                                    </div>
                                  </div>

                                  <div className="newsHtml">{ReactHtmlParser(item.shareText)}</div>
                                </div>
                                <div className="newsDetails">
                                  <div className="details">
                                    <div>
                                      <CalendarMonthIcon className="muiLogo calendar_icon" />
                                      {moment(item?.newsDate).format('D MMM YYYY')}
                                    </div>
                                    <div>
                                      <AccessTimeFilledIcon className="muiLogo" />
                                      {moment(item?.saApproveDtm).format(' hh:mm a').toUpperCase()}
                                    </div>
                                    <div>
                                      <VisibilityIcon className="muiLogo" />
                                      {item?.views}
                                    </div>
                                    <div>
                                      <AccountCircleIcon className="muiLogo" />
                                      {item?.author?.substr(0, 15)}
                                      {item?.author?.length > 15 && <span>...</span>}
                                    </div>
                                  </div>
                                  <div className="read_more_button_div">
                                    <div>
                                      <button variant="contained" onClick={() => handleSharedNewsReadMore(item)} className="read_more_btn">
                                        {t('READ_MORE')}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="fullstop_style">.</div>
          )}
          {!hideNewsFeed && !isError && (
            <div className="social_btns">
              <div className="flex">
                <a className="app-btn blu flex vert" href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer">
                  <AppleIcon fill={'#fff'} className="social_imgs" />
                  <p>
                    {t('DOWNLOAD_ON_THE')}
                    <br /> <span className="big-txt">{t('APP_STORE')}</span>
                  </p>
                </a>
                <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer">
                  <img alt="" src={GooglePlay} className="social_imgs" />
                  <p>
                    {t('GET_IT_ON')} <br /> <span className="big-txt">{t('Google Play')}</span>
                  </p>
                </a>
              </div>
              <div className="flex">
                <a className="app-btn blu flex vert coffee_web_link_div" href={STATIC_ROUTE_STRINGS.home}>
                  <LanguageIcon className="coffee_news_link_language_icon" />
                  <p>
                    <span className="coffee_news_link">www.coffeeweb.com</span>
                  </p>
                </a>
              </div>
            </div>
          )}
          {hideNewsFeed && (
            <div className="social_btns">
              <div className="flex flex_div">
                <div className="social_medial_links_div">{t('DOWNLOAD_ON_PLAY_STORE')}</div>
                <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer">
                  <img alt="" src={GooglePlay} className="social_media_google_play_img" />
                  <p>
                    {t('GET_IT_ON')} <br /> <span className="big-txt">{t('GOOGLE_PLAY')}</span>
                  </p>
                </a>
                <div className="social_medial_links_div">{t('DOWNLOAD_ON_APP_STORE')}</div>
                <a className="app-btn blu flex vert" href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer">
                  <AppleIcon fill={'#fff'} className="social_media_apple_img" />
                  <p>
                    {t('DOWNLOAD_ON_THE')}
                    <br /> <span className="big-txt">{t('APP_STORE')}</span>
                  </p>
                </a>
              </div>
              <div className="website_link">
                <div className="social_medial_links_div">{t('VISIT_OUR_WEBSITE')}</div>
                <a className="app-btn blu flex vert coffee_web_link_div" href={STATIC_ROUTE_STRINGS.home}>
                  <LanguageIcon className="coffee_news_link_language_icon" />
                  <p>
                    <span className="coffee_news_link">www.coffeeweb.com</span>
                  </p>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {location.search && generatedToken && <NewsReadMore />}
    </>
  )
}

export default Loader(ApiResponseHandler(NewsFeedLink))
