import '../styles.scss'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useHistory } from 'react-router-dom'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useTranslation } from 'react-i18next'
import Loader from 'Components/LayoutComponents/Loader'
import { Logo, devEnvLogo } from 'Assets/Icons'
import { warningIcon } from 'Assets/Images'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { coffeewebStorageKeys, coffeewebGetLocal, coffeewebSetLocal } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ApiResponseHandler from 'Components/ApiResponseHandler'

const LogoutModal = ({ setLoading, handleApiResponse, ...props }) => {
  const history = useHistory()
  const { i18n, t } = useTranslation()

  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const singleDeviceId = coffeewebGetLocal(coffeewebStorageKeys.singleDeviceId)

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const postIsLoggedOut = async () => {
    await apiAdapterCoffeeWeb.updateIsLoggedIn({ userId: userDetails.id, p2: false, p3: 0, p4: '-' })
  }

  const handleLogout = async () => {
    setLoading(true)
    postIsLoggedOut()
    sessionStorage.setItem('logoutFlag', 'true')
    const postData = {
      idUser: userDetails.id,
      deviceId: singleDeviceId,
      fbToken: userDetails.fbToken
    }

    try {
      await apiAdapterCoffeeWeb.userLogout(postData)
      logoutUser()
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: handleLogout, message: t('SORRY_UNABLE_TO_LOGOUT_THE_USER') })
    } finally {
      setLoading(false)
    }
  }

  const logoutUser = () => {
    const languageCode = coffeewebGetLocal(coffeewebStorageKeys.appLanguage)
    const singleDeviceId = coffeewebGetLocal(coffeewebStorageKeys.singleDeviceId)
    const freeMenus = coffeewebGetLocal(coffeewebStorageKeys.freeMenus)

    localStorage.clear()
    sessionStorage.clear()
    coffeewebSetLocal(coffeewebStorageKeys.appLanguage, languageCode)
    coffeewebSetLocal(coffeewebStorageKeys.singleDeviceId, singleDeviceId)
    coffeewebSetLocal(coffeewebStorageKeys.freeMenus, freeMenus)
    coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, false)

    history.push({
      pathname: ROUTE_STRINGS.guestNewsFeed
    })
  }

  return (
    <div className="logoutPopup">
      {/* Logout Modal */}
      <Modal show={props.show} onHide={handleClose} backdrop="static" keyboard={false} className="logoutModal">
        <Modal.Header className="logoutModalHeader">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>
        <Modal.Body className="logoutModalBody">
          <div>
            <h4 className="logoutText">{t('CONFIRM_LOGOUT')}</h4>
            <div className="logoutModalButtons">
              <button className="logoutModalButtonNo" onClick={() => props.onHide()}>
                {t('NO')}
              </button>
              <button className="logoutModalButtonYes" onClick={() => handleLogout()}>
                {t('YES')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Logout Popup LoggedIn User */}
      <Modal show={props.showLogoutPopup} className="logoutPopupModal">
        <Modal.Header className="logoutModalHeader">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>
        <Modal.Body className="logoutPopupModalBody">
          <div className="logoutPopupModalBodyWrapper">
            <div className="logoutPopupModalBodyImageDiv">
              <img alt="warning Icon" src={warningIcon} />
            </div>
            <div className="countDownCircleContainer">
              <CountdownCircleTimer
                isPlaying
                duration={5}
                colors={['#2cba00', '#a3ff00', '#fff400', '#ffa700', '#ff0000']}
                colorsTime={[4, 3, 2, 1, 0]}
                size={50}
                strokeWidth={3}
                onComplete={() => ({
                  shouldRepeat: false,
                  delay: 0.01
                })}
              >
                {({ remainingTime }) => <h6 className="remaining-time-text">{remainingTime}</h6>}
              </CountdownCircleTimer>
            </div>
            <h5 className="logoutPopupText">{t('ALREADY_LOGGED_IN')}</h5>
          </div>
        </Modal.Body>
        <Modal.Footer className="logoutPopupModalFooter">
          <button onClick={() => logoutUser()}>{t('OK')}</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Loader(ApiResponseHandler(LogoutModal))
