import React, { createContext, useState } from 'react'

export const CoffeeWebContext = createContext('')
const CoffeeWebContextProvider = (props) => {
  const [SearchInput, setSearchInput] = useState('')
  const values = { SearchInput, setSearchInput }

  return <CoffeeWebContext.Provider value={values}>{props.children}</CoffeeWebContext.Provider>
}

export default CoffeeWebContextProvider
