import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppleIcon, GooglePlay } from 'Assets/Icons'
import AnalogClock from 'Components/Clock'
import Header from 'Components/LayoutComponents/Header'
import FreeMenubar from 'Components/LayoutComponents/FreeUserMenubar'
import FooterDashboard from 'Components/LayoutComponents/Footer/FooterDashboard'
import { coffeewebGetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'

const AboutUs = () => {
  const { t } = useTranslation()
  const isLoggedIn = coffeewebGetLocal(coffeewebStorageKeys.isLoggedIn)

  return (
    <>
      {window.location.pathname !== '/about-us/mobile' && (
        <>
          {!isLoggedIn && <Header />}
          {!isLoggedIn && <FreeMenubar />}
        </>
      )}

      <div className="about-us-container">
        <AnalogClock />

        <div className="coffee-web-about-drop-clock"></div>

        <div className="coffee-web-about-container">
          <div className="coffee-web-about-mobile-view">
            <div className="coffee-web-about-map-heading">
              <span>{t('COFFEE_MAP')}</span>
            </div>
            <div className="world-map-image">
              <img src="https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/coffee_map.png" alt="world-map" />
            </div>
          </div>
          <div className="coffee-web-about-content-wrapper">
            <p className="about-content-heading">{t('COFFEE')}</p>
            <p className="text-align-last-center">
              {t('ABOUT_COFFEE')}
              <br />
              <br />
              {t('COUNTRIES_ABOUT_COFFEE')}
              <br />
              <br /> {t('GLOBAL_MARKET')}
            </p>
            <br />
            <p className="about-content-heading">{t('COFFEEWEB')}</p>
            <p className="text-align-last-center">{t('ABOUT_GLOBAL_COFFEE_INDUSTRY')}</p>
            <p className="text-align-last-center">{t('ABOUT_COFFEE_SOURCE')}</p>
            <p className="text-align-last-center">
              {t('ABOUT_COFFEE_PREMIER_SOURCE')}
              <br /> {t('ABOUT_COFFEE_WEB_SITE_FEATURE')}
              <br />
              {t('ABOUT_COFFEE_WEB_SITE_INFORMATION')}
            </p>
            <br />
            <p className="about-content-heading">{t('BENEFICIAL_FOR_ALL_COFFEE_INDUSTRY_STAKEHOLDERS')}</p>
            <p className="text-align-last-center">{t('BENEFICIAL_FOR_ALL_COFFEE')}</p>
          </div>
          <div className="coffee-web-about-drop"></div>
          <div className="coffee-web-about-map-wrapper">
            <div className="coffee-web-about-map-heading">
              <span>{t('COFFEE_MAP')}</span>
            </div>
            <div className="world-map-image">
              <img src="https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/coffee_map.png" alt="world-map" />
            </div>
            {window.location.pathname !== '/about-us/mobile' && (
              <div className="coffee-web-app-links">
                <div className="apple-link">
                  <a href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer">
                    <AppleIcon fill={'#fff'} />
                    <p>
                      {t('DOWNLOAD_ON_THE')}
                      <br /> <span className="big-txt">{t('APP_STORE')}</span>
                    </p>
                  </a>
                </div>
                <div className="play-store-link">
                  <a href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer">
                    <img alt="play-store" src={GooglePlay} />
                    <p>
                      {t('GET_IT_ON')} <br /> <span className="big-txt">{t('GOOGLE_PLAY')}</span>
                    </p>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <FooterDashboard />
    </>
  )
}

export default AboutUs
