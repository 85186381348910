import React from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { popupIcon } from 'Assets/Icons'

const AccordianData = (props) => {
  const { item, keyID, handleSelectContract } = props
  const { i18n, t } = useTranslation()

  const lastChng = item?.idMarket === 1 ? item?.lastChng : parseFloat(item?.lastChng || 0).toFixed(2)
  const chng = item?.idMarket === 1 ? item?.chng : parseFloat(item?.chng || 0).toFixed(2)
  const percentageVal = parseFloat(item?.percentageVal || 0).toFixed(2)
  const highRate = item?.idMarket === 1 ? item?.highRate : parseFloat(item?.highRate || 0).toFixed(2)
  const netHigh = item?.idMarket === 1 ? item?.netHigh : parseFloat(item?.netHigh || 0).toFixed(2)
  const netLow = item?.idMarket === 1 ? item?.netLow : parseFloat(item?.netLow || 0).toFixed(2)
  const lowRate = item?.idMarket === 1 ? item?.lowRate : parseFloat(item?.lowRate || 0).toFixed(2)
  const openRate = item?.idMarket === 1 ? item?.openRate : parseFloat(item?.openRate || 0).toFixed(2)
  const prevRate = item?.idMarket === 1 ? item?.prevRate : parseFloat(item?.prevRate || 0).toFixed(2)
  const bid = item?.idMarket === 1 ? item?.bid : parseFloat(item?.bid || 0).toFixed(2)
  const ask = item?.idMarket === 1 ? item?.ask : parseFloat(item?.ask || 0).toFixed(2)

  const highlightColor = () => {
    if (item?.isHighlightRow === 1 || item?.isHighlightRow === 2) return 'highlight_color'

    return ''
  }

  const getClassForChng = () => {
    if (item?.chng >= 0) {
      return 'text_new_green'
    }
    if (item?.chng < 0) {
      return 'text_new_red'
    }

    return ''
  }

  const getNetHighColor = (netHigh) => {
    if (netHigh > 0) {
      return 'bold_and_green'
    }
    if (netHigh < 0) {
      return 'bold_and_red '
    }

    return ''
  }
  const getOptionExpiryColor = (optionExpiryStatus) => {
    if (optionExpiryStatus === 'boldAndRed') {
      return 'bold_and_red'
    }
    if (optionExpiryStatus === 'expired') {
      return 'expired'
    }

    return ''
  }

  const getFirstNoticeDayColor = (optionExpiryStatus) => {
    if (optionExpiryStatus === 'boldAndBlack') return 'bold_and_black'
    if (optionExpiryStatus === 'boldAndRed') return 'bold_and_red'
    if (optionExpiryStatus === 'expired') return 'expired'

    return ''
  }

  const selectContract = (item) => {
    handleSelectContract(item, true)
  }

  return (
    <React.Fragment key={keyID}>
      <tr
        onClick={() => {
          selectContract(item)
        }}
        key={keyID}
        id="coffeequotes_accordian_component"
        className={highlightColor()}
      >
        {/* Contract Name */}
        <td className="contract_names" data-title={`${item?.marketName ?? ''} Contracts`}>
          {item?.contractName}
        </td>

        {/* Last */}
        <td className={getClassForChng()} data-title={t('LAST')}>
          {lastChng}
        </td>

        {/* Chg */}
        <td className={getClassForChng()} data-title={t('CHG')}>
          {item?.chng > 0 && '+'}
          {chng}
        </td>

        {/* % */}
        <td className="percentage" data-title="%">
          {item?.percentageVal > 0 && '+'}
          {percentageVal}
        </td>

        {/* Volume */}
        <td className="volume" data-title={t('VOL')}>
          {item?.volume}
        </td>

        {/* High */}
        <td data-title={t('HIGH')}>{highRate}</td>

        {/* Net-High */}
        <td data-title="$-High">
          <span className={getNetHighColor(item?.netHigh)}>
            {item.netHigh > 0 && '+'}
            {netHigh}
          </span>
        </td>

        {/* Low */}
        <td data-title={t('LOW')}>{lowRate}</td>

        {/* Net-Low */}
        <td data-title="$-Low">
          <span className={getNetHighColor(item?.netLow)}>
            {item.netLow > 0 && '+'}
            {netLow}
          </span>
        </td>

        {/* Open */}
        <td data-title={t('OPEN')}>{openRate}</td>

        {/* Pre Close */}
        <td data-title={t('PRE_CLOSE')}>{prevRate}</td>

        {/* Open Int */}
        <td data-title={t('OPEN_INT')}>{item.openInterest}</td>

        {/* Bid */}
        <td data-title={t('BID')}>{bid}</td>

        {/* B-size */}
        <td data-title={t('BSIZE')}>{item?.bsize}</td>

        {/* Ask */}
        <td data-title={t('ASK')}>{ask}</td>

        {/* A-size */}
        <td data-title={t('LET_S_HELP')}>{item?.asize}</td>

        {/* Option Expiry */}
        <td data-title={t('OPTION_EXPIRY')} className={getOptionExpiryColor(item.optionExpiryStatus)}>
          <div>{item?.optionExpiryDateFormat}</div>
        </td>

        {/* First Notice Date */}
        <td data-title={t('FIRST_NOTICE_DAY')} className={getFirstNoticeDayColor(item.firstNoticeDateStatus)}>
          <div>{item?.firstNoticeDateFormat}</div>
        </td>
        <td>
          <img className="popup-icon" src={popupIcon} alt="popup-icon" />
        </td>
      </tr>
    </React.Fragment>
  )
}

export default AccordianData
