import './styles.scss'
import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { setDoc, doc, getDoc } from 'firebase/firestore'
import { EyeFilled, EyeFilledOff, User, KeyIcon, Logo, devEnvLogo } from 'Assets/Icons'
import Loader from 'Components/LayoutComponents/Loader'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { db } from 'firebase.js'
import { coffeewebStorageKeys, coffeewebGetLocal, coffeewebSetLocal } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import Validation from 'Utils/Validation'
import { Modal } from 'react-bootstrap'
import { warningIcon } from 'Assets/Images'
import { useTranslation } from 'react-i18next'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import ResetPasswordModal from '../ResetPasswordModal'

const LoginComponent = ({ setLoading, handleTabSelection, appSettingsState, generatedToken, singleDeviceId, setNewsDataOnClick, handleApiResponse }) => {
  const history = useHistory()
  const params = new URLSearchParams()
  const { i18n, t } = useTranslation()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const accessToken = coffeewebGetLocal(coffeewebStorageKeys.authToken)
  const sharedNewsId = history.location.state?.encryptedId || coffeewebGetLocal(coffeewebStorageKeys.sharednewsid)
  const newsForSubscription = history.location.state?.newsForSubscription || coffeewebGetLocal(coffeewebStorageKeys.newsForSubscription)
  const newsId = history.location.state?.newsId
  const forGuestUser = history.location.state?.forGuestUser

  const [resetModal, setResetModal] = useState(false)
  const [deviceChangeModal, setDeviceChangeModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const mobileNumberInputRef = useRef(null)
  const passwordInputRef = useRef(null)

  const [errorState, setErrorState] = useState({
    email: false,
    password: false,
    phoneNo: false
  })

  const [userInputValues, setUserInputValue] = useState({
    email: '',
    password: '',
    phoneNo: ''
  })

  if (userDetails && accessToken) {
    coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, true)
    if (userDetails?.userLandingPageUrl) {
      history.push(`${ROUTE_STRINGS.dashboard}/${userDetails?.userLandingPageUrl}`)
    } else {
      history.push(ROUTE_STRINGS.coffeeNewsFeeds)
    }
  }

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.code === 'Enter' && !Validation.isInputFieldEmpty(mobileNumberInputRef.current.value)) {
        passwordInputRef.current.focus()
      }
    }

    const mobileNumberInput = mobileNumberInputRef.current

    mobileNumberInput.addEventListener('keyup', handleKeyUp)
    setNewsDataOnClick((previousState) => ({ ...previousState, encryptedId: sharedNewsId, newsForSubscription, newsId, forGuestUser }))

    return () => {
      mobileNumberInput.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const getPostData = (username, password, singleDeviceId, byPassOldDevice) => {
    const postData = {
      username,
      password,
      includeMenu: true,
      isMobile: false,
      deviceId: singleDeviceId,
      fbToken: singleDeviceId,
      idAndroidDevice: false,
      byPassOldDevice
    }

    return postData
  }

  const postLoginData = async () => {
    setLoading(true)
    const { phoneNo: username, password } = userInputValues
    const byPassOldDevice = false
    const userDataPost = getPostData(username, password, singleDeviceId, byPassOldDevice)

    try {
      const {
        data,
        data: { isError, userType }
      } = await apiAdapterCoffeeWeb.authenticateWithFB(userDataPost)

      if (!isError) {
        if (userType !== 0) {
          firebaseValidationForUser(data)
        } else {
          setInvalidCredentials(true)
        }
      } else {
        setInvalidCredentials(true)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: () => postLoginData(), message: t('SORRY_UNABLE_TO_LOGIN') })
    } finally {
      setLoading(false)
    }
  }

  const firebaseValidationForUser = async (res) => {
    setLoading(true)
    const { userFbregistrationId: userChatId, firstName: displayNameForChat, email: emailForChat, isLoggedIn } = res

    try {
      const data = await getDoc(doc(db, 'users', userChatId))

      if (!data.exists()) {
        await setDoc(doc(db, 'users', userChatId), {
          uid: userChatId,
          displayNameForChat,
          emailForChat
        })
        await setDoc(doc(db, 'userChats', userChatId), {})
        proceedToDashboard(res)
      } else if (isLoggedIn) {
        setLoading(false)
        setDeviceChangeModal(true)
      } else {
        proceedToDashboard(res)
      }
    } catch (err) {
      console.log('firebaseValidationForUser', err)
    }
  }

  const proceedToDashboard = (res) => {
    const {
      token,
      userLandingPageUrl,
      sub: { subType }
    } = res

    const newsSubscriptionType = newsForSubscription?.split(',')?.map((item) => item?.trim())
    const isUserSubscribed = newsSubscriptionType?.includes(subType.toString())

    coffeewebSetLocal(coffeewebStorageKeys.userDetails, res)
    coffeewebSetLocal(coffeewebStorageKeys.authToken, token)
    coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, true)
    coffeewebSetLocal(coffeewebStorageKeys.languageIdForNews, res?.languageId)

    if (newsId && forGuestUser) {
      if (isUserSubscribed) {
        params.set('newsId', newsId)
        history.push({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
      } else {
        history.push({ pathname: ROUTE_STRINGS.subscription, state: { newsId } })
        coffeewebSetLocal(coffeewebStorageKeys.subscriptionNewsId, newsId)
      }
    } else if (sharedNewsId) {
      if (isUserSubscribed) {
        history.push({ pathname: ROUTE_STRINGS.opensharednews, state: { encryptedId: sharedNewsId } })
      } else {
        history.push(ROUTE_STRINGS.subscription)
      }
    } else if (!userLandingPageUrl) {
      history.push(ROUTE_STRINGS.coffeenewsfeeds)
    } else {
      history.push(`${ROUTE_STRINGS.dashboard}/${userLandingPageUrl}`)
    }
    setLoading(false)
  }

  const handleSubmit = () => {
    const { phoneNo, password } = userInputValues

    if (Validation.checkAllInputFieldsNotEmpty(phoneNo, password)) {
      postLoginData()
    } else {
      if (Validation.isInputFieldEmpty(phoneNo)) {
        setErrorState((previousState) => ({ ...previousState, phoneNo: true }))
      }
      if (Validation.isInputFieldEmpty(password)) {
        setErrorState((previousState) => ({ ...previousState, password: true }))
      }
      if (Validation.checkEmptyField(password, phoneNo)) {
        setErrorState((previousState) => ({ ...previousState, phoneNo: true, password: true }))
      }
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    setInvalidCredentials(false)

    if (name === 'phoneNo') {
      if (Validation.isInputFieldEmpty(value) || value === '') {
        setErrorState((previousState) => ({ ...previousState, phoneNo: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, phoneNo: false }))
      }
      if (Validation.phoneNumberValidation(value) || value === '') {
        setUserInputValue((previousValue) => ({ ...previousValue, phoneNo: value }))
        setErrorState((previousState) => ({ ...previousState, phoneInvalidError: false }))
      } else {
        setErrorState((previousState) => ({ ...previousState, phoneInvalidError: true }))
      }
    } else if (name === 'password') {
      setUserInputValue((previousValues) => ({ ...previousValues, password: value }))

      if (Validation.isInputFieldEmpty(value) || value === '') {
        setErrorState((previousState) => ({ ...previousState, password: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, password: false }))
      }
    }
  }

  const handleBlur = ({ target: { name, value } }) => {
    if (name === 'password' || name === 'phoneNo') {
      const val = value.toString().toLowerCase()

      if (Validation.isInputFieldEmpty(val)) {
        setErrorState((previousState) => ({ ...previousState, [name]: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, [name]: false }))
      }
    }
  }

  const handleDeviceChangeConfirmation = async () => {
    setLoading(true)
    const { phoneNo: username, password } = userInputValues
    const byPassOldDevice = true
    const userDataPost = getPostData(username, password, singleDeviceId, byPassOldDevice)

    try {
      const response = await apiAdapterCoffeeWeb.authenticateWithFB(userDataPost)
      const { status, data } = response

      if (status === 200) {
        proceedToDashboard(data)
      }
    } catch (error) {
      setLoading(false)
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: () => postLoginData(), message: t('SORRY_UNABLE_TO_LOGIN') })
    }
  }

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleForgotPasswordClick = () => {
    setErrorState((previousState) => ({ ...previousState, password: false, phoneNo: false }))
    setResetModal(true)
  }

  return (
    <div className="login-tab-component">
      <div className="tab-content login_card_body">
        <div className="tab-pane active firsttab" id="signin" role="tabpanel">
          {invalidCredentials && <p className="invalid_credentials">{t('INVALID_CREDENTIALS')}</p>}
          <div className="frmlogn">
            <div className="email_input">
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text coffe-hard thard">
                    <User fill={'#fff'} />
                  </span>
                </div>
                <input id="mobilenumber" autoComplete="tel" type="tel" name="phoneNo" value={userInputValues.phoneNo} onChange={handleChange} onBlur={handleBlur} className="form-control login_form-control jumpInputs" placeholder={t('PHONE')} ref={mobileNumberInputRef} />
              </div>
              {errorState.phoneNo && !errorState.phoneInvalidError && <p className="errorMessage mt-1 mb-1">{t('PHONE_IS_REQUIRED')}</p>}
              {errorState.phoneInvalidError && <p className="errorMessage mt-1 mb-1">{t('PLEASE_ENTER_ONLY_NUMBERS')}</p>}
            </div>
            <div className="input-group form-group mobile_block">
              <div className="flex password_block">
                <div className="input-group-prepend">
                  <span className="input-group-text coffe-hard ">
                    <KeyIcon stroke={'#fff'} fill={'#fff'} />
                  </span>
                </div>
                <div className="pass-wrapper">
                  <input id="passwordInput" autoComplete="current-password" type={showPassword ? 'text' : 'password'} name="password" value={userInputValues.password} onBlur={handleBlur} onChange={handleChange} className="form-control login_form-control pass_flow jumpInputs" placeholder={t('PASSWORD')} ref={passwordInputRef} onKeyDown={(e) => e.code === 'Enter' && handleSubmit()} />
                  <span onClick={togglePassword} className="eye_icon">
                    {showPassword ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" />}
                  </span>
                </div>
              </div>
              {errorState.password && <p className="errorMessage mt-1 mb-1">{t('PASSWORD_IS_REQUIRED')}</p>}
            </div>
            <div>
              <button onClick={handleSubmit} className="btn login_btn coffe-hard">
                {t('LOGIN_BUTTON')}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="forgot_pass">
            <div className="forgot_pass_text" onClick={handleForgotPasswordClick}>
              {t('FORGET_PASSWORD_LABEL')}
            </div>
            <div>{resetModal && <ResetPasswordModal generatedToken={generatedToken} handleCloseModal={() => setResetModal(false)} />}</div>
          </div>
          {appSettingsState.showSignup === true && (
            <div className="d-flex justify-content-center login_links align-items-center">
              <span lang="en-us">{t('DONT_HAVE_AN_ACCOUNT')}</span>
              <button className="btn btn-sm coffe-hard sign_up_btn" onClick={() => handleTabSelection('create_account')}>
                {t('CREATE_ACCOUNT_BUTTON_LABEL')}
              </button>
            </div>
          )}
          <div className="loginSupportButton">
            <NavLink exact to={ROUTE_STRINGS.supportRequest} className="signup-link">
              {t('SUPPORT_LABEL')}
            </NavLink>
          </div>
        </div>
        <Modal show={deviceChangeModal}>
          <Modal.Header className="login-confirmation-modal">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>

          <Modal.Body>
            <div
              style={{
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
              }}
            >
              <div>
                <img alt="warning icon" style={{ width: '100px', margin: '10px' }} src={warningIcon} className="warning-icon" />
              </div>
              <div>
                <h6 style={{ color: 'black' }}>{t('SINGLE_DEVICE_CONTINUE_LOGIN')}</h6>
              </div>
              <div>
                <h6 style={{ color: 'red' }}>{t('SINGLE_DEVICE_LOGOUT_NOTE')}</h6>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', gap: '20px' }}>
              <button id="noBtn" onClick={() => setDeviceChangeModal(false)} style={{ width: '100px', height: '36px', backgroundColor: 'red' }}>
                {t('CANCEL')}
              </button>
              <button id="yesBtn" onClick={handleDeviceChangeConfirmation} style={{ width: '100px', height: '36px', backgroundColor: '#50b3f6' }}>
                {t('CONTINUE')}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default Loader(ApiResponseHandler(LoginComponent))
