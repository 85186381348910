const REG_EX = {
  ONLY_NUMBER_REG_EX: /^[0-9]/,
  ALPHANUMERIC_WITHOUT_SPACES: /^[a-zA-Z0-9\-_]{0,40}$/,
  NAME_REG_EX: /^[a-zA-Z ]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$/,
  PASSWORD_REG_EX: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
  PHONE_REG_EX: /^[1-9][0-9]*$/,
  EMAIL_REG_EX: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  LEADING_SPACES: /^\s*/
}

export default REG_EX
