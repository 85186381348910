import './styles.scss'
import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import Snackbar from '@mui/material/Snackbar'
import { useTranslation } from 'react-i18next'
import Loader from 'Components/LayoutComponents/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { popupIcon } from 'Assets/Icons'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import AccordionPopUp from '../AccordionPopUp'

const AccordianForMobile = ({ setLoading, data, GDItem, handleApiResponse }) => {
  const { t } = useTranslation()

  const { globalDifferentialData, qualityGroupName, terminalLevel } = data[0]

  const [isDialogModalOpen, setIsDialogModalOpen] = useState(false)
  const [globalDifferentialHistoryData, setGlobalDifferentialHistoryData] = useState([])
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarPosition, setSnackbarPosition] = React.useState({
    vertical: 'bottom',
    horizontal: 'right'
  })
  const { vertical, horizontal } = snackbarPosition

  const handleShowAccordionPopup = (idCountry, qualityName, countryName) => {
    getGlobalDifferentialHistoryDataByCOuntryId({ idCountry, qualityName, countryName })
  }

  const getGlobalDifferentialHistoryDataByCOuntryId = async ({ idCountry, qualityName, countryName }) => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getGlobalDifferentialHistoryData(idCountry, qualityName, countryName)
      const { data } = response

      if (data?.returnLst?.length) {
        setGlobalDifferentialHistoryData(data?.returnLst)
        setIsDialogModalOpen(true)
      } else {
        setGlobalDifferentialHistoryData([])
        setShowSnackbar(true)
        setTimeout(() => {
          setShowSnackbar(false)
        }, 4000)
      }
    } catch (error) {
      setGlobalDifferentialHistoryData([])
      handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_GLOBAL_DIFFERENTIAL_HISTORY_DATA', onRetry: () => getGlobalDifferentialHistoryDataByCOuntryId({ idCountry, qualityName }) })
    } finally {
      setLoading(false)
    }
  }

  const currencyStyle = (value) => {
    if (value) {
      const convertedToNumber = parseFloat(value?.replace(/[$¢]/g, ''))

      if (convertedToNumber >= 0) {
        return { color: 'green' }
      }

      return { color: 'red' }
    }

    return {}
  }

  return (
    <div className="global-differential-mobile-view">
      <Snackbar open={showSnackbar} anchorOrigin={{ vertical, horizontal }} message={t('FAILED_TO_GET_DATA')} />

      <Accordion defaultActiveKey="0" className="accordian-container">
        <div className="accordian_main_heading">
          <div>{qualityGroupName}</div>
          <div>
            {t('DIFFERENTIALS')} {t('BETWEEN')}
          </div>
        </div>
        {globalDifferentialData?.length > 0 &&
          globalDifferentialData?.map(({ qualityName, rangeStart, rangeEnd, qualityCode, terminal, remark, idCountry }, index) => (
            <React.Fragment key={index}>
              <div>
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header className="accordian_sub_heading">
                    <div className="tr_sub_heading">
                      <div className="first_row">{qualityName}</div>
                      <div className="differential-column">
                        <td className="range-starts" style={currencyStyle(rangeStart)} colSpan={3}>
                          {rangeStart}
                        </td>
                        <td className="range-ends" style={currencyStyle(rangeEnd)} colSpan={3}>
                          {rangeEnd}
                        </td>
                      </div>
                      <div>
                        <UnfoldMoreIcon sx={{ width: '17px' }} />
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="accordian_main_body">
                      <div className="accodian_sub_div">
                        <div>
                          <div className="each-data">
                            <div className="row-heading">{t('SCREEN')} :</div>
                            <div>{qualityCode}</div>
                          </div>
                          <div className="each-data">
                            <div className="row-heading"> {t('TERMINAL')} :&nbsp;</div>
                            <div>{terminal}</div>
                          </div>
                          <div className="each-data">
                            <div className="row-heading"> {t('TERMINAL_LEVEL')} :&nbsp;</div>
                            <div>{terminalLevel}</div>
                          </div>
                          <div className="each-data">
                            <div className="row-heading"> {t('DELIVERY_PORT')} :</div>
                            <div>{remark}</div>
                          </div>
                        </div>
                      </div>
                      <div className="each-data historical-data data_for_date" onClick={() => handleShowAccordionPopup(idCountry, qualityName, GDItem?.country)}>
                        {t('HISTORICAL_DATA')} <img className="popup-icon" src={popupIcon} alt="popup-icon" />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            </React.Fragment>
          ))}
      </Accordion>
      <AccordionPopUp isDialogModalOpen={isDialogModalOpen} setIsDialogModalOpen={setIsDialogModalOpen} globalDifferentialHistoryData={globalDifferentialHistoryData} />
    </div>
  )
}

export default Loader(ApiResponseHandler(AccordianForMobile))
