/* eslint-disable */
import './style.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import { v4 as uuid } from 'uuid'
import Marquee from 'react-fast-marquee'

import Modal from 'react-bootstrap/Modal'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import ReactHtmlParser from 'react-html-parser'
import { Table } from 'react-bootstrap'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { useTranslation } from 'react-i18next'

import { coffeewebStorageKeys, coffeewebGetLocal, updateUserDetails, coffeewebSetLocal } from 'Utils/LocalStorage_Handler'
import Loader from 'Components/LayoutComponents/Loader'
import TimerPicker from 'Components/TimerPicker'
import { RouteStrings } from 'Utils/Routes/RouteStrings'
import { onSnapshot, doc } from 'firebase/firestore'
import { db } from 'firebase.js'

import { ReactComponent as SaveIcon } from 'Assets/Icons/Save_Icon.svg'
import { ReactComponent as DeleteIcon } from 'Assets/Icons/trash.svg'
import { Logo, devEnvLogo } from 'Assets/Icons'

import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'

import AccordianData from './Components/AccordianData'
import AccordianForMobile from './Components/AccordianForMobile'
import AccordianPopUp from './Components/AccordianPopup'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import Header from 'Components/LayoutComponents/Header'
import FreeMenubar from 'Components/LayoutComponents/FreeUserMenubar'
import { robustaDummyData, arabicaDummyData } from 'Utils/coffeeQuotesDummyData'

const CoffeQuotes = ({ setLoading, handleApiResponse }) => {
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const { id: userId, countryId } = userDetails ?? {}
  const { t } = useTranslation()
  const date = new Date()
  const history = useHistory()
  const params = new URLSearchParams()

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const handleWorkbookDropDown = [
    { value: 1, contractType: 'RW' },
    { value: 2, contractType: 'AW' },
    { value: 3, contractType: 'RD' },
    { value: 4, contractType: 'AD' }
  ]
  const currentTime = Date.now()
  const convertToValue = 22.0462
  let initialLoader
  let lastUpdatedTimingForCoffeequotes
  let lastUpdatedTimingForCurrency
  let robustaGlobalArray = []
  let arabicaGlobalArray = []

  const [robustaData, setRobustaData] = useState([])
  const [arabicaData, setArabicaData] = useState([])
  const [marquee, setMarquee] = useState([])
  const [workbookRobustaData, setWorkbookRobustaData] = useState([])
  const [workbookArabicaData, setWorkbookArabicaData] = useState([])
  const [searchContractList, setSearchContractList] = useState([])
  const [searchContractListForRobusta, setSearchContractListForRobusta] = useState([])
  const [searchContractListForArabica, setSearchContractListForArabica] = useState([])
  const [selectedContract, setSelectedContract] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [draggableRow, setDraggableRow] = useState(null)
  const [dividerWarningPopup, setDividerWarningPopup] = useState(false)
  const [fxCurrencyList, setFXCurrencyList] = useState([])
  const [selectedFXCurrencyIndexForRobusta, setSelectedFXCurrencyIndexForRobusta] = useState(0)
  const [selectedFXCurrencyIndexForArabica, setSelectedFXCurrencyIndexForArabica] = useState(0)
  const [addContractStateForRobusta, setAddContractStateForRobusta] = useState(null)
  const [addContractStateForArabica, setAddContractStateForArabica] = useState(null)
  const [accordianPopupData, setAccordianPopupData] = useState(null)
  const [accordianPopupListData, setAccordianPopupListData] = useState([])
  const [terminalPopUp, setShowTerminalPopup] = useState(false)
  const [isTrendingNewsExist, setIsTrendingNewsExist] = useState(true)
  const [contractSearchData, setcontractSearchData] = useState({
    idFrequentContractSearch: 0,
    idUser: userId,
    idCountry: countryId,
    codeSelected: 1,
    idMarket: 1,
    searchDtm: new Date().toJSON(),
    keySearched: '',
    value: 1
  })

  const [snackbarPosition, setSnackbarPosition] = useState({
    showSnackbar: false,
    vertical: 'bottom',
    horizontal: 'right'
  })

  const { vertical, horizontal, showSnackbar } = snackbarPosition

  const showDeleteButtonForRobusta = workbookRobustaData?.some((ele) => ele?.isChecked)
  const showDeleteButtonForArabica = workbookArabicaData?.some((ele) => ele?.isChecked)
  const workbookRobustaAllChecked = workbookRobustaData ? workbookRobustaData?.every((ele) => ele?.isChecked) : false
  const workbookArabicaAllChecked = workbookArabicaData ? workbookArabicaData?.every((ele) => ele?.isChecked) : false

  const init = () => {
    if (userDetails) {
      const iceMarketData = {
        isHighlight: 0,
        id: 0,
        createdBy: 0,
        createdOn: '2023-08-07T04:13:55.454Z',
        updatedBy: 0,
        updatedDtms: '2023-08-07T04:13:55.454Z',
        idMarket: 0,
        contractName: '--- --',
        lastChng: 0,
        chng: 0,
        percentageVal: 0,
        volume: 0,
        highRate: 0,
        netHigh: 0,
        highRateCurrency: 0,
        lowRate: 0,
        netLow: 0,
        lowRateCurrency: 0,
        openRate: 0,
        prevRate: 0,
        openInterest: 0,
        bid: 0,
        bsize: 0,
        ask: 0,
        asize: 0,
        optionExpiry: 'DD-MMM-YYYY',
        optionExpiryStatus: null,
        optionExpiryDateFormat: 'DD-MMM-YYYY',
        firstNoticeDate: 'DD-MMM-YYYY',
        firstNoticeDateStatus: null,
        firstNoticeDateFormat: 'DD-MMM-YYYY',
        highCurrency: 0,
        lowCurrency: 0,
        marketName: '0',
        userSymbolId: 0,
        orderBy: 0
      }
      const rubustaSampleArray = []
      const arabicaSampleArray = []

      for (let i = 0; i < 5; i++) {
        rubustaSampleArray.push(iceMarketData)
        arabicaSampleArray.push(iceMarketData)
      }
      setRobustaData(rubustaSampleArray)
      setArabicaData(arabicaSampleArray)
    } else {
      setRobustaData(robustaDummyData)
      setArabicaData(arabicaDummyData)
    }

    let mounted = false

    if (userDetails?.phone) {
      const unSub = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb_Devp' : 'CoffeeWeb', 'NewsFeeds'), (doc) => {
        if (mounted) {
          const firebaseResponse = doc.data()
          const subscriptionList = firebaseResponse?.LatestNewsForSubscription?.split(',')?.map((ele) => parseInt(ele.trim(), 10))
          const countryList = firebaseResponse?.LatestNewsForCountries?.split(',')?.map((ele) => parseInt(ele.trim(), 10))
          if (subscriptionList?.includes(userDetails?.sub?.subType) && countryList.includes(countryId) && !firebaseResponse?.IsDeleted && firebaseResponse?.IsApproved) {
            getTrendingNews()
          }
        } else {
          mounted = true
        }
      })

      const fetchDataAndSetInterval = () => {
        getWorkbookData()
        getTerminalDetails()
        getTrendingNews()
        updateUser()

        return () => {}
      }

      const cleanUp = fetchDataAndSetInterval()
      const cleanUpWebSocket = getTincapheDataAndCurrency()

      const checkForSocketCloseForCoffeequotes = setInterval(() => {
        const elapsedTime = currentTime - lastUpdatedTimingForCoffeequotes
        if (elapsedTime > 15000) {
          cleanUpWebSocket()
          getTincapheDataAndCurrency('connect it again')
        }
      }, 2000)

      return () => {
        unSub()
        cleanUp()
        cleanUpWebSocket()
        clearInterval(checkForSocketCloseForCoffeequotes)
      }
    }
  }

  useEffect(() => {
    const cleanUp = init()

    return () => {
      if (userDetails) {
        cleanUp()
      }
    }
  }, [])

  useEffect(() => {
    if (accordianPopupData) {
      handleSelectContract(accordianPopupData, false)
    }
  }, [robustaData])

  const successSwal = (message) => {
    swal({
      title: t('SUCCESS'),
      text: message,
      icon: 'success'
    })
  }

  const getTincapheDataAndCurrency = (status) => {
    console.log('this is test console for websocket reconnection: ', status)

    const socket = new WebSocket(`${process.env.REACT_APP_BASE_WEBSOCKET_FOR_COFFEE_QUOTES}/api/GetAllTincapcheDataDetail`)

    socket.onmessage = (event) => {
      const coffeeqotesData = JSON.parse(event.data)?.ReturnLst?.CoffeeQuotesTincapcheData
      const currencyData = JSON.parse(event.data)?.ReturnLst?.CoffeequotescurrencyData
      if (coffeeqotesData?.length > 0) {
        filterTincapheResponse(coffeeqotesData)
      }
      if (currencyData?.length > 0) {
        setFXCurrencyList(currencyData)
      }
      lastUpdatedTimingForCoffeequotes = Date.now()
    }

    socket.onclose = function () {
      console.log('WebSocket connection closed here')
    }

    function closeConnection() {
      socket.close()
    }

    return closeConnection
  }

  useEffect(() => {
    if (fxCurrencyList.length !== 0) {
      const newWorkBookArrayForRobusta = workbookRobustaData?.map((ele) => ({ ...ele, fxcurrency: fxCurrencyList[selectedFXCurrencyIndexForRobusta]?.last }))

      setWorkbookRobustaData(newWorkBookArrayForRobusta)

      const newWorkBookArrayForArabica = workbookArabicaData?.map((ele) => ({ ...ele, fxcurrency: fxCurrencyList[selectedFXCurrencyIndexForArabica]?.last }))

      setWorkbookArabicaData(newWorkBookArrayForArabica)
    }
  }, [fxCurrencyList])

  useEffect(() => {
    if (addContractStateForRobusta) {
      const newObject = workbookRobustaData?.map((ele) => (ele?.isHighlight === 'newelyAdded' ? { ...ele, isHighlight: 'normal_white_bg_color' } : ele))

      setWorkbookRobustaData(newObject)
    }
  }, [addContractStateForRobusta])

  useEffect(() => {
    if (addContractStateForArabica) {
      const newObject = workbookArabicaData?.map((ele) => (ele?.isHighlight === 'newelyAdded' ? { ...ele, isHighlight: 'normal_white_bg_color' } : ele))

      setWorkbookArabicaData(newObject)
    }
  }, [addContractStateForArabica])

  useEffect(() => {
    const workbookRubusta = workbookRobustaData?.map((ele, index) => {
      const { workbookType, workbookTotal, fxpredis, fxcurrency, grossPrice, profit, contractName, isLastValueCustom, isNetPriceValueCustom, isOutRateValueCustom } = ele
      if (workbookType !== 0 && !isLastValueCustom) {
        const elementFound = robustaData?.find((element) => element?.contractName === contractName)
        const workbookDifferential = handleWorkbookDifferential(workbookRobustaData[index]?.workbookDifferential)
        const calculatedWorkbookTotal = sumTwoValues(workbookRobustaData[index]?.lastChng, workbookRobustaData[index]?.workbookDifferential, 0)
        const calculatedFxnet = sumTwoValues(fxcurrency, fxpredis, 2)
        const calculatedGrossPrice = handleWorkbookGrossPrice(calculatedFxnet, calculatedWorkbookTotal, 2)
        const calculatedNetPrice = sumTwoValues(calculatedGrossPrice, profit, 2)
        const calculatedLastChng = elementFound ? elementFound?.lastChng.toFixed(0) : workbookRobustaData[index]?.lastChng

        let modifiedElement = {
          ...ele,
          lastChng: calculatedLastChng,
          chngColorCondition: elementFound ? elementFound?.chng : workbookRobustaData[index]?.chng,
          workbookDifferential,
          workbookTotal: calculatedWorkbookTotal,
          grossPrice: calculatedGrossPrice,
          fxnet: calculatedFxnet,
          netPrice: calculatedNetPrice,
          fxcurrency: fxCurrencyList[selectedFXCurrencyIndexForRobusta]?.last
        }

        const workbookTotalForNetPrice = handleWorkbookTotalForNetPrice(grossPrice, calculatedFxnet, 0)
        let modifiedElementForNetPrice = {
          ...ele,
          lastChng: calculatedLastChng,
          customWorkbookDifferential: workbookTotalForNetPrice - calculatedLastChng,
          chngColorCondition: elementFound ? elementFound?.chng : workbookRobustaData[index]?.chng,
          fxcurrency: fxCurrencyList[selectedFXCurrencyIndexForRobusta]?.last,
          fxnet: calculatedFxnet,
          workbookTotal: workbookTotalForNetPrice
        }

        const modifiedDataForOutRate = {
          ...ele,
          lastChng: calculatedLastChng,
          customWorkbookDifferential: workbookTotal - calculatedLastChng
        }

        return isNetPriceValueCustom ? modifiedElementForNetPrice : isOutRateValueCustom ? modifiedDataForOutRate : modifiedElement
      }

      return ele
    })

    setWorkbookRobustaData(workbookRubusta)

    const workbookArabica = workbookArabicaData?.map((ele, index) => {
      const { workbookType, isLastValueCustom, workbookTotal, isNetPriceValueCustom, isOutRateValueCustom, fxcurrency, fxpredis, grossPrice, contractName, profit } = ele
      if (workbookType !== 0 && !isLastValueCustom) {
        const elementFound = arabicaData?.find((element) => element.contractName === contractName)
        const workbookDifferential = handleWorkbookDifferential(workbookArabicaData[index]?.workbookDifferential)
        const calculatedWorkbookTotal = sumTwoValues(workbookArabicaData[index]?.lastChng, workbookArabicaData[index]?.workbookDifferential, 2)
        const calculatedFxnet = sumTwoValues(fxcurrency, fxpredis, 2)
        const calculatedGrossPrice = handleWorkbookGrossPrice(calculatedFxnet, (calculatedWorkbookTotal * convertToValue).toFixed(0), 2)
        const calculatedNetPrice = sumTwoValues(calculatedGrossPrice, profit, 2)
        const convertTo = handleWorkbookConvertTo(calculatedWorkbookTotal, 0)
        const lastChng = elementFound ? elementFound?.lastChng.toFixed(2) : typeof workbookArabicaData[index]?.lastChng == 'string' ? workbookArabicaData[index]?.lastChng : (workbookArabicaData[index]?.lastChng).toFixed(2)
        const calculatedLastChng = elementFound ? elementFound?.lastChng.toFixed(2) : workbookArabicaData[index]?.lastChng
        let modifiedElement = {
          ...ele,
          lastChng,
          chngColorCondition: elementFound ? elementFound?.chng : workbookArabicaData[index]?.chng,
          workbookDifferential,
          workbookTotal: calculatedWorkbookTotal,
          grossPrice: calculatedGrossPrice,
          fxnet: calculatedFxnet,
          netPrice: calculatedNetPrice,
          convertTo,
          outRate: convertTo,
          fxcurrency: fxCurrencyList[selectedFXCurrencyIndexForArabica]?.last
        }
        const convertToForNetPrice = handleWorkbookTotalForNetPrice(grossPrice, calculatedFxnet, 0)
        const workbookTotalForNetPrice = (convertToForNetPrice / convertToValue).toFixed(2)
        let modifiedElementForNetPrice = {
          ...ele,
          lastChng: lastChng,
          customWorkbookDifferential: getValueWithoutDecimal(workbookTotal - lastChng),
          chngColorCondition: elementFound ? elementFound?.chng : workbookRobustaData[index]?.chng,
          fxcurrency: fxCurrencyList[selectedFXCurrencyIndexForArabica]?.last,
          fxnet: calculatedFxnet,
          convertTo: convertToForNetPrice,
          workbookTotal: workbookTotalForNetPrice,
          outRate: convertToForNetPrice
        }

        const calculatedCustomWorkbookDifferential = substractValues(workbookTotal, calculatedLastChng, 2)
        const modifiedDataForOutRate = {
          ...ele,
          lastChng: calculatedLastChng,
          customWorkbookDifferential: calculatedCustomWorkbookDifferential
        }

        return isNetPriceValueCustom ? modifiedElementForNetPrice : isOutRateValueCustom ? modifiedDataForOutRate : modifiedElement
      }
      return ele
    })

    setWorkbookArabicaData(workbookArabica)
  }, [robustaData])

  const getDecimalValue = (value, decimal) => parseFloat(value).toFixed(decimal)

  const getWorkbookData = async () => {
    await apiAdapterCoffeeWeb
      .getUsersCoffeeQuotes({ userId, countryId })
      .then((response) => {
        const workbookArray = response.data.returnLst

        const workbookRobusta = workbookArray?.usersWorkbookData?.filter((item) => item.idMarket === 1)
        const modifiedWorkbookRobusta = workbookRobusta?.map((ele) => {
          const { workbookTotal, lastChng, netPrice, grossPrice } = ele
          const calculatedWorkbookTotal = getDecimalValue(workbookTotal, 0)
          const customWorkbookDifferential = getValueWithoutDecimal(calculatedWorkbookTotal - lastChng)
          return {
            ...ele,
            customLastChg: getDecimalValue(lastChng, 0),
            customNetPrice: getDecimalValue(netPrice, 0),
            customWorkbookDifferential,
            grossPrice: getDecimalValue(grossPrice, 2),
            netPrice: getDecimalValue(netPrice, 2),
            customOutRate: calculatedWorkbookTotal
          }
        })
        setWorkbookRobustaData(modifiedWorkbookRobusta)

        const workbookArabica = workbookArray?.usersWorkbookData?.filter((item) => item.idMarket === 2)
        const modifiedWorkbookArabica = workbookArabica?.map((ele) => {
          const { workbookTotal, lastChng, netPrice, grossPrice, convertTo } = ele
          const calculatedWorkbookTotal = getDecimalValue(workbookTotal, 2)
          const customWorkbookDifferential = getValueWithoutDecimal(calculatedWorkbookTotal - lastChng)
          return {
            ...ele,
            customLastChg: getDecimalValue(lastChng, 2),
            workbookTotal: calculatedWorkbookTotal,
            customNetPrice: getDecimalValue(netPrice, 2),
            customWorkbookDifferential,
            grossPrice: getDecimalValue(grossPrice, 2),
            netPrice,
            outRate: getDecimalValue(convertTo, 0),
            customOutRate: getDecimalValue(convertTo, 0)
          }
        })
        setWorkbookArabicaData(modifiedWorkbookArabica)
      })
      .catch((error) => {
        if (!error?.response?.data?.statusCode) {
          handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_WORKBOOK_DATA', onRetry: getWorkbookData })
        }
      })
  }

  const filterTincapheResponse = (response) => {
    const robustaAndArabicaArray = response

    let robustaFilteredData = []

    robustaFilteredData = robustaAndArabicaArray?.filter((item) => item.idMarket === 1)

    const robusta = robustaFilteredData?.map((item, index) => {
      let isHighlightValue = 0
      // here item.chng is string in some case

      if (item?.chng != robustaGlobalArray[index]?.chng) {
        if (item?.chng >= 0) {
          isHighlightValue = 1
        } else if (item?.chng < 0) {
          isHighlightValue = 2
        } else {
          isHighlightValue = 3
        }
      }

      return {
        ...item,
        isHighlightRow: isHighlightValue
      }
    })

    setRobustaData(robusta)
    robustaGlobalArray = robusta

    let arabicaFilteredData = []

    arabicaFilteredData = robustaAndArabicaArray?.filter((item) => item.idMarket === 2)
    const arabica = arabicaFilteredData?.map((item, index) => {
      let isHighlightValue = 0
      // here item.chng is string in some case

      if (item?.chng != arabicaGlobalArray[index]?.chng) {
        if (item?.chng >= 0) {
          isHighlightValue = 1
        } else if (item?.chng < 0) {
          isHighlightValue = 2
        } else {
          isHighlightValue = 3
        }
      }

      return {
        ...item,
        isHighlightRow: isHighlightValue
      }
    })

    setArabicaData(arabica)
    arabicaGlobalArray = arabica
  }

  const updateUser = async () => {
    const response = await updateUserDetails()

    if (response?.status === 401) {
      handleApiResponse({ status: response?.status })
    }
  }

  const [isTerminalDataExists, setIsTerminalDataExists] = useState(false)

  const getTerminalDetails = async () => {
    await apiAdapterCoffeeWeb
      .getTerminalDetails()
      .then((response) => {
        const { data } = response
        if (data?.returnLst?.length) {
          const robustaTerminal = data.returnLst.filter((ele) => ele.idMarket === 1 && ele.isActive)
          const arabicaTerminal = data.returnLst.filter((ele) => ele.idMarket === 2 && ele.isActive)

          setSearchContractList(robustaTerminal)
          setSearchContractListForRobusta(robustaTerminal)
          setSearchContractListForArabica(arabicaTerminal)
          setIsTerminalDataExists(false)
        } else {
          setIsTerminalDataExists(true)
        }
      })
      .catch((error) => {
        setIsTerminalDataExists(true)
        handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_GET_TERMINAL_DETAILS', onRetry: getTerminalDetails })
      })
  }

  const getTrendingNews = async () => {
    await apiAdapterCoffeeWeb
      .getAllShortNewsForSubForCountry(userId)
      .then((response) => {
        const { data } = response
        if (data?.returnLst.length) {
          setMarquee(data?.returnLst)
          setIsTrendingNewsExist(false)
        } else {
          setIsTrendingNewsExist(true)
        }
      })
      .catch(() => {
        setIsTrendingNewsExist(true)
      })
  }

  const handleDecimal = (arg1, decimal) => parseFloat(arg1 || 0).toFixed(decimal)
  const sumTwoValues = (arg1, arg2, decimal) => (parseFloat(arg1 || 0) + parseFloat(arg2 || 0)).toFixed(decimal)
  const substractValues = (arg1, arg2, decimal) => (parseFloat(arg1 || 0) - parseFloat(arg2 || 0)).toFixed(decimal)
  const getValueWithoutDecimal = (value) => (value % 1 === 0 ? value.toFixed(0) : value.toFixed(2).replace(/\.?0+$/, ''))
  const handleWorkbookGrossPrice = (arg1, arg2, decimal) => ((parseFloat(arg1 || 0) * parseFloat(arg2 || 0)) / 1000).toFixed(decimal)
  const handleWorkbookConvertTo = (arg1, decimal) => parseFloat((arg1 || 0) * convertToValue).toFixed(decimal)
  const handleWorkbookTotalForNetPrice = (arg1, arg2, decimal) => (arg2 != 0 ? ((arg1 * 1000) / arg2).toFixed(decimal) : 0)
  const handleWorkbookTotalForCustomNetPrice = (arg1, arg2, decimal, value) => (((parseFloat(arg1 || 0) * 1000) / parseFloat(arg2 || 0)).toFixed(decimal) / value).toFixed(decimal)

  const handleChange = (event, data, index) => {
    const { value, name } = event.target
    if (/^-?\d*\.?\d{0,2}$/.test(value) || value === '' || Number.isNaN(Number(value))) {
      const isRobustaWorkbook = data?.workbookType === 1
      const numberOfDecimal = isRobustaWorkbook ? 0 : 2
      const workbookArray = isRobustaWorkbook ? workbookRobustaData : workbookArabicaData
      const updateArray = workbookArray.map((ele, ind) => {
        if (ind === index) {
          const modifiedElement = {
            ...ele,
            [name]: value
          }
          const { isNetPriceValueCustom, isLastValueCustom, isOutRateValueCustom, fxnet, customLastChg, lastChng, customNetPrice, workbookDifferential, fxcurrency, fxpredis, profit, customWorkbookDifferential } = modifiedElement
          const actualLastChng = isLastValueCustom ? customLastChg : lastChng
          const calculatedFxNet = sumTwoValues(fxcurrency, fxpredis, 2)

          if (isNetPriceValueCustom) {
            const calculatedNetPrice = parseFloat(customNetPrice || 0).toFixed(2)
            const calculatedGrossPrice = substractValues(calculatedNetPrice, profit, 2)
            const convertedToValue = isRobustaWorkbook ? 1 : convertToValue
            const calculatedWorkbookTotal = handleWorkbookTotalForCustomNetPrice(calculatedGrossPrice, calculatedFxNet, numberOfDecimal, convertedToValue)
            const calculatedConvertTo = parseFloat(calculatedWorkbookTotal * convertToValue).toFixed(0)
            const calculatedCustomWorkbookDifferential = substractValues(calculatedWorkbookTotal, actualLastChng, numberOfDecimal)

            const calculatedObject = {
              ...modifiedElement,
              netPrice: calculatedNetPrice,
              grossPrice: calculatedGrossPrice,
              fxnet: calculatedFxNet,
              workbookTotal: calculatedWorkbookTotal,
              customWorkbookDifferential: calculatedCustomWorkbookDifferential
            }

            if (!isRobustaWorkbook) {
              calculatedObject.convertTo = calculatedConvertTo
            }

            return calculatedObject
          } else {
            const actualWorkbookDifferential = isOutRateValueCustom ? customWorkbookDifferential : workbookDifferential
            const calculatedWorkbookTotal = sumTwoValues(actualLastChng, actualWorkbookDifferential, numberOfDecimal)
            const calculatedConvertTo = handleWorkbookConvertTo(calculatedWorkbookTotal, 0)
            const calculatedGrossPrice = handleWorkbookGrossPrice(calculatedFxNet, isRobustaWorkbook ? calculatedWorkbookTotal : calculatedConvertTo, 2)
            const calculatedNetPrice = sumTwoValues(calculatedGrossPrice, profit, 2)
            const calculatedObject = {
              ...modifiedElement,
              workbookTotal: calculatedWorkbookTotal,
              fxnet: calculatedFxNet,
              grossPrice: calculatedGrossPrice,
              netPrice: calculatedNetPrice
            }
            if (!isRobustaWorkbook) {
              calculatedObject.convertTo = calculatedConvertTo
            }

            const actualOutRate = isRobustaWorkbook ? value : parseFloat(value / convertToValue).toFixed(numberOfDecimal)
            const calculatedGrossPriceForOutRate = handleWorkbookGrossPrice(value, fxnet, 2)
            const calculatedNetPriceForOutRate = sumTwoValues(calculatedGrossPriceForOutRate, profit, 2)
            const calculatedWorkbookTotalForOutRate = substractValues(actualOutRate, lastChng, numberOfDecimal)
            const actualConvertedTo = value

            const isOutRateCustomValue = name === 'customOutRate'

            const calculatedObjectForOutRate = {
              ...modifiedElement,
              customWorkbookDifferential: calculatedWorkbookTotalForOutRate,
              workbookTotal: actualOutRate,
              grossPrice: calculatedGrossPriceForOutRate,
              netPrice: calculatedNetPriceForOutRate
            }

            if (!isRobustaWorkbook) {
              calculatedObjectForOutRate.convertTo = actualConvertedTo
            }

            return isOutRateCustomValue ? calculatedObjectForOutRate : calculatedObject
          }
        }

        return ele
      })
      if (isRobustaWorkbook) {
        setWorkbookRobustaData(updateArray)
      } else {
        setWorkbookArabicaData(updateArray)
      }
    }
  }

  const handleChangeForDivider = (event, data, index) => {
    const { value } = event.target
    handleCellChange(data, index, 'contractName', value)
  }

  const handleFocus = (data, index) => {
    handleCellChange(data, index, 'isHighlight', 'activeFocus')
  }

  const handleBlur = (data, index) => {
    handleCellChange(data, index, 'isHighlight', 'normal_white_bg_color')
  }

  const handleCellChange = (data, index, key, value) => {
    const isRobustaWorkbook = data?.idMarket === 1
    const workbookArray = isRobustaWorkbook ? workbookRobustaData : workbookArabicaData
    const newArray = workbookArray?.map((ele, ind) => (index === ind ? { ...ele, [key]: value } : ele))
    if (isRobustaWorkbook) {
      setWorkbookRobustaData(newArray)
    } else {
      setWorkbookArabicaData(newArray)
    }
  }

  const hasDuplicateDividers = (data) => data?.some((item) => item.contractName === '')

  const handleSaveDivider = async (type, newList) => {
    const isRobustaWorkbook = type === 'robusta'
    const workbookData = isRobustaWorkbook ? workbookRobustaData : workbookArabicaData
    const actualWorkbookData = newList || workbookData
    if (actualWorkbookData?.length > 0) {
      if (!hasDuplicateDividers(actualWorkbookData)) {
        setLoading(true)
        const postData = actualWorkbookData?.map((ele, index) => {
          const { idMarket, contractName, workbookType, outRate, marketNetPrice, grade, isLastValueCustom, customLastChg, lastChng, workbookDifferential, workbookTotal, convertTo, fxpredis, expenseIn, grandTotal, fxcurrency, fxnet, grossPrice, netPrice, profit, isNetPriceValueCustom, customWorkbookDifferential, isOutRateValueCustom } = ele
          const lastChngValue = isLastValueCustom ? customLastChg : lastChng
          const workBookObject = {
            idWorkbook: typeof ele?.idWorkbook === 'string' ? 0 : ele?.idWorkbook,
            createdBy: 0,
            createdOn: '2023-08-23T06:58:59.778Z',
            updatedBy: 0,
            updatedDtms: '2023-08-23T06:58:59.778Z',
            idUser: userId,
            idMarket,
            contractName,
            workbookType,
            outRate,
            marketNetPrice,
            grade,
            chng: 0,
            lastChng: lastChngValue,
            lastChg: lastChngValue,
            workbookDifferential: workbookDifferential !== '' ? workbookDifferential : 0,
            workbookTotal,
            convertTo,
            expenseIn,
            grandTotal: parseFloat(grandTotal),
            fxcurrencyType: 0,
            fxcurrency,
            fxpredis: fxpredis !== '' ? fxpredis : 0,
            fxnet: parseFloat(fxnet),
            grossPrice: parseFloat(grossPrice),
            netPrice: parseFloat(netPrice),
            profit: profit !== '' ? profit : 0,
            orderBy: index,
            isLastValueCustom,
            isNetPriceValueCustom,
            customWorkbookDifferential,
            isOutRateValueCustom
          }

          return workBookObject
        })

        await apiAdapterCoffeeWeb
          .addWorkbook(postData)
          .then((response) => {
            const modifiedWorkbookList = actualWorkbookData?.map((ele, index) => ({ ...ele, idWorkbook: response.data?.returnLst[index].idWorkbook }))
            if (isRobustaWorkbook) {
              setWorkbookRobustaData(modifiedWorkbookList)
            } else {
              setWorkbookArabicaData(modifiedWorkbookList)
            }

            if (newList === null) {
              successSwal(t('CHANGES_UPDATED_SUCCESSFULLY'))
            }
          })
          .catch((error) => {
            handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_ADD_WORKBOOK', onRetry: () => handleSaveDivider(type, newList) })
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setDividerWarningPopup(true)
      }
    }
  }

  const handleFxCurrencySelectForRobusta = (value) => {
    // value and item.last is string in some case
    const tergetedIndex = fxCurrencyList.findIndex((item) => item.last == value)

    setSelectedFXCurrencyIndexForRobusta(tergetedIndex)
    const updatePrice = workbookRobustaData?.map((item) => {
      const fxNet = sumTwoValues(value, item?.fxpredis, 2)
      const grossPrice = handleWorkbookGrossPrice(fxNet, item?.workbookTotal, 2)
      const netPrice = sumTwoValues(grossPrice, item?.profit, 2)

      return {
        ...item,
        fxcurrency: parseFloat(value),
        fxnet: fxNet,
        grossPrice,
        netPrice
      }
    })

    setWorkbookRobustaData([...updatePrice])
  }

  const handleFxCurrencySelectForArabica = (value) => {
    // value and item.last is string in some condition
    const tergetedIndex = fxCurrencyList.findIndex((item) => item.last == value)

    setSelectedFXCurrencyIndexForArabica(tergetedIndex)
    const updatePrice = workbookArabicaData.map((item) => {
      const fxNet = sumTwoValues(value, item.fxpredis, 2)
      const grossPrice = handleWorkbookGrossPrice(fxNet, item.workbookTotal * convertToValue, 2)
      const netPrice = sumTwoValues(grossPrice, item?.profit, 2)

      return {
        ...item,
        fxcurrency: value,
        fxnet: fxNet,
        grossPrice,
        netPrice
      }
    })

    setWorkbookArabicaData([...updatePrice])
  }

  const handleClickDelete = (type) => {
    const isRobustaWorkbook = type === 'robusta'
    const workbookArray = isRobustaWorkbook ? workbookRobustaData : workbookArabicaData
    const workbookIds = workbookArray?.filter((ele) => ele?.isChecked).map((ele) => ele?.idWorkbook)
    const workbookIdsForDelete = workbookIds?.filter((ele) => typeof ele !== 'string')

    if (workbookIdsForDelete?.length) {
      Swal.fire({
        title: t('ARE_YOU_SURE'),
        text: t('YOU_WILL_NOT_BE_ABLE_TO_REVERT_THIS'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('YES_DELETE_IT'),
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        confirmButtonColor: '#50b3f6',
        cancelButtonColor: '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true)
          await apiAdapterCoffeeWeb
            .deleteMarketWorkbookInBulk(workbookIdsForDelete?.toString())
            .then((response) => {
              if (response.status === 200) {
                successSwal(t('WORKBOOK_DELETED_SUCCESSFULLY'))
                filterWorkbooksOnDelete(isRobustaWorkbook, workbookIds)
              }
            })
            .catch((error) => {
              handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_DELETE_WORKBOOK_DATA', onRetry: () => handleClickDelete(type) })
            })
            .finally(() => {
              setLoading(false)
            })
        } else if (!result.isConfirmed) {
          Swal.fire({
            title: t('DELETE_FAILURE'),
            text: t('YOUR_MARKET_WORKBOOK_HAS_BEEN_REMAINS_SAFE'),
            confirmButtonText: t('OK'),
            confirmButtonColor: '#50b3f6'
          })
        }
      })
    } else if (workbookIds) {
      filterWorkbooksOnDelete(isRobustaWorkbook, workbookIds)
    }
  }

  const filterWorkbooksOnDelete = (isRobustaWorkbook, workbookIds) => {
    const workbookArray = isRobustaWorkbook ? workbookRobustaData : workbookArabicaData
    const updatedArray = workbookArray?.filter((ele) => !workbookIds.includes(ele.idWorkbook) && { ...ele, isHighlight: 'normal_white_bg_color' })
    if (isRobustaWorkbook) {
      setWorkbookRobustaData(updatedArray)
    } else {
      setWorkbookArabicaData(updatedArray)
    }
  }

  const handleSelectContractDropdown = (e) => {
    const { value } = e.target
    const idMarket = parseInt(value, 10)

    setcontractSearchData({
      ...contractSearchData,
      idMarket,
      keySearched: '',
      value: idMarket
    })
    if (idMarket === 1) {
      setSearchContractList(searchContractListForRobusta)
    } else {
      setSearchContractList(searchContractListForArabica)
    }
    setSelectedContract(null)
    setInputValue('')
  }

  const handleWorkbookDifferential = (arg1) => {
    if (arg1 !== '') return parseFloat(arg1)
    return ''
  }

  const createEmptyWorkbookObject = () => {
    let idMarket = null
    let workbookType = null
    let contractName = null

    const { value, keySearched } = contractSearchData

    if (value === 1) {
      idMarket = 1
      workbookType = 1
      contractName = keySearched
    } else if (value === 2) {
      idMarket = 2
      workbookType = 2
      contractName = keySearched
    } else if (value === 3) {
      idMarket = 1
      workbookType = 0
      contractName = ''
    } else if (value === 4) {
      idMarket = 2
      workbookType = 0
      contractName = ''
    }
    const newObject = {
      idWorkbook: uuid(),
      createdBy: 0,
      createdOn: '2023-03-03T12:21:38.873',
      updatedBy: 0,
      updatedDtms: '2023-04-10T17:37:10.873',
      idUser: userId,
      idMarket,
      contractName,
      workbookType,
      outRate: 0,
      marketNetPrice: 0,
      grade: '',
      chng: 0,
      lastChng: 0,
      workbookDifferential: 0,
      workbookTotal: 0,
      grandTotal: 0,
      convertTo: 0,
      expenseIn: 0,
      fxcurrencyType: 0,
      fxcurrency: 0,
      fxpredis: 0,
      fxnet: 0,
      grossPrice: 0,
      netPrice: 0,
      profit: 0,
      isHighlight: 'newelyAdded',
      orderBy: 0,
      isLastValueCustom: false,
      isNetPriceValueCustom: false,
      customWorkbookDifferential: 0,
      isOutRateValueCustom: false
    }

    return newObject
  }

  const addWorkBookRowToRespectiveTable = async () => {
    if ((contractSearchData?.value == 1 && contractSearchData?.keySearched) || contractSearchData?.value == 3) {
      addNewRowInWorkbook(workbookRobustaData, setWorkbookRobustaData, setAddContractStateForRobusta)
    } else if ((contractSearchData?.value == 2 && contractSearchData?.keySearched) || contractSearchData?.value == 4) {
      addNewRowInWorkbook(workbookArabicaData, setWorkbookArabicaData, setAddContractStateForArabica)
    }
    function addNewRowInWorkbook(state, setState, setAddContractState) {
      let updatedArray = state ? [...state, createEmptyWorkbookObject()] : [createEmptyWorkbookObject()]
      setState(updatedArray)
      let scrollIndex = updatedArray?.findIndex((ele) => ele?.isHighlight == 'newelyAdded')
      const targetDiv = document.querySelector(`.smoothScrollForArabica:nth-child(${scrollIndex})`)
      if (targetDiv) {
        const scrollToPosition = targetDiv.getBoundingClientRect().top + window.pageYOffset - 100
        window.scrollTo({ top: scrollToPosition, behavior: 'smooth' })
      }
      setTimeout(() => {
        setAddContractState(uuid())
      }, 5000)
    }
  }

  const setContractData = (ele) => setcontractSearchData({ ...contractSearchData, keySearched: ele.contractName?.toUpperCase() })

  const readMoreNews = (item) => {
    const { newsLock, id } = item
    if (!newsLock) {
      coffeewebSetLocal(coffeewebStorageKeys.latestNewsReadNotification, id)
      params.set('newsId', id)

      history.push({
        search: params.toString(),
        state: { pageRef: 'yes' },
        pathname: RouteStrings.opennewsnotification
      })
    } else history.push(RouteStrings.subscription)
  }

  const onDragStartRobusta = (event, id) => {
    event.dataTransfer.setData('text/plain', id)
    setDraggableRow('robusta')
  }

  const onDragStartArabica = (event, id) => {
    event.dataTransfer.setData('text/plain', id)
    setDraggableRow('arabica')
  }

  const onDropworkbook = (event, ele, index) => {
    const isWorkbookRobusta = draggableRow === 'robusta'
    const workbookList = isWorkbookRobusta ? workbookRobustaData : workbookArabicaData
    event.preventDefault()
    const draggedId = event.dataTransfer.getData('text/plain')
    // here idWorkbook is randomenly generated string when we add new row
    const draggedDiv = workbookList?.find((div) => div.idWorkbook == draggedId)
    const newList = workbookList?.filter((div) => div.idWorkbook != draggedId)

    newList.splice(index, 0, draggedDiv)
    isWorkbookRobusta ? setWorkbookRobustaData(newList) : setWorkbookArabicaData(newList)
    handleSaveDivider(draggableRow, newList)
  }

  const dateFormatBasedOnUser = () => {
    if (userDetails) {
      if (userDetails?.userDateFormat === 'DD-MMM-YYYY') return `${date.getDate().toString().padStart(2, '0')}-${monthNames[date.getMonth()]}-${date.getFullYear()}`
      if (userDetails?.userDateFormat === 'MMM-DD-YYYY') return `${monthNames[date.getMonth()]}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
      if (userDetails?.userDateFormat === 'YYYY-MMM-DD') return `${date.getFullYear()}-${monthNames[date.getMonth()]}-${date.getDate().toString().padStart(2, '0')}`
    } else {
      return `${date.getDate().toString().padStart(2, '0')}-${monthNames[date.getMonth()]}-${date.getFullYear()}`
    }
  }

  const getTableRowClassName = (ele, prefix) => {
    if (ele?.isHighlight === 'newelyAdded') return `newely_added_bg_color smoothScrollFor${prefix}`
    if (ele?.isHighlight === 'activeFocus') return `active_focus_bg_color smoothScrollFor${prefix}`
    return `normal_white_bg_color smoothScrollFor${prefix}`
  }

  const getNetPriceClassName = (chngColorCondition, ele) => {
    if (ele?.isLastValueCustom) {
      return 'bold_and_dark_gray'
    } else {
      if (chngColorCondition > 0) {
        return 'text_new_green'
      }
      if (chngColorCondition < 0) {
        return 'text_new_red'
      }
      return ''
    }
  }

  const handleSelectContract = (item, show) => {
    const isRobusta = item?.idMarket == 1
    let targetedIndex = null
    let actualData = isRobusta ? robustaData : arabicaData
    let selectedTerminal = actualData?.find((ele, index) => {
      if (ele?.contractName == item?.contractName) {
        targetedIndex = index
        return ele
      }
    })

    if (item.optionExpiry !== 'DD-MMM-YYYY') {
      setAccordianPopupData(selectedTerminal)
      let arrayList = []

      const { contractName, lastChng, chng } = selectedTerminal
      for (let i = 1; i < actualData.length - targetedIndex; i++) {
        let object = {
          parentTerminal: contractName,
          parentLastChng: lastChng,
          parentLastChngColor: chng >= 0 ? 'positive_green' : 'negative_red',
          childTerminal: actualData[targetedIndex + i]?.contractName,
          childTerminalChng: actualData[targetedIndex + i]?.lastChng,
          childLastChngColor: actualData[targetedIndex + i]?.chng >= 0 ? 'positive_green' : 'negative_red',
          difference: actualData[targetedIndex + i]?.lastChng - lastChng,
          differenceColor: actualData[targetedIndex + i]?.lastChng - lastChng >= 0 ? 'positive_green' : 'negative_red'
        }
        arrayList.push(object)
      }

      for (let i = 0; i < targetedIndex; i++) {
        let object = {
          parentTerminal: '----',
          parentLastChng: '--',
          parentLastChngColor: 'idle_color',
          childTerminal: '----',
          childTerminalChng: '--',
          childLastChngColor: 'idle_color',
          difference: '--',
          differenceColor: 'idle_color'
        }
        arrayList.push(object)
      }
      setAccordianPopupListData(arrayList)
      if (show) {
        setShowTerminalPopup(true)
      }
    }
  }

  const swapLastChg = (ele, index, type) => {
    const isWorkbookRobusta = type === 'workbookRobusta'
    const workbookList = isWorkbookRobusta ? workbookRobustaData : workbookArabicaData
    const lastChng = ele?.customLastChg === 0 ? ele?.lastChng : ele?.customLastChg
    const workbookTotal = sumTwoValues(ele.isLastValueCustom ? ele?.lastChng : lastChng, !ele.isNetPriceValueCustom ? ele?.workbookDifferential : ele?.customWorkbookDifferential, isWorkbookRobusta ? 0 : 2)
    const fxNet = sumTwoValues(ele?.fxcurrency, ele.fxpredis, 2)
    const grossPrice = handleWorkbookGrossPrice(fxNet, isWorkbookRobusta ? workbookTotal : workbookTotal * convertToValue, 2)
    const netPrice = sumTwoValues(grossPrice, ele?.profit, 2)
    const convertTo = handleWorkbookConvertTo(workbookTotal, 0)
    const customNetPrice = parseFloat(netPrice).toFixed(0)

    const modifiedData = workbookList.map((element, ind) => {
      if (ind === index) {
        const modifiedElement = {
          ...element,
          customLastChg: lastChng,
          isLastValueCustom: !element.isLastValueCustom,
          workbookTotal: workbookTotal,
          grandTotal: netPrice,
          grossPrice,
          netPrice,
          customNetPrice,
          isOutRateValueCustom: false
        }

        if (!isWorkbookRobusta) {
          modifiedElement.convertTo = convertTo
          modifiedElement.outRate = convertTo
        }

        return modifiedElement
      }
      return element
    })

    if (isWorkbookRobusta) {
      setWorkbookRobustaData(modifiedData)
    } else {
      setWorkbookArabicaData(modifiedData)
    }
  }

  const swapNetPrice = (ele, index, type) => {
    const isWorkbookRobusta = type === 'workbookRobusta'
    const workbookList = isWorkbookRobusta ? workbookRobustaData : workbookArabicaData
    if (!ele.isNetPriceValueCustom && ele.customNetPrice) {
      const netPrice = parseFloat(ele.customNetPrice).toFixed(2)
      const grossPrice = (ele.customNetPrice - ele.profit).toFixed(2)
      const workbookTotal = ((grossPrice * 1000) / ele.fxnet).toFixed(0)
      const customWorkbookDifferential = parseInt(workbookTotal, 10) - ele.lastChng
      const modifiedData = workbookList.map((item, ind) => {
        if (ind === index) {
          let modifiedElement = {
            ...item,
            isNetPriceValueCustom: !item.isNetPriceValueCustom,
            netPrice,
            grossPrice,
            workbookTotal,
            customWorkbookDifferential,
            isOutRateValueCustom: false
          }
          if (!isWorkbookRobusta) {
            modifiedElement.convertTo = workbookTotal
            modifiedElement.workbookTotal = (workbookTotal / convertToValue).toFixed(2)
            modifiedElement.customWorkbookDifferential = ((workbookTotal / convertToValue).toFixed(2) - ele.lastChng).toFixed(2)
          }
          return modifiedElement
        }
        return item
      })
      if (isWorkbookRobusta) {
        setWorkbookRobustaData(modifiedData)
      } else {
        setWorkbookArabicaData(modifiedData)
      }
    } else {
      const workbookTotal = sumTwoValues(ele.isLastValueCustom ? ele?.customLastChg : ele?.lastChng, ele.workbookDifferential, isWorkbookRobusta ? 0 : 2)
      const fxnet = sumTwoValues(ele.fxcurrency, ele.fxpredis, 2)
      const grossPrice = handleWorkbookGrossPrice(fxnet, isWorkbookRobusta ? workbookTotal : (workbookTotal * convertToValue).toFixed(0), 2)
      const netPrice = sumTwoValues(grossPrice, ele?.profit, 2)
      const modifiedData = workbookList.map((item, ind) => {
        if (ind === index) {
          let modifiedElement = {
            ...item,
            isNetPriceValueCustom: !item.isNetPriceValueCustom,
            workbookTotal,
            fxnet,
            grossPrice,
            netPrice
          }
          if (!isWorkbookRobusta) {
            modifiedElement.convertTo = (workbookTotal * convertToValue).toFixed(0)
          }
          return modifiedElement
        }
        return item
      })

      if (isWorkbookRobusta) {
        setWorkbookRobustaData(modifiedData)
      } else {
        setWorkbookArabicaData(modifiedData)
      }
    }
  }

  const swapOutRate = (ele, index) => {
    const { idMarket, customOutRate, profit, lastChng, fxcurrency, fxpredis } = ele
    const customOutRateValue = customOutRate || 0
    const isRobustaWorkbook = idMarket === 1
    const numberOfDecimal = isRobustaWorkbook ? 0 : 2
    const workbookArray = isRobustaWorkbook ? workbookRobustaData : workbookArabicaData
    const calculatedFxNet = sumTwoValues(fxcurrency, fxpredis, 2)
    const actualOutRate = isRobustaWorkbook ? customOutRateValue : (customOutRateValue / convertToValue).toFixed(2)
    const calculatedGrossPrice = handleWorkbookGrossPrice(calculatedFxNet, customOutRateValue, 2)
    const calculatedNetPrice = sumTwoValues(calculatedGrossPrice, profit, 2)
    const customWorkbookDifferential = substractValues(actualOutRate, lastChng, numberOfDecimal)
    const updatedArray = workbookArray.map((item, ind) => {
      if (ind === index) {
        let modifiedElement = {
          ...item,
          isOutRateValueCustom: !item?.isOutRateValueCustom,
          isNetPriceValueCustom: false,
          isLastValueCustom: false,
          workbookTotal: actualOutRate,
          grossPrice: calculatedGrossPrice,
          netPrice: calculatedNetPrice,
          customWorkbookDifferential
        }
        if (!isRobustaWorkbook) {
          modifiedElement.convertTo = customOutRateValue
        }
        return modifiedElement
      }
      return item
    })

    if (isRobustaWorkbook) {
      setWorkbookRobustaData(updatedArray)
    } else {
      setWorkbookArabicaData(updatedArray)
    }
  }

  const selectAllCheckBoxForDivider = (ele, index) => {
    const isRobustaWorkbook = ele.idMarket === 1
    const workbookArray = isRobustaWorkbook ? workbookRobustaData : workbookArabicaData
    const updatedArray = [...workbookArray]
    updatedArray[index].isChecked = !updatedArray[index].isChecked

    for (let i = index + 1; i < workbookArray.length; i++) {
      if (workbookArray[i].workbookType === 0) {
        break
      }
      updatedArray[i].isChecked = updatedArray[index]?.isChecked
    }

    if (isRobustaWorkbook) {
      setWorkbookRobustaData(updatedArray)
    } else {
      setWorkbookArabicaData(updatedArray)
    }
  }

  const selectCheckBox = (ele, index) => {
    const isRobustaWorkbook = ele.idMarket === 1
    const workbookArray = isRobustaWorkbook ? workbookRobustaData : workbookArabicaData
    const updatedArray = workbookArray?.map((ele, ind) => (index === ind ? { ...ele, isChecked: !ele?.isChecked } : ele))

    let firstParentIndex = -1
    for (let i = index - 1; i >= 0; i--) {
      if (updatedArray[i]?.workbookType === 0) {
        firstParentIndex = i
        break
      }
    }

    let lastParentIndex = updatedArray.length
    for (let i = index + 1; i < updatedArray.length; i++) {
      if (updatedArray[i]?.workbookType === 0) {
        lastParentIndex = i
        break
      }
    }

    const areAllChecked = updatedArray?.slice(firstParentIndex + 1, lastParentIndex).every((ele) => ele?.isChecked)
    if (firstParentIndex !== -1) {
      updatedArray[firstParentIndex].isChecked = areAllChecked
    }

    if (isRobustaWorkbook) {
      setWorkbookRobustaData(updatedArray)
    } else {
      setWorkbookArabicaData(updatedArray)
    }
  }

  const selectAllWorkbook = (type) => {
    const isRobustaWorkbook = type === 'robusta'
    const workbookArray = isRobustaWorkbook ? workbookRobustaData : workbookArabicaData
    const state = isRobustaWorkbook ? workbookRobustaAllChecked : workbookArabicaAllChecked
    const updatedArray = workbookArray?.map((ele) => ({ ...ele, isChecked: !state }))

    if (isRobustaWorkbook) {
      setWorkbookRobustaData(updatedArray)
    } else {
      setWorkbookArabicaData(updatedArray)
    }
  }

  return (
    <>
      {!userDetails && (
        <>
          <Header isLoggedIn={false} />
          <FreeMenubar />
        </>
      )}
      {!userDetails && (
        <div className="coffee_quotes_comming_soon_modal">
          <div className="comming_soon_card">{t('COMING_SOON')}</div>
        </div>
      )}
      <div className={`coffee_quotes_screen ${!userDetails && 'blue-coffeequotes-bg'}`}>
        {accordianPopupData && <AccordianPopUp item={accordianPopupData} accordianPopupListData={accordianPopupListData} terminalPopUp={terminalPopUp} setShowTerminalPopup={setShowTerminalPopup} />}{' '}
        <div className="coffee_quotes_screen_header">
          <div className="time_main_div_for_large_screen">
            <div className="time_sub_div time">
              <div>{dateFormatBasedOnUser()}</div>
            </div>
            <div className="time_sub_div">
              <div>
                {' '}
                <TimerPicker />{' '}
              </div>
            </div>
          </div>
          <div className="time_main_div_for_mobile_screen">
            <div className="time_sub_div">
              <div>{t('DATE')}</div>
              <div className="time_colon">:</div>
              <div>{dateFormatBasedOnUser()}</div>
            </div>
            <div className="time_sub_div">
              <div>{t('TIME')}</div>
              <div className="time_colon">:</div>
              <div>
                {' '}
                <TimerPicker />{' '}
              </div>
            </div>
          </div>

          <div className="for_trending_news">
            <div className={isTrendingNewsExist ? 'trenging-news-exists' : 'labaling'}>{t('TRENDING_NEWS')} : </div>
            <Marquee pauseOnHover="true" speed="27" className="marquee_main_div">
              {marquee?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="marquee_tag" key={index} target="_self" onClick={() => readMoreNews(item)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="bullet_dot"></div>
                        <div style={{ color: item?.trendingNewsColour == '#000000' ? '#000000' : item?.trendingNewsColour }}>{ReactHtmlParser(item?.newsSubject)}</div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
            </Marquee>
          </div>
          <div className="search_and_add">
            <select disabled={!userDetails?.isWorkBookAvailable} className="select_dropdown_for_contract" onChange={handleSelectContractDropdown}>
              {handleWorkbookDropDown.map(({ contractType, value }, index) => (
                <option name={contractType} key={index} value={value}>
                  {contractType}
                </option>
              ))}
            </select>
            <div className="drop_down_for_add">
              <div className="drop_down_div">
                {(contractSearchData?.value === 1 || contractSearchData?.value === 2) && (
                  <div className="drop_down">
                    <Autocomplete
                      id="country-select-demo1 dropDownForAddContract"
                      sx={{ width: '100%', height: '32px' }}
                      className="auto_complete"
                      disableClearable
                      disabled={!userDetails?.isWorkBookAvailable}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      options={searchContractList}
                      autoHighlight
                      getOptionLabel={(option) => option.contractName || 'No Label'}
                      clearOnBlur={false}
                      value={selectedContract}
                      inputValue={inputValue}
                      onInputChange={(event, newValue) => setInputValue(newValue)}
                      onChange={(event, newValue) => {
                        setSelectedContract(newValue)
                        setContractData(newValue)
                        setInputValue(newValue?.contractName || '')
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.contractName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={isTerminalDataExists ? 'No Options' : 'Add Contract'}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                            style: { border: 'none', height: '0px' }
                          }}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              {contractSearchData?.value === 3 && (
                <div className="dividerDropDownDiv">
                  {t('ROBUSTA_DIVIDER')}
                  <DragIndicatorIcon sx={{ width: '18px', height: '18px', color: 'gray', margin: '3px' }} />
                </div>
              )}
              {contractSearchData?.value === 4 && (
                <div className="dividerDropDownDiv">
                  {t('ARABICA_DIVIDER')}
                  <DragIndicatorIcon sx={{ width: '18px', height: '18px', color: 'gray', margin: '3px' }} />
                </div>
              )}
            </div>
            <button onClick={addWorkBookRowToRespectiveTable} className="add_btn">
              {t('ADD')}
            </button>
          </div>
        </div>
        <div className="coffee_quotes_screen_body">
          {/* Robusta and arabica table */}
          <Table hover className="robusta_and_arabica_table" wrapperclasses="table-responsive" responsive>
            <tbody className="robusta_table">
              <tr className="table_head">
                <td className="contract_names">{t('ROBUSTA_CONTRACTS')}</td>
                <td>{t('LAST')}</td>
                <td>{t('CHG')}</td>
                <td>%</td>
                <td>{t('VOL')}</td>
                <td>{t('HIGH')}</td>
                <td>{t('NET_HIGH')}</td>
                <td>{t('LOW')}</td>
                <td>{t('NET_LOW')}</td>
                <td>{t('OPEN')}</td>
                <td>{t('PRE_CLOSE')}</td>
                <td>{t('OPEN_INT')}</td>
                <td>{t('BID')}</td>
                <td>{t('BSIZE')}</td>
                <td>{t('ASK')}</td>
                <td>{t('ASIZE')}</td>
                <td>{t('OPTION_EXPIRY')}</td>
                <td>{t('FIRST_NOTICE_DAY')}</td>
                <td></td>
              </tr>
              {robustaData &&
                robustaData.map((item, index) => (
                  <React.Fragment key={index}>
                    <AccordianData handleSelectContract={handleSelectContract} item={item} keyID={index} key={index} />
                  </React.Fragment>
                ))}
            </tbody>
            <tbody className="arabica_table">
              <tr className="table_head">
                <td className="contract_names">{t('ARABICA_CONTRACTS')}</td>
                <td>{t('LAST')}</td>
                <td>{t('CHG')}</td>
                <td>%</td>
                <td>{t('VOL')}</td>
                <td>{t('HIGH')}</td>
                <td>{t('NET_HIGH')}</td>
                <td>{t('LOW')}</td>
                <td>{t('NET_LOW')}</td>
                <td>{t('OPEN')}</td>
                <td>{t('PRE_CLOSE')}</td>
                <td>{t('OPEN_INT')}</td>
                <td>{t('BID')}</td>
                <td>{t('BSIZE')}</td>
                <td>{t('ASK')}</td>
                <td>{t('ASIZE')}</td>
                <td>{t('OPTION_EXPIRY')}</td>
                <td>{t('FIRST_NOTICE_DAY')}</td>
                <td></td>
              </tr>
              {arabicaData &&
                arabicaData.map((item, index) => (
                  <React.Fragment key={index}>
                    <AccordianData handleSelectContract={handleSelectContract} item={item} keyID={index} key={index} />
                  </React.Fragment>
                ))}
            </tbody>
          </Table>

          <AccordianForMobile data={robustaData} labeling={t('ROBUSTA_CONTRACTS')} />
          <AccordianForMobile data={arabicaData} labeling={t('ARABICA_CONTRACTS')} />
          {/* workbook arabica and arabica table */}
          {userDetails?.isWorkBookAvailable === true && (
            <Table hover className="worbook_table" wrapperclasses="" responsive>
              <tbody className="workbook_body">
                <tr className="table_head smoothScrollForRobusta">
                  <td className="contract_names">
                    <span>{t('ROBUSTA_WORKBOOK')}</span>
                  </td>
                  <td>{t('GRADE')}</td>
                  <td>{t('NET_PRICE')}</td>
                  <td>{t('OUT_RATE')}</td>
                  <td>{t('LAST')}</td>
                  <td>{t('DIFFERENTIAL')}</td>
                  <td colSpan={2}>{t('TOTAL')}</td>
                  <td>
                    <div className="fx_currency">
                      {t('FX')}-
                      <select className="fxcurrency_select_dropdown" onChange={(e) => handleFxCurrencySelectForRobusta(e.target.value, 'robusta')}>
                        {fxCurrencyList?.length ? (
                          fxCurrencyList?.map((item, index) => (
                            <option value={item.last} className="" key={index}>
                              {item.currencyName}
                            </option>
                          ))
                        ) : (
                          <>
                            <option> {t('NO_DATA')}</option>
                          </>
                        )}
                      </select>
                    </div>
                  </td>
                  <td>{t('FX_PRE_DIS')}</td>
                  <td>{t('FX_NET')}</td>
                  <td>{t('GROSS_PRICE')}</td>
                  <td>{t('PROFIT')}</td>
                  <td>{t('NET_PRICE')}</td>
                  <td>
                    <Tooltip title={t('SAVE_ALL_CHANGES')} placement="top-end" arrow>
                      <SaveIcon className="workBookIcons" fontSize="small" stroke={workbookRobustaData?.length > 0 ? 'green' : 'gray'} onClick={() => handleSaveDivider('robusta', null)} />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title={t('SELECT_ALL_ROBUSTA_WORKBOOK')} placement="top-end" arrow>
                      <input className="delete-checkbox" type="checkbox" onChange={() => selectAllWorkbook('robusta')} checked={workbookRobustaAllChecked && workbookRobustaData.length !== 0} />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title={t('DELETE_SELECTED_WORKBOOK_DATA')} placement="top-end" arrow>
                      <DeleteIcon className="workBookIcons" onClick={showDeleteButtonForRobusta ? () => handleClickDelete('robusta') : null} fill={showDeleteButtonForRobusta ? '#FF0000' : '#6c757d'} />
                    </Tooltip>
                  </td>
                </tr>
                {workbookRobustaData &&
                  workbookRobustaData?.map((ele, index) => (
                    <tr draggable onDragStart={(event) => onDragStartRobusta(event, ele?.idWorkbook)} onDragOver={(event) => event.preventDefault()} onDrop={(event) => onDropworkbook(event, ele, index)} key={index} className={getTableRowClassName(ele, 'Robusta')}>
                      {ele?.workbookType !== 0 && (
                        <>
                          <td className="contract_names" data-title={t('ROBUSTA')}>
                            {ele?.contractName}
                          </td>
                          <td data-title={t('GRADE')}>
                            <div className="input_div">
                              <input type="text" className="input_field grade" name="grade" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.grade} placeholder="Grade" autoComplete="off" />
                            </div>
                          </td>
                          <td data-title={t('NET_PRICE')} className="custom-netPrice">
                            <div>
                              {ele?.isNetPriceValueCustom ? <input type="number" className="input_field custom-input" name="customNetPrice" onChange={(event) => handleChange(event, ele, index)} value={ele?.customNetPrice} placeholder="0" autoComplete="off" /> : <span className={getNetPriceClassName(ele?.chngColorCondition)}>{handleDecimal(ele?.netPrice, 0)}</span>}
                              <SwapVertIcon className="swap-icon" onClick={() => swapNetPrice(ele, index, 'workbookRobusta')} />
                            </div>
                          </td>
                          <td data-title={t('OUT_RATE')} className="custom-lastChg">
                            <div>
                              {ele?.isOutRateValueCustom ? <input type="number" className="input_field custom-input" name="customOutRate" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.customOutRate} placeholder="0" autoComplete="off" /> : <span className={getNetPriceClassName(ele?.chngColorCondition, ele)}>{ele?.workbookTotal}</span>}
                              <SwapVertIcon className="swap-icon" onClick={() => swapOutRate(ele, index, 'workbookRobusta')} />
                            </div>
                          </td>
                          <td data-title={t('LAST')} className="custom-lastChg">
                            <div>
                              {ele?.isLastValueCustom ? <input type="number" className="input_field custom-input" name="customLastChg" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.customLastChg} placeholder="0" autoComplete="off" /> : <span className={getNetPriceClassName(ele?.chngColorCondition)}>{ele?.lastChng}</span>}
                              <SwapVertIcon className="swap-icon" onClick={() => swapLastChg(ele, index, 'workbookRobusta')} />
                            </div>
                          </td>
                          <td data-title={t('DIFFERENTIAL')}>
                            <div className="input_div">
                              {/* here ele?.workbookDifferential is string */}
                              {ele?.isNetPriceValueCustom || ele.isOutRateValueCustom ? <span className="bold_and_dark_gray">{ele.customWorkbookDifferential}</span> : <input type="number" className="input_field" name="workbookDifferential" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.workbookDifferential != 0 && ele?.workbookDifferential} placeholder="0" />}
                            </div>
                          </td>
                          <td data-title={t('TOTAL')} colSpan={2}>
                            {ele?.workbookTotal}
                          </td>
                          <td data-title="FX Currency" className="fxCurrency">
                            {handleDecimal(ele?.fxcurrency, 2)}
                          </td>
                          <td data-title="FX-Pre/Dis">
                            <div className="input_div">
                              {/* here ele?.fxpredis is string */}
                              <input type="number" className="input_field" name="fxpredis" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.fxpredis != 0 && ele?.fxpredis} placeholder="0" />
                            </div>
                          </td>
                          <td data-title="FX Net">{ele?.fxnet}</td>
                          <td data-title="Gross Price">{ele?.grossPrice}</td>
                          <td data-title="Profit">
                            <div className="input_div">
                              {/* here ele?.profit is string */}
                              <input type="number" className="input_field" name="profit" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.profit != 0 && ele?.profit} placeholder="0" />
                            </div>
                          </td>
                          <td data-title="Net Price">
                            <span className="span_net_price">{ele?.netPrice}</span>
                          </td>
                          <td></td>
                          <td>
                            <input className="delete-checkbox" type="checkbox" onChange={() => selectCheckBox(ele, index)} checked={ele?.isChecked} />
                          </td>
                          <td>
                            <DragIndicatorIcon className="dragIcon" sx={{ width: '17px', height: '17px', color: 'gray', margin: '3px' }} />
                          </td>
                        </>
                      )}
                      {ele?.workbookType === 0 && (
                        <>
                          <td className="workbookDividerTd" colSpan={14}>
                            <input className="dividerInput" placeholder="Divider name" value={ele?.contractName} onChange={(event) => handleChangeForDivider(event, ele, index)} />
                          </td>
                          <td className="workbookDividerTd"></td>
                          <td className="workbookDividerTd">
                            <input className="delete-checkbox" type="checkbox" onChange={() => selectAllCheckBoxForDivider(ele, index)} checked={ele?.isChecked ? true : false} />
                          </td>
                          <td className="workbookDividerTd">
                            <DragIndicatorIcon className="dragIcon" sx={{ width: '17px', height: '17px', color: 'gray', margin: '3px' }} />
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
              <tbody className="workbook_body">
                <tr className="table_head smoothScrollForArabica">
                  <td className="contract_names">
                    <span>{t('ARABICA_WORKBOOK')}</span>
                  </td>
                  <td>{t('GRADE')}</td>
                  <td>{t('NET_PRICE')}</td>
                  <td>{t('OUT_RATE')}</td>
                  <td>{t('LAST')}</td>
                  <td>{t('DIFFERENTIAL')}</td>
                  <td>{t('TOTAL')}</td>
                  <td>{t('CONVTO')}-$</td>
                  <td>
                    <div className="fx_currency">
                      {t('FX')}-
                      <select onChange={(e) => handleFxCurrencySelectForArabica(e.target.value)} className="fxcurrency_select_dropdown">
                        {fxCurrencyList?.length ? (
                          fxCurrencyList?.map((item, index) => (
                            <option value={item.last} className="" key={index}>
                              {item.currencyName}
                            </option>
                          ))
                        ) : (
                          <option> {t('NO_DATA')}</option>
                        )}
                      </select>
                    </div>
                  </td>
                  <td>{t('FX_PRE_DIS')}</td>
                  <td>{t('FX_NET')}</td>
                  <td>{t('GROSS_PRICE')}</td>
                  <td>{t('PROFIT')}</td>
                  <td>{t('NET_PRICE')}</td>
                  <td>
                    <Tooltip title={t('SAVE_ALL_CHANGES')} placement="top-end" arrow>
                      <SaveIcon className="workBookIcons" fontSize="small" stroke={workbookArabicaData?.length > 0 ? 'green' : 'gray'} onClick={() => handleSaveDivider('arabica', null)} />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title={t('SELECT_ALL_ARABICA_WORKBOOK')} placement="top-end" arrow>
                      <input className="delete-checkbox" type="checkbox" onChange={() => selectAllWorkbook('arabica')} checked={workbookArabicaAllChecked && workbookArabicaData.length !== 0} />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title={t('DELETE_SELECTED_WORKBOOK_DATA')} placement="top-end" arrow>
                      <DeleteIcon className="workBookIcons" onClick={showDeleteButtonForArabica ? () => handleClickDelete('arabica') : null} fill={showDeleteButtonForArabica ? '#FF0000' : '#6c757d'} />
                    </Tooltip>
                  </td>
                </tr>
                {workbookArabicaData &&
                  workbookArabicaData.map((ele, index) => (
                    <tr draggable onDragStart={(event) => onDragStartArabica(event, ele?.idWorkbook)} onDragOver={(event) => event.preventDefault()} onDrop={(event) => onDropworkbook(event, ele, index)} key={index} className={getTableRowClassName(ele, 'Arabica')}>
                      {ele.workbookType !== 0 && (
                        <>
                          <td className="contract_names" data-title="Arabica">
                            {ele?.contractName}
                          </td>
                          <td data-title="Grade">
                            <div className="input_div">
                              <input type="text" className="input_field grade" name="grade" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.grade} placeholder="Grade" autoComplete="off" />
                            </div>
                          </td>

                          <td data-title="Net Price" className="custom-netPrice">
                            <div>
                              {ele?.isNetPriceValueCustom ? <input type="number" className="input_field custom-input" name="customNetPrice" onChange={(event) => handleChange(event, ele, index)} value={ele?.customNetPrice} placeholder="0" autoComplete="off" /> : <span className={getNetPriceClassName(ele?.chngColorCondition)}>{handleDecimal(ele?.netPrice, 0)}</span>}
                              <SwapVertIcon className="swap-icon" onClick={() => swapNetPrice(ele, index, 'workbookArabica')} />
                            </div>
                          </td>

                          <td data-title={t('OUT_RATE')} className="custom-netPrice">
                            <div>
                              {ele?.isOutRateValueCustom ? <input type="number" className="input_field custom-input" name="customOutRate" onChange={(event) => handleChange(event, ele, index)} value={ele?.customOutRate} placeholder="0" autoComplete="off" /> : <span className={getNetPriceClassName(ele?.chngColorCondition, ele)}>{ele?.convertTo}</span>}
                              <SwapVertIcon className="swap-icon" onClick={() => swapOutRate(ele, index, 'workbookArabica')} />
                            </div>
                          </td>
                          <td data-title={t('LAST')} className="custom-lastChg">
                            <div>
                              {ele?.isLastValueCustom ? <input type="number" className="input_field custom-input" name="customLastChg" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.customLastChg} placeholder="0" autoComplete="off" /> : <span className={getNetPriceClassName(ele?.chngColorCondition)}>{ele?.lastChng}</span>}
                              <SwapVertIcon className="swap-icon" onClick={() => swapLastChg(ele, index, 'workbookArabica')} />
                            </div>
                          </td>

                          <td data-title="Differential">
                            <div className="input_div">
                              {/* here ele?.workbookDifferential} is sting */}
                              {ele?.isNetPriceValueCustom || ele.isOutRateValueCustom ? <span className="bold_and_dark_gray">{ele.customWorkbookDifferential}</span> : <input type="number" className="input_field" name="workbookDifferential" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.workbookDifferential != 0 && ele?.workbookDifferential} placeholder="0" />}
                            </div>
                          </td>
                          <td data-title="Total">{ele?.workbookTotal}</td>
                          <td data-title="Conv.to $">{ele?.convertTo}</td>
                          <td data-title="FX Currency" className="fxCurrency fx_currency_title">
                            {handleDecimal(ele?.fxcurrency, 2)}
                          </td>
                          <td data-title="FX-Pre/Dis">
                            <div className="input_div">
                              {/* here ele?.fxpredis is string */}
                              <input type="number" className="input_field" name="fxpredis" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.fxpredis != 0 && ele?.fxpredis} placeholder="0" />
                            </div>
                          </td>
                          <td data-title="FX Net">{ele?.fxnet}</td>
                          <td data-title="Gross Price">{ele?.grossPrice}</td>
                          <td data-title="Profit">
                            <div className="input_div">
                              {/* here ele?.profit is string */}
                              <input type="number" className="input_field" name="profit" onChange={(event) => handleChange(event, ele, index)} onFocus={() => handleFocus(ele, index)} onBlur={() => handleBlur(ele, index)} value={ele?.profit != 0 && ele?.profit} placeholder="0" />
                            </div>
                          </td>
                          <td data-title="Net Price">
                            <span>{ele?.netPrice}</span>
                          </td>
                          <td></td>
                          <td>
                            <input className="delete-checkbox" type="checkbox" onChange={() => selectCheckBox(ele, index)} checked={ele?.isChecked} />
                          </td>
                          <td>
                            <DragIndicatorIcon className="dragIcon" sx={{ width: '17px', height: '17px', color: 'gray', margin: '3px' }} />
                          </td>
                        </>
                      )}
                      {ele?.workbookType === 0 && (
                        <>
                          <td className="workbookDividerTd" colSpan={14}>
                            <input className="dividerInput" placeholder="Divider name" value={ele?.contractName} onChange={(event) => handleChangeForDivider(event, ele, index)} />
                          </td>
                          <td className="workbookDividerTd"></td>
                          <td className="workbookDividerTd">
                            <input className="delete-checkbox" type="checkbox" onChange={() => selectAllCheckBoxForDivider(ele, index)} checked={ele?.isChecked ? true : false} />
                          </td>
                          <td className="workbookDividerTd">
                            <DragIndicatorIcon className="dragIcon" sx={{ width: '17px', height: '17px', color: 'gray', margin: '3px' }} />
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {/* divider warning popup */}
          <Modal className="coffee_quotes_modal" show={dividerWarningPopup} onHide={() => setDividerWarningPopup(false)} backdrop="static" keyboard={false}>
            <Modal.Header className="coffee-quotes-modal-header">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>
            <Modal.Body>
              <div>
                <ErrorOutlineIcon className="coffee_quotes_modal_error" />
                <h4 className="coffee_quotes_modal_h4">{t('PLEASE_FILL_DIVIDER_NAME')}</h4>
                <div className="coffee_quotes_modal_button_div">
                  <button className="coffee_quotes_modal_button" onClick={() => setDividerWarningPopup(false)}>
                    {t('OK')}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        {/* failed to fetch data snackbar */}
        <Snackbar open={showSnackbar} sx={{ marginBottom: '20px' }} autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }}>
          <MuiAlert elevation={6} variant="filled" severity="error" sx={{ width: '100%' }}>
            {t('FAILED_TO_GET_DATA')}
          </MuiAlert>
        </Snackbar>
      </div>
    </>
  )
}

export default Loader(ApiResponseHandler(CoffeQuotes))
