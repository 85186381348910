import './styles.scss'
import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Modal from 'react-bootstrap/Modal'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { doc, onSnapshot } from 'firebase/firestore'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { coffeewebGetLocal, coffeewebSetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { db } from 'firebase.js'
import { Logo, devEnvLogo } from 'Assets/Icons'
import Loader from 'Components/LayoutComponents/Loader'

const NotificationListenService = ({ setLoading }) => {
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const params = new URLSearchParams()
  const { t } = useTranslation()

  const history = useHistory()
  const onlyPageSize = 8
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [userNotificationForUser, setUserNotificationForUser] = useState([])
  const [onlyPageNumber, setOnlyPageNumber] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [showTostify, setShowTostify] = useState(false)
  const [isError, setIsError] = useState(false)
  const [firebaseLatestNewsId, setFirebaseLatestNewsId] = useState(null)
  const [notificationCount, setNotificationCount] = useState(null)
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const open = Boolean(anchorEl)
  const [notificationMessageDetail, setNotificationMessageDetail] = useState({
    message: '',
    notificationId: ''
  })

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    getUsersNotificationForUser(onlyPageNumber, onlyPageSize)
    let mounted = false

    if (userDetails?.phone) {
      const firebaseNewsId = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb-MicroServices' : 'CoffeeWeb', 'NewsFeeds'), (doc) => {
        const firebaseResponse = doc.data()

        const subscriptionList = firebaseResponse?.LatestNewsForSubscription?.split(',')?.map((number) => parseInt(number.trim(), 10))
        const countryList = firebaseResponse?.LatestNewsForCountries?.split(',')?.map((number) => parseInt(number.trim(), 10))

        if (mounted) {
          if (subscriptionList.includes(userDetails?.sub?.subType) && countryList.includes(userDetails?.countryId) && !firebaseResponse?.IsDeleted && firebaseResponse?.LatestNewsLanguage === userDetails?.languageId && firebaseResponse?.SendNotification) {
            setFirebaseLatestNewsId(firebaseResponse?.LatestNewsId)
          }
        } else {
          mounted = true
        }
      })

      const firebaseNotificationCount = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb-MicroServices' : 'CoffeeWeb', userDetails?.phone), (doc) => {
        const firebaseResponse = doc.data()

        if (firebaseResponse?.UserNotifications?.UnreadCount >= 0) {
          setNotificationCount(firebaseResponse?.UserNotifications?.UnreadCount)
          setUnreadNotificationCount(firebaseResponse?.UserNotifications?.UnreadCount)
        }
      })

      return () => {
        firebaseNewsId()
        firebaseNotificationCount()
      }
    }
  }

  useEffect(() => {
    const latestNewsReadNotification = coffeewebGetLocal(coffeewebStorageKeys.latestNewsReadNotification)

    const selectedNewsId = typeof latestNewsReadNotification === 'number' ? [latestNewsReadNotification] : latestNewsReadNotification

    const newArray = userNotificationForUser?.map((ele) => {
      if (selectedNewsId?.includes(ele.sourceId)) {
        return { ...ele, isRead: true }
      }

      return ele
    })

    setUserNotificationForUser(newArray)

    if (notificationCount === 0) {
      setUserNotificationForUser(userNotificationForUser?.map((ele) => ({ ...ele, isRead: true })))
    }
  }, [notificationCount])

  useEffect(() => {
    if (firebaseLatestNewsId) {
      getNewsAndMedia()
    }
  }, [firebaseLatestNewsId])

  const getUsersNotificationForUser = async (pageNumber, pageSize) => {
    await apiAdapterCoffeeWeb
      .getUsersNotificationForUser({
        userId: userDetails.id,
        pageNumber,
        pageSize
      })
      .then((response) => {
        if (response.data?.returnLst?.appNotificationDTO.length > 0) {
          setUserNotificationForUser((userNotificationForUser) => [...userNotificationForUser, ...response.data.returnLst.appNotificationDTO])
          setOnlyPageNumber(onlyPageNumber + 1)
          setIsError(false)

          const unReadNotifications = response.data.returnLst.notificationCount.unreadNotification

          setUnreadNotificationCount(unReadNotifications)
        } else {
          setHasMore(false)
        }
      })
      .catch(() => {
        setHasMore(false)
        setUserNotificationForUser('')
        setIsError(true)
      })
  }

  const getNewsAndMedia = async () => {
    await apiAdapterCoffeeWeb
      .getNewsAndMediaById(firebaseLatestNewsId)
      .then((response) => {
        const newObject = {
          isRead: false,
          sourceId: firebaseLatestNewsId,
          notificationMessage: response.data?.returnLst[0]?.subject,
          timeOnly: 'Just Now'
        }

        let found = false

        for (let i = 0; i < userNotificationForUser?.length; i++) {
          if (userNotificationForUser[i].sourceId === firebaseLatestNewsId) {
            found = true
          }
        }

        if (!found) {
          setUserNotificationForUser([newObject, ...userNotificationForUser])

          setShowTostify(true)

          setTimeout(() => {
            setShowTostify(false)
          }, 4000)
        }

        setNotificationMessageDetail({ ...notificationMessageDetail, message: response.data?.returnLst[0]?.subject, notificationId: firebaseLatestNewsId })
      })
      .catch(() => {
        setHasMore(false)
      })
  }

  const markNewsAsRead = async () => {
    setShowModal(false)
    setLoading(true)
    const updateData = {
      userId: userDetails.id,
      newsId: []
    }

    try {
      const { data } = await apiAdapterCoffeeWeb.updateNotificationReadAll(updateData)

      if (data) {
        const updatedNews = userNotificationForUser.map((ele) => ({ ...ele, isRead: true }))

        setUserNotificationForUser(updatedNews)
        setUnreadNotificationCount(0)
      }
    } catch (error) {
      console.log(error.response)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenNotification = (item, index) => {
    const { isRead, sourceId } = item

    if (isRead === false) {
      if (notificationCount >= 0) {
        setNotificationCount(notificationCount - 1)
      }
    }
    const updatedCards = userNotificationForUser.map((ele) => {
      if (ele.sourceId === sourceId) {
        return {
          ...ele,
          isRead: true
        }
      }

      return ele
    })

    setUserNotificationForUser(updatedCards)

    params.set('newsId', sourceId)

    history.push({
      search: params.toString(),
      state: { pageRef: 'yes' },
      pathname: ROUTE_STRINGS.opennewsnotification
    })
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <div>
        <div>
          {showTostify && (
            <div className="toast_div">
              <div className="toast_sub_div">
                <div className="toast_container">{ReactHtmlParser(notificationMessageDetail?.message)}</div>
              </div>
            </div>
          )}
        </div>
        <Box className="notification_icon_box">
          <IconButton onClick={handleClick} size="small" aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
            {notificationCount ? (
              <Badge badgeContent={notificationCount} color="error" className="notification_badge">
                <CircleNotificationsIcon className="notification_icon" color="action" />
              </Badge>
            ) : (
              <Badge color="error" className="notification_badge">
                <CircleNotificationsIcon className="notification_icon" color="action" />
              </Badge>
            )}
          </IconButton>
        </Box>

        <div className="chats">
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <div className="popup_card_for_notification" id="scrollableDiv">
              <div className="notifiction_sub_div">
                <div>{t('NOTIFICATIONS')}</div>
                <div>
                  {unreadNotificationCount > 0 && (
                    <div className="see_more_div" onClick={() => setShowModal(true)}>
                      {t('MARK_ALL_AS_READ')}
                    </div>
                  )}
                </div>
              </div>
              {userNotificationForUser.length && (
                <div className="see-more-notification">
                  <div className="see-all-notification-text" onClick={() => history.push(ROUTE_STRINGS.notification)}>
                    {t('SEE_ALL_NOTIFICATIONS')}
                  </div>
                </div>
              )}

              <InfiniteScroll dataLength={userNotificationForUser.length} next={() => getUsersNotificationForUser(onlyPageNumber, onlyPageSize)} hasMore={hasMore} scrollableTarget="scrollableDiv" loader={<div className="infinite_scroll_div">Loading...</div>} className="infinite_scroll">
                {userNotificationForUser &&
                  userNotificationForUser?.map((ele, index) => (
                    <MenuItem key={index} className="notifiction_menu" onClick={() => handleOpenNotification(ele, index)} id={ele.isRead === false && 'unRead'}>
                      <div className="notifiction_list">
                        <div>
                          <div className="notifiction_html_news_list">{ReactHtmlParser(ele.notificationMessage)}</div>
                        </div>
                        <div className="notification_news_date">{ele.timeOnly}</div>
                      </div>
                    </MenuItem>
                  ))}
              </InfiniteScroll>
              {userNotificationForUser?.length === 0 && !isError && <div className="no-notification">{t('NO_NOTIFICATIONS')}</div>}
              {isError && <p className="no-notification">{t('FAILED_TO_FETCH_NOTIFICATION')}</p>}
            </div>
          </Menu>
        </div>
      </div>
      <Modal show={showModal} backdrop="static" keyboard={false} className="notification-modal">
        <Modal.Header className="notification-modal-header">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>
        <Modal.Body className="notification-modal-body">
          <div>
            <h4 className="notification-text">{t('CONFORMATION_NOTIFICATION')}</h4>
            <div className="notification-modal-buttons">
              <button className="notification-modal-no-button" onClick={() => setShowModal(false)}>
                {t('NO')}
              </button>
              <button className="notification-modal-yes-button" onClick={markNewsAsRead}>
                {t('YES')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Loader(NotificationListenService)
