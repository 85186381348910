import './styles.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PickList } from 'primereact/picklist'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { SelectButton } from 'primereact/selectbutton'
import { Divider } from 'primereact/divider'
import swal from 'sweetalert'
import Loader from 'Components/LayoutComponents/Loader'
import { coffeewebGetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'

const ClockSettings = ({ setLoading, visible, setVisible, pickListSourceData, setPickListSourceData, setFavoriteCountryClock, setClockDataForAllCountries, setClockTab, pickListTargetData, setPickListTargetData }) => {
  const { t } = useTranslation()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)

  const clockType = ['Analog', 'Digital']
  const timeFormat = ['12-hour', '24-hour']

  const [buttonDisable, setButtonDisable] = useState(true)
  const [selectedClockType, setSelectedClockType] = useState(() => localStorage.getItem('selectedClockDisplay') || 'Analog')
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(() => localStorage.getItem('selectedTimeFormat') || '12-hour')

  const selectedCountryId = pickListTargetData.map((country) => country.countryId)

  const postFavoriteCountry = async () => {
    setLoading(true)
    const { id } = userDetails

    const postData = {
      userId: id,
      favouriteCountries: selectedCountryId.toString()
    }

    try {
      const {
        data: { statusCode, returnLst }
      } = await apiAdapterCoffeeWeb.makeFavoriteCountryClock(postData)

      if (statusCode === 200 || statusCode === 201) {
        const tabVisible = returnLst.some((ele) => ele.isFavorite)
        const favoriteCountries = returnLst.filter((country) => country.isFavorite)

        if (tabVisible) {
          setClockTab('My Favourite')
          localStorage.setItem('clockTab', 'My Favourite')
        } else {
          setClockTab('All Clock')
          localStorage.setItem('clockTab', 'All Clock')
        }

        setVisible(false)
        setClockDataForAllCountries(returnLst)
        setFavoriteCountryClock(favoriteCountries)
      }
    } catch (error) {
      swal({
        title: t('FAILED'),
        text: t('SOMETHING_WENT_WRONG'),
        icon: 'error'
      })
    } finally {
      setLoading(false)
      setVisible(false)
    }
  }

  const pickListItemTemplate = (item) => (
    <div className="country-picker-item">
      <div className="country-picker-flag-container">
        <img className="country-picker-flag" src={`${item?.flagURL}`} alt={item?.countryName} />
      </div>
      <div className="country-picker-name-container">
        <span className="country-picker-name">{item?.countryName}</span>
      </div>
    </div>
  )

  const onChange = (event) => {
    setButtonDisable(false)
    setPickListSourceData(event.source)
    setPickListTargetData(event.target)
  }

  const onClockTypeChange = (event) => {
    const selectedClockDisplay = event.target.value

    if (selectedClockDisplay === 'Analog') {
      setVisible(false)
      localStorage.setItem('selectedClockDisplay', selectedClockDisplay)
    } else {
      localStorage.setItem('selectedClockDisplay', selectedClockDisplay)
      localStorage.setItem('selectedTimeFormat', '12-hour')
      setSelectedTimeFormat('12-hour')
    }
    setSelectedClockType(selectedClockDisplay)
  }

  const onClockFormatChange = (event) => {
    const clockTimeFormat = event.target.value

    setVisible(false)
    setSelectedTimeFormat(clockTimeFormat)

    localStorage.setItem('selectedClockDisplay', 'Digital')
    localStorage.setItem('selectedTimeFormat', clockTimeFormat)
  }

  const headerElement = (
    <div className="dialog-header-container">
      <span className="font-bold white-space-nowrap">{t('CLOCK_SETTINGS')}</span>
    </div>
  )

  const footerContent = (
    <div className="dialog-button-container">
      <Button label={t('CLOSE')} className="close-button" onClick={() => setVisible(false)} autoFocus />
      <Button label={t('SAVE')} className="submit-button" onClick={postFavoriteCountry} autoFocus disabled={buttonDisable} />
    </div>
  )

  return (
    <div className="clock-settings-container">
      <Dialog visible={visible} modal header={headerElement} footer={footerContent} onHide={() => setVisible(false)} className="clock-settings-dialog">
        <div className="clock-type-wrapper">
          <div className="clock-type-title">{t('SELECT_TYPE')}</div>
          <div className="select-button-wrapper">
            <SelectButton value={selectedClockType} onChange={onClockTypeChange} options={clockType} className="select-button" allowEmpty={false} />
          </div>

          {selectedClockType === 'Digital' && (
            <div className="time-format-toggle">
              <div className="time-format-label">{t('SELECT_TIME_FORMAT')}</div>
              <div className="time-format-select-button">
                <SelectButton value={selectedTimeFormat} onChange={onClockFormatChange} options={timeFormat} className="select-button" allowEmpty={false} />
              </div>
            </div>
          )}
        </div>
        <Divider />
        <div className="my-favorite-wrapper">
          <div className="country-pick-list-title">{t('FAVORITE_CLOCK')}</div>
          <div className="country-pick-list-container">
            <PickList
              dataKey="countryId"
              source={pickListSourceData}
              target={pickListTargetData}
              showSourceControls={false}
              showTargetControls={false}
              onChange={onChange}
              itemTemplate={pickListItemTemplate}
              filter
              filterBy="countryName"
              sourceHeader={t('AVAILABLE_COUNTRIES')}
              targetHeader={t('SELECTED_COUNTRIES')}
              sourceFilterPlaceholder={t('SEARCH_BY_NAME')}
              targetFilterPlaceholder={t('SEARCH_BY_NAME')}
              pt={{
                moveAllToTargetButton: {
                  root: { className: 'country-picking-button' }
                },
                moveAllToSourceButton: {
                  root: { className: 'country-picking-button' }
                },
                moveToTargetButton: {
                  root: { className: 'country-picking-button' }
                },
                moveToSourceButton: {
                  root: { className: 'country-picking-button' }
                }
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default Loader(ClockSettings)
