import './style.scss'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Closebtn } from 'Assets/Icons'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import { useTranslation } from 'react-i18next'

const AccordianPopUp = (props) => {
  const { item, accordianPopupListData, terminalPopUp, setShowTerminalPopup, handleApiResponse } = props
  const totalSlides = 2
  const { t } = useTranslation()

  const [slideIndex, setSlideIndex] = useState(1)
  const [terminalData, setTerminalData] = useState([])
  const [terminalDataDetail, setTerminalDataDetail] = useState(null)
  const [showTodayTerminal, setShowTodayTerminal] = useState(false)

  useEffect(() => {
    if (item.terminalId !== terminalDataDetail?.terminalId) {
      getTerminalData()
      setTerminalDataDetail(item)
    }

    const timeZoneOffsetIST = -330
    const startTimeIST = (13 * 60 + 30 + timeZoneOffsetIST) % (24 * 60)
    const endTimeIST = (22 * 60 + 30 + timeZoneOffsetIST) % (24 * 60)
    const currentTime = new Date().getUTCHours() * 60 + new Date().getUTCMinutes()

    if (currentTime >= startTimeIST && currentTime <= endTimeIST) {
      setShowTodayTerminal(true)
    }
  }, [item])

  const lastChng = item?.idMarket === 1 ? item?.lastChng : parseFloat(item?.lastChng || 0).toFixed(2)
  const chng = item?.idMarket === 1 ? item?.chng : parseFloat(item?.chng || 0).toFixed(2)
  const percentageVal = parseFloat(item?.percentageVal || 0).toFixed(2)
  const highRate = item?.idMarket === 1 ? item?.highRate : parseFloat(item?.highRate || 0).toFixed(2)
  const netHigh = item?.idMarket === 1 ? item?.netHigh : parseFloat(item?.netHigh || 0).toFixed(2)
  const netLow = item?.idMarket === 1 ? item?.netLow : parseFloat(item?.netLow || 0).toFixed(2)
  const lowRate = item?.idMarket === 1 ? item?.lowRate : parseFloat(item?.lowRate || 0).toFixed(2)
  const openRate = item?.idMarket === 1 ? item?.openRate : parseFloat(item?.openRate || 0).toFixed(2)
  const prevRate = item?.idMarket === 1 ? item?.prevRate : parseFloat(item?.prevRate || 0).toFixed(2)
  const bid = item?.idMarket === 1 ? item?.bid : parseFloat(item?.bid || 0).toFixed(2)
  const ask = item?.idMarket === 1 ? item?.ask : parseFloat(item?.ask || 0).toFixed(2)

  const getOptionExpiryColor = (optionExpiryStatus) => {
    if (optionExpiryStatus === 'boldAndRed') return 'bold_and_red'
    if (optionExpiryStatus === 'expired') return 'expired'

    return ''
  }

  const getFirstNoticeDayColor = (firstNoticeDateStatus) => {
    if (firstNoticeDateStatus === 'boldAndBlack') return 'bold_and_black'
    if (firstNoticeDateStatus === 'boldAndRed') return 'bold_and_red'
    if (firstNoticeDateStatus === 'expired') return 'expired'

    return ''
  }

  const [isTerminalDataExists, setIsTerminalDataExists] = useState(true)

  const getTerminalData = async () => {
    await apiAdapterCoffeeWeb

      .getTerminalDataByContractName(item?.contractId)
      .then((response) => {
        if (response?.data?.returnLst?.length) {
          const { data } = response

          setTerminalData(data?.returnLst)
          setIsTerminalDataExists(false)
        } else {
          setIsTerminalDataExists(true)
          setTerminalData([])
        }
      })
      .catch((error) => {
        handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_TERMINAL_DATA', onRetry: getTerminalData })
        setTerminalData([])
        setIsTerminalDataExists(true)
      })
  }

  const getNetHighAndLowColor = (ele) => {
    if (ele > 0) {
      return 'text_new_green'
    }
    if (ele < 0) {
      return 'text_new_red'
    }

    return ''
  }

  const closeValue = terminalData[0]?.close || 0
  const lastChangeValue = item.idMarket === 1 ? (lastChng - closeValue).toFixed(0) : (lastChng - closeValue).toFixed(2)

  return (
    <div>
      <Modal show={terminalPopUp} size="" className="terminalDetailsModalPopup">
        <Modal.Header className="terminal_details_modal_header">
          <Modal.Title id="contained-modal-title-vcenter" className="terminal_details_modal_title">
            {/* Terminal Details */}
          </Modal.Title>
          <Closebtn className="terminal_details_modal_close_button" onClick={() => setShowTerminalPopup(false)} />
        </Modal.Header>
        <Modal.Body>
          <div className="terminal_detail_table">
            <table>
              <thead>
                <tr>
                  <td>Contract Type</td>
                  <td>Last</td>
                  <td>Chg</td>
                  <td>%</td>
                  <td>Volume</td>
                  <td>High</td>
                  <td>Net-High</td>
                  <td>Low</td>
                  <td>Net-Low</td>
                  <td>Open</td>
                  <td>Pre Close</td>
                  <td>Open Int</td>
                  <td>Bid</td>
                  <td>B-Size</td>
                  <td>Ask</td>
                  <td>A-Size</td>
                  <td>Option Expiry</td>
                  <td>First Notice Day</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* contractName */}
                  <td className="table_td">{item.contractName}</td>

                  {/* lastChng */}
                  <td className={item?.chng >= 0 ? 'text_new_green' : 'text_new_red'} data-title="Last">
                    {lastChng}
                  </td>

                  {/* chng */}
                  <td className={item?.chng >= 0 ? 'text_new_green' : 'text_new_red'} data-title="Chg">
                    {item?.chng > 0 && '+'}
                    {chng}
                  </td>

                  {/* % */}
                  <td className="percentage" data-title="%">
                    {item?.percentageVal > 0 && '+'}
                    {percentageVal}
                  </td>

                  {/* Volume */}
                  <td className="volume" data-title="Vol">
                    {item?.volume}
                  </td>

                  {/* High */}
                  <td data-title="High">{highRate}</td>

                  {/* Net-High */}
                  <td data-title="$-High">
                    <span className={getNetHighAndLowColor(item?.netHigh)}>
                      {item.netHigh > 0 && '+'}
                      {netHigh}
                    </span>
                  </td>

                  {/* Low */}
                  <td data-title="Low">{lowRate}</td>

                  {/* Net-Low */}
                  <td data-title="$-Low">
                    <span className={getNetHighAndLowColor(item?.netLow)}>
                      {item.netLow > 0 && '+'}
                      {netLow}
                    </span>
                  </td>

                  {/* Open */}
                  <td data-title="Open">{openRate}</td>

                  {/* Pre Close */}
                  <td data-title="Pre-Clos">{prevRate}</td>

                  {/* Open Int */}
                  <td data-title="Open Int">{item?.openInterest}</td>

                  {/* Bid */}
                  <td data-title="Bid">{bid}</td>

                  {/* B-size */}
                  <td data-title="Bsize">{item?.bsize}</td>

                  {/* Ask */}
                  <td data-title="Ask">{ask}</td>

                  {/* A-size */}
                  <td data-title="Let's help">{item?.asize}</td>

                  {/* Option Expiry */}
                  <td data-title="Option Expiry" className={getOptionExpiryColor(item.optionExpiryStatus)}>
                    {item?.optionExpiryDateFormat}
                  </td>

                  {/* First Notice Date */}
                  <td data-title="First Notice Day" className={getFirstNoticeDayColor(item.firstNoticeDateStatus)}>
                    {item?.firstNoticeDateFormat}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="terminal_detail_body">
            <div className="arrows" onClick={() => setSlideIndex(slideIndex === 1 ? totalSlides : slideIndex - 1)}>
              <DoubleArrowIcon className="left_icons" sx={{ color: '#50b3f6', width: '30px', height: '30px' }} />
            </div>
            {/* slide 1 */}
            {slideIndex === 1 && (
              <div>
                <div className="slide_1">
                  <table className="difference_table">
                    <thead>
                      <tr className="difference_table_head">
                        <td>Terminals</td>
                        <td className="last_column">Last</td>
                        <td>Difference</td>
                      </tr>
                      <tr className="table_tr"></tr>
                    </thead>
                    <tbody className="difference_table_body">
                      <>
                        {accordianPopupListData?.map((ele, index) => (
                          /* eslint-disable no-nested-ternary */
                          <React.Fragment key={index}>
                            <tr>
                              <td className={`${ele.parentLastChngColor} `}>{ele.parentTerminal}</td>
                              <td className={`${ele.parentLastChngColor} `}>{ele.parentLastChng !== '--' ? (item.idMarket === 1 ? ele.parentLastChng : ele.parentLastChng.toFixed(2)) : '--'}</td>
                              <td rowSpan="2" className={`${ele.differenceColor} differential_column`} style={{ border: '1px solid #50b3f6 !important' }}>
                                {ele.difference !== '--' ? (item.idMarket === 1 ? ele.difference : parseFloat(ele?.difference).toFixed(2)) : '--'}
                              </td>
                            </tr>
                            <tr style={{ marginBottom: '5px' }}>
                              <td style={{ fontWeight: '400' }} className={`${ele.childLastChngColor} `}>
                                {ele.childTerminal}
                              </td>
                              <td style={{ fontWeight: '400' }} className={`${ele.childLastChngColor} `}>
                                {ele?.childTerminalChng !== '--' ? (item.idMarket === 1 ? ele.childTerminalChng : parseFloat(ele?.childTerminalChng).toFixed(2)) : '--'}
                              </td>
                            </tr>
                            {accordianPopupListData?.length !== index + 1 && <tr style={{ height: '6px' }}></tr>}
                          </React.Fragment>
                          /* eslint-enable no-nested-ternary */
                        ))}
                      </>
                    </tbody>
                  </table>
                  <div>
                    <table className="contract_high_low">
                      <thead>
                        <tr className="contract_header">
                          <td colSpan="2" className="contract_header_td">
                            52 Weeks High
                          </td>
                          <td colSpan="2">52 Weeks Low</td>
                        </tr>
                        <tr className="contract_header_tr"></tr>
                      </thead>
                      <tbody>
                        <tr className="contract_body">
                          {/* eslint-disable-next-line no-underscore-dangle */}
                          <td>{item?._52weeksHighDateFormat}</td>
                          {/* eslint-disable-next-line no-underscore-dangle */}
                          <td>{item?._52weeksHighRate}</td>
                          {/* eslint-disable-next-line no-underscore-dangle */}
                          <td>{item?._52weeksLowDateFormat}</td>
                          {/* eslint-disable-next-line no-underscore-dangle */}
                          <td>{item?._52weeksLowRate}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {/* slide 2 */}
            {slideIndex === 2 && (
              <div>
                <div className="slide_2">
                  <div className="terminal_heading">Terminal Data</div>
                  <div className="terminal_table">
                    <div className="terminal_table_head">
                      <div>Date</div>
                      <div>Open</div>
                      <div>High</div>
                      <div>Low</div>
                      <div>Close</div>
                      <div>Net Chng</div>
                      <div>Volume</div>
                      <div>Compared to per day</div>
                      <div>Open Int</div>
                      <div>Compared to per day</div>
                      <div style={{ borderLeft: '1px solid #50b3f6', borderRight: '1px solid #50b3f6' }}></div>
                    </div>
                    {showTodayTerminal && terminalData?.length > 0 && (
                      <div className="today_terminal_table_head">
                        <div>Today</div>
                        <div>{openRate}</div>
                        <div>{highRate}</div>
                        <div>{lowRate}</div>
                        <div className={lastChng - (terminalData[0]?.close || 0) >= 0 ? 'positive_green' : 'negative_red'}>{lastChng}</div>
                        <div className={lastChng - (terminalData[0]?.close || 0) >= 0 ? 'positive_green' : 'negative_red'}>{lastChangeValue}</div>
                        <div className={(item?.volume || 0) - (terminalData[0]?.volume || 0) >= 0 ? 'positive_green' : 'negative_red'}>{item?.volume}</div>
                        <div className={(item?.volume || 0) - (terminalData[0]?.volume || 0) >= 0 ? 'positive_green' : 'negative_red'}>{(item?.volume || 0) - (terminalData[0]?.volume || 0)}</div>
                        <div className={(item?.openInterest || 0) - (terminalData[0]?.openInterest || 0) >= 0 ? 'positive_green' : 'negative_red'}>{item?.openInterest}</div>
                        <div className={(item?.openInterest || 0) - (terminalData[0]?.openInterest || 0) >= 0 ? 'positive_green' : 'negative_red'}>{(item?.openInterest || 0) - (terminalData[0]?.openInterest || 0)}</div>
                        <div></div>
                      </div>
                    )}
                    <div style={{ height: '5px', borderBottom: '1px solid #50b3f6' }}></div>
                    {isTerminalDataExists && <div className="no-termianl-data">{t('FAILED_TO_FETCH_TERMINAL_DATA')}</div>}

                    <div className={isTerminalDataExists ? 'no-termianl-data-table' : 'terminal_table_main_body'}>
                      {terminalData?.map((ele, index) => (
                        <div key={index} className="terminal_table_body">
                          <div>{ele?.dateFormat}</div>
                          <div>{item.idMarket === 1 ? ele?.open : parseFloat(ele?.open).toFixed(2)}</div>
                          <div>{item.idMarket === 1 ? ele?.high : parseFloat(ele?.high).toFixed(2)}</div>
                          <div>{item.idMarket === 1 ? ele?.low : parseFloat(ele?.low).toFixed(2)}</div>
                          <div className={ele?.netChg >= 0 ? 'positive_green' : 'negative_red'}>{item.idMarket === 1 ? ele?.close : parseFloat(ele?.close).toFixed(2)}</div>
                          <div className={ele?.netChg >= 0 ? 'positive_green' : 'negative_red'}>{item.idMarket === 1 ? ele?.netChg : parseFloat(ele?.netChg).toFixed(2)}</div>
                          <div className={ele?.comparedToPreDay >= 0 ? 'positive_green' : 'negative_red'}>{ele?.volume}</div>
                          <div className={ele?.comparedToPreDay >= 0 ? 'positive_green' : 'negative_red'}>{ele?.comparedToPreDay}</div>
                          <div className={ele?.comparedToPreDay1 >= 0 ? 'positive_green' : 'negative_red'}>{ele?.openInterest}</div>
                          <div className={ele?.comparedToPreDay1 >= 0 ? 'positive_green' : 'negative_red'}>{ele?.comparedToPreDay1}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="arrows" onClick={() => setSlideIndex(slideIndex === totalSlides ? 1 : slideIndex + 1)}>
              <DoubleArrowIcon className="right_icons" sx={{ color: '#50b3f6', width: '30px', height: '30px' }} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ApiResponseHandler(AccordianPopUp)
