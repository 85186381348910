import './styles.scss'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { coffeewebGetLocal, coffeewebSetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'
import { useTranslation } from 'react-i18next'

// ErrorFallback component to display when an error occurs
function ErrorFallback({ error, resetErrorBoundary }) {
  const history = useHistory()
  const { i18n, t } = useTranslation()

  if (error === 'unAuthorized') {
    setTimeout(() => {
      redirectToLogin()
    }, 5000)
  }

  const redirectToLogin = () => {
    const languageCode = coffeewebGetLocal(coffeewebStorageKeys.appLanguage)
    const singleDeviceId = coffeewebGetLocal(coffeewebStorageKeys.singleDeviceId)
    const freeMenus = coffeewebGetLocal(coffeewebStorageKeys.freeMenus)

    localStorage.clear()
    coffeewebSetLocal(coffeewebStorageKeys.appLanguage, languageCode)
    coffeewebSetLocal(coffeewebStorageKeys.singleDeviceId, singleDeviceId)
    coffeewebSetLocal(coffeewebStorageKeys.freeMenus, freeMenus)
    coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, false)

    history.push({
      pathname: ROUTE_STRINGS.guestNewsFeed
    })
    resetErrorBoundary()
  }

  return (
    <>
      {error === 'unAuthorized' ? (
        <div className="session-expired-wrapper">
          <div className="session-expired-container">
            <div className="info">
              <h3>{t('YOUR_SESSION_HAS_EXPIRED')}</h3>
              <p>{t('PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING_THE_APP')}</p>
            </div>
            <button onClick={redirectToLogin}>{t('RE_LOGIN')}</button>
          </div>
        </div>
      ) : (
        <div className="error-boundary-container">
          <h2>{t('SOMETHING_WENT_WRONG')}</h2>
          <p>{error.status}</p>
          <p>{error.title}</p>
          <button onClick={resetErrorBoundary}>{t('TRY_AGAIN')}</button>
        </div>
      )}
    </>
  )
}
// Wrapper component using ErrorBoundary
function MyErrorBoundary({ children }) {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
}
export default MyErrorBoundary
