import './styles.scss'
import React, { useState, useEffect } from 'react'
import ReactCustomFlagSelect from 'react-custom-flag-select'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import Loader from 'Components/LayoutComponents/Loader'
import { useTranslation } from 'react-i18next'

const CountryCodeSelect = ({ setLoading, ...props }) => {
  const { getPhoneCode, handleInputChange, handleBlur, value, setErrorState } = props
  const { t } = useTranslation()

  const [currentItem, setCurrentItem] = useState(null)
  const [countryList, setCountryList] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    await apiAdapterCoffeeWeb
      .getAllCountries()

      .then((response) => {
        if (response?.data?.length) {
          const modifiedList = response.data.map((ele) => ({
            ...ele,
            flag: ele.flagsUrl,
            id: ele.id.toString(),
            name: ele.nicename
          }))

          setCountryList(modifiedList)
          const defaultCountry = modifiedList.find((ele) => ele.phonecode === '91')

          setCurrentItem(defaultCountry)
          getPhoneCode(defaultCountry.phonecode, defaultCountry.id)
        } else {
          setErrorMessage(true)
          setErrorState((previousState) => ({ ...previousState, countryListError: true }))
        }
      })
      .catch((error) => {
        setErrorMessage(true)
        setErrorState((previousState) => ({ ...previousState, countryListError: true }))
      })
  }

  const handleSelect = (countryId) => {
    const selectedCountry = countryList.find((ele) => ele.id === countryId)

    setCurrentItem(selectedCountry)
    getPhoneCode(selectedCountry.phonecode, selectedCountry.id)
  }

  return (
    <div className={`country-code-select ${errorMessage ? 'error' : ''}`}>
      <div className="country-dropdown">
        <label htmlFor="txtPhoneOtp" className="form-label textLabel">
          {t('COUNTRY')}
          <span className="mandate"> *</span>{' '}
        </label>
        {currentItem && countryList.length && (
          <ReactCustomFlagSelect
            attributesWrapper={{ id: 'areaCodeWrapper', tabIndex: '1' }}
            attributesButton={{ id: 'areaCodeButton' }}
            attributesInput={{ id: 'areaCode', name: 'areaCode' }}
            value={currentItem?.id ? currentItem?.id : ''}
            disabled={false}
            showSearch={true}
            fields={['currencyName', 'currency', 'countryTag', 'nicename']}
            showArrow={true}
            animate={true}
            optionList={countryList}
            selectHtml={
              <div style={{ color: 'black', display: 'flex', justifyContent: 'start', width: '100%', gap: '8px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <img src={currentItem?.flag} style={{ borderRadius: '3px', border: '1px solid rgba(0, 17, 51, 0.15)' }} alt="flag" />
                </div>
                <div style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>{`${currentItem?.nicename}`}</div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}></div>
              </div>
            }
            customStyleContainer={{ border: 'none', fontSize: '12px' }}
            customStyleSelect={{ height: '40px', border: '1px solid #e4e4e4' }}
            customStyleOptionListItem={{ display: 'flex', justifyContent: 'space-around' }}
            customStyleOptionListContainer={{ maxHeight: '240px', overflow: 'auto' }}
            onChange={(item) => handleSelect(item)}
          />
        )}
        {!currentItem && <div className="loader">{!errorMessage ? <div>{t('LOADING')}</div> : <div>{t('NO_DATA')}</div>}</div>}
      </div>
      {!currentItem && <div className="text-danger-country">{errorMessage && <div>{t('COUNTRY_LIST_UNAVAILABLE')}</div>}</div>}

      <div className="phone-number">
        <label>
          {t('PHONE')}
          <span className="mandate"> *</span>{' '}
        </label>
        <div className="phone-number-field">
          <span>{currentItem ? `+${currentItem.phonecode}` : '-'}</span>
          <input className="signup_form-control" name="phoneNo" value={value} maxLength={15} onBlur={handleBlur} onChange={(e) => handleInputChange(e)} placeholder="Phone" />
        </div>
        {!currentItem && <div className="text-danger-country">{errorMessage && <div>{t('PHONE_CODE_UNAVAILABLE')}</div>}</div>}
      </div>
    </div>
  )
}

export default Loader(CountryCodeSelect)
