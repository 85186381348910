import '../styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import Modal from 'react-bootstrap/Modal'
import { Table } from 'react-bootstrap'
import swal from 'sweetalert'
import moment from 'moment'
import Swal from 'sweetalert2'
import { coffeewebStorageKeys, coffeewebSetLocal, coffeewebGetLocal, updateUserDetails } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { Closebtn, EyeFilled, EyeFilledOff } from 'Assets/Icons'
import Validation from 'Utils/Validation'
import Loader from 'Components/LayoutComponents/Loader'
import languageCode from 'Utils/languageCode'
import { useTranslation } from 'react-i18next'
import ApiResponseHandler from 'Components/ApiResponseHandler'

function ProfileModal({ setLoading, handleApiResponse, ...props }) {
  const history = useHistory()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const tabID = window.sessionStorage.getItem('tabid')
  const dateFormatData = userDetails.userDateFormat

  const { i18n, t } = useTranslation()
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  const [landingPage, setLandingPage] = useState([])
  const [dateFormat, setDateformat] = useState([])
  const [timeFormat, setTimeFormat] = useState([])
  const [timeZone, setTimeZone] = useState([])
  const [allLanguages, setAllLanguages] = useState([])
  const [languageState, setLanguageState] = useState('')

  const [inputValues, setInputValues] = useState({
    dateFormat: userDetails?.userDateFormat,
    timeFormat: userDetails?.userTimeFormat,
    Languages: userDetails?.languageId,
    landingPage: userDetails?.userLandingPageUrl,
    timeZone: userDetails?.idtimezone,
    showWebNotification: userDetails?.showWebNotification
  })

  const [errorStateDateFormat, setErrorStateForDateFormat] = useState(false)
  const [errorStateForTimeFormat, setErrorStateForTimeFormat] = useState(false)
  const [errorStateForLanguages, setErrorStateForLanguages] = useState(false)
  const [errorStateForLandingPage, setErrorStateForLandingPage] = useState(false)
  const [errorStateForTimeZone, setErrorStateForTimeZone] = useState(false)

  const [userInputValues, setUserInputValue] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: ''
  })
  const [ErrorState, setErrorState] = useState({
    wrongPassword: false,
    currentPassword: false,
    password: false,
    confirmPassword: false
  })
  const [activeTab, setActiveTab] = useState('myInfo')
  const [stateList, setStateList] = useState([])
  const [errorMessage, setErrorMessage] = useState({
    firstName: false,
    lastName: false,
    dob: false,
    occupation: false,
    country: false
  })

  const [allUserDetails, setAllUserDetails] = useState([])
  const [emailExistOrNot, setEmailExistOrNot] = useState({})
  const [occupation, setOccupation] = useState([])
  const [allCountries, setAllCountries] = useState([])
  const [paymentHistory, setPaymentHistory] = useState([])

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const init = async () => {
    setLoading(true)
    const getAllCountries = apiAdapterCoffeeWeb.getAllCountries()
    const getAllOccupation = apiAdapterCoffeeWeb.occupationType()
    const getUserByUserId = apiAdapterCoffeeWeb.getUserByUserId(userDetails?.id)
    const userSettings = apiAdapterCoffeeWeb.getProfileSettingMasters()

    try {
      const [getAllCountriesResponse, allOccupationResponse, getUserDetailsResponse, userSettingsResponse] = await Promise.all([getAllCountries, getAllOccupation, getUserByUserId, userSettings])

      if (getAllCountriesResponse?.data?.length) {
        setAllCountries(getAllCountriesResponse.data)
      }

      if (allOccupationResponse?.data?.returnLst?.length) {
        setOccupation(allOccupationResponse.data.returnLst)
      }

      if (getUserDetailsResponse?.data?.countryId) {
        getStateLists(getUserDetailsResponse.data.countryId)
        setAllUserDetails(getUserDetailsResponse.data)
        setEmailExistOrNot(getUserDetailsResponse.data)
        setPaymentHistory(getUserDetailsResponse.data?.paymentList)

        const data = getUserDetailsResponse.data.languageId
        const languageCodeValue = languageCode[data]

        coffeewebSetLocal(coffeewebStorageKeys.appLanguage, languageCodeValue)
        i18n.changeLanguage(languageCodeValue)
      }

      if (userSettingsResponse.data?.landingpage || userSettingsResponse.data?.dateFormat || userSettingsResponse.data?.timeFormat || userSettingsResponse.data?.timeZone || userSettingsResponse.data?.languages) {
        setLandingPage(userSettingsResponse.data?.landingpage)
        setDateformat(userSettingsResponse.data?.dateFormat)
        setTimeFormat(userSettingsResponse.data?.timeFormat)
        setTimeZone(userSettingsResponse.data?.timeZone)
        setAllLanguages(userSettingsResponse.data?.languages)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('UNABLE_TO_FETCH_USER_PROFILE_DETAILS'), onRetry: () => init() })
    } finally {
      setLoading(false)
    }
  }

  const postIsLoggedOut = async () => {
    await apiAdapterCoffeeWeb.updateIsLoggedIn({ userId: userDetails.id, p2: false, p3: 0, p4: '-' })
  }

  const logoutUser = () => {
    const languageCode = coffeewebGetLocal(coffeewebStorageKeys.appLanguage)
    const freeMenus = coffeewebGetLocal(coffeewebStorageKeys.freeMenus)

    localStorage.clear()
    coffeewebSetLocal(coffeewebStorageKeys.appLanguage, languageCode)
    coffeewebSetLocal(coffeewebStorageKeys.freeMenus, freeMenus)
    coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, false)
    history.push({ pathname: ROUTE_STRINGS.guestNewsFeed })
  }

  const handleLogout = async () => {
    setLoading(true)
    postIsLoggedOut()
    const postData = {
      idUser: userDetails.id,
      deviceId: tabID,
      fbToken: userDetails.fbToken
    }

    try {
      await apiAdapterCoffeeWeb.userLogout(postData)
      logoutUser()
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: () => handleLogout(), message: t('SORRY_UNABLE_TO_LOGOUT_THE_USER') })
    } finally {
      setLoading(false)
    }
  }

  const toggleCurrentPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown)
  }
  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }
  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown)
  }
  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'currentPassword') {
      setUserInputValue({
        ...userInputValues,
        currentPassword: value
      })
    } else if (name === 'password') {
      setUserInputValue({
        ...userInputValues,
        password: value
      })
      if (value.length >= 8) {
        setErrorState({
          ...ErrorState,
          password: false
        })
        if (value === userInputValues.confirmPassword) {
          setErrorState({
            ...ErrorState,
            confirmPassword: false
          })
        }
      } else {
        setErrorState({
          ...ErrorState,
          password: true
        })
      }
    } else if (name === 'confirmPassword') {
      setUserInputValue({
        ...userInputValues,
        confirmPassword: value
      })
      if (value === userInputValues.password) {
        setErrorState({
          ...ErrorState,
          confirmPassword: false
        })
      }
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    if (name === 'password') {
      if (value.length < 8) {
        setErrorState({
          ...ErrorState,
          password: true
        })
      } else {
        setErrorState({
          ...ErrorState,
          password: false
        })
      }
    }
    if (name === 'phoneNo') {
      if (value.length === 10) {
        setErrorState({
          ...ErrorState,
          phoneNo: false
        })
      } else {
        setErrorState({
          ...ErrorState,
          phoneNo: true
        })
      }
    }
  }

  const handleValidation = () => {
    if (Validation.checkEmptySomeInputField(userInputValues.currentPassword, userInputValues.password, userInputValues.confirmPassword)) {
      swal({
        text: t('PLEASE_FILL_ALL_THE_DETAILS'),
        icon: 'error'
      })
    } else if (userInputValues.currentPassword !== '' && userInputValues.password !== '' && userInputValues.confirmPassword !== '' && userInputValues.password !== userInputValues.confirmPassword) {
      swal({
        text: t('PASSWORD_AND_CONFIRM_PASSWORD_SHOULD_BE_SAME'),
        icon: 'error'
      })
    } else if (userInputValues.currentPassword !== '' && userInputValues.password !== '' && userInputValues.password === userInputValues.confirmPassword) {
      setLoading(true)
      handleSubmit()
    }
  }

  const handleSubmit = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.changePassword({
        id: userDetails?.id,
        password: userInputValues.password,
        currentPassword: userInputValues.currentPassword
      })

      if (response.status === 200) {
        if (response.data.isError === true) {
          setLoading(false)
          swal({
            title: 'Error' + '!',
            text: response.data.error,
            icon: 'error'
          })
        } else {
          setLoading(false)
          swal({
            title: t('SUCCESS'),
            text: t('PASSWORD_CHANGED_SUCCESSFULLY'),
            icon: 'success',
            timer: 1500
          })

          setTimeout(() => {
            handleLogout()
          }, 1500)
        }
      }
    } catch (error) {
      setLoading(false)
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('FAILED_TO_UPDATE_THE_PASSWORD'), onRetry: () => handleSubmit() })
    }
  }

  const handleChanges = (e) => {
    const { name, value } = e.target

    if (name === 'dateFormat') {
      setInputValues({
        ...inputValues,
        dateFormat: value
      })
      setErrorStateForDateFormat(false)
    } else if (name === 'timeFormat') {
      setInputValues({
        ...inputValues,
        timeFormat: value
      })
      setErrorStateForTimeFormat(false)
    } else if (name === 'Languages') {
      const languageCodeValue = languageCode[value]

      if (languageCodeValue) {
        setLanguageState(languageCodeValue)
        i18n.changeLanguage(languageCodeValue)
      }

      setInputValues({
        ...inputValues,
        Languages: value
      })
      setErrorStateForLanguages(false)
    } else if (name === 'landingPage') {
      setInputValues({
        ...inputValues,
        landingPage: value
      })
      setErrorStateForLandingPage(false)
    } else if (name === 'timeZone') {
      setInputValues({
        ...inputValues,
        timeZone: value
      })
      setErrorStateForTimeZone(false)
    }
  }

  const toggle = () => {
    if (inputValues.showWebNotification === true) {
      setInputValues({
        ...inputValues,
        showWebNotification: false
      })
    } else {
      setInputValues({
        ...inputValues,
        showWebNotification: true
      })
    }
  }
  const handleSaveButton = () => {
    coffeewebSetLocal(coffeewebStorageKeys.appLanguage, languageState)

    if (inputValues.dateFormat === '') {
      setErrorStateForDateFormat(true)
    } else if (inputValues.timeFormat === '') {
      setErrorStateForTimeFormat(true)
    } else if (inputValues.Languages === '') {
      setErrorStateForLanguages(true)
    } else if (inputValues.landingPage === '') {
      setErrorStateForLandingPage(true)
    } else {
      setLoading(true)
      updateUserSettings()
    }
  }

  const updateUserSettings = async () => {
    try {
      await apiAdapterCoffeeWeb.updateUserProfileSettings(userDetails.id, inputValues)

      setLoading(false)
      swal({
        title: t('SUCCESS'),
        text: t('UPDATED_SUCCESSFULLY'),
        icon: 'success',
        timer: 1000
      })

      updateUserDetails()
    } catch (error) {
      setLoading(false)

      const StatusCode = error?.response?.status

      handleApiResponse({ status: StatusCode, message: t('FAIL_TO_UPDATE_PROFILE_SETTINGS'), onRetry: () => updateUserSettings() })
    }
  }

  const changeMyInformation = (event) => {
    const { name, value, id } = event.target
    const eachOccupationName = occupation.filter((ele) => ele.idOccupationType === +value)

    if (name === 'firstName') {
      setAllUserDetails({
        ...allUserDetails,
        firstName: value
      })
      setErrorMessage(false)
    }

    if (name === 'lastName') {
      setAllUserDetails({
        ...allUserDetails,
        LastName: value
      })
      setErrorMessage(false)
    }

    if (name === 'email') {
      setAllUserDetails({
        ...allUserDetails,
        email: value
      })

      if (Validation.isInputFieldEmpty(value)) {
        setErrorMessage({
          ...errorMessage,
          email: false,
          emailnotvalid: false
        })
      } else {
        setErrorMessage({
          ...errorMessage,
          email: false,
          emailnotvalid: false
        })
      }
    }

    if (name === 'phone') {
      setAllUserDetails({
        ...allUserDetails,
        phone: value
      })
      if (Validation.isInputFieldEmpty(value)) {
        setErrorMessage({
          ...errorMessage,
          phone: true
        })
      } else {
        setErrorMessage({
          ...errorMessage,
          phone: false
        })
      }
    }

    if (name === 'dob') {
      setAllUserDetails({
        ...allUserDetails,
        doB: value
      })
      if (Validation.isInputFieldEmpty(value)) {
        setErrorMessage({
          ...errorMessage,
          dob: true
        })
      } else {
        setErrorMessage({
          ...errorMessage,
          dob: false
        })
      }
    }
    if (id === 'occupationId') {
      setAllUserDetails({
        ...allUserDetails,
        [id]: value,
        occupationName: eachOccupationName[0].occupationTypeName
      })
      setErrorMessage(false)
    }
    if (name === 'country') {
      setAllUserDetails({
        ...allUserDetails,
        countryId: value
      })
      setErrorMessage(false)
    }

    if (name === 'state') {
      setAllUserDetails({
        ...allUserDetails,
        idState: value
      })
    }
  }

  const myInformationSend = () => {
    if (Validation.isInputFieldEmpty(allUserDetails.firstName)) {
      setErrorMessage({
        ...errorMessage,
        firstName: true
      })
    } else if (Validation.isInputFieldEmpty(allUserDetails.phone)) {
      setErrorMessage({
        ...errorMessage,
        phone: true
      })
    } else if (Validation.isInputFieldEmpty(allUserDetails.doB)) {
      setErrorMessage({
        ...errorMessage,
        dob: true
      })
    } else if (occupation.length < 0) {
      setErrorMessage({
        ...errorMessage,
        occupation: true
      })
    } else if (allCountries?.nicename === '') {
      setErrorMessage({
        ...errorMessage,
        country: true
      })
    } else if (allUserDetails.email !== '' && allUserDetails.email !== null) {
      if (!Validation.emailValidation(allUserDetails.email)) {
        setErrorMessage({
          ...errorMessage,
          emailnotvalid: true
        })
      } else {
        setLoading(true)
        emailExist()
      }
    }
  }

  const emailExist = async () => {
    try {
      if (allUserDetails.email !== emailExistOrNot.email && allUserDetails.email !== '') {
        const response = await apiAdapterCoffeeWeb.isEmailExist({ email: allUserDetails.email, id: userDetails.id })

        if (response.data === true) {
          swal({
            title: t('EMAIL_ALREADY_EXISTS'),
            text: t('ENTER_ANOTHER_EMAIL'),
            icon: 'error'
          })
          setLoading(false)
        } else {
          updateUserInformation()
        }
      } else {
        updateUserInformation()
      }
    } catch (error) {
      setLoading(true)
      const StatusCode = error?.response?.status

      handleApiResponse({ status: StatusCode, onRetry: () => emailExist(), message: t('FAILED_TO_UPDATE_EMAIL') })
    }
  }

  const updateUserInformation = async () => {
    const { id, firstName, lastName, countryId, occupationId, occupationName, updatedBy, city, states, doB, showMobileNo, showEmailId, idtimezone, idState, isAllowedToConnect, headLine, userRole, email: userEmail } = allUserDetails

    const updateUserData = {
      id,
      firstName,
      lastName,
      countryId,
      occupationId,
      occupationName,
      updatedBy,
      city,
      states,
      doB,
      showMobileNo,
      showEmailId,
      idtimezone,
      idState,
      isAllowedToConnect,
      headLine,
      userRole,
      userEmail
    }

    await apiAdapterCoffeeWeb
      .updateOnlyUser(updateUserData)
      .then(() => {
        setLoading(false)
        swal({
          title: t('SUCCESS'),
          text: t('DATA_UPDATED_SUCCESSFULLY'),
          icon: 'success',
          timer: 1000
        })
      })
      .catch((error) => {
        setLoading(false)
        const statusCode = error?.response?.status

        handleApiResponse({ status: statusCode, message: t('FAILED_TO_UPDATE_USER_DATA'), onRetry: () => updateUserInformation() })
      })
  }

  const handleDeleteUserAccount = () => {
    Swal.fire({
      position: 'top',
      title: t('ARE_YOU_SURE'),
      text: t('IF_YOU_DELETE_ACCOUNT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50b3f6',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true)
        deleteUserAccount()
      }
    })
  }

  const deleteUserAccount = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.deleteAccount(userDetails.id)

      if (response.data === true) {
        setLoading(false)
        swal({
          title: t('SUCCESS'),
          text: t('ACCOUNT_DELETED_SUCCESSFULLY'),
          icon: 'success'
        })
      }

      const freeMenus = coffeewebGetLocal(coffeewebStorageKeys.freeMenus)

      localStorage.clear()
      coffeewebSetLocal(coffeewebStorageKeys.freeMenus, freeMenus)
      history.push({ pathname: ROUTE_STRINGS.guestNewsFeed })
      coffeewebSetLocal(coffeewebStorageKeys.singleDeviceId, uuid())
    } catch (error) {
      setLoading(false)
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: () => deleteUserAccount(), message: t('UNABLE_TO_DELETE_ACCOUNT') })
    }
  }

  const deActivateAccount = () => {
    Swal.fire({
      position: 'top',
      title: t('ARE_YOU_SURE'),
      text: t('DEACTIVATE_YOUR_ACCOUNT'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50b3f6',
      confirmButtonText: 'Deactivate'
    }).then((result) => {
      if (result.isConfirmed) {
        deActivateUserAccount()
      }
    })
  }

  const deActivateUserAccount = async () => {
    try {
      const { id, updatedBy } = userDetails

      await apiAdapterCoffeeWeb.deactivateAccount({ id, updatedBy })

      swal({
        title: t('SUCCESS'),
        text: t('ACCOUNT_DEACTIVATED'),
        icon: 'success'
      })

      const freeMenus = coffeewebGetLocal(coffeewebStorageKeys.freeMenus)

      localStorage.clear()
      coffeewebSetLocal(coffeewebStorageKeys.freeMenus, freeMenus)
      coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, false)
      history.push({ pathname: ROUTE_STRINGS.guestNewsFeed })
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('UNABLE_TO_DEACTIVATE_ACCOUNT'), onRetry: () => deActivateUserAccount() })
    }
  }

  const getStateLists = async (countryid) => {
    try {
      const response = await apiAdapterCoffeeWeb.getStateList(countryid)

      setStateList(response.data)
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: () => getStateLists(), message: t('UNABLE_FETCH_STATE_LIST') })
    }
  }

  return (
    <Modal show={props.show} size="lg" className="profile-modal">
      <Modal.Header className="profile-modal-header">
        <Modal.Title id="contained-modal-title-center"></Modal.Title>
        <Closebtn className="profile-modal-close-button" onClick={props.onHide} />
      </Modal.Header>
      <Modal.Body className="profile-modal-body">
        {
          <div className="profile-settings-tab-header">
            <div className="each-tab-main-container">
              <div className="each-tabs" onClick={() => setActiveTab('myInfo')} id={activeTab === 'myInfo' && 'activeTabClass'}>
                {t('MY_INFO')}
              </div>
              <div className="each-tabs" onClick={() => setActiveTab('profileSettings')} id={activeTab === 'profileSettings' && 'activeTabClass'}>
                {t('PROFILE_SETTINGS')}
              </div>
            </div>
            <div className="each-tab-main-container">
              <div className="each-tabs" onClick={() => setActiveTab('changePassword')} id={activeTab === 'changePassword' && 'activeTabClass'}>
                {t('CHANGE_PASSWORD')}
              </div>
              <div className="each-tabs" onClick={() => setActiveTab('accountSettings')} id={activeTab === 'accountSettings' && 'activeTabClass'}>
                {t('ACCOUNT_ACTIVITY')}
              </div>
            </div>
          </div>
        }
        {activeTab === 'myInfo' && (
          <div className="my-info-card">
            <div className="container">
              <h5 className="active-tab-text">{t('MY_INFO')}</h5>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="firstName" className="labelInHeader">
                    {t('FIRST_NAME')}
                  </label>
                  <input type="text" name="firstName" value={allUserDetails.firstName} onChange={changeMyInformation} className="signup_form-control pass_flow profile_modal_input" autoComplete="off" disabled />
                  {errorMessage.firstName === true && <span className="text-danger">{t('FIRST_NAME_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="lastName" className="labelInHeader">
                    {t('LAST_NAME')}
                  </label>
                  <input type="text" name="lastName" placeholder={t('LAST_NAME')} value={allUserDetails.lastName} className="signup_form-control pass_flow profile_modal_input" autoComplete="off" disabled />
                  {errorMessage.lastName === true && <span className="text-danger">{t('LAST_NAME_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="email" className="labelInHeader">
                    {t('EMAIL')}
                  </label>
                  <input type="text" name="email" placeholder={t('EMAIL')} value={allUserDetails.email} onChange={changeMyInformation} className="signup_form-control pass_flow" autoComplete="off" />
                  {errorMessage.email === true && <span className="text-danger">{t('EMAIL_IS_REQUIRED')}</span>}
                  {errorMessage.emailnotvalid === true && <span className="text-danger">{t('ENTER_VALID_EMAIL')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="lastName" className="labelInHeader">
                    {t('PHONE')}
                  </label>
                  <input type="number" name="phone" value={allUserDetails.phone} onChange={changeMyInformation} className="signup_form-control pass_flow profile_modal_input" autoComplete="off" maxLength={10} disabled />
                  {errorMessage.phone === true && <span className="text-danger">{t('PHONE_NUMBER_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="country" className="labelInHeader">
                    {t('COUNTRY')}
                  </label>
                  <select disabled name="country" value={allUserDetails.countryId} onChange={changeMyInformation} id="countries" className="signup_form-control profile_modal_select_dropdown">
                    {allCountries.map((country, index) => (
                      <option value={country.id} key={index}>
                        {country.nicename}
                      </option>
                    ))}
                  </select>
                  {errorMessage.country === true && <span className="text-danger">{t('COUNTRY_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="country" className="labelInHeader">
                    {t('STATE_LIST')}
                  </label>
                  <select name="state" value={allUserDetails.idState} onChange={changeMyInformation} className="signup_form-control profile_modal_select_dropdown">
                    <option value="0">-- {t('SELECT')} --</option>
                    {stateList.map((state, index) => (
                      <option value={state.idState} key={index}>
                        {state.stateName}
                      </option>
                    ))}
                  </select>
                  {/* {errorMessage.state === true && <span className="text-danger">{t('SELECT_STATE')}</span>} */}
                </div>
                <div className="col-md-6">
                  <label htmlFor="occupation" className="labelInHeader">
                    {t('OCCUPATION')}
                  </label>
                  <select value={allUserDetails.occupationId} id="occupationId" onChange={changeMyInformation} className="signup_form-control profile_modal_select_dropdown">
                    <option value="0">-- {t('SELECT')} --</option>
                    {occupation.map((occupation, index) => (
                      <option value={occupation.idOccupationType} key={index}>
                        {occupation.occupationTypeName}
                      </option>
                    ))}
                  </select>
                  {errorMessage.occupation === true && <span className="text-danger">{t('OCCUPATION_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="dob" className="labelInHeader">
                    {t('DATE_OF_BIRTH')}
                  </label>
                  <input type="date" name="dob" value={allUserDetails.doB && moment(allUserDetails.doB).format('YYYY-MM-DD')} onChange={changeMyInformation} max={new Date().toISOString().split('T')[0]} className="signup_form-control pass_flow" autoComplete="off" />
                  {errorMessage.dob === true && <span className="text-danger">{t('DATE_OF_BIRTH_IS_REQUIRED')}</span>}
                </div>
              </div>
            </div>
            <div translate="yes" className="modal-footer forgotpass_btns profile_modal_footer_action">
              <button className="profile-close-button" onClick={props.onHide}>
                {t('CLOSE')}
              </button>
              <button className="profile-save-button" onClick={myInformationSend}>
                {t('SAVE')}
              </button>
            </div>
          </div>
        )}
        {activeTab === 'profileSettings' && (
          <div className="profileSettingsCard">
            <div className="container">
              <h5 className="active_tab_text">{t('PROFILE_SETTINGS')}</h5>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('DATE_FORMAT')}
                  </label>
                  <select name="dateFormat" onBlur={handleBlur} value={inputValues.dateFormat} selected disabled={!dateFormat?.length} onChange={handleChanges} id="ddldateFormat" className="signup_form-control profile_modal_select_dropdown">
                    {dateFormat?.length > 0 ? (
                      dateFormat?.map((ele, index) => (
                        <option key={index} value={ele.dateFormat}>
                          {ele.dateFormat}
                        </option>
                      ))
                    ) : (
                      <option>{inputValues.dateFormat}</option>
                    )}
                  </select>
                  {errorStateDateFormat && <span className="text-danger">{t('DATE_FORMAT_IS_REQUIRED ')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('TIME_FORMAT')}
                  </label>
                  <select name="timeFormat" onBlur={handleBlur} selected disabled={!timeFormat.length} value={inputValues.timeFormat} onChange={handleChanges} id="ddltimeFormat" className="signup_form-control profile_modal_select_dropdown">
                    {timeFormat?.length > 0 ? (
                      timeFormat?.map((ele, index) => (
                        <option key={index} value={ele.timeFormat}>
                          {ele.timeFormat}
                        </option>
                      ))
                    ) : (
                      <option>{inputValues.timeFormat}</option>
                    )}
                  </select>
                  {errorStateForTimeFormat && <span className="text-danger">{t('TIME_FORMAT_IS_REQUIRED')}</span>}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('LANGUAGE')}
                  </label>
                  <select name="Languages" selected onBlur={handleBlur} value={inputValues.Languages} disabled={!allLanguages.length} onChange={handleChanges} id="ddlLanguages" className="signup_form-control profile_modal_select_dropdown">
                    {allLanguages?.length > 0 ? (
                      allLanguages.map((ele, index) => (
                        <option key={index} value={ele.idLanguage}>
                          {ele.languageName}
                        </option>
                      ))
                    ) : (
                      <option>{t('NO_DATA_FOUND')}</option>
                    )}
                  </select>
                  {errorStateForLanguages && <span className="text-danger">languages is required</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('LANDING_PAGE')}
                  </label>
                  <select name="landingPage" selected onBlur={handleBlur} value={inputValues.landingPage} disabled={!landingPage.length} onChange={handleChanges} id="ddllandingPage" className="signup_form-control profile_modal_select_dropdown">
                    {landingPage?.length > 0 ? (
                      landingPage?.map((ele, index) => (
                        <option key={index} value={ele.webAppurl}>
                          {ele.landingpage}
                        </option>
                      ))
                    ) : (
                      <option>{inputValues.landingPage}</option>
                    )}
                  </select>
                  {errorStateForLandingPage && <span className="text-danger">{t('LANDING_PAGE_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('TIME_ZONE')}
                  </label>
                  <select name="timeZone" selected onBlur={handleBlur} value={inputValues.timeZone} disabled={!timeZone.length} onChange={handleChanges} id="ddltimeZone" className="signup_form-control profile_modal_select_dropdown">
                    {timeZone?.length > 0 ? (
                      timeZone?.map((ele, index) => (
                        <option key={index} value={ele.idTimeZone}>
                          {ele.timeZone}
                        </option>
                      ))
                    ) : (
                      <option>{t('NO_DATA_FOUND')}</option>
                    )}
                  </select>
                  {errorStateForTimeZone && <span className="text-danger">{t('TIME_ZONE_IS_REQUIRED')}</span>}
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtLastName" className="form-label labelInHeader">
                    {t('SHOW_WEB_NOTIFICATION')}
                  </label>
                  <br></br>
                  <div className="admin_subscription_toggleBtn_container">
                    <div className="admin_subscription_toggleBtn">
                      {inputValues.showWebNotification === true ? (
                        <div className="admin_subscription_toggle_action" onClick={toggle}>
                          <div className="toggle_action_active">{t('ON')}</div>
                          <div className="toggle_action_inactive">.</div>
                        </div>
                      ) : (
                        <div className="admin_subscription_toggle_action" onClick={toggle}>
                          <div className="toggle_action_inactive">.</div>
                          <div className="toggle_action_active">{t('OFF')}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div translate="yes" className="modal-footer forgotpass_btns profile_modal_footer_action">
              <button className="profile-close-button" onClick={props.onHide}>
                {t('CLOSE')}
              </button>
              <button onClick={handleSaveButton} className="profile-save-button">
                {t('SAVE')}
              </button>
            </div>
          </div>
        )}
        {activeTab === 'changePassword' && (
          <div className="changePasswordCard">
            <div className="container">
              <div>
                <h5 className="active_tab_text">{t('CHANGE_PASSWORD')}</h5>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="txtPassword" className="form-label labelInHeader">
                      {t('CURRENT_PASSWORD')}
                      <span className="mandate">*</span>
                    </label>
                    <div className="pass-wrapper">
                      <input type={currentPasswordShown ? 'text' : 'password'} name="currentPassword" value={userInputValues.currentPassword} onBlur={handleBlur} onChange={handleChange} className="signup_form-control pass_flow" placeholder={t('CURRENT_PASSWORD')} autoComplete="new-password" />
                      <span onClick={toggleCurrentPassword} className="eye_icon">
                        {currentPasswordShown ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" />}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="txtPassword" className="form-label labelInHeader">
                      {t('NEW_PASSWORD')}
                      <span className="mandate">*</span>
                    </label>
                    <div className="pass-wrapper">
                      <input type={passwordShown ? 'text' : 'password'} name="password" value={userInputValues.password} onBlur={handleBlur} onChange={handleChange} className="signup_form-control pass_flow" placeholder={t('PASSWORD')} autoComplete="off" />
                      <span onClick={togglePassword} className="eye_icon">
                        {passwordShown ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" />}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="txtConfirmPassword" className="form-label labelInHeader">
                      {t('CONFIRM_NEW_PASSWORD')}
                      <span className="mandate">*</span>
                    </label>
                    <div className="pass-wrapper">
                      <input type={confirmPasswordShown ? 'text' : 'password'} placeholder={t('CONFIRM_PASSWORD')} name="confirmPassword" value={userInputValues.confirmPassword} onBlur={handleBlur} onChange={handleChange} className="signup_form-control pass_flow" autoComplete="off" />
                      <span onClick={toggleConfirmPassword} className="eye_icon">
                        {confirmPasswordShown ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" />}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div translate="yes" className="modal-footer forgotpass_btns profile_modal_footer_action">
                <button className="profile-close-button" onClick={props.onHide}>
                  {t('CLOSE')}
                </button>
                <button onClick={handleValidation} type="submit" className="profile-save-button">
                  {t('SAVE')}
                </button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'accountSettings' && (
          <div className="accountActivityCard">
            <h5 className="mainHeading">{t('ACCOUNT_ACTIVITY')}</h5>
            <div className="mainCard">
              {paymentHistory && (
                <div className="card subCard">
                  <div className="payment-history-screen">
                    <div className="payment-history">
                      <div className="subHeading">{t('PAYMENT_HISTORY')}</div>
                      <div className="bootstrap-data-table">
                        <Table responsive bordered>
                          <thead className="payment-history-header">
                            <tr>
                              <th>{t('SUBSCRIPTION_TYPE')}</th>
                              <th>{t('AMOUNT')}</th>
                              <th>{t('PURCHASE_DATE')}</th>
                              <th>{t('EXPIRY_DATE')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentHistory?.map((element, index) => (
                              <tr key={index}>
                                <td>{element.subscriptionId}</td>
                                <td>
                                  {element.currencytype}
                                  {element.totalAmount}
                                </td>
                                <td>{moment(element.durationFrom).format(dateFormatData)}</td>
                                <td>{moment(element.durationTo).format(dateFormatData)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="card subCard">
                <div className="subHeading">{t('DEACTIVATE_ACCOUNT')}</div>
                <div className="text">{t('DEACTIVATE_YOUR_ACCOUNT')}</div>
                <div className="activityCardActionButtonDiv">
                  <button onClick={deActivateAccount} className="activityCardActionButton">
                    {t('YES_DEACTIVATE')}
                  </button>
                </div>
              </div>
              <div className="card subCard">
                <div className="subHeading">{t('DELETE_ACCOUNT')}</div>
                <div className="text">{t('DELETE_YOUR_ACCOUNT')}</div>
                <div className="activityCardActionButtonDiv">
                  <button onClick={handleDeleteUserAccount} className="activityCardActionButton">
                    {t('YES_DELETE')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default Loader(ApiResponseHandler(ProfileModal))
