import './style.scss'
import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { useTranslation } from 'react-i18next'

const AccordianForMobile = (props) => {
  const { data, labeling } = props
  const { i18n, t } = useTranslation()

  const handleTotalwithDecimal = (arg1, arg2, arg3 = 2) => (parseFloat(arg1 || 0) + parseFloat(arg2 || 0)).toFixed(arg3)

  const getNetHighColor = (netHigh) => {
    if (netHigh >= 0) {
      return 'textNewGreen'
    }
    if (netHigh < 0) {
      return 'textNewRed'
    }

    return ''
  }

  const getOptionExpiryColor = (optionExpiryStatus) => {
    if (optionExpiryStatus === 'boldAndRed') {
      return 'bold_and_red'
    }
    if (optionExpiryStatus === 'expired') {
      return 'expired'
    }

    return ''
  }

  const getFirstNoticeDayColor = (optionExpiryStatus) => {
    if (optionExpiryStatus === 'boldAndBlack') return 'bold_and_black'
    if (optionExpiryStatus === 'boldAndRed') return 'bold_and_red'
    if (optionExpiryStatus === 'expired') return 'expired'

    return ''
  }

  return (
    <div>
      <div>
        <Accordion defaultActiveKey="0" className="coffeequtes-accordian_for_mobile">
          <div className="accordian_main_heading">
            <div>{labeling}</div>
            <div className="tr_heading">{t('LAST')}</div>
            <div className="tr_heading">{t('CHG')}</div>
            <div className="tr_heading">%</div>
          </div>
          {data &&
            data.map((item, index) => (
              <React.Fragment key={index}>
                <div>
                  <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header className="accordian_sub_heading" id={item?.isHighlightRow === 1 || item?.isHighlightRow === 2 ? 'change_color' : 'idle_color'}>
                      <div className="tr_sub_heading">
                        <div className="first_row">{item?.contractName}</div>
                        <div className="each_row_heading_name" id={item?.chng >= 0 ? 'textNewGreen' : 'textNewRed'}>
                          {item?.idMarket === 1 ? item?.lastChng : parseFloat(item?.lastChng).toFixed(2)}{' '}
                        </div>
                        <div className="each_row_heading_name" id={item?.chng >= 0 ? 'textNewGreen' : 'textNewRed'}>
                          {item?.chng > 0 && '+'} {item.idMarket === 1 ? item?.chng : parseFloat(item?.chng).toFixed(2)}
                        </div>
                        <div className="each_row_heading_name">
                          {item?.percentageVal > 0 && '+'}
                          {parseFloat(item?.percentageVal).toFixed(2)}
                        </div>
                        <div>
                          <UnfoldMoreIcon sx={{ width: '17px' }} />
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="accordian_main_body">
                        <div className="accodian_sub_div">
                          <div>
                            <div className="each_data">
                              <div>{t('HIGH')} :</div>
                              <div>{item.highRate}</div>
                            </div>

                            <div className="each_data">
                              <div>{t('LOW')} :</div>
                              <div>{item.lowRate}</div>
                            </div>
                            <div className="each_data">
                              <div>{t('VOL')} :</div>
                              <div>{item.volume}</div>
                            </div>
                            <div className="each_data">
                              <div>{t('OPEN')} :</div>
                              <div>{item.openRate}</div>
                            </div>
                            <div className="each_data">
                              <div>{t('BID')} :</div>
                              <div>{item.bid}</div>
                            </div>
                            <div className="each_data">
                              <div>{t('ASK')} :</div>
                              <div>{item.ask}</div>
                            </div>
                          </div>
                          <div>
                            <div className="each_data">
                              <div>{t('NET_HIGH')} :</div>
                              <div>
                                <div id={getNetHighColor(item?.netHigh)}>
                                  {' '}
                                  {item.netHigh > 0 && '+'}
                                  {item?.idMarket === 1 ? item?.netHigh : handleTotalwithDecimal(item?.netHigh)}
                                </div>
                              </div>
                            </div>
                            <div className="each_data">
                              <div>{t('NET_LOW')}:</div>
                              <div>
                                <div id={getNetHighColor(item?.netLow)}>
                                  {item.netLow > 0 && '+'}
                                  {item?.idMarket === 1 ? item?.netLow : handleTotalwithDecimal(item?.netLow)}
                                </div>
                              </div>
                            </div>
                            <div className="each_data">
                              <div>{t('OPEN_INT')}:</div>
                              <div>{item.openInterest}</div>
                            </div>
                            <div className="each_data">
                              <div>{t('PRE_CLOSE')} :</div>
                              <div>{item.prevRate}</div>
                            </div>
                            <div className="each_data">
                              <div>{t('BSIZE')} :</div>
                              <div>{item.bsize}</div>
                            </div>
                            <div className="each_data">
                              <div>{t('ASIZE')} :</div>
                              <div>{item.asize}</div>
                            </div>
                          </div>
                        </div>
                        <div className="each_data data_for_date">
                          <div>{t('OPTION_EXPIRY')} :</div>
                          <div className={getOptionExpiryColor(item.optionExpiryStatus)}>{item.optionExpiryDateFormat}</div>
                        </div>
                        <div className="each_data data_for_date">
                          <div>{t('FIRST_NOTICE_DAY')} :</div>
                          <div className={getFirstNoticeDayColor(item.firstNoticeDateStatus)}>{item?.firstNoticeDateFormat}</div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </React.Fragment>
            ))}
        </Accordion>
      </div>
    </div>
  )
}

export default AccordianForMobile
