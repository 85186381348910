import React, { useEffect, useState } from 'react'
import './styles.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import { useHistory, useLocation } from 'react-router-dom'
import { Pagination, Navigation, Keyboard } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next'
import { coffeewebStorageKeys, coffeewebGetLocal, updateUserDetails } from 'Utils/LocalStorage_Handler'
import Loader from 'Components/LayoutComponents/Loader'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import SubscriptionCard from './Components/SubscriptionCardDetails'

const Subscription = ({ setLoading, handleApiResponse }) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const params = new URLSearchParams()
  const [subscriptionUsers, setSubscriptionUsers] = useState([])
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const [highlightIndex, setHighlightIndex] = useState(null)

  useEffect(() => {
    getAllUsers()
    updateUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (subscriptionUsers.length > 0) {
      getSlideCurrentIndex()
    }
  }, [subscriptionUsers])

  const getAllUsers = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getSubscriptionFeatureWithCountry({ countryId: userDetails?.countryId, isTestPayment: userDetails?.isTestPayment })

      setSubscriptionUsers(response.data.returnLst)
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: getAllUsers, message: t('DATA_UNAVAILABLE') })
    } finally {
      setLoading(false)
    }
  }

  const updateUser = async () => {
    const response = await updateUserDetails()

    if (response?.status === 401) {
      handleApiResponse({ status: response.status })
    }
  }

  const getSlideCurrentIndex = () => {
    const { id } = subscriptionUsers[subscriptionUsers.length - 1]
    const locationBasedSubscriptionId = location.state?.subscriptionId

    if (locationBasedSubscriptionId) {
      setHighlightIndex(locationBasedSubscriptionId)
    } else if (id === userDetails?.sub?.subType) {
      setHighlightIndex(subscriptionUsers.length)
    } else {
      const findIndex = subscriptionUsers.findIndex(({ id }) => userDetails?.sub?.subType === id)

      setHighlightIndex(findIndex === -1 ? subscriptionUsers.length : findIndex + 1)
    }
  }

  const selectPlan = (selectedSubType) => {
    params.set('id', selectedSubType.id)
    history.push({
      pathname: ROUTE_STRINGS.paymentgateway,
      state: selectedSubType,
      search: params.toString()
    })
  }

  return (
    <div className="subscription-main-container">
      {highlightIndex !== 0 && (
        <Swiper
          slidesPerView={4}
          centeredSlides={true}
          spaceBetween={30}
          pagination={{
            dynamicMainBullets: true
          }}
          keyboard={{
            enabled: true
          }}
          initialSlide={highlightIndex}
          navigation={true}
          modules={[Keyboard, Navigation, Pagination]}
          className="mySwiper "
          autoplay={{
            delay: 1000
          }}
          breakpoints={{
            200: {
              slidesPerView: 1,
              spaceBetween: 1
            },
            300: {
              slidesPerView: 1.1,
              spaceBetween: 5
            },
            555: {
              slidesPerView: 2,
              spaceBetween: 40
            },
            1200: {
              slidesPerView: 3.5,
              spaceBetween: 40
            },
            1700: {
              slidesPerView: 4
            }
          }}
        >
          {subscriptionUsers.map((ele, index) => (
            <SwiperSlide key={ele.id}>
              <SubscriptionCard subscription={ele} userDetails={userDetails} isHighlight={index === location.state?.subscriptionId} selectPlan={selectPlan} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

export default Loader(ApiResponseHandler(Subscription))
