import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { coffeewebStorageKeys, coffeewebGetLocal, coffeewebSetLocal } from 'Utils/LocalStorage_Handler'

function paypal({ setLoading, handleApiResponse }) {
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const paypalCreateOrder = async (ele) => {
    try {
      const postData = {
        order: {
          userId: userDetails.id,
          billingAddress: 'NA',
          shippingAddress: 'NA',
          states: userDetails.states,
          city: userDetails.city,
          totalQuantity: 1,
          totalAmount: ele.basePrice,
          paymentDate: '2022-12-24T04:51:11.228Z',
          orderDate: '2022-12-24T04:51:11.228Z',
          payOrderId: 'NA',
          subscriptionId: ele.name,
          facilitatorAccessToken: 'NA',
          paymentId: ele.paymentId,
          paymentSource: ele.paymentName,
          paymentStatus: 'Pending',
          orderSatus: 1
        },
        orderDetails: [
          {
            idOrderDetail: 0,
            orderId: 0,
            planId: 'NA',
            serviceName: 'NA',
            subscriptionType: ele.paymentName,
            quantity: 0,
            rate: 0
          }
        ]
      }

      const response = await apiAdapterCoffeeWeb.createOrder(postData)

      if (response.data.isSuccess === true) {
        InitiatePaypalPayment(ele, response.data.returnLst)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: 'SORRY_FAILED_TO_CREATE_PAYMENT', onRetry: () => paypalCreateOrder(ele) })
    }
  }

  const InitiatePaypalPayment = async (ele, orderData) => {
    setLoading(true)

    const postData = {
      currency_code: 'USD',
      userPhoneNumber: userDetails.phone,
      payPalItems: [
        {
          name: ele.name,
          description: ele.subscriptionSecondName,
          unit_amount: ele.amountInDollars,
          quantity: '1'
        }
      ],
      payer: {
        name: {
          given_name: userDetails.firstName,
          surname: userDetails.lastName
        }
      }
    }

    try {
      const response = await apiAdapterCoffeeWeb.makePayPalPayment(postData)

      if (response.data.statusCode === 200) {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
        const paypalLink = response.data.returnLst?.links.find((ele) => ele.rel === 'approve')

        window.open(`${paypalLink.href}`, '_parent')

        const paymentDetails = {
          orderId: orderData.orderDetails[0].orderId,
          paymentId: response.data?.returnLst?.id,
          uuid: response.data?.returnLst?.uuid,
          amount: ele.basePrice,
          subName: ele.name,
          paymentSource: ele.paymentName
        }

        coffeewebSetLocal(coffeewebStorageKeys.paypalDetails, paymentDetails)
        setLoading(false)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: () => InitiatePaypalPayment(ele, orderData) })
    } finally {
      setLoading(false)
    }
  }

  return {
    paypalCreateOrder
  }
}

export default paypal
