import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { doc, onSnapshot } from 'firebase/firestore'
import ReactHtmlParser from 'react-html-parser'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AppBar, Stack, IconButton, Skeleton, Toolbar, Typography, InputAdornment, Divider, Autocomplete, Box, TextField } from '@mui/material'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import SearchIcon from '@mui/icons-material/Search'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import HomeIcon from '@mui/icons-material/Home'
import { db } from 'firebase.js'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { coffeewebStorageKeys, coffeewebSetLocal, coffeewebGetLocal } from 'Utils/LocalStorage_Handler'
import { Closebtn, UnFilledHeartIcon, FilledHeartIcon, CoffeeWebLogo, devEnvLogo, Logo } from 'Assets/Icons'
import { addBlankTargetToAnchors } from 'Utils/HtmlUtils'
import { NoData } from 'Assets/Images'
import Header from 'Components/LayoutComponents/Header'
import FreeMenubar from 'Components/LayoutComponents/FreeUserMenubar'
import FooterDashboard from 'Components/LayoutComponents/Footer/FooterDashboard'
import Loader from 'Components/LayoutComponents/Loader'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import NewsReadMore from '../NewsReadMore'

function GuestNewsFeed({ setLoading, handleApiResponse }) {
  const history = useHistory()
  const params = new URLSearchParams()
  const location = useLocation()
  const videoRefs = useRef([])
  const { t } = useTranslation()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const userLoginStatus = coffeewebGetLocal(coffeewebStorageKeys.isLoggedIn)
  const pageSize = 10

  const [coffeeNewsData, setCoffeeNewsData] = useState([])
  const [filteredCoffeeNewsData, setFilteredCoffeeNewsData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [skeletonArray, setSkeletonArray] = useState([])
  const [skeletonLoading, setSkeletonLoading] = useState(false)
  const [dateForTheDay, setDateForTheDay] = useState([])
  const [active, setActive] = useState(true)
  const [defaultNewsLanguage, setDefaultNewsLanguage] = useState({})
  const [allLanguages, setAllLanguages] = useState([])
  const [popupForSmallScreenOnly, setPopupForSmallScreenOnly] = useState(false)
  const [scrollPos, setScrollPos] = useState(0)
  const [isHidden, setIsHidden] = useState(false)
  const [currentPlayer, setCurrentPlayer] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [showSearchPopup, setShowSearchPopup] = useState(false)
  const [scrollPositionForData, setScrollPositionForData] = useState(0)
  const [stickyDateList, setStickyDateList] = useState([])
  const [showStickyDate, setShowStickyDate] = useState(null)
  const [firebaseLatestNewsId, setFirebaseLatestNewsId] = useState(null)
  const [selectedLanguageId, setSelectedLanguageId] = useState(null)
  const [newsFeedStatus, setNewsFeedStatus] = useState({
    noNewsFeedImage: false,
    errorMessage: '',
    stationaryErrorMessage: ''
  })
  const [generatedToken, setGeneratedToken] = useState('')
  const [newsId, setNewsId] = useState('')
  const [showSearchPopupForBack, setShowSearchPopupForBack] = useState(false)
  const [newsClosed, setNewsClosed] = useState(false)
  const [listNewsLoaded, setListNewsLoaded] = useState(false)

  // TODO if Required
  // const [showModal, setShowModal] = useState(false)
  // const [newsDataOnClick, setNewsDataOnClick] = useState({
  //   encryptedId: '',
  //   newsForSubscription: ''
  // })

  const hasMore = true
  const { noNewsFeedImage, errorMessage, stationaryErrorMessage } = newsFeedStatus

  if (userLoginStatus) {
    if (userDetails?.userLandingPageUrl) {
      history.push(`${ROUTE_STRINGS.dashboard}/${userDetails?.userLandingPageUrl}`)
    } else {
      history.push(ROUTE_STRINGS.coffeenewsfeeds)
    }
  }

  const init = () => {
    const array = Array.from({ length: pageSize }, () => 1)

    setSkeletonLoading(true)
    setSkeletonArray(array)

    const handleScroll = () => {
      setScrollPositionForData(window.scrollY || document.documentElement.scrollTop)
    }

    generateToken()
    getCoffeeNewsData(1, 'first')

    let mounted = false

    const unSub = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb-MicroServices' : 'CoffeeWeb', 'NewsFeeds'), (doc) => {
      if (mounted) {
        const firebaseResponse = doc.data()
        const freeNewsLanguageId = coffeewebGetLocal(coffeewebStorageKeys.freeNewsLanguageId)

        if (!firebaseResponse?.IsDeleted && firebaseResponse?.LatestNewsLanguage === freeNewsLanguageId && firebaseResponse?.IsFreeNews) {
          setFirebaseLatestNewsId(firebaseResponse?.LatestNewsId)
        }
      } else {
        mounted = true
      }
    })

    window.addEventListener('scroll', handleScroll)

    return () => {
      unSub()
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY

      if (currentScrollPos > scrollPos) {
        if (currentScrollPos > 100) {
          setIsHidden(true)
        }
        setScrollPos(currentScrollPos)
      } else if (currentScrollPos < scrollPos - 100) {
        setIsHidden(false)
        setScrollPos(currentScrollPos)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPos])

  useEffect(() => {
    if (firebaseLatestNewsId) {
      let found = false

      for (let i = 0; i < coffeeNewsData.length; i++) {
        if (coffeeNewsData[i].id === firebaseLatestNewsId) {
          found = true
        }
      }

      if (!found) {
        if (active) {
          setTimeout(() => {
            getNewsAndMediaById()
          }, 5000)
        }
      }
    }
  }, [firebaseLatestNewsId])

  useEffect(() => {
    setBodyOverflowToAuto()

    if (!location.search && (!newsClosed || !listNewsLoaded)) {
      init()
    } else if (!generatedToken) {
      generateToken()
    }
  }, [location.search])

  useEffect(() => {
    const objectDates = document.getElementsByClassName('object-date')
    const stickyDates = []

    for (let i = 0; i < objectDates?.length; i++) {
      const objectDate = objectDates[i]
      const offsetTop = objectDate.offsetTop

      const deviceWidth = window.innerWidth

      if (deviceWidth < 767 && scrollPositionForData + 112 >= offsetTop) {
        stickyDates.push(objectDate.getAttribute('data-id'))
        setShowStickyDate(objectDate.getAttribute('data-id'))
      }
      if (scrollPositionForData + 84 >= offsetTop) {
        stickyDates.push(objectDate.getAttribute('data-id'))
        setShowStickyDate(objectDate.getAttribute('data-id'))
      }
    }
    setStickyDateList(stickyDates)
  }, [scrollPositionForData])

  useEffect(() => {
    if (errorMessage) {
      const timeoutId = setTimeout(() => {
        setNewsFeedStatus((previousState) => ({ ...previousState, errorMessage: '' }))
      }, 5000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [errorMessage])

  // Generate Auth-Token
  const generateToken = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.generateAuthToken()
      const { token } = response.data

      setLoading(false)
      setGeneratedToken(token)
    } catch (error) {
      setLoading(false)
      handleApiResponse({ status: error.response?.status, message: 'FAILED_TO_GENERATE_AUTH_TOKEN', onRetry: () => generateToken() })
    }
  }

  // To get coffee news on Daily Feeds on PAGE LOAD
  const getCoffeeNewsData = async (languageId, firstLoad) => {
    setActive(true)
    setCoffeeNewsData([])
    setSkeletonLoading(true)
    setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: '', stationaryErrorMessage: '' }))
    try {
      const response = await apiAdapterCoffeeWeb.getAllFreeNewsByLanguage({ languageId: languageId || 1 })

      if (response?.data?.returnLst?.menus?.length && response?.data?.returnLst?.languageMasters?.length && response?.data?.returnLst?.languageMasters?.length) {
        coffeewebSetLocal(coffeewebStorageKeys.freeMenus, response.data?.returnLst.menus)
        setAllLanguages(response.data?.returnLst.languageMasters)
        firstLoad && setDefaultNewsLanguage(response.data?.returnLst.languageMasters[0])
        setPageNumber((prevState) => prevState + 1)
        const dateList = []
        const updatedNewsData = response.data?.returnLst.usersNewsFeedModel.map((ele) => {
          const { customDateWithDay, newsHtml } = ele
          const data = addBlankTargetToAnchors(newsHtml)

          if (!dateList.includes(customDateWithDay)) {
            dateList.push(customDateWithDay)

            return { ...ele, customDate: customDateWithDay, newsHtml: data }
          }

          return ele
        })

        setCoffeeNewsData(updatedNewsData)
        setFilteredCoffeeNewsData(updatedNewsData)
        setDateForTheDay(dateList)
      } else {
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, stationaryErrorMessage: t('NEWS_FEED_NO_DATA') }))
      }
    } catch (err) {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, stationaryErrorMessage: t('NEWS_FEED_API_FAILED') }))
      handleApiResponse({ status: err.response?.status, message: 'FAILED_TO_LOAD_NEWS', onRetry: () => getCoffeeNewsData(languageId, 'first') })
    } finally {
      setSkeletonLoading(false)
    }
  }

  // To get coffee news on Daily Feeds on SCROLL
  const getNewsOnInfiniteScroll = async () => {
    if (!searchValue) {
      setSkeletonLoading(true)

      try {
        const response = await apiAdapterCoffeeWeb.getFreeNewsToDisplayForUser({ pageNumber, pageSize, languageId: defaultNewsLanguage.idLanguage || 1 })

        if (response?.data?.returnLst?.length) {
          const dateList = []
          const theDayData = response?.data?.returnLst.map((ele) => {
            const { newsHtml, customDateWithDay } = ele
            const data = addBlankTargetToAnchors(newsHtml)

            if (!dateList.includes(customDateWithDay) && !dateForTheDay.includes(customDateWithDay)) {
              dateList.push(customDateWithDay)

              return { ...ele, customDate: customDateWithDay, newsHtml: data }
            }

            return ele
          })

          const modifiedData = theDayData.filter(({ id }) => !filteredCoffeeNewsData?.some((item) => item.id === id))

          setDateForTheDay((dateForTheDay) => [...dateForTheDay, ...dateList])
          setFilteredCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...modifiedData])
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          setCoffeeNewsData((coffeeNewsData) => [...coffeeNewsData, ...modifiedData])
        } else {
          setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_ALL_NEWS_FETCHED') }))
        }
      } catch (err) {
        handleApiResponse({ status: err.response.status, message: 'FAILED_TO_LOAD_NEWS', onRetry: getNewsOnInfiniteScroll })
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: t('NEWS_FEED_API_FAILED') }))
      } finally {
        setSkeletonLoading(false)
      }
    } else {
      // TODO: Search News On Scroll
    }
  }

  // To make API request to get the latest News added and update it on UI
  const getNewsAndMediaById = async () => {
    await apiAdapterCoffeeWeb.getNewsById({ userId: 0, newsId: firebaseLatestNewsId, isRead: false }, generatedToken).then((response) => {
      const newObject = [{ ...response.data?.returnLst, customDateWithDay: 'Today' }]
      const newDataList = newObject.concat(coffeeNewsData)
      const dateList = []
      const newData = newDataList.map((ele) => {
        const { customDateWithDay } = ele

        if (!dateList.includes(customDateWithDay)) {
          dateList.push(customDateWithDay)

          return { ...ele, customDate: customDateWithDay }
        }

        return { ...ele, customDate: null }
      })

      setCoffeeNewsData(newData)
      setFilteredCoffeeNewsData(newData)
      setDateForTheDay(dateList)
    })
  }

  // To Load coffee news on DAILY FEEDS Tab Click action
  const getCoffeeNewsDataOnClick = () => {
    setActive(true)
    setCoffeeNewsData([])
    setPageNumber(1)
    setSearchValue('')
  }

  // Navigate To News Read More
  const navigateToNewsReadMoreScreen = ({ showFullfreeNews, id: newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, idNewsFavourite, views }) => {
    setShowSearchPopup(false)
    setListNewsLoaded(true)
    const videoContainers = document.querySelectorAll('.video-container')

    videoContainers.forEach((container) => {
      const video = container.querySelector('video')

      video.pause()
    })

    if (!showFullfreeNews) {
      redirectForAuthentication(encryptedId, newsForSubscription, newsId)
    } else {
      setNewsId(newsId)
      params.set('showFullNews', true)
      params.set('newsId', newsId)
      history.push({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, idNewsFavourite, views } })
    }
  }

  // Close News Read More Component
  const handleNewsFalseModal = (item, newsClosed) => {
    const modifiedData = coffeeNewsData.map((ele) => (ele.id === item?.id ? { ...ele, views: item?.views } : ele))

    setNewsClosed(newsClosed)
    setCoffeeNewsData(modifiedData)
    history.replace(ROUTE_STRINGS.guestNewsFeed)
    if (showSearchPopupForBack === true) {
      setShowSearchPopup(true)
    }
    setBodyOverflowToAuto()
  }

  // To load news based on Language change
  const handleChangeForLanguage = (e) => {
    if (selectedLanguageId !== e.idLanguage) {
      setSearchValue('')
      setSelectedLanguageId(e.idLanguage)
      setDefaultNewsLanguage(e)
      coffeewebSetLocal(coffeewebStorageKeys.freeNewsLanguageId, e.idLanguage)
      setPopupForSmallScreenOnly(false)
      setPageNumber(1)
      window.scrollTo(0, 0)

      if (active) {
        getCoffeeNewsData(e.idLanguage)
      }
    }
  }

  // To handle search operation in Locally Loaded News.
  const handleSearchExistingData = (e) => {
    setSearchValue(e.target.value)
    setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: false, errorMessage: '', stationaryErrorMessage: '' }))

    const searchedDataFromExistingData = filteredCoffeeNewsData.filter((item) => {
      if (item.subject.toLowerCase().includes(e.target.value.toLowerCase()) || item.author.toLowerCase().includes(e.target.value.toLowerCase()) || item.customDateWithDay.toLowerCase().includes(e.target.value.toLowerCase()) || item.shareText.toLowerCase().includes(e.target.value.toLowerCase())) {
        return item
      }
    })

    const dateList = []
    const dateCustom = searchedDataFromExistingData.map((element) => {
      const { newsHtml, customDateWithDay } = element

      if (!dateList.includes(customDateWithDay)) {
        dateList.push(customDateWithDay)

        return { ...element, customDate: customDateWithDay, newsHtml: addBlankTargetToAnchors(newsHtml) }
      }

      return element
    })

    if (!searchedDataFromExistingData.length) {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, stationaryErrorMessage: t('NO_RESULTS_FOR_SEARCH') }))
    }

    setCoffeeNewsData(dateCustom)
  }

  const handleFilterOptions = (options, { inputValue }) => options.filter((option) => option.languageName.toLowerCase().startsWith(inputValue.toLowerCase()))

  // To handle video play in news feed card
  const handlePlay = (index) => {
    const player = videoRefs.current[index]

    if (currentPlayer && currentPlayer !== player) {
      currentPlayer.pause()
      currentPlayer.currentTime = 0
    }
    player.play()
    setCurrentPlayer(player)
  }

  // To handle Close Icon click action in search field
  const handleSearchClear = () => {
    setSearchValue('')
    setCoffeeNewsData(filteredCoffeeNewsData)
    if (filteredCoffeeNewsData?.length === 0) {
      setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('NEWS_FEED_NO_DATA') }))
    }
  }

  const handleSearchInput = () => {
    setShowSearchPopup(true)
    setShowSearchPopupForBack(true)
    setSearchValue('')
    const body = document.querySelector('body')

    body.style.overflow = 'hidden'
  }

  // To handle Back Icon Button Action in Search Modal Mobile Screen
  const handleCloseSearchModal = () => {
    setShowSearchPopup(false)
    setShowSearchPopupForBack(true)
    setBodyOverflowToAuto()
  }

  // To handle GO TO TOP Action in Mobile Screen
  function handleGoToTop() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0
    } else if (document.body.scrollTop > 0) {
      document.body.scrollTop = 0
    }
  }

  const getSubscriptionLabelColor = (ele) => {
    const colorCodes = ele?.subscriptionColor?.split(',')

    return `linear-gradient(100deg, ${colorCodes[0]}, ${colorCodes[1]}, ${colorCodes[2]})`
  }

  const redirectForAuthentication = (encryptedId, newsForSubscription, newsId) => {
    if (userLoginStatus === false) {
      history.push({ pathname: ROUTE_STRINGS.login, state: { encryptedId, newsForSubscription, newsId, forGuestUser: true } })
    } else {
      history.push({ pathname: ROUTE_STRINGS.createAccount, state: { encryptedId, newsForSubscription, newsId, forGuestUser: true } })
    }
  }

  const setBodyOverflowToAuto = () => {
    const body = document.querySelector('body')

    body.style.overflow = 'auto'
  }

  // Render Method to Update Error Message on News API Fail
  const MessageContentRenderer = ({ noNewsFeedImage, errorMessage, stationaryErrorMessage }) => (
    <>
      <div className="search_on_page_load">{noNewsFeedImage && <img src={NoData} alt="NoDataFound" className="no_data_found_image" />}</div>
      {(errorMessage || stationaryErrorMessage) && <div className="all-data-fetched">{errorMessage || stationaryErrorMessage}</div>}
    </>
  )

  // TODO if Required

  // const handleModalDismiss = () => {
  //   setShowModal(false)
  //   setNewsDataOnClick((previousState) => ({ ...previousState, encryptedId: '', newsForSubscription: '' }))
  // }

  // const ForbiddenContentModal = () => (
  //   <Modal show={showModal}>
  //     <Modal.Header className="login-confirmation-modal">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>

  //     <Modal.Body>
  //       <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
  //         <div>
  //           <h6 style={{ color: 'black' }}>{t('CONTENT_FORBIDDEN_MESSAGE')}</h6>
  //         </div>
  //         <div>
  //           <h6 style={{ color: 'black' }}>{t('CONTENT_FORBIDDEN_MESSAGE_CONFIRMATION')}</h6>
  //         </div>
  //       </div>
  //     </Modal.Body>
  //     <Modal.Footer>
  //       <div style={{ display: 'flex', justifyContent: 'space-between', width: '50%', gap: '20px' }}>
  //         <button id="noBtn" onClick={() => handleModalDismiss()} style={{ width: '100px', height: '36px', backgroundColor: 'red' }}>
  //           {t('CANCEL')}
  //         </button>
  //         <button id="yesBtn" onClick={() => redirectForAuthentication()} style={{ width: '100px', height: '36px', backgroundColor: '#50b3f6' }}>
  //           {t('CONTINUE')}
  //         </button>
  //       </div>
  //     </Modal.Footer>
  //   </Modal>
  // )

  return (
    <div>
      <Header isLoggedIn={false} />
      <FreeMenubar />
      <div className="coffee_news_feeds content-wrapper restrictEverything" onContextMenu={(e) => e.preventDefault()}>
        <div className="feeds_container">
          <div className="news_feeds_card" style={{ margin: '0px' }}>
            {!location.search && (
              <>
                <div
                  className="fixed_coffee_news_feed stickyLanguageDropDown"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    zIndex: '2',
                    boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 2px',
                    position: 'fixed'
                  }}
                >
                  <div className="coffee_news_title">{t('COFFEE_NEWS_FEEDS')}</div>
                  <div className="headerSec2">
                    <div className="search_for_big_screen ">
                      <TextField
                        sx={{ width: '100%' }}
                        value={searchValue}
                        fullWidth
                        onChange={(e) => handleSearchExistingData(e)}
                        placeholder={t('SEARCH_FOR_NEWS')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" sx={{ display: 'flex' }}>
                              {searchValue?.length > 0 && (
                                <IconButton
                                  onClick={handleSearchClear}
                                  sx={{
                                    borderRadius: '50%',
                                    padding: '6px',
                                    marginRight: '4px'
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '1.7rem', color: '#50b3f6' }} />
                                </IconButton>
                              )}
                              <IconButton
                                sx={{
                                  borderRadius: '50%',
                                  padding: '6px'
                                }}
                                // TODO
                                // onClick={globalSearchData}
                              >
                                <SearchIcon sx={{ fontSize: '1.7rem', color: '#50b3f6' }} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                    <div className="language_and_search">
                      <div className="big_screen_language_dropdown">
                        <div className="language_div">{defaultNewsLanguage && defaultNewsLanguage != null && <img src={defaultNewsLanguage.flagsUrl} className="flag_image" alt="flag" />}</div>
                        <div className="country_select_dropdown">
                          <Autocomplete
                            id="country-select-demo1"
                            value={defaultNewsLanguage}
                            defaultValue={allLanguages[0]}
                            className="language_dropdown"
                            disableClearable
                            filterOptions={handleFilterOptions}
                            options={allLanguages}
                            autoHighlight
                            getOptionLabel={(option) => option?.localLanguageName || 'No Label'}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(newValue, v) => handleChangeForLanguage(v)}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img src={option?.flagsUrl} loading="lazy" width="20" className="dropdown_flag_image" alt="flag" />
                                {option?.localLanguageName}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'off',
                                  style: { border: 'none', height: '7px' }
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="small_screen_language_dropdown guest-user-text-border" onClick={() => setPopupForSmallScreenOnly(true)}>
                        <div className="small_screen_language_div">
                          <div className="small_screen_language_sub_div">
                            <div className="small_screen_language">{defaultNewsLanguage && defaultNewsLanguage != null && <img src={defaultNewsLanguage.flagsUrl} className="small_screen_flag_image" alt="flag" />}</div>
                            <div className="small_screen_language_text_div">
                              {defaultNewsLanguage && defaultNewsLanguage != null && (
                                <span onClick={() => setPopupForSmallScreenOnly(true)} className="small_screen_language_text">
                                  {defaultNewsLanguage.localLanguageName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div>
                            <ArrowDropDownIcon className="dropdown_arrow_icon guest-user-text-border" />
                          </div>
                        </div>
                      </div>

                      <div className="buy_subscription_div">
                        <div className="buy_subscription_sub_div guest-user-background">
                          <NavLink activeClassName="active_nav-link" exact to={ROUTE_STRINGS.createAccount} className="buy_subscription_button">
                            {t('CREATE_ACCOUNT_BUTTON_LABEL')}
                          </NavLink>
                        </div>

                        <div
                          className="small_screen_search_div d-none"
                          onClick={() => {
                            handleSearchInput()
                          }}
                        >
                          <SearchIcon className="small_screen_search_icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`fixed_coffee_news_feed${isHidden ? ' hidden' : ' visible'}`} id="forScroll" style={{ zIndex: '1' }}>
                  <nav className="tabs_for_news_feed_screen">
                    <div className="tabs_nav_link">
                      <div className="tabs_nav_link_sub_div guest-user-background">
                        <InsertInvitationIcon sx={{ color: 'white', width: '20px', margin: '0px 3px', marginTop: '-3px' }} />
                        {t('DAILY_FEED')}
                      </div>

                      <div onClick={() => history.push(ROUTE_STRINGS.login)} className="tab_daily_feed guest-mobile-view">
                        {t('LOGIN_BUTTON')}
                      </div>

                      <div onClick={() => redirectForAuthentication()} className="tab_daily_feed guest-large-view">
                        <FilledHeartIcon className="filled_heart" />
                        {t('MY_FAVOURITE')}
                      </div>
                    </div>
                  </nav>
                </div>
              </>
            )}
            {location.search && generatedToken && <NewsReadMore handleApiResponse={handleApiResponse} redirectForAuthentication={redirectForAuthentication} generatedToken={generatedToken} newsId={newsId} handleNewsFalseModal={handleNewsFalseModal} setNewsFeedStatus={setNewsFeedStatus} />}

            <InfiniteScroll
              dataLength={coffeeNewsData?.length}
              next={getNewsOnInfiniteScroll}
              hasMore={hasMore}
              loader={
                <>
                  {skeletonLoading && (
                    <div>
                      {skeletonArray.map((ele, index) => (
                        <div className="skeleton_for_full_screen" key={index}>
                          <div>
                            <Stack spacing={1}>
                              <Skeleton variant="rounded" className="skeleton_tag" height={225} />
                            </Stack>
                          </div>
                          <div>
                            <Stack spacing={1}>
                              <Skeleton variant="rounded" className="skeleton_tag" height={30} />
                              <Skeleton variant="rounded" className="skeleton_tag" height={150} />
                              <div className="skeleton_div">
                                <Skeleton variant="rounded" className="skeleton_tag" height={30} />
                                <Skeleton variant="rounded" className="skeleton_tag" height={30} />
                              </div>
                            </Stack>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              }
            >
              <div className="coffee_news_feeds cardsDivForNewsScreen content-wrapper restrictEverything" style={{ marginTop: '0px' }}>
                <div className="feeds_container">
                  <div className="news_feeds_card">
                    <div className="outer-card">
                      {!showSearchPopup && (
                        <div className={`bottom_button bottomButtonScroll${isHidden ? ' slide-down' : ' down'}`}>
                          <div className="bottom_button_sub_div" onClick={handleGoToTop}>
                            <div className="bottom_button_icon_div">
                              <HomeIcon sx={{ color: '#50b3f6' }} />
                            </div>
                          </div>
                          <div className="bottom_button_search_bar_div" onClick={handleSearchInput}>
                            <div className="search_text">{t('SEARCH')}</div>
                            <div className="search_icon_div">
                              <SearchIcon className="search_icon" />
                            </div>
                          </div>
                        </div>
                      )}
                      {coffeeNewsData?.length > 0 && (
                        <div>
                          <div>
                            <div className="paginated" style={{ marginBottom: '10px' }}>
                              {coffeeNewsData?.map((item, index) => (
                                <React.Fragment key={index}>
                                  {item.customDate && (
                                    <div className="date_and_time_stamp col-md-12">
                                      <div className="date_and_time_stamp_sub_div">.</div>
                                      <div className="date">{item.customDate}</div>
                                      <div className="date_and_time_stamp_sub_div">.</div>
                                    </div>
                                  )}
                                  {
                                    <>
                                      {window.innerWidth > 767 && index < 1 && scrollPositionForData > 117 && (
                                        <div className={'date_tag_design col-md-12 '}>
                                          <div className="date_horizontal_line">.</div>
                                          <div className="date">{showStickyDate}</div>
                                          <div className="date_horizontal_line">.</div>
                                        </div>
                                      )}
                                    </>
                                  }
                                  {
                                    <>
                                      {window.innerWidth <= 767 && index < 1 && scrollPositionForData > 50 && (
                                        <div className={isHidden ? 'date_tag_for_big_screen col-md-12' : 'date_tag_design col-md-12'}>
                                          <div className="date_tag_horizontal_line">.</div>
                                          <div className="date">{showStickyDate}</div>
                                          <div className="date_tag_horizontal_line">.</div>
                                        </div>
                                      )}
                                    </>
                                  }
                                  {item.idNewsFavourite === null ? (
                                    ''
                                  ) : (
                                    <>
                                      <div key={index} className={`object-date sticky_date ${stickyDateList.includes('object-id') ? 'sticky' : ''}`} data-id={`${item.customDateWithDay}`}>
                                        <div className="image_div">
                                          <div className="data_for_mobile_screen" style={{ padding: '4px 9px 2px' }}>
                                            <div>
                                              <CalendarMonthIcon className="muiLogo calendar_icon" />
                                              {item?.customdate}
                                            </div>
                                            <div className="time_icon_div">
                                              <AccessTimeFilledIcon className="muiLogo time_icon" />
                                              {item?.customtime}
                                            </div>
                                          </div>
                                          {item?.nwsFeedMedia?.length !== 0 ? (
                                            <div className="video_div">
                                              {item?.nwsFeedMediaVideo?.length > 0 ? (
                                                <div className="video-container" style={{ margin: 'auto' }}>
                                                  <div key={item.id}>
                                                    <video
                                                      className="video_display"
                                                      src={item?.nwsFeedMediaVideo[0]?.pathOfMedia}
                                                      controls
                                                      ref={(ref) => {
                                                        videoRefs.current[index] = ref
                                                      }}
                                                      onPlay={() => handlePlay(index)}
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="sliding_image_div">
                                                  {item?.nwsFeedMediaImage?.length > 4 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_five_image container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>{index < 5 && <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" />}</div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 4 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_four_image container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>
                                                            <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" key={index} />
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 3 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_three_images container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>
                                                            <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" />
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 2 && (
                                                    <div className="sliding_image_sub_div" id="run">
                                                      <div className="carousel_for_two_images container-carosalForAll" id="carosal">
                                                        {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                          <div key={index}>
                                                            <img className="imgSize" src={ele.pathOfMedia} alt="thumbnail" />
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  )}
                                                  {item?.nwsFeedMediaImage?.length === 1 && (
                                                    <div className="single_image_div" id="run">
                                                      {item?.nwsFeedMediaImage?.map((ele, index) => (
                                                        <div key={index}>
                                                          <img
                                                            className="imgSize single_image"
                                                            style={{
                                                              width: '100%',
                                                              objectFit: 'contain',
                                                              padding: '8px',
                                                              borderRadius: '10px'
                                                            }}
                                                            src={ele.pathOfMedia}
                                                            alt="thumbnail"
                                                          />
                                                        </div>
                                                      ))}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="news_media_div">
                                              <img src={process.env.REACT_APP_NODE_ENV === 'production' ? CoffeeWebLogo : devEnvLogo} className="static_image" alt="coffee-web-logo" />
                                            </div>
                                          )}
                                          <div className="data_for_mobile_screen " style={{ padding: '0px 9px 6px' }}>
                                            <div className="account_icon_div">
                                              <AccountCircleIcon className="muiLogo account_icon" /> {item?.author}
                                            </div>

                                            <div className="visibility_icon_div">
                                              <div>
                                                <VisibilityIcon className="muiLogo visibility_icon" /> {item?.views}
                                              </div>
                                              <div>
                                                <UnFilledHeartIcon className="heartLogoBlob cursor_pointer" onClick={() => redirectForAuthentication()} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="subSecForNews">
                                          <div>
                                            <div className="d-flex justify-content-between ">
                                              <div className="newsHeading cursor_pointer" onClick={() => navigateToNewsReadMoreScreen(item)}>
                                                <div>{ReactHtmlParser(item?.subject)}</div>
                                              </div>
                                            </div>
                                            <div className="newsHtml">{ReactHtmlParser(item?.shareText)}</div>
                                          </div>
                                          <div className="newsDetails">
                                            <div className="details">
                                              <div className="calendar_icon_div">
                                                <CalendarMonthIcon className="muiLogo" />
                                                {item?.customdate}
                                              </div>
                                              <div className="time_icon_div">
                                                <AccessTimeFilledIcon className="muiLogo" />
                                                {item?.customtime}
                                              </div>
                                              <div className="visibility_icon_div">
                                                <VisibilityIcon className="muiLogo" />
                                                {item?.views}
                                              </div>
                                              <div className="account_icon_div">
                                                <AccountCircleIcon className="muiLogo" />
                                                {item?.author?.substr(0, 9)}
                                                {item?.author?.length > 9 && <span>...</span>}
                                              </div>
                                              <div className="published_for_div">
                                                <div className="published_for_sub_div">
                                                  <span className="mr-2 d-none">Published for </span>
                                                  {item?.newsForSubName?.map((ele, index) => (
                                                    <React.Fragment key={index}>
                                                      {ele?.subscriptioncode && (
                                                        <span
                                                          className="eachLabeling"
                                                          style={{
                                                            backgroundSize: '400% 400%',
                                                            backgroundImage: getSubscriptionLabelColor(ele),
                                                            animation: 'gradient 7s ease infinite',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                            fontSize: '12px',
                                                            padding: '1px 7px',
                                                            margin: '1px 2px'
                                                          }}
                                                        >
                                                          {ele?.subscriptioncode}
                                                        </span>
                                                      )}
                                                    </React.Fragment>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="sub_labeling_main_div">
                                              <div className="sub_labeling">
                                                <span className="mr-2 d-none">Published for </span>
                                                {item?.newsForSubName?.map((ele, index) => (
                                                  <React.Fragment key={index}>
                                                    {ele?.subscriptioncode && (
                                                      <span
                                                        className="eachLabeling"
                                                        style={{
                                                          backgroundSize: '400% 400%',
                                                          backgroundImage: `linear-gradient(100deg, ${ele?.subscriptionColor?.slice(0, 7)}, ${ele?.subscriptionColor?.slice(9, 16)}, ${ele?.subscriptionColor?.slice(18, 27)})`,
                                                          animation: 'gradient 7s ease infinite',
                                                          color: 'white',
                                                          borderRadius: '4px'
                                                        }}
                                                      >
                                                        {ele?.subscriptioncode}
                                                      </span>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              </div>
                                              <div className="heart_pulse_logo_for_read_more">
                                                <UnFilledHeartIcon className="heartLogoBlob cursor_pointer" onClick={() => redirectForAuthentication()} />
                                              </div>
                                              <div>
                                                <div className="read_more_button" onClick={() => navigateToNewsReadMoreScreen(item)}>
                                                  {t('READ_MORE')}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </InfiniteScroll>
            <div className="full_Stop">.</div>

            {/* select language popup */}
            <Modal show={popupForSmallScreenOnly} className="language_modal" onHide={() => setPopupForSmallScreenOnly(false)}>
              <Modal.Header>
                <Modal.Title>
                  <div className="language_modal_title">{t('SELECT_LANGUAGE')}</div>
                </Modal.Title>
                <Closebtn className="language_modal_close_button" onClick={() => setPopupForSmallScreenOnly(false)} />
              </Modal.Header>
              <Modal.Body>
                <div className="language_modal_body">
                  <div>
                    {allLanguages?.map((item, index) => (
                      <div key={index} onClick={() => handleChangeForLanguage(item)} className="language_div">
                        <div className="language_sub_div">
                          <img className="language_modal_flag_image" src={item.flagsUrl} alt="flag" />
                        </div>
                        {item.nicename !== 'DEFAULT' && <div>{item.nicename}</div>}
                        {item.nicename !== 'DEFAULT' && <div>-</div>}
                        <div>{item.localLanguageName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            {/* searchPopup for news */}
            {showSearchPopup && (
              <div className="search_popup_for_news">
                <AppBar sx={{ position: 'relative' }} className="app_bar">
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => handleCloseSearchModal()} aria-label="close">
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      {t('COFFEE_NEWS_FEEDS')}
                    </Typography>
                  </Toolbar>
                </AppBar>
                <div className="input_field">
                  <TextField
                    sx={{ width: '100%' }}
                    value={searchValue}
                    fullWidth
                    onChange={(e) => handleSearchExistingData(e)}
                    autoFocus="true"
                    placeholder={t('SEARCH_FOR_NEWS')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ display: 'flex' }}>
                          {searchValue?.length > 0 && (
                            <IconButton
                              onClick={handleSearchClear}
                              sx={{
                                borderRadius: '50%',
                                padding: '6px',
                                marginRight: '4px'
                              }}
                            >
                              <CloseIcon sx={{ fontSize: '1.7rem', color: '#50b3f6' }} />
                            </IconButton>
                          )}
                          <IconButton
                            sx={{ borderRadius: '50%', padding: '6px' }}
                            // TODO
                            // onClick={globalSearchData}
                          >
                            <SearchIcon sx={{ fontSize: '1.7rem', color: '#50b3f6' }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <Divider />
                <div className="search_trending_data">
                  {coffeeNewsData.map((item, index) => (
                    <div key={index}>
                      <div className="search_trending_data_sub_div">
                        <div>{ReactHtmlParser(item.subject)}</div>
                        <ArrowForwardIcon sx={{ color: '#50b3f6', cursor: 'pointer' }} onClick={() => redirectForAuthentication(item.encryptedId, item.newsForSubscription, item.id)} />
                      </div>
                      <Divider />
                    </div>
                  ))}
                  <div className="search_trending_data_full_stop">.ssw</div>
                </div>
              </div>
            )}
          </div>

          {/* TODO */}
          {/* {coffeeNewsData?.length === 0 && searchValue && !errorMessage && (
            <div className="date_and_time_stamp search_news_not_found col-md-12">
              <div className="date search_news_not_found_sub_Div" onClick={globalSearchData}>
                {t('SEE_MORE')}
              </div>
            </div>
          )} */}

          <MessageContentRenderer noNewsFeedImage={noNewsFeedImage} errorMessage={errorMessage || stationaryErrorMessage} />

          {/* <ForbiddenContentModal /> TODO if Required */}
        </div>
      </div>
      <FooterDashboard />
    </div>
  )
}

export default Loader(ApiResponseHandler(GuestNewsFeed))
