import './styles.scss'
import React, { useEffect, useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import Header from 'Components/LayoutComponents/Header'
import { useTranslation } from 'react-i18next'
import { ROUTE_STRINGS } from 'Utils/Constants'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import { AppleIcon, NewMainLogoPng, devEnvLogo, GooglePlay } from '../../Assets/Icons'
import Loader from '../../Components/LayoutComponents/Loader'
import { coffeewebStorageKeys, coffeewebGetLocal, coffeewebSetLocal } from '../../Utils/LocalStorage_Handler'
import Footer from '../../Components/LayoutComponents/Footer'
import SignupComponent from './Components/SignupComponent'
import apiAdapterCoffeeWeb from '../../Services/apiAdapter_CoffeeWeb'
import LoginComponent from './Components/LoginComponent'

const LoginScreen = ({ setLoading, handleApiResponse }) => {
  const { i18n, t } = useTranslation()

  const history = useHistory()

  const pageUrl = window.location.pathname
  const splitPageUrl = pageUrl.split('/')
  const currentUrl = splitPageUrl.pop()

  const singleDeviceId = coffeewebGetLocal(coffeewebStorageKeys.singleDeviceId)
  const [activeTab, setActiveTab] = useState(currentUrl)
  const [generatedToken, setGeneratedToken] = useState('')
  const [appSettingsState, setAppSettingsState] = useState({})
  const [newsDataOnClick, setNewsDataOnClick] = useState({ encryptedId: '', newsForSubscription: '', newsId: 0, forGuestUser: '' })

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const init = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.generateAuthToken()
      const {
        data: { token }
      } = response

      if (!singleDeviceId) {
        coffeewebSetLocal(coffeewebStorageKeys.singleDeviceId, uuid())
      }

      setGeneratedToken(token)
      getApiSettings(token)
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: () => init(), message: t('FAILED_TO_GENERATE_AUTH_TOKEN_PLEASE_TRY_AGAIN') })
      setLoading(false)
    }
  }

  const getApiSettings = async (generatedToken) => {
    setLoading(true)

    try {
      const { data } = await apiAdapterCoffeeWeb.appSetting(generatedToken)

      setAppSettingsState(data)
      coffeewebSetLocal(coffeewebStorageKeys.appSettings, data)
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: () => getApiSettings(), message: t('FAILED_TO_FETCH_APP_SETTINGS') })
    } finally {
      setLoading(false)
    }
  }

  const handleTabSelection = (selectedTab) => {
    if (selectedTab === 'create_account') {
      history.replace({ pathname: ROUTE_STRINGS.createAccount, state: newsDataOnClick })
    } else {
      history.replace({ pathname: ROUTE_STRINGS.login, state: newsDataOnClick })
    }
    setActiveTab(selectedTab)
  }

  return (
    <>
      <Header isLoggedIn={false} />
      <div className="login_screen authScreen_wrapper">
        <div className="container authScreen_container">
          <div className="row authScreen_div_container">
            <div className="col-md-6 m-auto d-none d-md-block authScreen_logo_container">
              <img src={process.env.REACT_APP_NODE_ENV === 'production' ? NewMainLogoPng : devEnvLogo} alt="coffeeimg" className="w-100" />
            </div>
            <div className="col-md-6 mx-auto p-0 authScreen_form_container">
              <div className="login_card card authScreen_login_signup_div">
                <div className="box authScreen_shadow_container">
                  <div className="login_card_container authScreen_login_signup_shadow">
                    <Tabs activeKey={activeTab} id="uncontrolled-tab-example" className="login_header_btn login_signup_tabs" onSelect={handleTabSelection}>
                      <Tab eventKey="login" title={t('LOGIN')}>
                        {activeTab === 'login' && <LoginComponent setNewsDataOnClick={setNewsDataOnClick} generatedToken={generatedToken} singleDeviceId={singleDeviceId} appSettingsState={appSettingsState} handleTabSelection={handleTabSelection} />}
                      </Tab>
                      <Tab eventKey="create_account" title={t('CREATE_ACCOUNT_LABEL')}>
                        <div className="otp_screen">{activeTab === 'create_account' && <SignupComponent setNewsDataOnClick={setNewsDataOnClick} generatedToken={generatedToken} singleDeviceId={singleDeviceId} handleTabSelection={handleTabSelection} />}</div>
                      </Tab>
                    </Tabs>
                    <div className="card_footer">
                      <div className="social_btns">
                        <div className="flex">
                          <a className="app-btn blu flex vert" href="https://apps.apple.com/in/app/coffeeweb/id1618878403" target="_blank" rel="noreferrer">
                            <AppleIcon fill={'#fff'} className="social_imgs" />
                            <p>
                              {t('DOWNLOAD_ON_THE')}
                              <br /> <span className="big-txt">{t('APP_STORE')}</span>
                            </p>
                          </a>
                          <a className="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.coffeeweb.app" target="_blank" rel="noreferrer">
                            <img alt="" src={GooglePlay} className="social_imgs" />
                            <p>
                              {t('GET_IT_ON')} <br /> <span className="big-txt">{t('GOOGLE_PLAY')}</span>
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Loader(ApiResponseHandler(LoginScreen))
