const ROUTE_STRINGS = {
  login: '/login',
  createAccount: '/create_account',
  coffeeNewsFeeds: '/dashboard/coffeenewsfeeds',
  guestNewsFeed: '/',
  aboutUs: '/aboutUs',
  guestCoffeeQuotes: '/coffeeQuotes',
  aboutMobileView: '/about-us/mobile',
  aboutUsCoffeeWeb: '/dashboard/aboutUs',
  guestGlobalDifferentials: '/globalDifferentials',

  // signup: `/signup`,
  // newsdummy: `/coffeenewsfeeds`,
  // supportRequest: `/supportrequest`,
  supportRequest: '/support',
  pnf: '/dashboard/pnf',
  // otpScreen: '/coffeeweb/otpscreen',
  dashboard: '/dashboard',
  // admindashboard: '/dashboard/admindashboard',
  // coffeeentries: '/dashboard/coffeeentries',
  // differentialsentry: '/dashboard/differentialsentry',
  // freightrateentry: '/dashboard/freightrateentry',
  // addtradebox: '/dashboard/addtradebox',
  // listtraders: '/dashboard/listtraders',
  // edittraders: '/dashboard/edittraders',
  // listnewsfeed: '/dashboard/listnewsfeed',
  // addnewsfeed: '/dashboard/addnewsfeed',
  // editnewsfeed: '/dashboard/editnewsfeed',
  // newsfeedapprovalscreen: '/dashboard/newsfeedapprovalscreen',
  // uploadvideo: '/dashboard/uploadvideo',
  // listusers: '/dashboard/listusers',
  // adduser: '/dashboard/adduser',
  // accountsettings: '/dashboard/adduser/accountsettings',
  // approvalsettings: '/dashboard/adduser/approvalsettings',
  // edituser: '/dashboard/edituser/',
  // edituserProfile: '/dashboard/edituser/',
  // approvallist: '/dashboard/approvallist',
  // listmenugroup: '/dashboard/listmenugroup',
  // addmenugroup: '/dashboard/addmenugroup',
  // editmenugroup: '/dashboard/editmenugroup',

  // Nav-bar
  coffeequotes: '/dashboard/CoffeeQuotes',
  globaldifferentials: '/dashboard/globaldifferentials',

  // coffeeQuint: '/dashboard/coffeeQuint',
  // reports: '/dashboard/reports',
  coffeenewsfeedsFvt: '/dashboard/coffeenewsfeedsFvt',
  newsreadmore: '/dashboard/newsreadmore',
  newsreadmorefvt: '/dashboard/newsreadmorefvt',
  notification: '/dashboard/notification',
  newsreadmorefornotification: '/dashboard/newsreadmorefornotification',
  opennewsnotification: '/dashboard/opennewsnotification',
  opensearchednews: '/dashboard/opensearchednews',
  opensharednews: '/dashboard/opensharednews',
  previewpdf: '/dashboard/previewpdf',
  searchfornews: '/dashboard/searchfornews',

  support: '/dashboard/support',
  // mynotification: '/dashboard/mynotification',
  // reports: '/dashboard/reports',
  // adminreports: '/dashboard/adminreports',
  // socialMediaProfilePage: '/dashboard/smProfilePage',
  // approvalscreens: '/dashboard/approvalscreens',
  // countrydropdownselector: '/dashboard/countrydropdownselector',

  subscription: '/dashboard/subscription',
  paymentgateway: '/dashboard/paymentgateway',
  success: '/dashboard/success',
  cancel: '/dashboard/cancel',

  // approveTrader: '/dashboard/approvetrader',
  // globalprice: '/dashboard/globalprice',
  // globalrawcoffeeprice: '/dashboard/globalrawcoffeeprice',
  // getsubscriptation: '/dashboard/getsubscriptation',
  // addsubscription: '/dashboard/addsubscription',
  // subscriptionfeatures: '/dashboard/subscriptionfeatures',
  // marketdata: '/dashboard/marketdata',
  // xedata: '/dashboard/xedata',

  // Newly Integrated

  coffeeprices: '/dashboard/coffeeprices',
  globalfreightrates: '/dashboard/globalfreightrates',
  globalrawcoffeeprices: '/dashboard/globalrawcoffeeprices',
  coffeequint: '/dashboard/coffeequint',
  weather: '/dashboard/weather',
  paymenthistory: '/dashboard/paymenthistory',
  traderequest: '/dashboard/traderequest',
  mynotification: '/dashboard/mynotification',
  coffeeweb: '/dashboard/coffeeweb'
}

export default ROUTE_STRINGS
