import React, { useEffect, useState } from 'react'
import './styles.scss'
import { NavLink, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import LoginIcon from '@mui/icons-material/Login'
import { v4 as uuid } from 'uuid'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import EditIcon from '@mui/icons-material/Edit'
import { doc, onSnapshot } from 'firebase/firestore'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { coffeewebStorageKeys, coffeewebGetLocal, coffeewebSetLocal } from 'Utils/LocalStorage_Handler'
import { ROUTE_STRINGS, STATIC_ROUTE_STRINGS } from 'Utils/Constants'
import { NewMainLogoPng, devEnvLogo } from 'Assets/Icons'
import { commonMethods, useResizeHandler } from 'Utils/commonMethods'
import Loader from '../Loader'
import NotificationListenService from './NotificationDropdown'
import { db } from '../../../firebase'
import ProfileModal from './Components/ProfileModal'
import LogoutModal from './Components/LogoutModal'
// import Package from '../../../../package.json'

const Header = ({ isLoggedIn }) => {
  const history = useHistory()
  const { i18n, t } = useTranslation()
  const { isWebView } = useResizeHandler()

  const appSettings = coffeewebGetLocal(coffeewebStorageKeys?.appSettings)
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys?.userDetails)
  const freeNewsLanguageId = coffeewebGetLocal(coffeewebStorageKeys.freeNewsLanguageId)
  const userLoginStatus = coffeewebGetLocal(coffeewebStorageKeys.isLoggedIn)
  const freeMenus = coffeewebGetLocal(coffeewebStorageKeys.freeMenus)
  const isLoggedInStatus = userDetails?.isLoggedIn
  let displayUrl

  if (!freeNewsLanguageId) {
    coffeewebSetLocal(coffeewebStorageKeys.freeNewsLanguageId, 1)
  }

  const [showLogoutPopup, setShowLogoutPopup] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)
  const [drawerState, setDrawerState] = React.useState(false)
  const [currentCity, setCurrentCity] = useState(null)
  const [menuDetails, setMenuDetails] = useState([])
  const [currentDateTime, setCurrentDateTime] = useState({ currentDate: '', currentTime: '' })

  const { currentDate, currentTime } = currentDateTime
  const pageUrl = window.location.pathname
  const splitPageUrl = pageUrl.split('/')
  const currentUrl = splitPageUrl.pop()

  useEffect(() => {
    if (isLoggedInStatus) {
      const unsubscribe = init()

      return () => {
        unsubscribe()
      }
    }
  }, [isLoggedInStatus])

  useEffect(() => {
    updateDateTime()

    const intervalId = setInterval(updateDateTime, 1000)

    const fetchLocationAndUpdateTime = async () => {
      try {
        const locationResponse = await commonMethods.searchLocation()

        setCurrentCity(locationResponse?.name)

        return () => {
          clearInterval(intervalId)
        }
      } catch (error) {
        console.error('Error getting geolocation:', error)
      }
    }

    fetchLocationAndUpdateTime()

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const updateDateTime = () => {
    const updatedDateTime = commonMethods.updateDateTime()

    setCurrentDateTime(updatedDateTime)
  }

  const init = () => {
    const unSub = onSnapshot(doc(db, process.env.REACT_APP_ENVIRONMENT === 'develop' ? 'CoffeeWeb-MicroServices' : 'CoffeeWeb', userDetails?.phone), (doc) => {
      const response = doc.data()
      const singleDeviceId = coffeewebGetLocal(coffeewebStorageKeys.singleDeviceId)
      const userDetails = coffeewebGetLocal(coffeewebStorageKeys?.userDetails)
      const isLoggedInStatus = userDetails?.isLoggedIn

      if (response) {
        if (response?.DeviceId !== singleDeviceId && isLoggedInStatus) {
          clearStorage('setNewDeviceId')
        } else if (response.LoginStatus === 'Log-Off' && !sessionStorage.getItem('logoutFlag')) {
          clearStorage()
        }
      }
    })

    if (userDetails?.menus !== null) {
      setMenuDetails(userDetails?.menus)
    }

    return () => {
      unSub()
    }
  }

  const clearStorage = (setNewDeviceId) => {
    setShowLogoutPopup(true)
    setTimeout(() => {
      const appSetting = coffeewebGetLocal(coffeewebStorageKeys.appSettings)
      const languageCode = coffeewebGetLocal(coffeewebStorageKeys.appLanguage)
      const singleDeviceId = coffeewebGetLocal(coffeewebStorageKeys.singleDeviceId)
      const freeMenus = coffeewebGetLocal(coffeewebStorageKeys.freeMenus)

      localStorage.clear()
      coffeewebSetLocal(coffeewebStorageKeys.appSettings, appSetting)
      coffeewebSetLocal(coffeewebStorageKeys.appLanguage, languageCode)
      coffeewebSetLocal(coffeewebStorageKeys.freeMenus, freeMenus)
      coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, false)

      if (setNewDeviceId) {
        coffeewebSetLocal(coffeewebStorageKeys.singleDeviceId, uuid())
      } else {
        coffeewebSetLocal(coffeewebStorageKeys.singleDeviceId, singleDeviceId)
      }
      sessionStorage.clear()
      history.push(ROUTE_STRINGS.guestNewsFeed)
    }, 5000)
  }

  const logoutFunctionForSmallDevice = () => {
    setDrawerState(false)
    setShowLogoutModal(true)
  }

  const handleOpenForProfileSetting = () => {
    setDrawerState(false)
    setModalShow(true)
  }

  const openProfileSetting = () => {
    setDrawerState(false)
    setModalShow(true)
  }

  const navigateToSubscription = (event) => {
    setDrawerState(false)
    event.stopPropagation()
    history.push(ROUTE_STRINGS.subscription)
  }

  const navigateToCreateAccount = (event) => {
    setDrawerState(false)
    event.stopPropagation()
    history.push({ pathname: ROUTE_STRINGS.createAccount })
  }

  const navigateToLoginAccount = (event) => {
    setDrawerState(false)
    history.push({ pathname: ROUTE_STRINGS.login })
  }

  const checkForMenu = userDetails?.menus?.findIndex((ele) => ele.menuUrl === currentUrl)

  if (userDetails?.menus !== null) {
    displayUrl = userDetails?.menus[checkForMenu]?.menuName
  }

  function generateLinearGradientColor() {
    const defaultColor = '#50b3f6'
    const color = userDetails?.sub?.subscriptionColor || defaultColor

    const colorPart1 = color.slice(0, 7)
    const colorPart2 = color.slice(9, 16)
    const colorPart3 = color.slice(18, 27)

    return `linear-gradient(100deg, ${colorPart1}, ${colorPart2}, ${colorPart3})`
  }

  const handleClose = () => {
    const userLanguage = coffeewebGetLocal(coffeewebStorageKeys?.appLanguage)

    i18n.changeLanguage(userLanguage)
    setModalShow(false)
  }

  const handleLogin = () => {
    if (userLoginStatus === false) {
      history.push(ROUTE_STRINGS.login)
    } else {
      history.push(ROUTE_STRINGS.createAccount)
    }
  }

  const redirectToHome = () => {
    if (isLoggedIn) {
      history.push(ROUTE_STRINGS.coffeeNewsFeeds)
    } else {
      history.push(ROUTE_STRINGS.guestNewsFeed)
    }
  }

  const checkForMenuLock = ({ menulock }) => {
    setDrawerState(false)
    if (menulock) {
      setTimeout(() => {
        history.push({ pathname: `${ROUTE_STRINGS.subscription}`, state: { subscriptionId: 2 } })
      }, 0)
    }
  }

  return (
    <>
      <div className="pre-header" id="header">
        <div className="newNavBar">
          {/* Large Screen */}
          <div className="forLargeScreen" onClick={redirectToHome}>
            <img className="coffeeWebLogo" alt="coffee-web-logo" src={process.env.REACT_APP_NODE_ENV === 'production' ? NewMainLogoPng : devEnvLogo} />
          </div>

          {/* Mobile Screen */}
          {!isWebView && (
            <div className="forMobileScreen">
              {isLoggedIn ? <MenuIcon className="mobileScreenMenuIcon" onClick={() => setDrawerState(true)} /> : <img alt="coffee-web-logo" onClick={redirectToHome} src={process.env.REACT_APP_NODE_ENV === 'production' ? NewMainLogoPng : devEnvLogo} className="coffeeWebLogo" />}

              <Drawer open={drawerState} anchor={isLoggedIn ? 'left' : 'right'} onClose={() => setDrawerState(false)} className="mobileScreenDrawer">
                <div className="mobileScreenLogoWrapper">
                  <img alt="" src={process.env.REACT_APP_NODE_ENV === 'production' ? NewMainLogoPng : devEnvLogo} />
                  <div className="close-icon">
                    <CloseOutlinedIcon onClick={() => setDrawerState(false)} />
                  </div>
                </div>

                <div className="sideBar">
                  <div className="sideBarCardWrapper">
                    <div className="sideBarCard" style={{ backgroundImage: `linear-gradient(100deg, ${userDetails?.sub?.subscriptionColor ? userDetails?.sub?.subscriptionColor.slice(0, 7) : '#ae5e49'}, ${userDetails?.sub?.subscriptionColor ? userDetails?.sub?.subscriptionColor.slice(9, 16) : '#ae5e49'}, ${userDetails?.sub?.subscriptionColor ? userDetails?.sub?.subscriptionColor.slice(18, 27) : '#ae5e49'})`, animation: 'gradient 7s ease infinite' }}>
                      <div>
                        {/* User Country Image Div */}
                        <div>{userDetails?.countryFlag ? <img alt="country-flag" src={userDetails?.countryFlag} /> : <img alt="flag-india" src="https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/IND.svg"></img>}</div>

                        {/* User Details */}
                        <div>
                          {/* Name and Edit Button */}
                          <div>
                            <div className="userFirstLastName" onClick={() => openProfileSetting()}>
                              {userDetails?.firstName ? `${userDetails.firstName} ${userDetails.lastName}` : 'Guest User'}
                            </div>
                            <div className="EditButtonDiv">
                              <EditIcon className="edit-icon" style={{ width: '18px', cursor: 'pointer' }} onClick={userDetails ? () => openProfileSetting() : () => handleLogin()} />
                            </div>
                          </div>
                          {/* Users Occupation,Phone Number, Email */}
                          <div>
                            {userDetails?.occupationName !== 'NA' && <div onClick={() => openProfileSetting()}>{userDetails?.occupationName}</div>}
                            {isLoggedIn ? (
                              <div onClick={() => openProfileSetting()}>
                                +{userDetails?.countryPhoneCode} {userDetails?.phone}
                              </div>
                            ) : (
                              <div>{currentCity}</div>
                            )}
                            {userDetails?.email && <div onClick={() => openProfileSetting()}>{userDetails?.email}</div>}
                          </div>
                        </div>
                      </div>

                      {isLoggedIn ? (
                        <div>
                          <div>
                            {userDetails?.sub?.endDate && userDetails?.sub?.subType !== 1 && <div> {userDetails?.sub?.subscriptionValidTill}</div>}
                            {userDetails?.sub?.endDate && userDetails?.sub?.subType !== 1 && <div> {userDetails?.sub?.subscriptionValidTillDate}</div>}
                          </div>
                          <div>{userDetails?.sub?.subTypeName}</div>
                        </div>
                      ) : (
                        <div className="date-time-wrapper">
                          <div className="current-date">{currentDate}</div>
                          <div className="current-time">{currentTime}</div>
                        </div>
                      )}

                      {appSettings?.showrenewal && userDetails?.sub?.renewProcess ? (
                        <div onClick={() => setDrawerState(false)}>
                          {appSettings?.showrenewal && (
                            <button className="upgradeButton renew-button" onClick={navigateToSubscription} style={{ border: `2px solid ${userDetails?.sub?.subscriptionColor && userDetails?.sub?.subscriptionColor.slice(0, 7)}`, color: `${userDetails?.sub?.subscriptionColor && userDetails?.sub?.subscriptionColor.slice(0, 7)}`, padding: '1px 15px' }}>
                              {userDetails?.sub?.renewProcess}
                            </button>
                          )}
                        </div>
                      ) : (
                        <div>
                          <button className="upgradeButton guest-user-text-border" onClick={navigateToCreateAccount}>
                            <div className="renew-text">{t('CREATE_ACCOUNT_BUTTON_LABEL')}</div>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sidebar-menus-wrapper">
                    <div className="sideMenu-container">
                      {menuDetails &&
                        menuDetails.map((ele, index) => (
                          <React.Fragment key={index}>
                            {ele?.isWebDisplayEnable === true && ele?.isLaunch === true && ele?.menuUrl !== null && ele?.isPwadisplayEnable && (
                              <div key={index} className="menu-icons-container" onClick={() => checkForMenuLock(ele)}>
                                <NavLink exact to={ele?.menuUrl} className="nav-link-container">
                                  <div className="menu-icons">{ele?.menuIcon !== 'NA' && <img alt="" src={ele?.menuIcon} className="menu-image" />}</div>
                                  <div className="menu-name">{ele?.menuName}</div>
                                </NavLink>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      {!isLoggedIn &&
                        freeMenus &&
                        freeMenus.map((ele, index) => (
                          <React.Fragment key={index}>
                            {ele?.isWebDisplayEnable === true && ele?.isLaunch === true && ele?.menuUrl !== null && ele?.isPwadisplayEnable && (
                              <div key={index} className="menu-icons-container" onClick={() => setDrawerState(false)}>
                                <NavLink exact to={ele.menuUrl === 'coffeenewsfeeds' ? ROUTE_STRINGS.guestNewsFeed : ele.menuUrl} className="nav-link-container">
                                  <div className="menu-icons">{ele?.menuIcon !== 'NA' && <img alt="" src={ele?.menuIcon} className="menu-image" />}</div>
                                  <div className="menu-name">{ele?.menuName}</div>
                                </NavLink>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                  {!isLoggedIn && (
                    <div className="create-account-text">
                      To enjoy more features{' '}
                      <span className="create-account-label" onClick={navigateToCreateAccount}>
                        {' '}
                        {t('CREATE_ACCOUNT_BUTTON_LABEL')}
                      </span>
                    </div>
                  )}

                  {
                    <div className="sidebar-logout-setting-wrapper">
                      <div className="sidebar-action-logout">
                        {isLoggedIn ? (
                          <div className="logout-button" onClick={() => logoutFunctionForSmallDevice()}>
                            <PowerSettingsNewIcon className="sidebar-action-icon" />
                            <div className="logout">{t('LOGOUT')}</div>
                          </div>
                        ) : (
                          <div className="logout-button guest-user-text-border" onClick={() => navigateToLoginAccount()}>
                            <LoginIcon className="sidebar-action-icon" />
                            <div className="logout">{t('LOGIN_BUTTON')}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  }

                  <div className="buy-subscription-container"></div>
                </div>

                {/* TODO : FOR THE FUTURE RELEASE */}
                {/* <div className="sidebar-version-number">{Package.version}</div> */}
                {/* --------Footer In Mobile Screen-------- */}
                <div className="sidebar-footer">
                  <div className="sidebar-footer-copyright" style={{ textAlign: 'center' }}>
                    {t('COPYRIGHT')} <b>{t('COFFEE_WEB')}</b>
                  </div>
                  <div className="sidebar-footer-terms" style={{ display: 'flex', justifyContent: 'center' }}>
                    {t('ALL_RIGHTS_RESERVED')}
                    <a href={STATIC_ROUTE_STRINGS.termsAndCondition} target="_blank" rel="noreferrer">
                      {t('TERMS_CONDITIONS')}
                    </a>
                    <span className="">{t('AND')}</span>
                    <a href={STATIC_ROUTE_STRINGS.privacyPolicy} target="_blank" rel="noreferrer">
                      {t('PRIVACY_POLICY')}
                    </a>
                  </div>
                </div>
              </Drawer>
            </div>
          )}

          <div className="forMobileScreen mobile-screen-display-page">{displayUrl}</div>

          <div className="subActionsContainer">
            {isLoggedIn ? (
              <div className="subActionsDiv">
                <div className="forLargeScreen">
                  {userDetails?.userType !== 0 && (
                    <div className="avatarContainer">
                      <div onClick={() => setModalShow(true)} className="avatar-logo">
                        {userDetails?.firstName?.substr(0, 1).toUpperCase()}
                      </div>
                      <div
                        className="avatarCard"
                        onClick={() => setModalShow(true)}
                        style={{
                          '--custom-background': generateLinearGradientColor()
                        }}
                      >
                        <div className="avatarDiv">
                          <div>
                            <img alt="" className="avatarUserCountryImg" src={userDetails?.countryFlag} />
                          </div>
                          <div className="avatar-user-personal-details">
                            <div className="username-edit-button">
                              <div>
                                {userDetails?.firstName} {userDetails?.lastName}
                              </div>
                              <div>
                                <EditIcon className="edit-icon" />
                              </div>
                            </div>
                            {userDetails?.occupationName !== 'NA' && <div className="user-occupation">{userDetails?.occupationName}</div>}
                            <div>
                              {t('PHONE')} : + {userDetails?.countryPhoneCode} {userDetails?.phone}
                            </div>
                            {userDetails?.email && (
                              <div>
                                {t('EMAIL')} : {userDetails?.email}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="userSubscriptionDetails">
                          <div className="subscriptionDate">
                            {userDetails?.sub?.endDate && userDetails?.sub?.subType !== 1 && <div> {userDetails?.sub?.subscriptionValidTill}</div>}
                            {userDetails?.sub?.endDate && userDetails?.sub?.subType !== 1 && <div> {userDetails?.sub?.subscriptionValidTillDate}</div>}
                          </div>
                          <div className="subscriptionType">{userDetails?.sub?.subTypeName}</div>
                        </div>
                        {appSettings?.showrenewal && userDetails?.sub?.renewProcess && (
                          <div className="upgrade-button-large-screen-container">
                            {appSettings?.showrenewal && (
                              <button className="upgradeButtonLargeScreen" onClick={navigateToSubscription}>
                                {userDetails?.sub?.renewProcess}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {userDetails?.userType !== 0 && (
                  <div className="">
                    <NotificationListenService />
                  </div>
                )}

                <div className="forLargeScreen hoverOnLogout">
                  <PowerSettingsNewIcon className="logout-icon" onClick={() => setShowLogoutModal(true)} />
                  <div className="tooltipLogout" onClick={() => setShowLogoutModal(true)}>
                    <div>{t('LOGOUT')}</div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {currentUrl !== 'login' && currentUrl !== 'create_account' && (
                  <div>
                    <div className="auth-screen-button forLargeScreen" onClick={handleLogin}>
                      {t('LOGIN_AND_CREATE_ACCOUNT')}
                    </div>
                    <div className="forMobileScreen">
                      <MenuIcon className="mobileScreenMenuIcon" onClick={() => setDrawerState(true)} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isLoggedIn && (
        <div>
          <LogoutModal show={showLogoutModal} showLogoutPopup={showLogoutPopup} onHide={() => setShowLogoutModal(false)} />
          {modalShow && <ProfileModal show={modalShow} setModalShow={setModalShow} onHide={handleClose} />}
        </div>
      )}
    </>
  )
}

export default Loader(Header)
