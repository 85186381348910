/* eslint-disable */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Navbar } from 'react-bootstrap'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { coffeewebGetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'

const FreeMenubar = () => {
  const freeMenus = coffeewebGetLocal(coffeewebStorageKeys.freeMenus)

  return (
    <>
      <div className="post_header">
        <Navbar className="navbar-top navbar-dark" id="navbar-main">
          <Container fluid>
            <Navbar aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <ul className="navbar-nav ">
                {freeMenus?.length &&
                  freeMenus?.map((ele, index) => (
                    <div key={index}>
                      {ele.isWebDisplayEnable === true && ele.isLaunch === true && ele.menuUrl !== null && (
                        <li className="nav-item1 menucolor1" id="navitem" style={{ margin: '0px 1px' }}>
                          <NavLink activeClassName="active_nav-link1" exact to={ele.menuUrl === 'coffeenewsfeeds' ? ROUTE_STRINGS.guestNewsFeed : ele.menuUrl} className="nav-link1 nav-link-ltr">
                            <div style={{ padding: '0px 5px' }}>{ele.menuName}</div>
                          </NavLink>
                        </li>
                      )}
                    </div>
                  ))}
              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  )
}

export default FreeMenubar
