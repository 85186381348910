/*eslint-disable*/
import axios from 'axios'
import { coffeewebGetLocal, coffeewebStorageKeys } from '../Utils/LocalStorage_Handler'

function getConfig(generatedToken) {
  const token = generatedToken || coffeewebGetLocal(coffeewebStorageKeys.authToken)

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      'accept-language': 'en_US',
      'content-type': 'application/json'
    }
  }
}

function handleResponse(response, callback) {
  if (callback) {
    callback(null, response.data)
  }

  return response
}

function handleResponseError(error, callback) {
  if (callback) {
    callback(error.response.data, null)
  }

  return Promise.reject(error)
}

const coffeeWebApiService = {
  get: async (url, token, callback) =>
    await axios
      .get(url, getConfig(token))
      .then((response) => handleResponse(response, callback))
      .catch((error) => handleResponseError(error, callback)),

  post: async (url, token, body, callback) =>
    await axios
      .post(url, body, getConfig(token))
      .then((response) => handleResponse(response, callback))
      .catch((error) => handleResponseError(error, callback)),

  put: async (url, token, body, callback) =>
    await axios
      .put(url, body, getConfig(token))
      .then((response) => handleResponse(response, callback))
      .catch((error) => handleResponseError(error, callback)),

  delete: async (url, token, callback) =>
    await axios
      .delete(url, getConfig(token))
      .then((response) => handleResponse(response, callback))
      .catch((error) => handleResponseError(error, callback))
}

export default coffeeWebApiService
