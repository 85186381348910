import './style.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { Logo, devEnvLogo } from 'Assets/Icons'
import { ReactComponent as TimeChevronUp } from 'Assets/Icons/Time_chevron_up.svg'
import { ReactComponent as TimeChevronDown } from 'Assets/Icons/Time_chevron_down.svg'
import { coffeewebStorageKeys, coffeewebGetLocal, updateUserDetails } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ApiResponseHandler from 'Components/ApiResponseHandler'

const TimerPicker = ({ handleApiResponse }) => {
  const { t } = useTranslation()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)

  const [time, setTime] = useState('')
  const [formattedTime, setFormattedTime] = useState('')
  const [isTwelveHourFormat, setTwelveHourFormat] = useState(true)
  const [isDialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    getTime()
  }, [userDetails?.userTimeFormat])

  const getTime = () => {
    setInterval(() => {
      const date = new Date()

      let x = 'AM'

      if (date.getHours() >= 12) {
        x = 'PM'
      }
      setTime(`${String(date.getHours() % 12 === 0 ? 12 : date.getHours() % 12).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')} ${x}`)
      setFormattedTime(`${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`)
    }, 1000)
    if (userDetails?.userTimeFormat === '12 Hr') {
      setTwelveHourFormat(true)
    } else {
      setTwelveHourFormat(false)
    }
  }

  const updateTimeFormat = async () => {
    const userId = userDetails?.id
    const newTimeFormat = isTwelveHourFormat ? '24 Hr' : '12 Hr'

    try {
      const response = await apiAdapterCoffeeWeb.updateTimeFormat({ userId, TimeFormat: newTimeFormat })

      updateUserDetails()

      setTwelveHourFormat(!isTwelveHourFormat)
      setDialogOpen(false)
      setFormattedTime(response.data)
    } catch (error) {
      setDialogOpen(false)
      handleApiResponse({ status: error?.response?.status, message: 'UNABLE_TO_CHANGE_TIME_FORMAT', onRetry: updateTimeFormat })
    }
  }

  const handleTimeFormatClick = () => {
    setDialogOpen(true)
  }

  const handleCancelDialog = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <div className="updated-time-pick" onClick={handleTimeFormatClick}>
        <div className="updated-time-pick-div">
          <div> {isTwelveHourFormat ? time : formattedTime}</div>
          <div className="updated-time-pick-arrows">
            <TimeChevronUp />
            <TimeChevronDown />
          </div>
        </div>
      </div>
      <Modal show={isDialogOpen} onHide={handleCancelDialog} backdrop="static" keyboard={false} className="timeFormatModal">
        <Modal.Header className="timeFormatModalHeader">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>
        <Modal.Body className="timeFormatModalBody modal-body ">
          <div className="timeFormatModalContent">
            <h4 className="timeFormatText">{t('DO_YOU_WANT_TO_CHANGE_THE_TIME_FORMAT_PERMANENTLY')}</h4>
            <div className="timeFormatModalButtons">
              <button className="timeFormatModalButtonNo" onClick={handleCancelDialog}>
                {t('NO')}
              </button>
              <button className="timeFormatModalButtonYes" onClick={updateTimeFormat}>
                {t('YES')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ApiResponseHandler(TimerPicker)
