import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { ROUTE_STRINGS } from './Constants'

export const coffeewebSetLocal = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}
export const coffeewebGetLocal = (key) => {
  const value = localStorage.getItem(key)

  return JSON.parse(value)
}
export const coffeewebClearLocal = () => {
  localStorage.clear()
}

export const updateUserDetails = async () => {
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)

  try {
    const response = await apiAdapterCoffeeWeb.getUserByUserId(userDetails?.id)

    coffeewebSetLocal(coffeewebStorageKeys.userDetails, response.data)

    return response
  } catch (err) {
    return err.response
  }
}

export const redirectToHome = (history) => {
  history.push({
    pathname: ROUTE_STRINGS.login
  })
}

export const coffeewebStorageKeys = {
  authToken: 'authToken',
  userDetails: 'userDetails',
  appSettings: 'appSettings',
  coffeeQuotesSubFeatures: 'coffeeQuotesSubFeatures',
  payment: 'payment',
  coffeeNewsScreen: 'coffeeNewsScreen',
  checkForUserLoggedIn: 'checkForUserLoggedIn',
  backToNotificationScreen: 'backToNotificationScreen',
  newsMediaForFirebaseEvent: 'newsMediaForFirebaseEvent',
  sharednewsid: 'sharedNewsId',
  notificationSouceId: 'notificationSourceId',
  previewpdf: 'previewPdf',
  singleSignOn: 'singleSignOn',
  languageId: 'languageId',
  languageIdForNews: 'languageIdForNews',
  isLoggedIn: 'isLoggedIn',
  paypalDetails: 'paypalDetails',
  latestNewsReadNotification: 'latestNewsReadNotification',
  appLanguage: 'appLanguage',
  singleDeviceId: 'singleDeviceId',
  freeMenus: 'freeMenus',
  freeNewsLanguageId: 'freeNewsLanguageId',
  newsForSubscription: 'newsForSubscription',
  subscriptionNewsId: 'subscriptionNewsId'
}
