import React, { useState, useEffect } from 'react'
import './styles.scss'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'
import Loader from 'Components/LayoutComponents/Loader'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { coffeewebStorageKeys, coffeewebGetLocal } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import { Message } from 'primereact/message'
import razorpay from './PaymentServices/razorpay'
import paypal from './PaymentServices/paypal'
import stripe from './PaymentServices/stripe'

const PaymentGateway = ({ setLoading, handleApiResponse }) => {
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const location = useLocation()
  const { t } = useTranslation()

  const params = new URLSearchParams(location.search)
  const selectedSubscriptionId = params.get('id')
  const { state: selectedSubscription } = location

  const [subPaymentMapping, setSubPaymentMapping] = useState([])
  const [selectedSubscriptionDetails, setSelectedSubscriptionDetails] = useState({})
  const [stateList, setStateList] = useState([])
  const [userSelectedState, setUserSelectedState] = useState(userDetails.idState)
  const [billingInformation, setBillingInformation] = useState()
  const [selectedState, setSelectedState] = useState(0)
  const [accordingSymbol, setAccordingSymbol] = useState(true)
  const [gstInfoMessage, setGstInfoMessage] = useState(false)
  const [gstInput, setGstInput] = useState({
    gstNumber: '',
    organistationName: '',
    organistationAddress: '',
    selectedState: '',
    email: userDetails?.email,
    userName: `${userDetails.firstName} ${userDetails.lastName}`
  })

  const subscriptionColorConst = [
    {
      id: '0',
      color: '#cbcccd, #e5e8eb, #cbcccd'
    },
    {
      id: '1',
      color: '#50b3f6, #8DD5F4, #50b3f6'
    },
    {
      id: '2',
      color: '#a48011, #e7cd77, #a48011'
    },
    {
      id: '3',
      color: '#8A8A8A, #D4D4D4, #8A8A8A'
    }
  ]

  useEffect(() => {
    GetSubscriptionTypes()
    getGstDetails()
    getStateList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const GetSubscriptionTypes = async () => {
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.getAllSubscriptionPaymentMappingById({
        id: selectedSubscriptionId,
        userId: userDetails.id
      })

      if (response?.data?.returnLst) {
        setSubPaymentMapping(response.data?.returnLst)
        setSelectedSubscriptionDetails(response.data?.returnLst[0])
      } else {
        handleApiResponse({ status: response?.status, onRetry: GetSubscriptionTypes, headerMessage: t('WE_ARE_UNABLE_TO_PROCEED_WITH_THE_SELECTED_PLAN'), customMessage: t('PLEASE_TRY_AGAIN_OR_CHOOSE_ANOTHER_ONE') })
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: GetSubscriptionTypes })
    } finally {
      setLoading(false)
    }
  }

  const getStateList = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getStateList(userDetails.countryId)

      setStateList(response.data)
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, onRetry: getStateList })
    }
  }

  const selectPaymentBtn = (ele) => {
    const razorPaymentInstance = razorpay({ gstInput, setLoading, handleApiResponse })
    const paypalPaymentInstances = paypal({ setLoading, handleApiResponse })
    const stripeInstances = stripe({ setLoading, handleApiResponse })

    if (ele.paymentName === 'PayPal') {
      paypalPaymentInstances.paypalCreateOrder(ele)
    }

    if (ele.paymentName === 'Stripe') {
      stripeInstances.stripeCreateOrder(ele)
    }
    if (ele?.paymentName === 'RazorPay') {
      razorPaymentInstance.razorpayCreateOrder(ele, billingInformation)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    setGstInput((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const postGstDetails = async () => {
    try {
      const { userName, organistationName, organistationAddress, gstNumber, email } = gstInput
      const { id: userId, firstName } = userDetails
      const getOrDefault = (value, defaultValue) => value || defaultValue

      const gstData = {
        idUserGstdetails: 0,
        userId,
        userName: getOrDefault(userName, firstName),
        organistationName,
        organistationAddress,
        stateId: selectedState,
        gstno: getOrDefault(gstNumber, 'NA'),
        entryDate: new Date().toISOString(),
        lastUsed: true,
        lastUsedDtm: new Date().toISOString(),
        email: getOrDefault(email, userDetails.email)
      }

      await apiAdapterCoffeeWeb.postUserGSTDetail(gstData)

      swal({
        title: t('SUCCESS'),
        text: t('UPDATED_SUCCESSFULLY'),
        icon: 'success'
      })

      getGstDetails()
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('UPDATE_FAILED'), onRetry: postGstDetails })
    }
  }

  const getGstDetails = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getUserGSTDetailByList(userDetails.id)

      const data = response?.data?.returnLst

      if (data) {
        setAccordingSymbol(true)

        const userActiveData = response?.data?.returnLst?.filter((item) => item.lastUsed === true)

        if (userActiveData) {
          setBillingInformation(userActiveData[0])
        }

        setGstInput({
          ...gstInput,
          organistationAddress: userActiveData[0]?.organistationAddress,
          organistationName: userActiveData[0]?.organistationName,
          gstNumber: userActiveData[0]?.gstno,
          userName: userActiveData[0]?.userName === '' ? userDetails?.firstName : userActiveData[0]?.userName,
          email: userActiveData[0]?.email === '' ? userDetails?.email : userActiveData[0]?.email
        })

        setSelectedState(userActiveData[0]?.stateId)
        setUserSelectedState(userActiveData[0]?.stateId)
      } else {
        setGstInfoMessage(true)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('FAILED_TO_GET_GST_DETAILS'), onRetry: () => getGstDetails() })
    }
  }
  const selectState = (event) => {
    setSelectedState(event.target.value)
    setUserSelectedState(event.target.value)
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 2

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }
  const content = (
    <div className="gst-message">
      <div className="ml-2">{t('FAILED_TO_GET_GST_DETAILS')}</div>
    </div>
  )

  const selectedColor = subscriptionColorConst.filter(({ id }) => id === selectedSubscriptionId).map(({ color }) => color)
  const neutralColor = selectedColor.length ? selectedColor.toString().slice(0, 7) : subscriptionColorConst[0].color

  const subscriptionColor = selectedSubscription ? selectedSubscription.subscriptionColor.slice(0, 7) : neutralColor.slice(0, 7)

  return (
    <>
      {subPaymentMapping.length > 0 && (
        <div className="payment-container">
          <div className="container payment-method">
            <div
              className="card v-100 shadow card1 payment-card"
              style={{
                boxShadow: `0px 0px 10px ${subscriptionColor}`,
                border: `1px solid ${subscriptionColor}`
              }}
            >
              <div className="order-container" style={{ backgroundColor: ` ${subscriptionColor}` }}>
                <h4 className="order-summary">{t('ORDER_SUMMARY')}</h4>
              </div>
              <div className="subscription-conatiner">
                <div className="subscription-name">{selectedSubscriptionDetails?.name}</div>
                <div className="subscription-country">{userDetails.countryId === 102 ? `${selectedSubscriptionDetails?.currencyType}` : '$'}</div>
                <div className="subscription-baseprice">{userDetails.countryId === 102 ? `${selectedSubscriptionDetails?.basePrice}` : `${selectedSubscriptionDetails?.amountInDollars}.00`}</div>
              </div>
              {userDetails.countryId === 102 && (
                <>
                  {userSelectedState === 17 ? (
                    <>
                      {selectedSubscriptionDetails?.discountedamount > 0 && (
                        <>
                          <div className="subscription-conatiner">
                            <div className="discount-amount">{t('DISCOUNTED_AMOUNT')}</div>
                            <div>{selectedSubscriptionDetails?.currencyType}</div>
                            <div className="discount-currency">{selectedSubscriptionDetails?.discountedamount}</div>
                          </div>
                          <div className="subscription-conatiner">
                            <div className="actual-amount">
                              {t('ACTUAL_AMOUNT')} {selectedSubscriptionDetails?.name}
                            </div>
                            <div className="actual-amount">{selectedSubscriptionDetails?.currencyType}</div>
                            <div className="actual-currency">{selectedSubscriptionDetails?.actualAmount}</div>
                          </div>
                        </>
                      )}
                      <div className="subscription-conatiner">
                        <div className="cgst">
                          {t('CGST_AND_FEES')}({selectedSubscriptionDetails?.cgst}%)
                        </div>
                        <div className="cgst-currencytype">{selectedSubscriptionDetails?.currencyType}</div>
                        <div className="cgst-amount">{selectedSubscriptionDetails?.cgstAmount}</div>
                      </div>
                      <div className="subscription-conatiner">
                        <div className="sgst">
                          {t('SGST_AND_FEES')}({selectedSubscriptionDetails?.sgst}%)
                        </div>
                        <div className="sgst-currency">{selectedSubscriptionDetails?.currencyType}</div>
                        <div className="sgst-amount">{selectedSubscriptionDetails?.sgstAmount}</div>
                      </div>
                      {selectedSubscriptionDetails?.roundoftonearby > 0 && (
                        <div className="subscription-conatiner">
                          <div className="round-to-nearby">Round of to nearby</div>
                          <div className="round-to-nearby-currency">{selectedSubscriptionDetails?.currencyType}</div>
                          <div className="round-to-nearby-details">{selectedSubscriptionDetails?.roundoftonearby}</div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="subscription-conatiner">
                        <div className="igst">
                          {t('IGST_AND_FEES')}({selectedSubscriptionDetails?.igst}%)
                        </div>
                        <div className="igst-currencytype">{selectedSubscriptionDetails?.currencyType}</div>
                        <div className="igst-amount">{selectedSubscriptionDetails?.igstAmount}</div>
                      </div>
                      {selectedSubscriptionDetails?.roundoftonearby > 0 && (
                        <div className="subscription-conatiner">
                          <div className="round-to-nearby">{t('ROUND_OF_TO_NEARBY')}</div>
                          <div className="round-to-nearby-currency">{selectedSubscriptionDetails?.currencyType}</div>
                          <div className="round-to-nearby-details">{selectedSubscriptionDetails?.roundoftonearby}</div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              <hr></hr>
              {userDetails.countryId === 102 && (
                <>
                  {userSelectedState === 17 ? (
                    <div className="subscription-conatiner" style={{ margin: '0px 0px 10px' }}>
                      <div className="total">{t('TOTAL')}</div>
                      <div className="total-currency">{userDetails.countryId === 102 ? `${selectedSubscriptionDetails?.currencyType}` : '$'}</div>
                      <div className="cgst-tax">{userDetails.countryId === 102 ? `${selectedSubscriptionDetails?.csgstTaxableAmount}` : `${selectedSubscriptionDetails?.amountInDollars}.00`}</div>
                    </div>
                  ) : (
                    <div className="subscription-conatiner" style={{ margin: '0px 0px 10px' }}>
                      <div className="total">{t('TOTAL')}</div>
                      <div className="total-currency">{userDetails.countryId === 102 ? `${selectedSubscriptionDetails?.currencyType}` : '$'}</div>
                      <div className="cgst-tax">{userDetails.countryId === 102 ? `${selectedSubscriptionDetails?.igstTaxableAmount}` : `${selectedSubscriptionDetails?.amountInDollars}.00`}</div>
                    </div>
                  )}
                </>
              )}
              {userDetails.countryId !== 102 && (
                <>
                  <div className="subscription-conatiner" style={{ margin: '0px 0px 10px' }}>
                    <div className="total">{t('TOTAL')}</div>
                    <div className="total-currencytype">{userDetails.countryId === 102 ? `${selectedSubscriptionDetails?.currencyType}` : '$'}</div>
                    <div className="igst-amount">{userDetails.countryId === 102 ? `${selectedSubscriptionDetails?.igstTaxableAmount}` : `${selectedSubscriptionDetails?.amountInDollars}.00`}</div>
                  </div>
                </>
              )}
            </div>

            <div
              className="payment-card"
              style={{
                border: `1px solid ${subscriptionColor}`
              }}
            >
              <div
                className="payment-method-container"
                style={{
                  backgroundColor: ` ${subscriptionColor}`
                }}
              >
                <h4 className="payment-method-heading">{t('PAYMENT_METHOD')}</h4>
              </div>
              <div className="no-payment">
                <>
                  {subPaymentMapping.length === 0 ? (
                    <h4 className="no-payment-available">{t('NO_PAYMENT_METHOD_AVAILABLE')}</h4>
                  ) : (
                    subPaymentMapping.map((ele, index) => (
                      <div key={index} className="paypal-payment-btn" onClick={() => selectPaymentBtn(ele)}>
                        <img alt="" src={ele.imageUrl} />
                      </div>
                    ))
                  )}
                </>
              </div>
            </div>
          </div>

          <div className="container payment-method-gst">
            <div className="payment-card-gst">
              <div className="billingInfo-card-gst " style={{ boxShadow: `0px 0px 10px ${subscriptionColor}`, border: `1px solid ${subscriptionColor}` }}>
                <div
                  className="gst-header-card"
                  style={{
                    backgroundColor: ` ${subscriptionColor}`
                  }}
                >
                  <div className="billing-text">{t('BILLING_INFORMATION')}</div>
                  <div className="accordian-gst">{accordingSymbol === true ? <ModeEditIcon onClick={() => setAccordingSymbol(false)} /> : <DriveFileRenameOutlineIcon onClick={() => setAccordingSymbol(true)} />}</div>
                </div>

                {accordingSymbol && (
                  <div className="card shadow m-1" style={{ padding: '15px' }}>
                    {gstInput.userName}
                  </div>
                )}
                {gstInfoMessage && (
                  <div>
                    <Message className="message-conatiner" severity="info" content={content} />
                  </div>
                )}

                {!accordingSymbol && (
                  <div className="text-field">
                    <TextField id="outlined-basic" sx={{ mt: 1 }} label="Name" name="userName" variant="outlined" fullWidth autoComplete="off" value={gstInput.userName} onChange={handleChange} />

                    <TextField id="outlined-basic" sx={{ mt: 1 }} label="Organisation Name" name="organistationName" variant="outlined" fullWidth autoComplete="off" onChange={handleChange} value={gstInput?.organistationName} />
                    <TextField id="outlined-basic" sx={{ mt: 1 }} label="Billing Address" name="organistationAddress" variant="outlined" fullWidth autoComplete="off" onChange={handleChange} value={gstInput?.organistationAddress} />

                    {userDetails.countryId === 102 && (
                      <>
                        {' '}
                        <FormControl fullWidth sx={{ mt: 1 }}>
                          <InputLabel id="demo-simple-select-label">
                            <span style={{ color: 'black' }}>State </span>
                          </InputLabel>
                          <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedState} label="State" onChange={selectState} MenuProps={MenuProps}>
                            {stateList.map((ele, index) => (
                              <MenuItem key={index} value={ele.idState}>
                                {ele.stateName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField id="outlined-basic" sx={{ mt: 1 }} label="GST No" name="gstNumber" variant="outlined" fullWidth autoComplete="off" onChange={handleChange} value={gstInput?.gstNumber} />
                      </>
                    )}
                    <TextField id="outlined-basic" sx={{ mt: 1 }} label="Email" name="email" variant="outlined" fullWidth autoComplete="off" onChange={handleChange} value={gstInput.email} />
                    <span style={{ margin: '0px 10px' }}>{t('NOTE_INVOICE')}</span>

                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, background: '#50b3f6' }} onClick={postGstDetails}>
                      {t('SAVE_DETAILS')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Loader(ApiResponseHandler(PaymentGateway))
