import { Redirect, Route } from 'react-router'
import { useHistory } from 'react-router-dom'
import { RouteStrings } from 'Utils/Routes/RouteStrings'
import { coffeewebGetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'
import { ROUTE_STRINGS } from 'Utils/Constants'

const ProtectedRoute = (props) => {
  const { component: Component, ...rest } = props

  const history = useHistory()

  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const accessToken = coffeewebGetLocal(coffeewebStorageKeys.authToken)

  const currentMenuUrl = props.location.pathname.split('/').pop()

  const currentMenuDetails = userDetails?.menus?.find((ele) => {
    if (ele.menuUrl === currentMenuUrl) {
      return ele
    }
  })

  const excludedUrls = ['notification', 'opennewsnotification', 'opensharednews', 'previewpdf', 'subscription', 'paymentgateway', 'success', 'cancel', 'support', 'coffeeprices', 'globalfreightrates', 'globalrawcoffeeprices', 'coffeequint', 'paymenthistory', 'traderequest', 'mynotification', 'weather', 'coffeeweb']

  if (!excludedUrls.includes(currentMenuUrl)) {
    if (currentMenuDetails?.isWebDisplayEnable && currentMenuDetails?.isLaunch && currentMenuDetails?.menuUrl) {
      if (currentMenuDetails?.menulock) {
        if (currentMenuUrl !== 'subscription') {
          history.push(ROUTE_STRINGS.subscription)
        }
      }
    } else if (currentMenuUrl !== 'pnf') {
      history.push(ROUTE_STRINGS.pnf)
    }
  }

  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          if (accessToken && userDetails !== null) {
            return <Component {...props} />
          }

          return <Redirect to={RouteStrings.login} />
        }}
      />
    </>
  )
}

export default ProtectedRoute
