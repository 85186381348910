import { REG_EX } from './Constants'

const { PHONE_REG_EX, EMAIL_REG_EX, PASSWORD_REG_EX, NAME_REG_EX } = REG_EX
const Validation = {
  phoneNumberValidation(value) {
    return PHONE_REG_EX.test(value.trim())
  },
  emailValidation(value) {
    return EMAIL_REG_EX.test(value.trim())
  },
  passwordValidation(value) {
    return PASSWORD_REG_EX.test(value.trim())
  },
  nameValidation(value) {
    return NAME_REG_EX.test(value.trim())
  },
  checkEmptyField(...values) {
    return values.every((value) => value === '')
  },
  checkAllInputFieldsNotEmpty(...values) {
    return values.every((value) => value !== '')
  },
  checkEmailValidity(checkEmail) {
    return EMAIL_REG_EX.test(checkEmail)
  },
  checkEmptySomeInputField(...values) {
    return values.some((value) => value === '')
  },
  isInputFieldEmpty(value) {
    return value.trim() === ''
  }
}

export default Validation
