import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { IMAGE_SOURCE_URLS } from 'Utils/Constants'

const SubscriptionCard = ({ subscription, userDetails, selectPlan, isHighlight }) => {
  const { t } = useTranslation()
  const colorSlice = subscription.subscriptionColor.slice(0, 7)

  const subscriptionCard = {
    '--subscription-color': colorSlice
  }
  const gradientBackground = {
    backgroundSize: '400% 400%',
    backgroundImage: `linear-gradient(100deg, ${subscription?.subscriptionColor.slice(0, 7)}, ${subscription?.subscriptionColor.slice(9, 16)}, ${subscription?.subscriptionColor.slice(18, 27)})`
  }

  return (
    <div className="subscription-main-card" id={isHighlight && 'highlightedSubscriptionCard'}>
      <div className="subscription-card" style={subscriptionCard}>
        <div className="Subscription-card-header" style={gradientBackground}>
          <>
            {userDetails?.sub?.subType === subscription.id && (
              <div className="card-box">
                <span></span>
              </div>
            )}
          </>
          <div>
            {' '}
            <h3 className="subscription-name"> {subscription.name}</h3>
            {
              <div className=" subscription-basePrice">
                {subscription.basePrice === 0 ? (
                  <h5>{t('FREE')}</h5>
                ) : (
                  <>
                    {userDetails?.sub?.subType === subscription.id ? (
                      <div>
                        <div className="validity-day">
                          <div className="per-month ">
                            <>
                              {userDetails.countryId === 102 ? (
                                <>
                                  {subscription.currencyType} {subscription.basePrice}/- {t('PER_MONTH')}{' '}
                                </>
                              ) : (
                                <>
                                  $ {subscription.amountInDollars}/- {t('PER_MONTH')}
                                </>
                              )}
                            </>
                          </div>
                          <div className="validity-Detail">
                            <div>{userDetails.sub?.subscriptionValidTill}</div>
                            <div>{userDetails.sub?.subscriptionValidTillDate}</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <h5 className=" subscription-currency">
                        {subscription.basePrice !== 0 && (
                          <>
                            {userDetails.countryId === 102 ? (
                              <>
                                {subscription.currencyType} {subscription.basePrice}/- {t('PER_MONTH')}{' '}
                              </>
                            ) : (
                              <>
                                $ {subscription.amountInDollars}/- {t('PER_MONTH')}
                              </>
                            )}
                          </>
                        )}
                      </h5>
                    )}
                  </>
                )}
              </div>
            }
          </div>
        </div>
        <div className=" upgrade-main">
          {subscription.basePrice === 0 ? (
            <>
              <div className=" upgrade-container">
                <button className="you-can-upgrade">{t('YOU_CAN_UPGRADE')}</button>
              </div>
            </>
          ) : (
            <div className=" renew-container">
              {userDetails?.sub?.subType === subscription.id ? (
                <button className="renew-button" onClick={() => selectPlan(subscription)}>
                  {t('RENEW')}
                </button>
              ) : (
                <button className="buy-now-button" onClick={() => selectPlan(subscription)}>
                  {t('BUY_NOW')}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="subscription-card subscription-features" style={subscriptionCard}>
        <div className="features-list">
          <div className="features-container">
            {subscription.subscriptionFeatures.map((subItems, index) => (
              <div key={index} className="feature-item">
                <div className="subscription-line" style={gradientBackground}>
                  {subItems.isAvailable === true ? <img src={IMAGE_SOURCE_URLS.checkImage} alt="Check" className="subscription-icon check" /> : <img src={IMAGE_SOURCE_URLS.cancelImage} alt="Cancel" className="subscription-icon cancel" />}
                  <div className="feature-Description">{subItems.featureDescription}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCard
