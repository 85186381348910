const KA_LANGUAGES = {
  OK: 'ಸರಿ',
  NO: 'ಸಂ',
  YES: 'ಹೌದು',
  CONTINUE: 'ಮುಂದುವರಿಸಿ',
  CANCEL: 'ರದ್ದುಮಾಡಿ',
  ALREADY_LOGGED_IN: 'ನೀವು ಈಗಾಗಲೇ ಇನ್ನೊಂದು ಸಾಧನದಿಂದ ಲಾಗ್ ಇನ್ ಆಗಿರುವಿರಿ.',
  CONFIRM_LOGOUT: 'ನೀವು ಲಾಗ್ಔಟ್ ಮಾಡಲು ಬಯಸುವಿರಾ?',
  DONT_HAVE_AN_ACCOUNT: 'ಖಾತೆ ಇಲ್ಲವೇ?',
  CREATE_ACCOUNT_LABEL: 'ಖಾತೆ ತೆರೆ',
  SUPPORT_LABEL: 'ಬೆಂಬಲ?',
  FORGET_PASSWORD_LABEL: 'ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ?',
  LOGIN: 'ಲಾಗಿನ್ ಮಾಡಿ',
  SINGLE_DEVICE_CONTINUE_LOGIN: 'ನೀವು ಈಗಾಗಲೇ ಇನ್ನೊಂದು ಸಾಧನದಿಂದ ಲಾಗ್ ಇನ್ ಆಗಿರುವಿರಿ, ಈ ಸಾಧನದಿಂದ ಮುಂದುವರಿಯಲು ನೀವು ಬಯಸುವಿರಾ?',
  SINGLE_DEVICE_LOGOUT_NOTE: 'ಗಮನಿಸಿ: ನೀವು ಈ ಸಾಧನದಿಂದ ಮುಂದುವರಿದರೆ ನೀವು ಇತರ ಸಾಧನದಿಂದ ಲಾಗ್ ಔಟ್ ಆಗುತ್ತೀರಿ!',
  INVALID_CREDENTIALS: 'ಅಮಾನ್ಯ ರುಜುವಾತುಗಳು!',
  PHONE_IS_REQUIRED: 'ಫೋನ್ ಅಗತ್ಯವಿದೆ',
  PASSWORD_IS_REQUIRED: 'ಗುಪ್ತಪದದ ಅಗತ್ಯವಿದೆ',
  PHONE: 'ದೂರವಾಣಿ',
  PASSWORD: 'ಗುಪ್ತಪದ',
  ALREADY_HAVE_AN_ACCOUNT: 'ಈಗಾಗಲೇ ಖಾತೆ ಹೊಂದಿದ್ದೀರ?',
  LOGIN_HERE: 'ಇಲ್ಲಿ ಲಾಗಿನ್ ಮಾಡಿ',
  VERIFY_CREATE_ACCOUNT: 'ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಖಾತೆಯನ್ನು ರಚಿಸಿ',
  CLOSE: 'ಮುಚ್ಚಿ',
  RESEND_OTP: 'OTP ಅನ್ನು ಮರುಕಳುಹಿಸಿ',
  OTP_IS_REQUIRED: 'OTP ಅಗತ್ಯವಿದೆ',
  VERIFY_MOBILE_NUMBER: 'ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ಪರಿಶೀಲಿಸಿ',
  ENTER_THE_OTP_SENT_TO: 'ಗೆ ಕಳುಹಿಸಲಾದ OTP ಅನ್ನು ನಮೂದಿಸಿ ',
  PHONE_NUMBER_ALREADY_EXISTS: 'ಫೋನ್ ಸಂಖ್ಯೆ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ',
  INVALID_PHONE_NUMBER: 'ಅಮಾನ್ಯವಾದ ಫೋನ್ ಸಂಖ್ಯೆ',
  PHONE_NUMBER_IS_REQUIRED: 'ಫೋನ್ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ',
  SELECT_COUNTRY: 'ದೇಶವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  COUNTRY: 'ದೇಶ',
  NAME: 'ಹೆಸರು',
  PLEASE_ENTER_ONLY_LETTERS: 'ದಯವಿಟ್ಟು ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ',
  NAME_IS_REQUIRED: 'ಹೆಸರು ಅಗತ್ಯವಿದೆ',
  BY_CLICKING_ON_CONTINUE_YOU_AGREE_OUR: 'ಮುಂದುವರಿಸಿ ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ನೀವು ನಮ್ಮ ಸಮ್ಮತಿಸುತ್ತೀರಿ ',
  TERMS_CONDITIONS: 'ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು ',
  AND: 'ಮತ್ತು ',
  PRIVACY_POLICY: 'ಗೌಪ್ಯತಾ ನೀತಿ',
  VALID_PHONE_NUMBER_IS_REQUIRED: 'ಫೋನ್ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ',
  SUBMIT: 'ಸಲ್ಲಿಸು',
  OTP: 'OTP',
  RESEND: 'ಮರುಕಳುಹಿಸಿ',
  CONFIRM_PASSWORD: 'ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ',
  PASSWORD_CONFIRMATION_IS_REQUIRED: 'ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಣದ ಅಗತ್ಯವಿದೆ',
  ENTER_OTP: 'Otp ನಮೂದಿಸಿ',
  MOBILE_NUMBER_NOT_REGISTERED: 'ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ನೋಂದಾಯಿಸಲಾಗಿಲ್ಲ',
  ENTER_ANOTHER_NUMBER: 'ಇನ್ನೊಂದು ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ!',
  ERROR: 'ದೋಷ',
  SOMETHING_WENT_WRONG: 'ಏನೋ ತಪ್ಪಾಗಿದೆ!',
  PLEASE_FILL_ALL_THE_DETAILS: 'ದಯವಿಟ್ಟು ಎಲ್ಲಾ ವಿವರಗಳನ್ನು ಭರ್ತಿ ಮಾಡಿ',
  FAILURE: 'ವೈಫಲ್ಯ',
  FAILED_TO_SEND_OTP: 'OTP ಕಳುಹಿಸಲು ವಿಫಲವಾಗಿದೆ!',
  SUCCESS: 'ಯಶಸ್ಸು ',
  NEW_PASSWORD_UPDATED_SUCCESSFULLY: 'ಹೊಸ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ!',
  FAILED_TO_UPDATE_NEW_PASSWORD: 'ಹೊಸ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ನವೀಕರಿಸಲು ವಿಫಲವಾಗಿದೆ!',
  PLEASE_TRY_AFTER_SOMETIME: 'ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ',
  VISIT_OUR_WEBSITE: 'ನಮ್ಮ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಭೇಟಿ ನೀಡಿ',
  LOGOUT: 'ಲಾಗ್ ಔಟ್',
  SETTINGS: 'ಸಂಯೋಜನೆಗಳು',

  // DOWNLOAD_ON_THE : 'ನಲ್ಲಿ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ',
  // APP_STORE : 'ಆಪ್ ಸ್ಟೋರ್',
  // GET_IT_ON : 'ಅದನ್ನು ಪಡೆಯಿರಿ',
  // GOOGLE_PLAY :'ಗೂಗಲ್ ಆಟ',
  // VISIT_OUR_WEBSITE: 'ನಮ್ಮ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಭೇಟಿ ನೀಡಿ',

  DOWNLOAD_ON_APP_STORE: 'ಆಪ್ ಸ್ಟೋರ್‌ನಲ್ಲಿ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ',
  DOWNLOAD_ON_PLAY_STORE: 'ಪ್ಲೇ ಸ್ಟೋರ್‌ನಲ್ಲಿ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ',
  YOU_WILL_NOT_BE_ABLE_TO_REVERT_THIS: 'ಇದನ್ನು ಹಿಂತಿರುಗಿಸಲು ನಿಮಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ!',

  // Support Page
  SUPPORT: 'ಬೆಂಬಲ',
  ENTER_YOUR_NAME: 'ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
  PLEASE_ENTER_NAME: 'ದಯವಿಟ್ಟು ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
  ENTER_YOUR_EMAIL: 'ನಿಮ್ಮ ಇಮೇಲ್ ನಮೂದಿಸಿ',
  PLEASE_FILL_IN_THE_EMAIL: 'ದಯವಿಟ್ಟು ಇಮೇಲ್ ನಮೂದಿಸಿ',
  ENTER_VALID_EMAIL: 'ದಯವಿಟ್ಟು ಇಮೇಲ್ ಅನ್ನು ಭರ್ತಿ ಮಾಡಿ',
  ENTER_YOUR_PHONE: 'ದಯವಿಟ್ಟು ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ',
  SUBJECT: 'ವಿಷಯ',
  PLEASE_ENTER_SUBJECT: 'ದಯವಿಟ್ಟು ವಿಷಯವನ್ನು ನಮೂದಿಸಿ',
  SUBJECT_ERROR_STATE: 'ವಿಷಯವು ಕನಿಷ್ಠ 10 ಅಕ್ಷರಗಳಾಗಿರಬೇಕು',
  MESSAGE: 'ಸಂದೇಶ',
  PLEASE_FILL_THIS: 'ದಯವಿಟ್ಟು ಸಂದೇಶವನ್ನು ನಮೂದಿಸಿ',
  MESSAGE_ERROR_STATE: 'ಸಂದೇಶವು ಕನಿಷ್ಠ 100 ಅಕ್ಷರಗಳಾಗಿರಬೇಕು',
  I_AGREE_TO_THE: 'ನಾನು ಒಪ್ಪುತ್ತೇನೆ ',
  TERMS_AND_CONDITIONS: 'ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು ',
  AGREE_TERMS_AND_CONDITIONS: 'ದಯವಿಟ್ಟು ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು ಒಪ್ಪಿಕೊಳ್ಳಿ',
  WE_HAVE_RECEIVED_YOUR_EMAIL: 'ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನಾವು ಸ್ವೀಕರಿಸಿದ್ದೇವೆ, ನಾವು ಹಿಂತಿರುಗುತ್ತೇವೆ.',
  FAILED_TO_SEND_MESSAGE: 'ಸಂದೇಶ ಕಳುಹಿಸಲು ವಿಫಲವಾಗಿದೆ!',
  SUBMIT_BUTTON: 'ಸಲ್ಲಿಸು',
  // Subscription Page

  ORDER_SUMMARY: 'ಆದೇಶ ಸಾರಾಂಶ',
  DISCOUNTED_AMOUNT: 'ರಿಯಾಯಿತಿ ಮೊತ್ತ',
  ACTUAL_AMOUNT: 'ನಿಜವಾದ ಮೊತ್ತ',
  CGST_AND_FEES: 'CGST ಮತ್ತು ಶುಲ್ಕಗಳು',
  SGST_AND_FEES: 'SGST ಮತ್ತು ಶುಲ್ಕಗಳು',
  IGST_AND_FEES: 'IGST ಮತ್ತು ಶುಲ್ಕಗಳು',
  TOTAL: 'ಒಟ್ಟು',
  PAYMENT_METHOD: 'ಪಾವತಿ ವಿಧಾನ',
  NO_PAYMENT_METHOD_AVAILABLE: 'ಯಾವುದೇ ಪಾವತಿ ವಿಧಾನ ಲಭ್ಯವಿಲ್ಲ',
  BILLING_INFORMATION: 'ಬಿಲ್ಲಿಂಗ್ ಮಾಹಿತಿ',
  NOTE_INVOICE: 'ಗಮನಿಸಿ: ನೀವು ಸರಕುಪಟ್ಟಿ ಪ್ರತಿಯನ್ನು ಬಯಸಿದರೆ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅನ್ನು ನವೀಕರಿಸಿ',
  SAVE_DETAILS: 'ವಿವರಗಳನ್ನು ಉಳಿಸಿ',
  ROUND_OF_TO_NEARBY: 'ಸಮೀಪದಿಂದ ಸುತ್ತು',
  UPDATE_FAILED: 'ನವೀಕರಣ ವಿಫಲವಾಗಿದೆ',
  FREE: 'ಉಚಿತ',
  PER_MONTH: 'ಪ್ರತಿ ತಿಂಗಳು ',
  YOU_CAN_UPGRADE: 'ನೀವು ಅಪ್ಗ್ರೇಡ್ ಮಾಡಬಹುದು!',
  NOT_NOW: 'ಈಗ ಸಾಧ್ಯವಿಲ್ಲ',
  UPGRADE: 'ನವೀಕರಿಸಿ',
  UPGRADE_YOUR_PLAN: 'ನಿಮ್ಮ ಯೋಜನೆಯನ್ನು ಅಪ್‌ಗ್ರೇಡ್ ಮಾಡಿ',
  TO_ACCESS: 'ಪ್ರವೇಶಿಸಲು',
  PLEASE_UPGRADE_YOUR_SUBSCRIPTION: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಚಂದಾದಾರಿಕೆಯನ್ನು ಅಪ್‌ಗ್ರೇಡ್ ಮಾಡಿ',
  RENEW: 'ನವೀಕರಿಸಿ',
  BUY_NOW: 'ಈಗ ಖರೀದಿಸು',

  // Profile Modal
  MY_INFO: 'ನನ್ನ ಮಾಹಿತಿ',
  PROFILE_SETTINGS: 'ಪ್ರೊಫೈಲ್ ಸೆಟ್ಟಿಂಗ್‌ಗಳು',
  CHANGE_PASSWORD: 'ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ',
  ACCOUNT_ACTIVITY: 'ಖಾತೆ ಚಟುವಟಿಕೆ',
  FIRST_NAME: 'ಮೊದಲ ಹೆಸರು',
  FIRST_NAME_IS_REQUIRED: 'ಮೊದಲ ಹೆಸರು ಅಗತ್ಯವಿದೆ',
  LAST_NAME: 'ಕೊನೆಯ ಹೆಸರು',
  LAST_NAME_IS_REQUIRED: 'ಕೊನೆಯ ಹೆಸರು ಅಗತ್ಯವಿದೆ',
  EMAIL: 'ಇಮೇಲ್',
  EMAIL_IS_REQUIRED: 'ಇಮೇಲ್ ಅಗತ್ಯವಿದೆ',
  COUNTRY_IS_REQUIRED: 'ದೇಶದ ಅಗತ್ಯವಿದೆ',
  STATE_LIST: 'ರಾಜ್ಯ ಪಟ್ಟಿ',
  SELECT: 'ಆಯ್ಕೆ ಮಾಡಿ',
  SELECT_STATE: 'ರಾಜ್ಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  OCCUPATION: 'ಉದ್ಯೋಗ',
  OCCUPATION_IS_REQUIRED: 'ಉದ್ಯೋಗ ಅಗತ್ಯವಿದೆ',
  DATE_OF_BIRTH: 'ಹುಟ್ತಿದ ದಿನ',
  DATE_OF_BIRTH_IS_REQUIRED: 'ಹುಟ್ಟಿದ ದಿನಾಂಕದ ಅಗತ್ಯವಿದೆ',
  SAVE: 'ಉಳಿಸಿ',
  DATE_FORMAT: 'ದಿನಾಂಕ ಸ್ವರೂಪ',
  DATE_FORMAT_IS_REQUIRED: 'ದಿನಾಂಕ ಸ್ವರೂಪದ ಅಗತ್ಯವಿದೆ',
  TIME_FORMAT: 'ಸಮಯ ಸ್ವರೂಪ',
  TIME_FORMAT_IS_REQUIRED: 'ಟೈಮ್ ಫಾರ್ಮ್ಯಾಟ್ ಅಗತ್ಯವಿದೆ',
  LANGUAGE: 'ಭಾಷೆ',
  LANDING_PAGE: 'ಲ್ಯಾಂಡಿಂಗ್ ಪೇಜ್',
  LANDING_PAGE_IS_REQUIRED: 'ಲ್ಯಾಂಡಿಂಗ್ ಪೇಜ್ ಅಗತ್ಯವಿದೆ',
  TIME_ZONE: 'ಸಮಯ ವಲಯ',
  TIME_ZONE_IS_REQUIRED: 'ಸಮಯ ವಲಯದ ಅಗತ್ಯವಿದೆ',
  SHOW_WEB_NOTIFICATION: 'ವೆಬ್ ಅಧಿಸೂಚನೆಯನ್ನು ತೋರಿಸಿ',
  CURRENT_PASSWORD: 'ಪ್ರಸ್ತುತ ಗುಪ್ತಪದ',
  NEW_PASSWORD: 'ಹೊಸ ಪಾಸ್ವರ್ಡ್',
  CONFIRM_NEW_PASSWORD: 'ಹೊಸ ಗುಪ್ತಪದವನ್ನು ಖಚಿತಪಡಿಸಿ',
  DEACTIVATE_ACCOUNT: 'ಖಾತೆಯನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸು',
  DEACTIVATE_YOUR_ACCOUNT: 'ನಿಮ್ಮ ಖಾತೆಯನ್ನು ತಾತ್ಕಾಲಿಕವಾಗಿ ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಿ. ನೀವು ನಂತರ ಅದನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಬಹುದು ಮತ್ತು CoffeeWeb ನೊಂದಿಗೆ ಸಂಪರ್ಕಿಸಬಹುದು',
  YES_DEACTIVATE: 'ಹೌದು, ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಿ',
  DELETE_ACCOUNT: 'ಖಾತೆಯನ್ನು ಅಳಿಸಿ',
  DELETE_YOUR_ACCOUNT: 'ನೀವು ಖಾತೆಯನ್ನು ಅಳಿಸಿದರೆ, ಅದನ್ನು ಮರಳಿ ಪಡೆಯಲಾಗುವುದಿಲ್ಲ. ನೀವು ಇದನ್ನು ಮಾಡಲು ಬಯಸುತ್ತೀರಾ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.',
  YES_DELETE: 'ಹೌದು, ಅಳಿಸಿ',
  ON: 'ಆನ್',
  OFF: 'ಆರಿಸಿ',
  PASSWORD_AND_CONFIRM_PASSWORD_SHOULD_BE_SAME: 'ಪಾಸ್ವರ್ಡ್ ಮತ್ತು ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ ಒಂದೇ ಆಗಿರಬೇಕು',
  PASSWORD_CHANGED_SUCCESSFULLY: 'ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಬದಲಾಯಿಸಲಾಗಿದೆ!',
  UPDATED_SUCCESSFULLY: 'ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ',
  EMAIL_ALREADY_EXISTS: 'ಇಮೇಲ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ',
  ENTER_ANOTHER_EMAIL: 'ಇನ್ನೊಂದು ಇಮೇಲ್ ನಮೂದಿಸಿ',
  DATA_UPDATED_SUCCESSFULLY: 'ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ',
  FAILED: 'ವಿಫಲವಾಗಿದೆ',
  ARE_YOU_SURE: 'ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ?',
  IF_YOU_DELETE_ACCOUNT: 'ನೀವು ಖಾತೆಯನ್ನು ಅಳಿಸಿದರೆ, ಅದನ್ನು ಮರಳಿ ಪಡೆಯಲಾಗುವುದಿಲ್ಲ. ನೀವು ಇದನ್ನು ಮಾಡಲು ಬಯಸುತ್ತೀರಾ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.',
  ACCOUNT_DELETED_SUCCESSFULLY: 'ಖಾತೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ',
  TRY_AGAIN_AFTER_SOMETIME: 'ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ!',
  ACCOUNT_DEACTIVATED: 'ಖಾತೆಯನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ',
  OTP_IS_EXPIRED: 'OTP ಅವಧಿ ಮೀರಿದೆ',
  CLICK_ON_RESEND_TO_GET_NEW_OTP: 'ಹೊಸ OTP ಪಡೆಯಲು ಮರುಕಳುಹಿಸಿ ಕ್ಲಿಕ್ ಮಾಡಿ',

  // Coffee News Feed
  COFFEE_NEWS_FEEDS: 'ಕಾಫಿ ನ್ಯೂಸ್ ಫೀಡ್‌ಗಳು',
  BUY_SUBSCRIPTION: 'ಚಂದಾದಾರಿಕೆಯನ್ನು ಖರೀದಿಸಿ',
  DAILY_FEED: 'ದೈನಂದಿನ ಫೀಡ್',
  MY_FAVOURITE: 'ನನ್ನ ಮೆಚ್ಚಿನ',
  SEARCH: 'ಹುಡುಕು',
  SEARCH_FOR_NEWS: 'ಸುದ್ದಿಗಾಗಿ ಹುಡುಕಿ',
  READ_MORE: 'ಮತ್ತಷ್ಟು ಓದು',
  LOADING: 'ಲೋಡ್ ಆಗುತ್ತಿದೆ...',
  SELECT_LANGUAGE: 'ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  ARE_YOU_SURE_YOU_WANT_TO_DISLIKE_THIS_NEWS: 'ಈ ಸುದ್ದಿಯನ್ನು ಇಷ್ಟಪಡದಿರಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?',
  NO_DATA_FOUND: 'ಯಾವುದೇ ಡೇಟಾ ಕಂಡುಬಂದಿಲ್ಲ',
  SEE_MORE: 'ಇನ್ನೂ ಹೆಚ್ಚು ನೋಡು',

  // News Read More
  GO_BACK: 'ಹಿಂದೆ ಹೋಗು',
  SHARE: 'ಹಂಚಿಕೊಳ್ಳಿ',
  COFFEE_NEWS_DATA_DOWNLOAD: 'ಕಾಫಿ ನ್ಯೂಸ್ ಡೇಟಾ ಡೌನ್‌ಲೋಡ್ ',
  DOWNLOAD_DOCUMENT: 'ಡಾಕ್ಯುಮೆಂಟ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ',
  VIEW_DOCUMENT: 'ಡಾಕ್ಯುಮೆಂಟ್ ವೀಕ್ಷಿಸಿ',

  // Share News Feed
  COPY: 'ನಕಲಿಸಿ',
  WHATS_APP: 'WhatsApp',
  FACEBOOK: 'ಫೇಸ್ಬುಕ್',
  LINKED_IN: 'ಲಿಂಕ್ಡ್‌ಇನ್',
  TWITTER: 'ಟ್ವಿಟರ್',
  TELEGRAM: 'ಟೆಲಿಗ್ರಾಮ್',
  MAIL: 'ಮೇಲ್',

  // Coffee Quotes
  VOL: 'ಪರಿಮಾಣ',
  HIGH: 'ಹೆಚ್ಚು',
  LOW: 'ಕಡಿಮೆ',
  OPEN: 'ತೆರೆಯಿರಿ',
  BID: 'ಬಿಡ್',
  ASK: 'ಕೇಳು',
  OPEN_INT: 'Int ತೆರೆಯಿರಿ',
  NET_HIGH: 'ನೆಟ್-ಹೈ',
  NET_LOW: 'ನೆಟ್-ಕಡಿಮೆ',
  PRE_CLOSE: 'ಪೂರ್ವ ಮುಚ್ಚಿ',
  BSIZE: 'ಬಿ-ಗಾತ್ರ',
  ASIZE: 'ಎ-ಗಾತ್ರ',
  OPTION_EXPIRY: 'ಆಯ್ಕೆಯ ಮುಕ್ತಾಯ',
  FIRST_NOTICE_DAY: 'ಮೊದಲ ಸೂಚನೆ ದಿನ',
  LET_S_HELP: 'ಸಹಾಯ ಮಾಡೋಣ',
  LAST: 'ಕೊನೆಯದು',
  CHG: 'Chg',
  PLEASE_FILL_DIVIDER_NAME: 'ದಯವಿಟ್ಟು ವಿಭಾಜಕ ಹೆಸರನ್ನು ಭರ್ತಿ ಮಾಡಿ',
  ROBUSTA_DIVIDER: 'ದೃಢವಾದ ವಿಭಾಜಕ',
  ARABICA_DIVIDER: 'ಅರೇಬಿಕಾ ವಿಭಾಜಕ',
  ROBUSTA: 'ರೋಬಸ್ಟಾ',
  ADD: 'ಸೇರಿಸಿ',
  GRADE: 'ಗ್ರೇಡ್',
  NET_PRICE: 'ನಿವ್ವಳ ಬೆಲೆ',
  OUT_RATE: 'ಔಟ್ ರೇಟ್',
  DIFFERENTIAL: 'ಭೇದಾತ್ಮಕ',
  TIME: 'ಸಮಯ',
  DATE: 'ದಿನಾಂಕ',
  TRENDING_NEWS: 'ಟ್ರೆಂಡಿಂಗ್ ಸುದ್ದಿ',
  ROBUSTA_CONTRACTS: 'ರೋಬಸ್ಟಾ ಒಪ್ಪಂದಗಳು',
  ARABICA_CONTRACTS: 'ಅರೇಬಿಕಾ ಒಪ್ಪಂದಗಳು',
  FX: 'FX',
  FX_PRE_DIS: 'FXPreDis',
  FX_NET: 'FXNet',
  GROSS_PRICE: 'ನಿವ್ವಳ ಬೆಲೆ',
  PROFIT: 'ಲಾಭ',
  DELETE: 'ಅಳಿಸಿ',
  CONVTO: 'convto',
  ROBUSTA_WORKBOOK: 'ರೋಬಸ್ಟಾ ವರ್ಕ್‌ಬುಕ್‌ಗಳು',
  ARABICA_WORKBOOK: 'ಅರೇಬಿಕಾ ಕಾರ್ಯಪುಸ್ತಕ',
  CHANGES_UPDATED_SUCCESSFULLY: 'ಬದಲಾವಣೆಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ!',
  YES_DELETE_IT: 'ಹೌದು, ಅದನ್ನು ಅಳಿಸಿ!',
  MARKET_WORK_DELETED_SUCCESSFULLY: 'MarketWork ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ!',
  YOUR_MARKET_WORKBOOK_HAS_BEEN_REMAINS_SAFE: 'ನಿಮ್ಮ ಮಾರ್ಕೆಟ್‌ವರ್ಕ್‌ಬುಕ್ ಸುರಕ್ಷಿತವಾಗಿ ಉಳಿದಿದೆ!',
  DELETE_FAILURE: 'ವೈಫಲ್ಯವನ್ನು ಅಳಿಸಿ!',
  FAILED_TO_GET_DATA: 'ಡೇಟಾ ಪಡೆಯಲು ವಿಫಲವಾಗಿದೆ',
  SAVE_ALL_CHANGES: 'ಎಲ್ಲಾ ಬದಲಾವಣೆಗಳನ್ನು ಉಳಿಸಿ',
  SELECT_ALL_ROBUSTA_WORKBOOK: 'ಎಲ್ಲಾ ರೋಬಸ್ಟಾ ವರ್ಕ್‌ಬುಕ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  SELECT_ALL_ARABICA_WORKBOOK: 'ಎಲ್ಲಾ ಅರೇಬಿಕಾ ವರ್ಕ್‌ಬುಕ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  DELETE_SELECTED_WORKBOOK_DATA: 'ಆಯ್ದ ವರ್ಕ್‌ಬುಕ್ ಡೇಟಾವನ್ನು ಅಳಿಸಿ',

  // Global Differentials
  GLOBAL_DIFFERENTIALS: 'ಜಾಗತಿಕ ವ್ಯತ್ಯಾಸಗಳು',
  SELECT_ORIGIN: 'ಮೂಲವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  CROP_YEAR: 'ಬೆಳೆ ವರ್ಷ',
  UPDATED_ON: 'ರಂದು ನವೀಕರಿಸಲಾಗಿದೆ',
  PRICES_BASED_ON_FOB: 'FOB ಆಧಾರದ ಮೇಲೆ ಬೆಲೆಗಳು',
  QUALITY: 'ಗುಣಮಟ್ಟ',
  DIFFERENTIALS: 'ವ್ಯತ್ಯಾಸಗಳು',
  QUALITY_STANDARDS: 'ಗುಣಮಟ್ಟದ ಮಾನದಂಡಗಳು',
  REMARKS: 'ಟೀಕೆಗಳು',
  DELIVERY_PORT: 'ಡೆಲಿವರಿ ಪೋರ್ಟ್',
  FOREX_LEVEL: 'ವಿದೇಶೀ ವಿನಿಮಯ ಮಟ್ಟ',
  SIZE: 'ಗಾತ್ರ',
  DIFFERENTIALS_BASED_ON_FOB: 'FOB ಆಧಾರದ ಮೇಲೆ ವ್ಯತ್ಯಾಸಗಳು',
  SL: 'SL',
  SCREEN: 'ಪರದೆಯ',
  BETWEEN: 'ನಡುವೆ',
  MOISTURE: 'ತೇವಾಂಶ',
  SCREEN_RETENTION: 'ಪರದೆಯ ಧಾರಣ',
  DEFECTS: 'ದೋಷಗಳು',
  NO_DATA_FOUND_FOR_THIS_COUNTRY: 'ಈ ದೇಶಕ್ಕಾಗಿ ಯಾವುದೇ ಡೇಟಾ ಕಂಡುಬಂದಿಲ್ಲ!',
  RANGE_STARTS: 'ಶ್ರೇಣಿ ಪ್ರಾರಂಭವಾಗುತ್ತದೆ',
  RANGE_ENDS: 'ವ್ಯಾಪ್ತಿ ಕೊನೆಗೊಳ್ಳುತ್ತದೆ',
  QUALITY_NAME: 'ಗುಣಮಟ್ಟದ ಹೆಸರು',
  TERMINAL: 'ಟರ್ಮಿನಲ್',
  TO_ACCESS_THIS_INFORMATION_FOR_GLOBAL_DIFFERENTIAL: 'ಈ ಮಾಹಿತಿಯನ್ನು ಪ್ರವೇಶಿಸಲು, ದಯವಿಟ್ಟು ಖಾತೆಯನ್ನು ರಚಿಸಿ ಅಥವಾ ಲಾಗ್ ಇನ್ ಮಾಡಿ. ಈ ಡೇಟಾವು ಪ್ಲಾಟಿನಂ ಬಳಕೆದಾರರಿಗೆ ಪ್ರತ್ಯೇಕವಾಗಿ ಲಭ್ಯವಿದೆ',

  // Error Boundary
  YOUR_SESSION_HAS_EXPIRED: 'ನಿಮ್ಮ ಅವಧಿಯು ಮುಕ್ತಾಯಗೊಂಡಿದೆ',
  PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING_THE_APP: 'ಈ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಬಳಸುವುದನ್ನು ಮುಂದುವರಿಸಲು ದಯವಿಟ್ಟು ಮತ್ತೊಮ್ಮೆ ಲಾಗ್ ಇನ್ ಮಾಡಿ',
  RE_LOGIN: 'ಮರು ಲಾಗಿನ್ ಮಾಡಿ',
  TRY_AGAIN: 'ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು',

  // Footer
  COPYRIGHT: '© ಹಕ್ಕುಸ್ವಾಮ್ಯ ',
  COFFEE_WEB: 'ಕಾಫಿವೆಬ್ ಟೆಕ್ನಾಲಜೀಸ್ ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್.',
  ALL_RIGHTS_RESERVED: 'ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ '
}

export default KA_LANGUAGES
