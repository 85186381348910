import './styles.scss'
import React, { useEffect, useState, useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import Carousel from 'framer-motion-carousel'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Element } from 'react-scroll'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ShareIcon from '@mui/icons-material/Share'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { ClockIcon, EyeFilled, FilledHeartIcon, UnFilledHeartIcon, Closebtn } from 'Assets/Icons'
import { coffeewebGetLocal, coffeewebStorageKeys, coffeewebSetLocal } from 'Utils/LocalStorage_Handler'
import { addBlankTargetToAnchors } from 'Utils/HtmlUtils'
import Loader from 'Components/LayoutComponents/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { NoData } from 'Assets/Images'
import ShareNewsfeed from '../ShareNewsfeed'

const NewsReadMore = ({ setLoading, handleFavoriteAction, setOnRemoval, setNewsFeedStatus, redirectForAuthentication, handleNewsFalseModal, generatedToken, handleApiResponse }) => {
  const appSettings = coffeewebGetLocal(coffeewebStorageKeys.appSettings)
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const isLoggedIn = coffeewebGetLocal(coffeewebStorageKeys.isLoggedIn)

  const { i18n, t } = useTranslation()
  const location = useLocation()
  const scrollableDivRef = useRef(null)
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)

  const newsIdUpdated = searchParams.get('newsId')
  const isFullFreeNews = searchParams.get('showFullNews')
  const [coffeeNewsData, setCoffeeNewsData] = useState([])
  const [images, setImages] = useState([])
  const [showShareNewsModal, setShowShareNewsModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const [previousScrollTop, setPreviousScrollTop] = useState(0)
  const [scrollDirection, setScrollDirection] = useState('up')
  const [like, setLike] = useState(false)
  const [downloadModal, setDownloadModal] = useState(false)
  const [newsFavoriteId, setNewsFavoriteId] = useState(null)
  const [newsError, setNewsError] = useState(false)

  const [newsDetails, setNewsDetails] = useState({
    newsId: null,
    author: null,
    customdate: null,
    customtime: null,
    newsHtml: null,
    filePath: null,
    fileType: null,
    nwsFeedMedia: null,
    encryptedId: null,
    newsForSubscription: null,
    subject: null,
    newsFavourite: null,
    views: 0
  })

  const { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } = newsDetails

  useEffect(() => {
    if (location.state?.newsId) {
      getNewsWithViewCount(generatedToken)
    } else {
      getCoffeeNewsData(generatedToken)
    }
  }, [])

  const paramsData = (newsId) => {
    const params = new URLSearchParams(location.search)

    params.set('newsId', newsId)

    return params
  }

  const getNewsWithViewCount = async () => {
    setLoading(true)

    const { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } = location.state
    let updatedViews = views
    let updatedNewsFavourite = newsFavourite

    setLike(updatedNewsFavourite?.isFavourite)
    setNewsDetails((previousData) => ({ ...previousData, newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite: updatedNewsFavourite, views: updatedViews }))
    addBlankTargetToAnchors(newsHtml)
    const imageMatches = nwsFeedMedia?.filter((item) => item.typeMedia === 'image')

    setImages(imageMatches)
    try {
      if (!isFullFreeNews) {
        const response = await apiAdapterCoffeeWeb.updateNewsViewCountOnUser({ NewsId: newsIdUpdated, userid: userDetails?.id })
        const {
          data,
          data: { views, idNewsFavourite, newsFavourite }
        } = response

        updatedViews = views || updatedViews
        updatedNewsFavourite = newsFavourite || updatedNewsFavourite

        const newData = [data].map((ele) => ({ ...ele, encryptedId }))

        setCoffeeNewsData(newData)
        setNewsDetails((previousData) => ({ ...previousData, newsFavourite: updatedNewsFavourite, views: updatedViews }))
        setNewsFavoriteId(idNewsFavourite)
        setLike(newsFavourite?.isFavourite)

        if (location.state.newsFavourite?.isFavourite !== newsFavourite?.isFavourite) {
          handleFavoriteChange(idNewsFavourite, newsFavourite?.isFavourite)
        }

        const params = paramsData(newsId)

        history.replace({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, idNewsFavourite, views } })
      } else {
        setNewsDetails((previousData) => ({ ...previousData, newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite: updatedNewsFavourite, views: updatedViews }))
      }
      addBlankTargetToAnchors(newsHtml)
    } finally {
      setLoading(false)
    }
  }

  const getParameterValue = (parameter) => new URLSearchParams(window.location.search).get(parameter)
  const shareValue = getParameterValue('share')

  const getCoffeeNewsData = async (generatedToken) => {
    setLoading(true)
    setLike(newsFavourite?.isFavourite)
    try {
      let isRead = false

      if (userDetails?.id) {
        isRead = true
      }

      const payload = {
        userId: userDetails?.id || 0,
        newsId: newsIdUpdated,
        isRead
      }

      const {
        data: { returnLst: newsData }
      } = await apiAdapterCoffeeWeb.getNewsById(payload, generatedToken)

      const { newsForLock, newsForSubscription, id: newsId, showFullfreeNews } = newsData

      const subscribedNews = newsForSubscription.split(',')
      const lockedNews = newsForLock.split(',')

      const isSubscriptionValid = subscribedNews.includes(`${userDetails?.sub?.subType}`) && !lockedNews.includes(`${userDetails?.sub?.subType}`)

      if (isSubscriptionValid || showFullfreeNews) {
        const data = addBlankTargetToAnchors(newsData.newsHtml)
        const newData = [newsData].map((ele) => ({ ...ele, newsHtml: data }))

        const { id: newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } = newsData

        setCoffeeNewsData(newData)
        setNewsDetails((previousData) => ({ ...previousData, newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views }))
        if (newsData?.newsFavourite) {
          setLike((previousLike) => !previousLike)
        }

        document.querySelector('body').style.overflow = 'hidden'

        coffeewebSetLocal(coffeewebStorageKeys.coffeeNewsScreen, true)

        const imageMatches = newsData?.nwsFeedMedia?.filter((item) => item.typeMedia === 'image')

        setImages(imageMatches)

        const params = paramsData(newsId)

        history.replace({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } })
      } else {
        history.replace(ROUTE_STRINGS.subscription)
        coffeewebSetLocal(coffeewebStorageKeys.subscriptionNewsId, newsId)
      }
    } catch (error) {
      coffeewebSetLocal(coffeewebStorageKeys.coffeeNewsScreen, false)
      setNewsError(true)
      handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_LOAD_NEWS', onRetry: () => getCoffeeNewsData(generatedToken) })
    } finally {
      setLoading(false)
    }
  }

  const addToFavorites = async () => {
    const postData = {
      id: 0,
      idUser: userDetails.id,
      idNews: newsId,
      isFavourite: true
    }

    await apiAdapterCoffeeWeb
      .postNewsUsersFavorite(postData)
      .then((response) => {
        const { id, isFavourite } = response.data.returnLst

        const newsFavourite = response.data.returnLst

        setCoffeeNewsData({ ...coffeeNewsData, newsFavourite })
        setNewsFavoriteId(id)
        handleFavoriteChange(id, isFavourite)
        setLike(true)

        const params = paramsData(newsId)

        history.replace({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, subject, newsFavourite, views } })
      })
      .catch((err) => {
        console.log('err', err)
      })
    setLoading(false)
  }

  const removeFromFavorites = async (favouriteNewsId) => {
    await apiAdapterCoffeeWeb
      .makeNewsUnfavorite(favouriteNewsId)
      .then((response) => {
        const newsFavourite = response.data.returnLst
        const previousNewsData = coffeeNewsData[0]

        setCoffeeNewsData({ ...previousNewsData, newsFavourite })
        setLike(false)
        setOnRemoval(true)
        setNewsFeedStatus((previousState) => ({ ...previousState, noNewsFeedImage: true, errorMessage: t('NEWS_FEED_NO_DATA') }))

        const params = paramsData(newsId)

        history.replace({ search: params.toString(), state: { newsId, author, customdate, customtime, newsHtml, filePath, fileType, nwsFeedMedia, encryptedId, newsForSubscription, newsFavourite, subject, views } })

        handleFavoriteChange(favouriteNewsId)
      })
      .catch((error) => {
        console.log('error', error)
      })
    setLoading(false)
  }

  const handleShowShareButton = () => {
    setShowShareNewsModal(true)
  }

  const handleScroll = () => {
    const scrollableDiv = document.getElementById('scrollable-div')
    const width = window.innerWidth

    if (width < 767) {
      const { scrollTop: currentScrollPos, scrollHeight: currentScrollPoss, clientHeight: currentScrollPosss } = scrollableDiv
      const minValue = currentScrollPoss - 7

      if (currentScrollPos > previousScrollTop) {
        setPreviousScrollTop(currentScrollPos)
        setScrollDirection('down')
        if (currentScrollPos + currentScrollPosss > minValue) {
          setScrollDirection('up')
        }
      } else if (currentScrollPos < previousScrollTop) {
        setPreviousScrollTop(currentScrollPos)
        setScrollDirection('up')
      }
      setPreviousScrollTop(currentScrollPos)
    }
  }

  const handleImageModal = (ele) => {
    const indexValue = images.map((e) => e.idNewsFeedMedia).indexOf(ele.idNewsFeedMedia)

    setImageIndex(indexValue)
    setIsOpen(true)
  }

  const handleViewDocument = () => {
    setDownloadModal(true)
  }

  const handleBackButton = () => {
    if (shareValue) {
      history.goBack()
    } else {
      coffeewebSetLocal(coffeewebStorageKeys.coffeeNewsScreen, false)
      handleNewsFalseModal(coffeeNewsData[0])
    }
  }

  const handleFavoriteChange = (favouriteNewsId, isFavourite) => {
    handleFavoriteAction(newsIdUpdated, favouriteNewsId, isFavourite)
  }

  const handleFavoriteFreeNews = (encryptedId, newsForSubscription, newsId) => {
    redirectForAuthentication(encryptedId, newsForSubscription, newsId)
  }

  const ShareButtonComponent = () => (
    <div className="share-news-button" onClick={() => handleShowShareButton()}>
      <div className="share-icon-container arrow-share-icons">
        <ShareIcon className="share-icon" />
      </div>
      <div className="arrow-share-icons">{t('SHARE')}</div>
    </div>
  )

  return (
    <>
      {newsHtml ? (
        <div className="news-read-more-screen-main-div">
          <div>
            <div className="news-read-more">
              <div className="main-card">
                <React.Fragment>
                  <div className="image-div">
                    <div className="sticky-buttons">
                      <div className="back-button" onClick={handleBackButton}>
                        <div className="arrow-btn-container">
                          <ArrowBackIcon className="arrow-share-icons" />
                        </div>
                        <div className="go-back-text">{t('GO_BACK')}</div>
                      </div>
                      {userDetails?.isLoggedIn && appSettings?.showShareButton && <ShareButtonComponent />}
                      {!isLoggedIn && <ShareButtonComponent />}
                    </div>

                    {nwsFeedMedia?.length > 0 ? (
                      <div className="img-container">
                        {nwsFeedMedia?.map((ele, index) => (
                          <div key={index}>{ele?.typeMedia === 'image' ? <img alt="" src={ele?.pathOfMedia} className="assets" onClick={() => handleImageModal(ele, index)} /> : <video src={ele?.pathOfMedia} className="assets" controls />}</div>
                        ))}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <Element className="content-div scrollable-div" id="scrollable-div" ref={scrollableDivRef} onScroll={handleScroll}>
                    <div className={`sticky-buttons scroll-back-button${scrollDirection === 'down' ? ' slide-down' : ' down'}`}>
                      <div className="back-button" onClick={handleBackButton}>
                        <div className="arrow-btn-container">
                          <ArrowBackIcon className="arrow-share-icons" />
                        </div>
                        <div className="go-back-text">{t('GO_BACK')}</div>
                      </div>
                      {userDetails?.isLoggedIn && appSettings?.showShareButton && <ShareButtonComponent />}
                      {!isLoggedIn && <ShareButtonComponent />}
                    </div>
                    <div className="sticky-container-div">
                      <div className="sticky-div">
                        {scrollDirection === 'up' && (
                          <div className="sub-sticky-div">
                            <div className="sticky-icons-container">
                              <CalendarMonthIcon className="social-feed-icons calendar-clock-icons" />
                              {customdate}
                            </div>
                            <div className="sticky-icons-container">
                              <ClockIcon fill={'#50b3f6'} className="social-feed-icons calendar-clock-icons" />
                              {customtime}
                            </div>
                          </div>
                        )}
                        <div className="banner-image">{ReactHtmlParser(subject)}</div>
                        <div className="sticky-box-bottom-part"></div>
                        {scrollDirection === 'up' && (
                          <div className="sub-sticky-div">
                            <div className="account-icon-container">
                              <AccountCircleIcon fill={'#50b3f6'} className="social-feed-icons account-icon" />
                              {author}
                            </div>
                            <div className="right-part-icon-container">
                              <div className="eyefilled-container">
                                <EyeFilled fill={'#50b3f6'} className="social-feed-icons eyefilled-icon" /> {views}
                              </div>

                              {!like ? (
                                <div onClick={userDetails ? () => addToFavorites() : () => handleFavoriteFreeNews(encryptedId, newsForSubscription, newsId)} className="heart-icon">
                                  <UnFilledHeartIcon className="heart-logo-blob" fill={'red'} />
                                </div>
                              ) : (
                                <div onClick={() => removeFromFavorites(coffeeNewsData[0]?.idNewsFavourite || newsFavoriteId)} className="heart-icon">
                                  <FilledHeartIcon />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      {nwsFeedMedia?.length > 1 && (
                        <>
                          <div className="carousel-for-news hideCarosel">
                            <Carousel interval={appSettings?.imageScrollingInterval ? appSettings.imageScrollingInterval * 1000 : 8000}>
                              {nwsFeedMedia?.map((ele, i) => (
                                <div key={i} className="carousel-container">
                                  {ele.typeMedia === 'image' ? <img alt="coffee-img" className="carousel-img" src={ele?.pathOfMedia} onClick={() => handleImageModal(ele, i)} /> : <video className="carousel-img" src={ele?.pathOfMedia} controls />}
                                </div>
                              ))}
                            </Carousel>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      {nwsFeedMedia?.length === 1 && (
                        <>
                          <div className="carousel-for-news">
                            <div>
                              {nwsFeedMedia?.map((ele, i) => (
                                <div key={i} className="carousel-container">
                                  {ele.typeMedia === 'image' ? <img alt="news-pic" className="carousel-img" src={ele?.pathOfMedia} onClick={() => handleImageModal(ele, i)} /> : <video className="carousel-img" src={ele?.pathOfMedia} controls />}
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {fileType === 'pdf' && (
                      <div className="view-document-and-file-container">
                        <button className="view-doc-btn" onClick={() => handleViewDocument()}>
                          {t('VIEW_DOCUMENT')}
                        </button>
                      </div>
                    )}
                    {fileType === 'xlsx' && (
                      <div className="view-document-and-file-container">
                        <a className="download-doc-link" href={filePath}>
                          {t('DOWNLOAD_DOCUMENT')}
                        </a>
                      </div>
                    )}

                    <div>
                      <div className="news-html">{ReactHtmlParser(newsHtml)}</div>
                    </div>
                    <div className="news-html-full-stop">.</div>
                  </Element>
                </React.Fragment>

                {images.length === 1 ? <> {isOpen && <Lightbox mainSrc={images[imageIndex].pathOfMedia} onCloseRequest={() => setIsOpen(false)} onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)} onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)} />}</> : <> {isOpen && <Lightbox mainSrc={images[imageIndex].pathOfMedia} nextSrc={images[(imageIndex + 1) % images.length].pathOfMedia} prevSrc={images[(imageIndex + images.length - 1) % images.length].pathOfMedia} onCloseRequest={() => setIsOpen(false)} onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)} onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)} />}</>}

                <Modal show={downloadModal} className="modal-read-more-download-pdf">
                  <Modal.Header>
                    <Modal.Title>
                      <div className="modal-title-share-news-read-more">{t('COFFEE_NEWS_DATA_DOWNLOAD')}</div>
                    </Modal.Title>
                    <Closebtn className="modal-close-btn-share-news-read-more" onClick={() => setDownloadModal(false)} />
                  </Modal.Header>
                  <Modal.Body>
                    <div className="read-more-download-pdf-body">
                      <iframe title="View Document" src={filePath} />
                    </div>
                  </Modal.Body>
                </Modal>
                <div className="modal-body-share-news-read-more">{showShareNewsModal && <ShareNewsfeed setShowShareNewsModal={setShowShareNewsModal} generatedToken={generatedToken} closeModal={() => setShowShareNewsModal(false)} newsData={newsDetails || coffeeNewsData[0]} />}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="news-read-more-screen-main-div">
          <div className="news-read-more">
            <div className="main-card">
              <React.Fragment>
                <div className="image-div">
                  <div className="sticky-buttons">
                    <div className="back-button">
                      <div className="arrow-btn-container">
                        <ArrowBackIcon className="arrow-share-icons" />
                      </div>
                      <div className="go-back-text">{t('GO_BACK')}</div>
                    </div>
                    {userDetails?.isLoggedIn && appSettings?.showShareButton && <ShareButtonComponent />}
                    {!isLoggedIn && <ShareButtonComponent />}
                  </div>

                  <div className={'img-container'}>
                    <img alt="" src="https://www.coffeeweb.com/CoffeeWeb_Logo_White_Background_Blue_Text.png" className="assets" />
                  </div>
                </div>
                <Element className="content-div scrollable-div" id="scrollable-div">
                  <div className={'sticky-buttons scroll-back-button down'}>
                    <div className="back-button">
                      <div className="arrow-btn-container">
                        <ArrowBackIcon className="arrow-share-icons" />
                      </div>
                      <div className="go-back-text">{t('GO_BACK')}</div>
                    </div>

                    {userDetails?.isLoggedIn && appSettings?.showShareButton && <ShareButtonComponent />}
                    {!isLoggedIn && <ShareButtonComponent />}
                  </div>
                  <div className="sticky-container-div">
                    <div className="sticky-div">
                      {scrollDirection === 'up' && (
                        <div className="sub-sticky-div">
                          <div className="sticky-icons-container">
                            <CalendarMonthIcon className="social-feed-icons calendar-clock-icons" />
                            {t('DUMMY_NEWS_DATE')}
                          </div>
                          <div className="sticky-icons-container">
                            <ClockIcon fill={'#50b3f6'} className="social-feed-icons calendar-clock-icons" />
                            {t('DUMMY_NEWS_TIME')}
                          </div>
                        </div>
                      )}
                      <div className="banner-image">{t('DUMMY_NEWS_TITLE')}</div>
                      <div className="sticky-box-bottom-part"></div>
                      {scrollDirection === 'up' && (
                        <div className="sub-sticky-div">
                          <div className="account-icon-container">
                            <AccountCircleIcon fill={'#50b3f6'} className="social-feed-icons account-icon" />
                            {t('DUMMY_NEWS_AUTHOR')}
                          </div>
                          <div className="right-part-icon-container">
                            <div className="eyefilled-container">
                              <EyeFilled fill={'#50b3f6'} className="social-feed-icons eyefilled-icon" /> {views}
                            </div>

                            <div className="heart-icon">
                              <UnFilledHeartIcon className="heart-logo-blob" fill={'red'} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {!newsError && (
                    <div className={`carousel-for-news ${newsError ? 'error-class' : ''} hideCarosel`}>
                      <div className="carousel-container">
                        <img alt="" src="https://www.coffeeweb.com/CoffeeWeb_Logo_White_Background_Blue_Text.png" className="assets" />
                      </div>
                    </div>
                  )}

                  <div className="search_on_page_load">
                    {newsError && (
                      <div>
                        <img src={NoData} alt="NoDataFound" className="no_data_found_image" />
                        <div className="all-data-fetched">{t('NEWS_FEED_API_FAILED')}</div>
                      </div>
                    )}
                  </div>
                </Element>
              </React.Fragment>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Loader(NewsReadMore)
