import COFFEEWEB_STORAGE_KEYS from './LocalStorage'
import REG_EX from './RegEx'
import ROUTE_STRINGS from './RouteStrings'

const STATIC_ROUTE_STRINGS = {
  home: 'https://www.coffeeweb.com/',
  termsAndCondition: 'https://www.coffeeweb.com/TermsAndCondition.html',
  privacyPolicy: 'https://www.coffeeweb.com/PrivacyPolicy.html'
}

const IMAGE_SOURCE_URLS = {
  checkImage: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/img_check.png',
  cancelImage: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/img_cancel.png'
}

const WEATHER_API_URL = {
  apiUrl: 'https://api.weatherapi.com/v1/search.json'
}

export { COFFEEWEB_STORAGE_KEYS, REG_EX, ROUTE_STRINGS, STATIC_ROUTE_STRINGS, IMAGE_SOURCE_URLS, WEATHER_API_URL }
