const HI_LANGUAGES = {
  OK: 'ठीक',
  NO: 'नहीं',
  YES: 'हां',
  CONTINUE: 'जारी रखना',
  CANCEL: 'रद्द करना',
  ALREADY_LOGGED_IN: 'आप पहले से ही किसी अन्य डिवाइस से लॉग इन हैं।',
  CONFIRM_LOGOUT: 'क्या आप लॉगआउट करना चाहते हैं?',
  DONT_HAVE_AN_ACCOUNT: 'कोई खाता नहीं है?',
  CREATE_ACCOUNT_LABEL: 'खाता बनाएं',
  SUPPORT_LABEL: 'सहायता?',
  FORGET_PASSWORD_LABEL: 'पासवर्ड भूल गए?',
  LOGIN: 'लॉग इन करें',
  SINGLE_DEVICE_CONTINUE_LOGIN: 'आप पहले ही किसी अन्य डिवाइस से लॉग इन कर चुके हैं, क्या आप इस डिवाइस से जारी रखना चाहेंगे?',
  SINGLE_DEVICE_LOGOUT_NOTE: 'नोट: यदि आप इस डिवाइस से जारी रखते हैं तो आपको अन्य डिवाइस से लॉग आउट कर दिया जाएगा!',
  INVALID_CREDENTIALS: 'अवैध प्रत्यय पत्र!',
  PHONE_IS_REQUIRED: 'फ़ोन आवश्यक है',
  PASSWORD_IS_REQUIRED: 'पासवर्ड की आवश्यकता है',
  PHONE: 'फ़ोन',
  PASSWORD: 'पासवर्ड',
  ALREADY_HAVE_AN_ACCOUNT: 'क्या आपके पास पहले से एक खाता मौजूद है?',
  LOGIN_HERE: 'यहां लॉगिन करें',
  VERIFY_CREATE_ACCOUNT: 'सत्यापित करें और खाता बनाएं',
  CLOSE: 'बंद करना',
  RESEND_OTP: 'ओटीपी पुनः भेजें',
  OTP_IS_REQUIRED: 'ओटीपी आवश्यक है',
  VERIFY_MOBILE_NUMBER: 'मोबाइल नंबर सत्यापित करें',
  ENTER_THE_OTP_SENT_TO: 'भेजे गए ओटीपी को दर्ज करें ',
  PHONE_NUMBER_ALREADY_EXISTS: 'फ़ोन नंबर पहले से मौजूद है',
  INVALID_PHONE_NUMBER: 'अवैध फोन नंबर',
  PHONE_NUMBER_IS_REQUIRED: 'फ़ोन नंबर आवश्यक है',
  SELECT_COUNTRY: 'देश चुनें',
  COUNTRY: 'देश',
  NAME: 'नाम',
  PLEASE_ENTER_ONLY_LETTERS: 'कृपया केवल अक्षर दर्ज करें',
  NAME_IS_REQUIRED: 'नाम आवश्यक है',
  BY_CLICKING_ON_CONTINUE_YOU_AGREE_OUR: 'जारी रखें पर क्लिक करके आप हमारी बात से सहमत हैं ',
  TERMS_CONDITIONS: 'नियम एवं शर्तें ',
  AND: 'और ',
  PRIVACY_POLICY: 'गोपनीयता नीति',
  VALID_PHONE_NUMBER_IS_REQUIRED: 'फ़ोन नंबर आवश्यक है ',
  SUBMIT: 'जमा करना',
  OTP: 'ओ.टी.पी',
  RESEND: 'पुन: भेजें',
  CONFIRM_PASSWORD: 'पासवर्ड की पुष्टि कीजिये',
  PASSWORD_CONFIRMATION_IS_REQUIRED: 'पासवर्ड पुष्टिकरण आवश्यक है',
  ENTER_OTP: 'ओटीपी दर्ज करें',
  MOBILE_NUMBER_NOT_REGISTERED: 'मोबाइल नंबर पंजीकृत नहीं है',
  ENTER_ANOTHER_NUMBER: 'दूसरा नंबर दर्ज करें!',
  ERROR: 'गलती',
  SOMETHING_WENT_WRONG: 'कुछ गलत हो गया!',
  PLEASE_FILL_ALL_THE_DETAILS: 'कृपया सभी विवरण भरें',
  FAILURE: 'असफलता',
  FAILED_TO_SEND_OTP: 'ओटीपी भेजने में विफल!',
  SUCCESS: 'सफलता',
  NEW_PASSWORD_UPDATED_SUCCESSFULLY: 'नया पासवर्ड सफलतापूर्वक अपडेट किया गया!',
  FAILED_TO_UPDATE_NEW_PASSWORD: 'नया पासवर्ड अपडेट करने में विफल!',
  PLEASE_TRY_AFTER_SOMETIME: 'कृपया कुछ देर बाद प्रयास करें...',
  VISIT_OUR_WEBSITE: 'हमारी वेबसाइट पर पधारें',
  LOGOUT: 'लॉग आउट',
  SETTINGS: 'समायोजन',
  // DOWNLOAD_ON_THE : 'पर डाउनलोड करें',
  // APP_STORE : 'ऐप स्टोर',
  // GET_IT_ON : 'इसे लगाओ ',
  // GOOGLE_PLAY :'गूगल प्ले',
  // VISIT_OUR_WEBSITE: 'हमारी वेबसाइट पर पधारें',

  DOWNLOAD_ON_APP_STORE: 'ऐप स्टोर पर डाउनलोड करें',
  DOWNLOAD_ON_PLAY_STORE: 'प्ले स्टोर पर डाउनलोड करें',
  YOU_WILL_NOT_BE_ABLE_TO_REVERT_THIS: 'आप इसे वापस नहीं कर पाएंगे!',

  // Support Page
  SUPPORT: 'समर्थन',
  ENTER_YOUR_NAME: 'अपना नाम दर्ज करें ',
  PLEASE_ENTER_NAME: 'कृपया नाम दर्ज करें',
  ENTER_YOUR_EMAIL: 'अपना ईमेल दर्ज करें',
  PLEASE_FILL_IN_THE_EMAIL: 'कृपया ईमेल दर्ज करें',
  ENTER_VALID_EMAIL: 'मान्य ईमेल दर्ज करें ',
  ENTER_YOUR_PHONE: 'कृपया फ़ोन नंबर दर्ज करें',
  SUBJECT: 'विषय',
  PLEASE_ENTER_SUBJECT: 'कृपया विषय दर्ज करें',
  SUBJECT_ERROR_STATE: 'विषय कम से कम 10 अक्षर का होना चाहिए',
  MESSAGE: 'संदेश',
  PLEASE_FILL_THIS: 'कृपया संदेश दर्ज करें',
  MESSAGE_ERROR_STATE: 'संदेश कम से कम 100 अक्षर का होना चाहिए',
  I_AGREE_TO_THE: ' मैं करने के लिए सहमत हूं ',
  TERMS_AND_CONDITIONS: ' नियम और शर्तें ',
  AGREE_TERMS_AND_CONDITIONS: 'कृपया नियम व शर्तों की सहमति दें',
  WE_HAVE_RECEIVED_YOUR_EMAIL: 'हमें आपका ईमेल प्राप्त हुआ है, हम वापस प्राप्त करेंगे।',
  FAILED_TO_SEND_MESSAGE: 'संदेश भेजने में विफल!',
  SUBMIT_BUTTON: 'जमा करना',

  // Subscription Page
  ORDER_SUMMARY: 'आदेश सारांश',
  DISCOUNTED_AMOUNT: 'रियायती राशि',
  ACTUAL_AMOUNT: 'वास्तविक राशि',
  CGST_AND_FEES: 'सीजीएसटी और फीस',
  SGST_AND_FEES: 'एसजीएसटी और फीस',
  IGST_AND_FEES: 'आईजीएसटी और फीस',
  TOTAL: 'कुल',
  PAYMENT_METHOD: 'भुगतान विधि',
  NO_PAYMENT_METHOD_AVAILABLE: 'कोई भुगतान विधि उपलब्ध नहीं है',
  BILLING_INFORMATION: 'बिलिंग जानकारी',
  NOTE_INVOICE: 'नोट: यदि आप चालान की प्रतिलिपि चाहते हैं तो कृपया अपना ईमेल अपडेट करें',
  SAVE_DETAILS: 'विवरण सहेजें',
  ROUND_OF_TO_NEARBY: 'पास का चक्कर',
  UPDATE_FAILED: 'डेटा अद्यतन करने में विफल',
  FREE: 'मुफ्त',
  PER_MONTH: 'प्रति महीने ',
  YOU_CAN_UPGRADE: 'आप अपग्रेड कर सकते हैं',
  NOT_NOW: 'अभी नहीं',
  UPGRADE: 'उन्नत करना',
  UPGRADE_YOUR_PLAN: 'अपनी योजना अपग्रेड करें',
  TO_ACCESS: 'उपयोग करने के लिए',
  PLEASE_UPGRADE_YOUR_SUBSCRIPTION: 'कृपया अपनी सदस्यता अपग्रेड करें',
  RENEW: 'नवीकरण',
  BUY_NOW: 'अभी खरीदें',

  // Profile Modal
  MY_INFO: 'मेरी जानकारी',
  PROFILE_SETTINGS: 'पार्श्वचित्र समायोजन',
  CHANGE_PASSWORD: 'पासवर्ड बदलें',
  ACCOUNT_ACTIVITY: 'खाता संबंधी काम',
  FIRST_NAME: 'पहला नाम',
  FIRST_NAME_IS_REQUIRED: 'पहला नाम आवश्यक है',
  LAST_NAME: 'उपनाम',
  LAST_NAME_IS_REQUIRED: 'अंतिम नाम आवश्यक है',
  EMAIL: 'ईमेल',
  EMAIL_IS_REQUIRED: 'ईमेल की जरूरत है',
  COUNTRY_IS_REQUIRED: 'देश आवश्यक है',
  STATE_LIST: 'राज्य सूची',
  SELECT: 'चुनना',
  SELECT_STATE: 'राज्य चुनें',
  OCCUPATION: 'पेशा',
  OCCUPATION_IS_REQUIRED: 'व्यवसाय आवश्यक है',
  DATE_OF_BIRTH: 'जन्म की तारीख',
  DATE_OF_BIRTH_IS_REQUIRED: 'जन्मतिथि आवश्यक है',
  SAVE: 'बचाना',
  DATE_FORMAT: 'तारिख का प्रारूप',
  DATE_FORMAT_IS_REQUIRED: 'दिनांक प्रारूप आवश्यक है',
  TIME_FORMAT: 'समय स्वरूप',
  TIME_FORMAT_IS_REQUIRED: 'टाइमफ़ॉर्मेट आवश्यक है',
  LANGUAGE: 'भाषा',
  LANDING_PAGE: 'लैंडिंग पृष्ठ',
  LANDING_PAGE_IS_REQUIRED: 'लैंडिंगपेज आवश्यक है',
  TIME_ZONE: 'समय क्षेत्र',
  TIME_ZONE_IS_REQUIRED: 'समय क्षेत्र आवश्यक है',
  SHOW_WEB_NOTIFICATION: 'वेब अधिसूचना दिखाएँ',
  CURRENT_PASSWORD: 'वर्तमान पासवर्ड',
  NEW_PASSWORD: 'नया पासवर्ड',
  CONFIRM_NEW_PASSWORD: 'नए पासवर्ड की पुष्टि करें',
  DEACTIVATE_ACCOUNT: 'खाता निष्क्रिय करें',
  DEACTIVATE_YOUR_ACCOUNT: 'अपना खाता अस्थायी रूप से निष्क्रिय करें. आप बाद में इसे सक्रिय कर सकते हैं और कॉफीवेब से जुड़ सकते हैं.',
  YES_DEACTIVATE: 'हाँ, निष्क्रिय करें',
  DELETE_ACCOUNT: 'खाता हटा दो',
  DELETE_YOUR_ACCOUNT: 'यदि आप खाता हटा देते हैं, तो इसे वापस प्राप्त करना संभव नहीं है। सुनिश्चित करें कि आप ऐसा करना चाहते हैं.',
  YES_DELETE: 'हाँ, हटाएँ',
  ON: 'चालू',
  OFF: 'बंद',
  PASSWORD_AND_CONFIRM_PASSWORD_SHOULD_BE_SAME: 'पासवर्ड और कन्फर्म पासवर्ड एक ही होना चाहिए',
  PASSWORD_CHANGED_SUCCESSFULLY: 'पासवर्ड सफलतापूर्वक बदला गया!',
  UPDATED_SUCCESSFULLY: 'सफलतापूर्वक अपडेट किया गया',
  EMAIL_ALREADY_EXISTS: 'ईमेल पहले से ही मौजूद है',
  ENTER_ANOTHER_EMAIL: 'दूसरा ईमेल दर्ज करें',
  DATA_UPDATED_SUCCESSFULLY: 'डेटा सफलतापूर्वक अपडेट किया गया',
  FAILED: 'असफल',
  ARE_YOU_SURE: 'क्या आपको यकीन है?',
  IF_YOU_DELETE_ACCOUNT: 'यदि आप खाता हटा देते हैं, तो उसे वापस पाना संभव नहीं है। सुनिश्चित करें कि आप ऐसा करना चाहते हैं।',
  ACCOUNT_DELETED_SUCCESSFULLY: 'खाता सफलतापूर्वक हटा दिया गया',
  TRY_AGAIN_AFTER_SOMETIME: 'कुछ देर बाद पुनः प्रयास करें!',
  ACCOUNT_DEACTIVATED: 'खाता निष्क्रिय कर दिया गया',
  OTP_IS_EXPIRED: 'ओटीपी समाप्त हो गया है',
  CLICK_ON_RESEND_TO_GET_NEW_OTP: 'नया ओटीपी प्राप्त करने के लिए पुनः भेजें पर क्लिक करें',

  // Coffee News Feed
  COFFEE_NEWS_FEEDS: 'कॉफ़ी समाचार फ़ीड',
  BUY_SUBSCRIPTION: 'सदस्यता खरीदें',
  DAILY_FEED: 'दैनिक फ़ीड',
  MY_FAVOURITE: 'मेरा पसंदीदा',
  SEARCH: 'खोज',
  SEARCH_FOR_NEWS: 'समाचार खोजें',
  READ_MORE: 'और पढ़ें',
  LOADING: 'लोड हो रहा है...',
  SELECT_LANGUAGE: 'भाषा चुने',
  ARE_YOU_SURE_YOU_WANT_TO_DISLIKE_THIS_NEWS: 'क्या आप वाकई इस खबर को नापसंद करना चाहते हैं?',
  NO_DATA_FOUND: 'डाटा प्राप्त नहीं हुआ',
  SEE_MORE: 'और देखें',

  // News Read More
  GO_BACK: 'वापस जाओ',
  SHARE: 'शेयर करना',
  COFFEE_NEWS_DATA_DOWNLOAD: 'कॉफ़ीन्यूज़डेटा डाउनलोड',
  DOWNLOAD_DOCUMENT: 'दस्तावेज़ डाउनलोड करें',
  VIEW_DOCUMENT: 'दस्तावेज़ देखें',

  // Share News Feed
  COPY: 'कॉपी',
  WHATS_APP: 'WhatsApp',
  FACEBOOK: 'फेसबुक',
  LINKED_IN: 'LinkedIn',
  TWITTER: 'ट्विटर',
  TELEGRAM: 'Telegram',
  MAIL: 'मेल',

  // Coffee Quotes
  VOL: 'वॉल्यूम',
  HIGH: 'उच्च',
  LOW: 'कम',
  OPEN: 'खुला',
  BID: 'बोली',
  ASK: 'पूछना',
  OPEN_INT: 'इंट खोलें',
  NET_HIGH: 'नेट-हाई',
  NET_LOW: 'नेट-कम',
  PRE_CLOSE: 'पूर्व करीब',
  BSIZE: 'बी-आकार',
  ASIZE: 'ए-आकार',
  OPTION_EXPIRY: 'विकल्प समाप्ति',
  FIRST_NOTICE_DAY: 'पहला नोटिस दिवस',
  LET_S_HELP: 'चलो मदद करते हैं',
  LAST: 'अंतिम',
  CHG: 'बदलाव',
  PLEASE_FILL_DIVIDER_NAME: 'कृपया विभाजक का नाम भरें',
  ROBUSTA_DIVIDER: 'रोबस्टा डिवाइडर',
  ARABICA_DIVIDER: 'अरेबिका डिवाइडर',
  ROBUSTA: 'रोबस्टा',
  ARABICA: 'arabica',
  ADD: 'जोड़ना',
  GRADE: 'श्रेणी',
  NET_PRICE: 'नेट कीमत',
  OUT_RATE: 'आउट रेट',
  DIFFERENTIAL: 'अंतर',
  TIME: 'समय',
  DATE: 'तारीख',
  TRENDING_NEWS: 'ट्रेंडिंग न्यूज़',
  ROBUSTA_CONTRACTS: 'रोबस्टा कॉन्ट्रैक्ट्स',
  ARABICA_CONTRACTS: 'अरेबिका कॉन्ट्रैक्ट्स',
  FX: 'एफएक्स',
  FX_PRE_DIS: 'एफएक्स प्रीडिस',
  FX_NET: 'एफएक्स नेट',
  GROSS_PRICE: 'सकल मूल्य',
  PROFIT: 'लाभ',
  DELETE: 'मिटाना',
  CONVTO: 'convto',
  ROBUSTA_WORKBOOK: 'रोबस्टा वर्कबुक',
  ARABICA_WORKBOOK: 'अरेबिका वर्कबुक',
  CHANGES_UPDATED_SUCCESSFULLY: 'परिवर्तन सफलतापूर्वक अपडेट किए गए!',
  YES_DELETE_IT: 'हाँ, इसे हटा दें!',
  MARKET_WORK_DELETED_SUCCESSFULLY: 'मार्केटवर्क सफलतापूर्वक हटा दिया गया!',
  YOUR_MARKET_WORKBOOK_HAS_BEEN_REMAINS_SAFE: 'आपकी मार्केटवर्कबुक सुरक्षित बनी हुई है!',
  DELETE_FAILURE: 'विफलता हटाएँ!',
  FAILED_TO_GET_DATA: 'डेटा_प्राप्त_करने में विफल',
  SAVE_ALL_CHANGES: 'सभी परिवर्तनों को सहेजें',
  SELECT_ALL_ROBUSTA_WORKBOOK: 'सभी रोबस्टा कार्यपुस्तिकाएँ चुनें',
  SELECT_ALL_ARABICA_WORKBOOK: 'सभी अरेबिका कार्यपुस्तिकाएँ चुनें',
  DELETE_SELECTED_WORKBOOK_DATA: 'चयनित कार्यपुस्तिका डेटा हटाएँ',

  // Global Differentials
  GLOBAL_DIFFERENTIALS: 'वैश्विक विभेदक',
  SELECT_ORIGIN: 'उत्पत्ति का चयन करें',
  CROP_YEAR: 'फसल वर्ष',
  UPDATED_ON: 'को अपडेट',
  PRICES_BASED_ON_FOB: 'कीमतें एफओबी पर आधारित हैं',
  QUALITY: 'गुणवत्ता',
  DIFFERENTIALS: 'भिन्नता',
  QUALITY_STANDARDS: 'गुणवत्ता के मानक',
  REMARKS: 'टिप्पणी',
  DELIVERY_PORT: 'डिलिवरी पोर्ट',
  FOREX_LEVEL: 'विदेशी मुद्रा स्तर',
  SIZE: 'आकार',
  DIFFERENTIALS_BASED_ON_FOB: 'एफओबी के आधार पर अंतर',
  SL: 'क्र',
  SCREEN: 'स्क्रीन',
  BETWEEN: 'बीच में',
  MOISTURE: 'नमी',
  SCREEN_RETENTION: 'स्क्रीन प्रतिधारण',
  DEFECTS: 'दोष के',
  NO_DATA_FOUND_FOR_THIS_COUNTRY: 'इस देश के लिए कोई डेटा नहीं मिला!',
  RANGE_STARTS: 'रेंज प्रारंभ',
  RANGE_ENDS: 'सीमा समाप्त',
  QUALITY_NAME: 'गुणवत्ता का नाम',
  TERMINAL: 'टर्मिनल',
  TO_ACCESS_THIS_INFORMATION_FOR_GLOBAL_DIFFERENTIAL: 'इस जानकारी तक पहुंचने के लिए, कृपया एक खाता बनाएं या लॉग इन करें। यह डेटा विशेष रूप से प्लेटिनम उपयोगकर्ता के लिए उपलब्ध है',

  // Error Boundary
  YOUR_SESSION_HAS_EXPIRED: 'आपका सत्र समाप्त हो गया है',
  PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING_THE_APP: 'ऐप का उपयोग जारी रखने के लिए कृपया दोबारा लॉग इन करें',
  RE_LOGIN: 'पुनः लॉगिन',
  TRY_AGAIN: 'पुनः प्रयास करें',

  // Footer
  COPYRIGHT: '© कॉपीराइट  ',
  COFFEE_WEB: 'कॉफ़ीवेब टेक्नोलॉजीज प्राइवेट लिमिटेड.',
  ALL_RIGHTS_RESERVED: 'सर्वाधिकार सुरक्षित '
}

export default HI_LANGUAGES
