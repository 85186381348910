import './styles.scss'
import React, { useState } from 'react'
import { AppLoader } from 'Assets/Icons'

const Loader = (WrappedComponent) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(false)

    const setLoadingState = (isComponentLoading) => {
      setLoading(isComponentLoading)
    }

    return (
      <div className="loaderHOC">
        {isLoading && (
          <div className="loader-wrapper">
            <AppLoader className="loader" />
          </div>
        )}
        <WrappedComponent {...props} setLoading={setLoadingState} isLoading={isLoading} />
      </div>
    )
  }

  return HOC
}

export default Loader
