import './styles.scss'
import Modal from 'react-bootstrap/Modal'
import { Logo, devEnvLogo } from 'Assets/Icons'
import { warningIcon } from 'Assets/Images'
import { useTranslation } from 'react-i18next'

export default function AlreadyLoggedIn({ showLogoutPopup, logoutUser }) {
  const { i18n, t } = useTranslation()

  return (
    <div>
      <Modal show={showLogoutPopup}>
        <Modal.Header className="login-warning-modal">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>

        <Modal.Body>
          <div className="modalBody">
            <div>
              <img src={warningIcon} alt="warning Icon" />
            </div>
            <div>
              <h5>{t('ALREADY_LOGGED_IN')}</h5>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={logoutUser}>{t('OK')}</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
