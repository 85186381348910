import React, { useEffect, useState } from 'react'
import './styles.scss'
import { useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import ErrorIcon from '@mui/icons-material/Error'
import { Modal } from 'react-bootstrap'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import { coffeewebStorageKeys, coffeewebGetLocal, coffeewebSetLocal, updateUserDetails } from 'Utils/LocalStorage_Handler'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Logo, devEnvLogo } from 'Assets/Icons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Loader from 'Components/LayoutComponents/Loader'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { useTranslation } from 'react-i18next'

function PaymentStatus({ setLoading, handleApiResponse }) {
  const location = useLocation()
  const { t } = useTranslation()
  const params = new URLSearchParams()
  const isPaymentSuccess = location.pathname.endsWith(ROUTE_STRINGS.success)
  const history = useHistory()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const newsId = coffeewebGetLocal(coffeewebStorageKeys.subscriptionNewsId)
  const payment = coffeewebGetLocal(coffeewebStorageKeys.payment)
  const payPalData = coffeewebGetLocal(coffeewebStorageKeys.paypalDetails)
  const { id: userId, states, city } = userDetails

  const [successfully, setSuccessfully] = useState(false)
  const [errorSuccessfully, setErrorSuccessfully] = useState(false)

  useEffect(() => {
    if (location.pathname === ROUTE_STRINGS.success) {
      if (payment) {
        stripePayment()
      } else if (payPalData) {
        captureOrderPaypal()
      } else {
        setSuccessfully(true)
      }
    } else {
      resetLocalStorage()
    }
  }, [])

  const stripePayment = async () => {
    try {
      const { amount, paymentIntent, subName, paymentId, paymentSource } = payment

      const createStripeOrder = {
        order: {
          userId,
          billingAddress: 'string',
          shippingAddress: 'string',
          states,
          city,
          totalQuantity: 1,
          totalAmount: amount,
          paymentDate: '2022-12-24T04:51:11.228Z',
          orderDate: '2022-12-24T04:51:11.228Z',
          payOrderId: paymentIntent,
          subscriptionId: subName,
          facilitatorAccessToken: paymentId,
          paymentId: 2,
          paymentSource,
          paymentStatus: 'Success',
          orderSatus: 2
        },
        orderDetails: [
          {
            idOrderDetail: 0,
            orderId: 0,
            planId: 'string',
            serviceName: 'string',
            subscriptionType: paymentSource,
            quantity: 0,
            rate: 0
          }
        ]
      }

      const response = await apiAdapterCoffeeWeb.createOrder(createStripeOrder)
      const data = response.data

      if (data && data.isSuccess) {
        const { idOrder: orderId, payOrderId, paymentSource, subscriptionId } = response.data.returnLst.order

        await stripeOrderUpdate(orderId, payOrderId, paymentSource, subscriptionId)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('SORRY_FAILED_TO_CREATE_PAYMENT'), onRetry: () => stripePayment() })
    }
  }

  const stripeOrderUpdate = async (orderId, paymentId, paymentSource, subscriptionId) => {
    setLoading(true)

    const UpdateOrder = {
      updateOrderDTO: {
        idOrder: orderId,
        payOrderId: paymentId,
        subscriptionId,
        facilitatorAccessToken: 'string',
        paymentSource,
        paymentStatus: 'Success',
        orderSatus: 2
      },
      userGSTDetails: {
        idUserGstdetails: 0,
        userId: 0,
        organistationName: 'NA',
        organistationAddress: 'NA',
        stateId: 0,
        gstno: 'NA',
        entryDate: '2023-05-25T12:19:02.839Z',
        lastUsed: false,
        lastUsedDtm: '2023-05-25T12:19:02.839Z',
        userName: 'NA',
        email: 'NA'
      }
    }

    try {
      await apiAdapterCoffeeWeb.UpdateOrder(UpdateOrder)
      setTimeout(() => {
        updateUserDetails
      }, 1000)
      coffeewebSetLocal(coffeewebStorageKeys.payment, null)
      setSuccessfully(true)
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('SORRY_FAILED_TO_UPDATE_PAYMENT'), onRetry: () => stripeOrderUpdate(orderId, payment, paymentSource, subscriptionId) })
    } finally {
      setLoading(false)
    }
  }

  const captureOrderPaypal = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.captureOrder({
        paymentId: payPalData.paymentId,
        uuid: payPalData.uuid
      })

      if (response.data.returnLst?.id === null) {
        setTimeout(updateUserDetails, 3000)
        coffeewebSetLocal(coffeewebStorageKeys.paypalDetails, null)
        setErrorSuccessfully(true)
      } else {
        payPalPayment(response.data.returnLst?.id, response.data.returnLst?.payment_source?.paypal?.account_id)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('SORRY_FAILED_TO_UPDATE_PAYMENT'), onRetry: () => captureOrderPaypal() })
    } finally {
      setLoading(false)
    }
  }

  const payPalPayment = async (paypalIds) => {
    try {
      const { amount, subName, uuid, paymentSource } = payPalData

      const createPayPalOrder = {
        order: {
          userId,
          billingAddress: 'string',
          shippingAddress: 'string',
          states,
          city,
          totalQuantity: 1,
          totalAmount: amount,
          paymentDate: '2022-12-24T04:51:11.228Z',
          orderDate: '2022-12-24T04:51:11.228Z',
          payOrderId: paypalIds,
          subscriptionId: subName,
          facilitatorAccessToken: uuid,
          paymentId: 2,
          paymentSource,
          paymentStatus: 'Success',
          orderSatus: 2
        },
        orderDetails: [
          {
            idOrderDetail: 0,
            orderId: 0,
            planId: 'string',
            serviceName: 'string',
            subscriptionType: paymentSource,
            quantity: 0,
            rate: 0
          }
        ]
      }

      const response = await apiAdapterCoffeeWeb.createOrder(createPayPalOrder)

      if (response.data.isSuccess) {
        const { idOrder: orderID, payOrderId: payOrderID, subscriptionId, facilitatorAccessToken } = response.data.returnLst.order

        await orderUpdatePayPal(orderID, payOrderID, paymentSource, subscriptionId, facilitatorAccessToken)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('SORRY_FAILED_TO_CREATE_PAYMENT'), onRetry: () => payPalPayment(paypalIds) })
    }
  }

  const orderUpdatePayPal = async (orderId, paymentId, paymentSource, subscriptionId, facilitatorAccessToken) => {
    const updateOrderDTO = {
      idOrder: orderId,
      payOrderId: paymentId,
      subscriptionId,
      facilitatorAccessToken,
      paymentSource,
      paymentStatus: 'Success',
      orderSatus: 2
    }

    const userGSTDetails = {
      idUserGstdetails: 0,
      userId: 0,
      organistationName: 'NA',
      organistationAddress: 'NA',
      stateId: 0,
      gstno: 'NA',
      entryDate: '2023-05-25T12:19:02.839Z',
      lastUsed: false,
      lastUsedDtm: '2023-05-25T12:19:02.839Z',
      userName: 'NA',
      email: 'NA'
    }

    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.UpdateOrder({
        updateOrderDTO,
        userGSTDetails
      })

      if (response.data.statusCode === 200) {
        setTimeout(updateUserDetails, 3000)
        coffeewebSetLocal(coffeewebStorageKeys.paypalDetails, null)
        setSuccessfully(true)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: t('SORRY_FAILED_TO_UPDATE_PAYMENT'), onRetry: () => orderUpdatePayPal(orderId, paymentId, paymentSource, subscriptionId, facilitatorAccessToken) })
    } finally {
      setLoading(false)
    }
  }

  const resetLocalStorage = () => {
    coffeewebSetLocal(coffeewebStorageKeys.paypalDetails, null)
    coffeewebSetLocal(coffeewebStorageKeys.payment, null)
  }

  const backButtonFromSuccessFullPage = () => {
    if (newsId) {
      params.set('newsId', newsId)
      history.push({ pathname: ROUTE_STRINGS.coffeeNewsFeeds, search: params.toString() })
      coffeewebSetLocal(coffeewebStorageKeys.subscriptionNewsId, null)
    } else {
      window.location.href = userDetails.userLandingPageUrl
    }
  }

  const backButtonFromErrorPage = () => {
    window.location.href = 'subscription'
  }

  return (
    <div className="payment-status">
      {isPaymentSuccess ? (
        <div className="successpayment">
          <Modal show={successfully} size="md" style={{ margin: '100px auto auto auto' }}>
            <Modal.Header className="logoutModalHeader payment-status-modal">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>
            <Modal.Body>
              <div className="Thank-you">
                <div className="Thankyou-second-div">Thank You!</div>
                <div>
                  <CheckCircleIcon sx={{ color: '#16d716', width: '17px', height: '17px', marginTop: '-3px', marginRight: '4px' }} />

                  {t('PAYMENT_SUCCESS')}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button className="back-to-home-btn" onClick={backButtonFromSuccessFullPage}>
                  {' '}
                  {t('OK')}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal show={errorSuccessfully} size="md" style={{ margin: '100px auto auto auto' }}>
            <Modal.Header className="logoutModalHeader payment-status-modal">{process.env.REACT_APP_NODE_ENV === 'production' ? <Logo fill="#fff" stroke="#3598db" strokeWidth="1" aria-expanded className="coffeeWebLogoModal" /> : <img src={devEnvLogo} alt="Dev Logo" className="coffeeWebDevLogo" />}</Modal.Header>

            <Modal.Body>
              <div className="Please-try-again">
                <div className="Please-try-again-second-div"> {t('PLEASE_TRY_AGAIN')}</div>
                <div>
                  <ErrorIcon sx={{ color: 'red', width: '18px', height: '18px', marginTop: '-3px', marginRight: '4px' }} />
                  {t('PAYMENT_FAILED')}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="Back-to-home">
                <button className="Back-to-home-btn" onClick={backButtonFromErrorPage}>
                  {t('OK')}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div className="cancel">
          <div className="cancel-conatiner">
            <div className="cancel-payment">{t('PAYMENT_CANCELED')}</div>
            <div>
              <ErrorIcon sx={{ color: 'red', width: '18px', height: '18px', marginTop: '-3px', marginRight: '4px' }} />

              {t('PAYMENT_FAILED')}
            </div>
            <div>
              <Button color="error" variant="outlined" onClick={() => history.push('/dashboard/coffeenewsfeeds')}>
                {t('OK')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Loader(PaymentStatus)
