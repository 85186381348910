import './styles.scss'
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'
import Loader from 'Components/LayoutComponents/Loader'
import CountryCodeSelect from 'Components/CountryCodeSelect'
import { EyeFilled, EyeFilledOff, Closebtn } from 'Assets/Icons'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import Validation from 'Utils/Validation'
import ApiResponseHandler from 'Components/ApiResponseHandler'

const ResetPasswordModal = ({ setLoading, handleCloseModal, generatedToken }) => {
  const { t } = useTranslation()

  const [showOtpModal, setShowOtpModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState('91')
  const [selectedCountryId, setSelectedCountryId] = useState('102')
  const [minutes, setMinutes] = useState()
  const [seconds, setSeconds] = useState()

  const [errorState, setErrorState] = useState({
    phoneNo: false,
    password: false,
    confirmPassword: false,
    email: false,
    email_exist: false,
    phoneNoNotExist: false,
    phoneNoExistsApiStatus: false,
    countryListError: false,
    countryPhoneCodeError: false,
    otpSendError: false,
    otpSendApiStatus: false,
    otpMismatch: false,
    otpExpired: false,
    isUserInvalid: false,
    isFieldEmpty: false,
    otpVerificationFailed: false,
    updatePasswordFailed: false
  })
  const [userInputValues, setUserInputValue] = useState({
    email: '',
    phoneNo: '',
    password: '',
    confirmPassword: '',
    otp: '',
    sessionId: ''
  })
  const [userIdValue, setUserIdValue] = useState({
    userId: ''
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  const verifyMobileThenProceed = async () => {
    const { phoneNo } = userInputValues
    const phoneNumberLength = phoneNo.length

    if (phoneNumberLength >= 6) {
      setLoading(true)
      try {
        const { data } = await apiAdapterCoffeeWeb.isPhoneExist({ phoneNo, countryPhoneCode: selectedCountryCode })

        if (data) {
          getAndUpdateUserId(phoneNo)
        } else {
          setErrorState((previousState) => ({ ...previousState, phoneNoNotExist: true, phoneNo: false }))
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        setErrorState((previousState) => ({ ...previousState, phoneNoExistsApiStatus: true, phoneNo: false }))
      }
    } else {
      setErrorState((previousState) => ({ ...previousState, phoneNo: true }))
    }
  }

  const sendOtpAndShowModal = async (phoneNumber) => {
    const concatenatedPhoneNumber = selectedCountryCode + phoneNumber

    try {
      const response = await apiAdapterCoffeeWeb.sendOtpWithTemplate(concatenatedPhoneNumber)
      const {
        status: responseStatus,
        data: { status }
      } = response

      if (responseStatus === 200) {
        if (status === 'Success') {
          setShowOtpModal(true)
          setUserInputValue((previousValue) => ({ ...previousValue, sessionId: response?.data?.details, otp: '', password: '', confirmPassword: '' }))
          setLoading(false)
          handleOtpTimer()
        } else {
          setErrorState((previousState) => ({ ...previousState, otpSendApiStatus: true }))
          setLoading(false)
        }
      } else {
        setErrorState((previousState) => ({ ...previousState, otpSendApiStatus: true }))
        setLoading(false)
      }
    } catch (error) {
      setErrorState((previousState) => ({ ...previousState, otpSendApiStatus: true }))
    } finally {
      setLoading(false)
    }
  }

  const getAndUpdateUserId = async (phone) => {
    try {
      const response = await apiAdapterCoffeeWeb.getUserByPhone({ phone, selectedCountryId })

      if (response.status === 200) {
        setUserIdValue((previousValue) => ({ ...previousValue, userId: response?.data?.id }))
        sendOtpAndShowModal(phone)
      } else {
        setErrorState((previousState) => ({ ...previousState, isUserInvalid: true }))
      }
    } catch (error) {
      setErrorState((previousState) => ({ ...previousState, isUserInvalid: true }))
    } finally {
      setLoading(false)
    }
  }

  const handleOtpSubmit = () => {
    const { otp, password, confirmPassword, sessionId } = userInputValues

    if (sessionId === '') {
      setErrorState((previousState) => ({ ...previousState, otpExpired: true }))
    } else if (Validation.checkEmptySomeInputField(otp, password, confirmPassword)) {
      setErrorState((previousState) => ({ ...previousState, isFieldEmpty: true }))
    } else if (Validation.checkAllInputFieldsNotEmpty(otp, password, confirmPassword) && password !== confirmPassword) {
      setErrorState((previousState) => ({ ...previousState, confirmPassword: true }))
    } else if (Validation.checkAllInputFieldsNotEmpty(otp, password, confirmPassword) && password === confirmPassword) {
      setLoading(true)
      verifyOtpAndUpdatePassword(sessionId, otp, password)
    }
  }

  const verifyOtpAndUpdatePassword = async (sessionId, otp, password) => {
    const { userId } = userIdValue

    try {
      const response = await apiAdapterCoffeeWeb.verifyOtp({ sessionId, otp })
      const {
        status: responseStatus,
        data: { status }
      } = response

      if (responseStatus === 200) {
        if (status === 'Error') {
          setErrorState((previousState) => ({ ...previousState, otpMismatch: true }))
        } else {
          updateUserPassword(userId, password)
        }
      }
    } catch (error) {
      setErrorState((previousState) => ({ ...previousState, otpVerificationFailed: true }))
    } finally {
      setLoading(false)
    }
  }

  const updateUserPassword = async (userId, password) => {
    try {
      const { status } = await apiAdapterCoffeeWeb.updateUserPassword({ userId, password }, generatedToken)

      if (status === 200) {
        swal({
          title: t('SUCCESS'),
          text: t('NEW_PASSWORD_UPDATED_SUCCESSFULLY'),
          icon: 'success'
        })
        setSelectedCountryCode('91')
        setSelectedCountryId('102')
        setUserInputValue((previousValue) => ({ ...previousValue, phoneNo: '', otp: '', password: '', confirmPassword: '', sessionId: '' }))
        handleCloseModal()
      }
    } catch (error) {
      setErrorState((previousState) => ({ ...previousState, updatePasswordFailed: true }))
    } finally {
      setLoading(false)
    }
  }

  const getPhoneCode = (e, id) => {
    setSelectedCountryCode(e)
    setSelectedCountryId(id)
  }

  const handleOtpTimer = () => {
    setMinutes(4)
    setSeconds(59)
    setTimeout(() => {
      setUserInputValue({
        ...userInputValues,
        sessionId: ''
      })
    }, 299000)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const { password, confirmPassword } = userInputValues

    if (name === 'otp') {
      setUserInputValue((previousValue) => ({ ...previousValue, otp: value }))
      if (!value) {
        setErrorState((previousState) => ({ ...previousState, otp: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, otp: false, otpExpired: false, isUserInvalid: false, isFieldEmpty: false, otpVerificationFailed: false, updatePasswordFailed: false, otpMismatch: false }))
      }
    } else if (name === 'phoneNo') {
      setErrorState((previousState) => ({
        ...previousState,
        phoneNo: !value.trim(),
        phoneNoNotExist: false,
        phoneNoExistsApiStatus: false,
        otpSendError: false,
        otpSendApiStatus: false
      }))
      if ((!Number.isNaN(value) && Validation.phoneNumberValidation(value)) || value === '' || value === '-') {
        setUserInputValue({ ...userInputValues, phoneNo: value })
      }
    } else if (name === 'password') {
      setUserInputValue((previousValue) => ({ ...previousValue, password: value }))
      // if (value.length > 0) {
      //   setErrorState((previousState) => ({
      //     ...previousState,
      //     password: false,
      //     otpExpired: false,
      //     isUserInvalid: false,
      //     isFieldEmpty: false,
      //     otpVerificationFailed: false,
      //     updatePasswordFailed: false
      //   }))
      //   if (value === confirmPassword) {
      //     setErrorState((previousState) => ({
      //       ...previousState,
      //       confirmPassword: false,
      //       otpExpired: false,
      //       isUserInvalid: false,
      //       isFieldEmpty: false,
      //       otpVerificationFailed: false,
      //       updatePasswordFailed: false
      //     }))
      //   }
      // }

      setErrorState((previousState) => ({ ...previousState, password: !value, otpExpired: false, isUserInvalid: false, isFieldEmpty: false, otpVerificationFailed: false, updatePasswordFailed: false }))
      if (confirmPassword && value !== confirmPassword) {
        setErrorState((previousState) => ({ ...previousState, confirmPassword: false, confirmMismatchError: value !== confirmPassword, otpExpired: false, isUserInvalid: false, isFieldEmpty: false, otpVerificationFailed: false, updatePasswordFailed: false }))
      }
    } else if (name === 'confirmPassword') {
      setUserInputValue((previousValue) => ({ ...previousValue, confirmPassword: value }))

      setErrorState((previousState) => ({ ...previousState, confirmPassword: !value, confirmMismatchError: password && value !== password, otpExpired: false, isUserInvalid: false, isFieldEmpty: false, otpVerificationFailed: false, updatePasswordFailed: false }))
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    if (name === 'phoneNo') {
      if (!value.trim()) {
        setErrorState((previousState) => ({ ...previousState, phoneNo: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, phoneNo: false }))
      }
    }
    if (name === 'otp') {
      if (!value) {
        setErrorState((previousState) => ({ ...previousState, otp: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, otp: false }))
      }
    }
    if (name === 'password') {
      if (!value) {
        setErrorState((previousState) => ({ ...previousState, password: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, password: false }))
      }
    } else if (name === 'confirmPassword') {
      if (!value) {
        setErrorState((previousState) => ({ ...previousState, confirmPassword: true }))
      } else {
        setErrorState((previousState) => ({ ...previousState, confirmPassword: false }))
      }
    }
  }

  const togglePassword = () => {
    setShowPassword((previousValue) => !previousValue)
  }
  const toggleConfirmPassword = () => {
    setShowConfirmPassword((previousValue) => !previousValue)
  }

  return (
    <Modal show={true} size="" className="reset-password-popup" aria-labelledby="contained-modal-title-vcenter">
      {!showOtpModal ? (
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter" style={{ margin: 'auto' }}>
              <div style={{ fontSize: '20px' }}>{t('FORGET_PASSWORD_LABEL')}</div>
            </Modal.Title>
            <Closebtn fill="#50b3f6" style={{ cursor: 'pointer' }} onClick={() => handleCloseModal()} />
          </Modal.Header>
          <Modal.Body>
            <div className="reset-password-body">
              <div className="d-flex justify-content-center h-100">
                <div style={{ height: 'auto', background: 'none' }}>
                  <CountryCodeSelect Disabled={false} inputName={'phoneNo'} value={userInputValues.phoneNo} handleInputChange={handleChange} handleBlur={handleBlur} getPhoneCode={getPhoneCode} setErrorState={setErrorState} />
                  {errorState.phoneNo && (
                    <div disabled={false} className="text-danger-reset-modal one">
                      {t('VALID_PHONE_NUMBER_IS_REQUIRED')}
                    </div>
                  )}
                  {errorState.phoneNoNotExist && (
                    <div disabled={false} className="text-danger-reset-modal one">
                      {t('MOBILE_NUMBER_NOT_REGISTERED')}
                    </div>
                  )}
                  {errorState.phoneNoExistsApiStatus && (
                    <div disabled={false} className="text-danger-reset-modal one">
                      {t('FAILED_TO_VALIDATE_PHONE_NO')}
                    </div>
                  )}
                  {errorState.otpSendApiStatus && (
                    <div disabled={false} className="text-danger-reset-modal one">
                      {t('FAILED_TO_SEND_OTP')}
                    </div>
                  )}
                  {errorState.isUserInvalid && (
                    <div disabled={false} className="text-danger-reset-modal one">
                      {t('FAILED_TO_VERIFY_USER')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div translate="yes" className="forgot-password-Buttons">
              <button className="close-Button" onClick={() => handleCloseModal()}>
                {t('CLOSE')}
              </button>
              <button onClick={verifyMobileThenProceed} className="Request-otp-button">
                {t('REQUEST_FOR_OTP')}
              </button>
            </div>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter" style={{ margin: 'auto' }}>
              <div style={{ fontSize: '20px' }}>{t('FORGET_PASSWORD_LABEL')}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="d-flex justify-content-center h-100">
                <div style={{ height: 'auto', background: 'none' }}>
                  <div className="forgotPasswordOtpScreen">
                    <div>
                      <div className="row" style={{ margin: '10px' }}>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="OTP" className="form-label text-label" style={{ marginTop: '0px' }}>
                            {t('OTP')}
                            <span className="mandate emandate">*</span>
                          </label>
                          <div className="otp-text-box-design">
                            <div>
                              {' '}
                              <input type="text" name="otp" value={userInputValues.otp} onChange={handleChange} onBlur={handleBlur} maxLength={6} className="signup_form-control pass_flow" placeholder={t('ENTER_OTP')} />
                              {errorState.otp && <p className="text-danger ">{t('OTP_IS_REQUIRED')}</p>}
                            </div>
                            <div className="form-control login_form-control otp-timer-button">
                              {seconds > 0 || minutes > 0 ? (
                                <div className="otp-count-timer">
                                  {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                </div>
                              ) : (
                                <div className="otp-resend-button" onClick={() => sendOtpAndShowModal(userInputValues.phoneNo)}>
                                  {t('RESEND')}
                                </div>
                              )}
                            </div>
                            {errorState.otpMismatch && !errorState.otp && (
                              <div disabled={false} className="text-danger no-country-data">
                                {t('OTP_MISMATCH')}
                              </div>
                            )}
                            {errorState.otpExpired && !errorState.otp && (
                              <div disabled={false} className="text-danger no-country-data">
                                {t('OTP_EXPIRED')}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="txtPassword" className="form-label text-label">
                            {t('PASSWORD')}
                            <span className="mandate">*</span>
                          </label>
                          <div className="pass-wrapper">
                            <input type={showPassword ? 'text' : 'password'} name="password" value={userInputValues.password} onBlur={handleBlur} onChange={handleChange} className="signup_form-control pass_flow" placeholder={t('PASSWORD')} />
                            <span onClick={togglePassword} className="eye_icon">
                              {showPassword ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" />}
                            </span>
                          </div>
                          {errorState.password && <p className="text-danger">{t('PASSWORD_IS_REQUIRED')}</p>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="txtConfirmPassword" className="form-label text-label">
                            {t('CONFIRM_PASSWORD')}
                            <span className="mandate">*</span>
                          </label>
                          <div className="pass-wrapper">
                            <input type={showConfirmPassword ? 'text' : 'password'} placeholder={t('CONFIRM_PASSWORD')} name="confirmPassword" value={userInputValues.confirmPassword} onBlur={handleBlur} onChange={handleChange} className="signup_form-control pass_flow" />

                            <span onClick={toggleConfirmPassword} className="eye_icon">
                              {showConfirmPassword ? <EyeFilled /> : <EyeFilledOff className="eye-filled-off-icon" />}
                            </span>
                          </div>
                          {errorState.confirmPassword && <p className="text-danger">{t('PASSWORD_CONFIRMATION_IS_REQUIRED')}</p>}
                          {errorState.confirmMismatchError && !errorState.confirmPassword && !errorState.password && <p className="text-danger">{t('PASSWORD_AND_CONFIRM_PASSWORD_SHOULD_BE_SAME')}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {errorState.isFieldEmpty && !errorState.password && !errorState.confirmPassword && (
                <div disabled={false} className="text-danger-reset-modal">
                  {t('PLEASE_FILL_ALL_THE_DETAILS')}
                </div>
              )}
              {errorState.updatePasswordFailed && (
                <div disabled={false} className="text-danger-reset-modal">
                  {t('FAILED_TO_UPDATE_PASSWORD')}
                </div>
              )}
              {errorState.otpVerificationFailed && (
                <div disabled={false} className="text-danger-reset-modal">
                  {t('FAILED_TO_VERIFY_OTP')}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div translate="yes" className="forgot-password-Buttons" style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
              <button
                className="close-Button"
                onClick={() => {
                  setShowOtpModal(false)
                  setErrorState((previousState) => ({ ...previousState, otp: false, password: false, confirmPassword: false, confirmMismatchError: false, phoneNoNotExist: false, phoneNoExistsApiStatus: false, countryListError: false, countryPhoneCodeError: false, otpSendError: false, otpSendApiStatus: false, otpMismatch: false, otpExpired: false, isUserInvalid: false, isFieldEmpty: false, otpVerificationFailed: false, updatePasswordFailed: false }))
                }}
              >
                {t('CLOSE')}
              </button>
              <button onClick={handleOtpSubmit} className="submit-Button">
                {t('SUBMIT')}
              </button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default Loader(ApiResponseHandler(ResetPasswordModal))
