import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Clock from 'react-clock'
import { Dropdown } from 'primereact/dropdown'
import { Carousel } from 'primereact/carousel'
import { SelectButton } from 'primereact/selectbutton'
import { ClockSetting } from 'Assets/Icons'
import CloseIcon from '@mui/icons-material/Close'
import { coffeewebGetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import Loader from 'Components/LayoutComponents/Loader'
import { commonMethods } from 'Utils/commonMethods'
import ClockSettings from './Components/MyClockSettingsModal'

const AnalogClock = ({ setLoading, isLoading }) => {
  const { t } = useTranslation()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)

  let carouselValue

  const responsiveOptions = [
    {
      breakpoint: '1280px',
      numVisible: 5,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 5,
      numScroll: 3
    },
    {
      breakpoint: '1024px',
      numVisible: 5,
      numScroll: 3
    }
  ]

  const clockList = ['All Clock', 'My Favourite']
  const [page, setPage] = useState(0)
  const [clockDataForAllCountries, setClockDataForAllCountries] = useState([])
  const [favoriteCountryClock, setFavoriteCountryClock] = useState([])
  const [pickListSourceData, setPickListSourceData] = useState([])
  const [pickListTargetData, setPickListTargetData] = useState([])
  const [countryTime, setCountryTime] = useState({})
  const [digitalClockTime, setDigitalClockTime] = useState({})
  const [digitalTimeHour, setDigitalTimeHour] = useState({})
  const [digitalClock, setDigitalClock] = useState({})
  const [digitalPeriod, setDigitalPeriod] = useState({})
  const [countryDay, setCountryDay] = useState({})
  const [selectedCountry, setSelectedCountry] = useState({})
  const [filterCountry, setFilterCountry] = useState({})
  const [visible, setVisible] = useState(false)
  const [selectedCountryData, setSelectedCountryData] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [clockTab, setClockTab] = useState(() => localStorage.getItem('clockTab') || 'All Clock')
  const [selectedClockType, setSelectedClockType] = useState(() => localStorage.getItem('selectedClockDisplay') || 'Analog')
  const [timeFormat, setTimeFormat] = useState(() => localStorage.getItem('selectedTimeFormat') || '12-hour')

  useEffect(() => {
    setSelectedClockType(localStorage.getItem('selectedClockDisplay') || 'Analog')
  }, [localStorage.getItem('selectedClockDisplay')])

  useEffect(() => {
    setTimeFormat(localStorage.getItem('selectedTimeFormat') || '12-hour')
  }, [localStorage.getItem('selectedTimeFormat')])

  useEffect(() => {
    getAllCountryTimeDetails()

    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (clockDataForAllCountries.length) {
      const intervalID = setInterval(() => {
        getCurrentTime(clockDataForAllCountries)
      }, 1000)

      getGeolocation()

      return () => {
        clearInterval(intervalID)
      }
    }
  }, [clockDataForAllCountries])

  useEffect(() => {
    document.querySelector('body').style.overflow = visible ? 'hidden' : 'auto'
  }, [visible])

  const getAllCountryTimeDetails = async () => {
    setLoading(true)
    try {
      let userId
      let authToken

      if (userDetails) {
        const { id } = userDetails

        userId = id
      }

      if (!userDetails) {
        const {
          data: { token }
        } = await apiAdapterCoffeeWeb.generateAuthToken()

        authToken = token
      }
      const { data } = await apiAdapterCoffeeWeb.getCountryTimeZone({ userId: userId || 0 }, authToken)
      const favoriteCountries = data.filter((country) => country.isFavorite)
      const filteredCountry = data.filter(({ countryId }) => !favoriteCountries.map(({ countryId }) => countryId).includes(countryId))

      if (favoriteCountries?.length) {
        if (localStorage.getItem('clockTab') === 'All Clock') {
          localStorage.setItem('clockTab', 'All Clock')
          setClockTab('All Clock')
        } else {
          localStorage.setItem('clockTab', 'My Favourite')
          setClockTab('My Favourite')
        }
      }

      setClockDataForAllCountries(data)
      getCurrentTime(data)
      setFavoriteCountryClock(favoriteCountries)
      setPickListTargetData(favoriteCountries)
      setPickListSourceData(filteredCountry)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getCurrentTime = (clockData) => {
    const analogClockTime = {}
    const newWeekDay = {}
    const digitalTime = {}
    const digitalPeriod = {}
    const timeForDigitalClockClassName = {}
    const digitalClock24Hour = {}

    const getTime = (timeZone, options) => new Date().toLocaleTimeString('en-US', { timeZone, ...options })

    clockData.forEach((item) => {
      const options = {
        timeZone: item.timeZone,
        weekday: 'long',
        hour12: false
      }

      const digitalOptions = {
        timeZone: item.timeZone,
        hour12: true,
        hour: 'numeric',
        minute: 'numeric'
      }

      const options24Hour = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: item.timeZone
      }

      const currentTimeForAnalogClock = getTime(item.timeZone)
      const currentTimeForDigitalClock = getTime(item.timeZone, digitalOptions)
      const currentTime24Hour = getTime(item.timeZone, options24Hour)

      const [time, period] = currentTimeForDigitalClock.split(' ')
      const currentDay = new Date().toLocaleDateString('en-us', options)

      analogClockTime[item.countryName] = currentTimeForAnalogClock
      timeForDigitalClockClassName[item.countryName] = currentTimeForDigitalClock
      digitalClock24Hour[item.countryName] = currentTime24Hour
      digitalTime[item.countryName] = time
      digitalPeriod[item.countryName] = period
      newWeekDay[item.countryName] = currentDay
    })

    setCountryTime(analogClockTime)
    setDigitalClockTime(digitalTime)
    setDigitalPeriod(digitalPeriod)
    setCountryDay(newWeekDay)
    setDigitalClock(timeForDigitalClockClassName)
    setDigitalTimeHour(digitalClock24Hour)
  }

  const getClockClassName = (timeString) => {
    if (timeString) {
      const timeParts = timeString.split(':')
      const period = timeString.split(' ')

      const hours = parseInt(timeParts[0], 10)

      const isNightTime = (hours >= 8 && hours < 12 && period[1] === 'PM') || ((hours < 6 || hours === 12) && period[1] === 'AM')

      return isNightTime ? ` ${selectedClockType === 'Digital' ? 'digital-clock-dark' : 'analog-clock-dark'}` : ` ${selectedClockType === 'Digital' ? 'digital-clock-light' : 'analog-clock-light'}`
    }

    return ''
  }

  const getGeolocation = async () => {
    setLoading(true)

    try {
      const locationResponse = await commonMethods.searchLocation()
      const currentCountry = clockDataForAllCountries?.find((item) => item?.countryName === locationResponse?.country || item?.countryName === locationResponse?.region || item?.countryName === locationResponse?.name)

      setSelectedCountry(currentCountry)
    } catch (error) {
      setSelectedCountry({
        countryName: 'India',
        flagURL: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/IND.svg',
        gmtOffSet: 'UTC +05:30',
        roundOffSet: '+5.5',
        currentTime: '2024-03-14T15:36:57.8199849Z',
        timeZone: 'Asia/Kolkata'
      })
    } finally {
      setLoading(false)
    }
  }

  const onClockTabChange = (event) => {
    setPage(0)
    handleClearFilter()

    const selectedClockTab = event.target.value

    setClockTab(selectedClockTab)
    localStorage.setItem('clockTab', selectedClockTab)
  }

  const handleAddButtonClick = () => {
    handleClearFilter()

    setVisible(true)
  }

  const handleCountrySelection = (selectedCountry) => {
    setPage(0)

    const filteredCountry = clockDataForAllCountries?.filter((country) => country?.countryName === selectedCountry?.countryName)

    setSelectedCountryData(filteredCountry?.length > 0 ? filteredCountry[0] : null)
  }

  const displayButton = clockDataForAllCountries.some((ele) => ele.isFavorite)

  if (selectedCountryData) {
    carouselValue = [selectedCountryData]
  } else {
    carouselValue = clockTab === 'All Clock' ? clockDataForAllCountries : favoriteCountryClock
  }

  const handleClearFilter = () => {
    setFilterCountry({})
    setSelectedCountryData(null)
  }

  const pageChange = (event) => {
    setPage(event.page)
  }

  const productTemplate = (item) =>
    selectedClockType === 'Analog' ? (
      <div className="clock-container">
        <div className="clock-content">
          <div className="clock-details-info">
            <div className="country-name-info">{item.countryName}</div>
            <Clock value={countryTime[item.countryName]} renderNumbers={false} hourMarksLength={10} minuteMarksLength={4} size={windowWidth <= 1024 ? 90 : 140} className={getClockClassName(countryTime[item.countryName])} />
            <img src={item.flagURL} alt={`${item.countryName} Flag`} className="country-flag-info" />
          </div>
          <div className="time-details-container">
            <div className="weekday-info">{countryDay[item.countryName]}</div>

            <div className="current-time-info">
              {digitalClockTime[item.countryName]} {digitalPeriod[item.countryName]}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <>
        <div className="digital-clock-container">
          <div className="digital-clock-country-details">
            <div className="digital-country-name-info">{item.countryName}</div>
          </div>
          <div className="digital-clock-wrapper">
            <div className="digital-clock">
              <div className={`digital-clock-item ${getClockClassName(digitalClock[item.countryName])}`}>
                <div className="digital-clock-time-wrapper">
                  {timeFormat === '12-hour' ? (
                    <>
                      <div className="digital-time-info">{digitalClockTime[item.countryName]}</div>
                      <div className="digital-period-info">{digitalPeriod[item.countryName]}</div>
                    </>
                  ) : (
                    <>
                      <div className="digital-time-info">{digitalTimeHour[item.countryName]}</div>
                    </>
                  )}
                </div>
                <div className="country-flag-wrapper">
                  <img src={item.flagURL} alt={`${item.countryName} Flag`} className="country-flag-info" />
                </div>
              </div>
            </div>
          </div>
          <div className="digital-weekday-info">{countryDay[item.countryName]}</div>
        </div>
      </>
    )

  return (
    <div className="country-clock-container">
      {userDetails && (
        <div className="search-container">
          <div className="select-button-wrapper">{displayButton && <SelectButton value={clockTab} onChange={onClockTabChange} options={clockList} className="select-button" allowEmpty={false} />}</div>
          <div className="search-country-wrapper">
            <div className="country-dropdown-wrapper">
              <Dropdown
                value={filterCountry}
                filter
                resetFilterOnHide={true}
                filterPlaceholder={t('SEARCH')}
                onChange={(e) => {
                  setFilterCountry(e.value)
                  handleCountrySelection(e.value)
                }}
                options={clockTab === 'All Clock' ? clockDataForAllCountries : favoriteCountryClock}
                optionLabel="countryName"
                placeholder={t('SEARCH_COUNTRY')}
                className="drop-down-field"
              />
              {selectedCountryData && (
                <div className="clear-icon" onClick={handleClearFilter}>
                  <CloseIcon />
                  <div className="close-icon-tool-tip">
                    <div>{t('CLOSE')}</div>
                  </div>
                </div>
              )}
            </div>
            <div className="setting-icon" onClick={handleAddButtonClick}>
              <ClockSetting />
              <div className="setting-tooltip">
                <div>{t('CLOCK_SETTINGS')}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {windowWidth >= 767 ? (
        <div className="carousel-wrapper">{clockDataForAllCountries.length > 0 ? <Carousel value={carouselValue} numVisible={5} numScroll={3} itemTemplate={productTemplate} showIndicators={false} responsiveOptions={responsiveOptions} onPageChange={pageChange} page={page} /> : <div className="no-data-title"> {isLoading ? t('FETCHING') : t('NO_DATA_FOUND')} </div>}</div>
      ) : (
        <div className="mobile-view-container">
          {userDetails ? (
            <div className="mobile-view-header-wrapper">
              <div className={`${displayButton ? 'select-button-wrapper' : 'remove-button'}`}>
                <SelectButton value={clockTab} onChange={onClockTabChange} options={clockList} className="select-button" allowEmpty={false} />
              </div>

              <div className={`${displayButton ? 'setting-wrapper' : 'setting-button-container'}`}>
                <div className="dropdown-wrapper">
                  <Dropdown value={selectedCountry} onChange={(e) => setSelectedCountry(e.value)} filter resetFilterOnHide={true} filterPlaceholder={t('SEARCH')} options={clockTab === 'All Clock' ? clockDataForAllCountries : favoriteCountryClock} optionLabel="countryName" className="drop-down-field" placeholder={t('SELECT_COUNTRY')} />
                </div>
                <div className="setting-icon">
                  <ClockSetting onClick={handleAddButtonClick} />
                </div>
              </div>
            </div>
          ) : (
            <div className="guest-dropdown-wrapper">
              <Dropdown value={selectedCountry} onChange={(e) => setSelectedCountry(e.value)} filter resetFilterOnHide={true} filterPlaceholder={t('SEARCH')} options={clockDataForAllCountries} optionLabel="countryName" className="guest-drop-down-field" placeholder={t('SELECT_COUNTRY')} />
            </div>
          )}

          <div className="mobile-view-clock-wrapper">
            <div className="clock-content">
              <div className="clock-details-info">
                {selectedClockType === 'Analog' ? (
                  <>
                    {selectedCountry?.countryName ? (
                      <>
                        <Clock value={countryTime[selectedCountry?.countryName]} renderNumbers={false} hourMarksLength={10} minuteMarksLength={4} size={160} className={getClockClassName(countryTime[selectedCountry?.countryName])} />
                        <img src={selectedCountry?.flagURL} alt={`${selectedCountry?.countryName} Flag`} className="country-flag-info" />
                      </>
                    ) : (
                      <div>{isLoading ? t('FETCHING') : t('NO_DATA_FOUND')}</div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="digital-clock-container">
                      <div className="digital-clock-wrapper">
                        <div className="digital-clock">
                          <div className={`digital-clock-item ${getClockClassName(digitalClock[selectedCountry?.countryName])}`}>
                            <div className="digital-clock-time-wrapper">
                              {timeFormat === '12-hour' ? (
                                <>
                                  <div className="digital-time-info">{digitalClockTime[selectedCountry?.countryName]}</div>
                                  <div className="digital-period-info">{digitalPeriod[selectedCountry?.countryName]}</div>
                                </>
                              ) : (
                                <>
                                  <div className="digital-time-info">{digitalTimeHour[selectedCountry?.countryName]}</div>
                                </>
                              )}
                            </div>
                            <div className="country-flag-wrapper">
                              <img src={selectedCountry?.flagURL} alt={`${selectedCountry?.countryName} Flag`} className="country-flag-info" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="time-details-container">
                <div className="weekday-info">
                  {countryDay[selectedCountry?.countryName]}
                  {countryDay[selectedCountry?.countryName] && ','}
                </div>
                <div className="current-time-info">{digitalClockTime[selectedCountry?.countryName]}</div>
                <div className="current-time-info">{digitalPeriod[selectedCountry?.countryName]}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="clock-setting-layout">
        <ClockSettings visible={visible} setVisible={setVisible} pickListSourceData={pickListSourceData} setPickListSourceData={setPickListSourceData} favoriteCountryClockData={favoriteCountryClock} setFavoriteCountryClock={setFavoriteCountryClock} pickListTargetData={pickListTargetData} setPickListTargetData={setPickListTargetData} setClockDataForAllCountries={setClockDataForAllCountries} setClockTab={setClockTab} />
      </div>
    </div>
  )
}

export default Loader(AnalogClock)
