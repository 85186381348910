/*eslint-disable*/
import './styles.scss'
import React, { Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { RouteStrings } from 'Utils/Routes/RouteStrings'
import { ROUTE_STRINGS } from 'Utils/Constants'
import CoffeeNewsFeeds from 'Screens/CoffeeNewsFeeds'
import CoffeeWebContextProvider from 'Context/CoffeeWebContext'
import PaymentGateWay from 'Screens/Subscription/Pages/PaymentGateway'
import SupportRequest from 'Screens/SupportRequest'
import PageNotFound from 'Screens/PageNotFound'
import Subscription from 'Screens/Subscription'
import OpenSharedNews from 'Screens/NewsFeedLink/Pages/OpenSharedNews'
import OpenNewsNotification from 'Screens/Notification/Pages/OpenNewsNotification'
import Notification from 'Screens/Notification'
import PaymentStatus from 'Screens/Subscription/Pages/PaymentStatus'
import Menubar from '../Menubar'
import Header from '../Header'
import FooterDashboard from '../Footer/FooterDashboard'
import Loader from '../Loader'
import CoffeeQuotes from 'Screens/CoffeeQuotes'
import GlobalDifferentials from 'Screens/GlobalDifferentials'
import AboutUs from 'Screens/AboutUs'
// import PreviewPdf from 'Components/PreviewPdf'

const Routes = () => {
  return (
    <>
      <CoffeeWebContextProvider>
        <Suspense fallback={Loader}>
          <div className="dashboard">
            <Header isLoggedIn={true} />
            <Menubar />
            <Switch>
              <Route exact path="/dashboard"></Route>
              <Route exact path={ROUTE_STRINGS.coffeeNewsFeeds} component={CoffeeNewsFeeds} />
              <Route exact path={ROUTE_STRINGS.coffeequotes} component={CoffeeQuotes} />
              <Route exact path={RouteStrings.notification} component={Notification} />
              <Route exact path={RouteStrings.opennewsnotification} component={OpenNewsNotification} />
              <Route exact path={RouteStrings.opensharednews} component={OpenSharedNews} />
              {/* <Route exact path={RouteStrings.previewpdf} component={PreviewPdf} /> */}
              <Route exact path={ROUTE_STRINGS.subscription} component={Subscription} />
              <Route exact path={ROUTE_STRINGS.paymentgateway} component={PaymentGateWay} />
              <Route exact path={ROUTE_STRINGS.success} component={PaymentStatus} />
              <Route exact path={ROUTE_STRINGS.cancel} component={PaymentStatus} />
              <Route exact path={ROUTE_STRINGS.support} component={SupportRequest} />
              <Route exact path={ROUTE_STRINGS.globaldifferentials} component={GlobalDifferentials} />
              <Route exact path={ROUTE_STRINGS.aboutUsCoffeeWeb} component={AboutUs} />
              <Route exact path={ROUTE_STRINGS.pnf} component={PageNotFound} />
              <Route path="*" component={PageNotFound} />
            </Switch>
            <FooterDashboard />
          </div>
        </Suspense>
      </CoffeeWebContextProvider>
    </>
  )
}

export default Routes
