export const configUrl = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  userService: process.env.REACT_APP_USER_SERVICE_BASE_URL,
  masterService: process.env.REACT_APP_MASTER_SERVICE_BASE_URL,
  authService: process.env.REACT_APP_AUTH_BASE_URL,
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  paymentService: process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL,
  baseUrlForCoffeeQuotes: process.env.REACT_APP_BASE_URL_FOR_COFFEE_QUOTES,
  notificationService: process.env.REACT_APP_NOTIFICATION_SERVICE_BASE_URL,
  communicationService: process.env.REACT_APP_COMMUNICATION_SERVICE_BASE_URL,
  differentialService: process.env.REACT_APP_DIFFERENTIAL_SERVICE_BASE_URL,
  newsService: process.env.REACT_APP_NEWS_SERVICE_BASE_URL,
  terminalDetailsService: process.env.REACT_APP_TERMINAL_DETAILS_SERVICE_BASE_URL,
  coffeeQuotesService: process.env.REACT_APP_COFFEE_QUOTES_BASE_URL,
  workbookService: process.env.REACT_APP_WORKBOOK_SERVICE_BASE_URL,
  razorpaySDKUrl: 'https://checkout.razorpay.com/v1/checkout.js'
}
