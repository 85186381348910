import './styles.scss'
import { React, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Closebtn } from 'Assets/Icons'
import { ModalBody } from 'react-bootstrap'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { useTranslation } from 'react-i18next'
import { useResizeHandler } from 'Utils/commonMethods'

const AccordionPopUp = ({ isDialogModalOpen, setIsDialogModalOpen, globalDifferentialHistoryData }) => {
  const totalSlides = globalDifferentialHistoryData?.length

  const { t } = useTranslation()

  const [slideIndex, setSlideIndex] = useState(0)
  const [currentSlideData, setCurrentSlideData] = useState(null)

  const { isWebView } = useResizeHandler()

  useEffect(() => {
    setCurrentSlideData(globalDifferentialHistoryData[0])
  }, [globalDifferentialHistoryData])

  const switchSlide = (type) => {
    if (type === 'forword') {
      const forwordIndex = slideIndex === totalSlides - 1 ? 0 : slideIndex + 1

      setSlideIndex(forwordIndex)
      setCurrentSlideData(globalDifferentialHistoryData[forwordIndex])
    } else {
      const reverseIndex = slideIndex === 0 ? totalSlides - 1 : slideIndex - 1

      setSlideIndex(reverseIndex)
      setCurrentSlideData(globalDifferentialHistoryData[reverseIndex])
    }
  }

  const currencyStyle = (value, nextValue) => {
    if (value) {
      const convertedToNumber = parseFloat(value?.replace(/[$¢]/g, ''))
      const nextConvertedToNumber = parseFloat(nextValue?.replace(/[$¢]/g, '')) || 0

      if (convertedToNumber >= nextConvertedToNumber) {
        return { color: 'green', fontWeight: 600 }
      }

      return { color: 'red', fontWeight: 600 }
    }

    return { color: 'black' }
  }

  return (
    <div>
      <Modal show={isDialogModalOpen} size="" className="global-differential-modal">
        <Modal.Header className="global-differential-modal-header">
          <Modal.Title id="contained-modal-title-vcenter" className="global-differential-modal-title"></Modal.Title>
          <Closebtn className="global-differential-modal_close_button" onClick={() => setIsDialogModalOpen(false)} />
        </Modal.Header>

        <ModalBody>
          <div className="global-differential-body">
            {globalDifferentialHistoryData?.length !== 1 ? (
              <div className="left-arrow" onClick={() => switchSlide('reverse')}>
                <DoubleArrowIcon className="left_icons" sx={{ color: '#50b3f6', width: '30px', height: '30px' }} />
              </div>
            ) : (
              <div className="empty-block"></div>
            )}

            <div className="slide-wrapper">
              <div className="slide-title">{currentSlideData?.label}</div>
              <div className="slide-content">
                {currentSlideData?.label === 'Previous Data' && (
                  <div className="history-data-slide">
                    {isWebView && (
                      <div className="history-table-table-wparrer">
                        <table>
                          <thead style={{ position: 'sticky', top: '0px' }}>
                            <tr>
                              <th colSpan={2}>
                                {t('COUNTRY')} : {currentSlideData?.country} <img src={currentSlideData?.flagUrl} alt="Flag" />
                              </th>
                              <th colSpan={4}>
                                {t('GRADE')} : {currentSlideData?.qualityName}
                              </th>
                              <th>{t('FOREX_LEVEL')}</th>
                            </tr>
                            <tr>
                              <th>{t('DATE')}</th>
                              <th>Crop Year</th>
                              <th>{t('TERMINAL')}</th>
                              <th>{t('TERMINAL_LEVEL')}</th>
                              <th colSpan={2}>
                                {t('DIFFERENTIALS')} {t('BETWEEN')}
                              </th>
                              <th>{currentSlideData?.forexType}</th>
                            </tr>
                            <div className="empty_div">{/* This is needed for table heading overlap */}</div>
                          </thead>
                          <tbody>
                            {currentSlideData?.data?.map(({ uploadedDtm, cropYear, terminal, terminalLevel, rangeStart, rangeEnd, forexValue }, index) => (
                              <tr key={index}>
                                <td>{uploadedDtm || '-'}</td>
                                <td>{cropYear || '-'}</td>
                                <td>{terminal || '-'}</td>
                                <td>{terminalLevel || '-'}</td>
                                <td style={currencyStyle(rangeStart, currentSlideData?.data[index + 1]?.rangeStart)}>{rangeStart || '-'}</td>
                                <td style={currencyStyle(rangeEnd, currentSlideData?.data[index + 1]?.rangeEnd)}>{rangeEnd || '-'}</td>
                                <td className="remark-cell">{forexValue || '-'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {!isWebView && (
                      <div className="mobile-view">
                        <div className="popup-header">
                          <div>
                            {t('COUNTRY')} : {currentSlideData?.country} <img src={currentSlideData?.flagUrl} alt="Flag" />
                          </div>
                          <div>
                            {t('GRADE')} : {currentSlideData?.qualityName}
                          </div>
                        </div>
                        <div className="history-data-cards">
                          {currentSlideData?.data?.map(({ uploadedDtm, cropYear, terminal, terminalLevel, rangeStart, rangeEnd, remarks, forexValue }, index) => (
                            <div className="each-card" key={index}>
                              <div className="each-row">
                                <span>{t('DATE')}: </span>
                                <div>{uploadedDtm || '-'}</div>
                              </div>
                              <div className="each-row">
                                <span>Crop Year: </span>
                                <div>{cropYear || '-'}</div>
                              </div>
                              <div className="each-row">
                                <span>{t('TERMINAL')}: </span>
                                <div>{terminal || '-'}</div>
                              </div>
                              <div className="each-row">
                                <span>{t('TERMINAL_LEVEL')}:</span>
                                <div>{terminalLevel || '-'}</div>
                              </div>
                              <div className="each-row">
                                <span>
                                  {t('DIFFERENTIALS')} {t('BETWEEN')} {t('RANGE_STARTS')} :
                                </span>
                                <div>
                                  <div className="differentil-between">
                                    <div style={currencyStyle(rangeStart, currentSlideData?.data[index + 1]?.rangeStart)}>{rangeStart || '-'}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="each-row">
                                <span>
                                  {t('DIFFERENTIALS')} {t('BETWEEN')} {t('RANGE_ENDS')} :
                                </span>
                                <div>
                                  <div className="differentil-between">
                                    <div style={currencyStyle(rangeEnd, currentSlideData?.data[index + 1]?.rangeEnd)}>{rangeEnd || '-'}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="each-row">
                                <span>{t('DELIVERY_PORT')}:</span>
                                <div>{remarks || '-'}</div>
                              </div>
                              <div className="each-row">
                                <span>{t('FOREX_LEVEL')}:</span>
                                <div>
                                  {currentSlideData?.forexType} : {forexValue}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {currentSlideData?.label === 'Slide One' && (
                  <div className="history-data-slide">
                    <table className="history-table-header">
                      <thead>
                        <tr>
                          <th className="country-name-th">
                            {t('COUNTRY')} : {currentSlideData?.country} <img src={currentSlideData?.flagUrl} alt="Flag" />
                          </th>
                          <th className="crop-year-th">{t('CROP_YEAR')} : 2030</th>
                          <th colSpan={2}>{currentSlideData?.qualityName}</th>
                        </tr>
                        <tr className="thead-rows">
                          <th className="date-cell">{t('DATE')}</th>
                          <th className="terminal-cell">{t('TERMINAL')}</th>
                          <th className="range-starts-cell" colSpan={2}>
                            {t('DIFFERENTIALS')} {t('BETWEEN')}
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <table>
                      <tbody>
                        {currentSlideData?.data?.map(({ uploadedDtm, terminal, rangeStart, rangeEnd }, index) => (
                          <tr key={index}>
                            <td className="date-cell">{uploadedDtm}</td>
                            <td className="terminal-cell">{terminal || '-'}</td>
                            <td className="range-starts-cell">{rangeStart}</td>
                            <td className="range-ends-cell">{rangeEnd}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>

            {totalSlides !== 1 ? (
              <div className="right-arrow" onClick={() => switchSlide('forword')}>
                <DoubleArrowIcon className="right_icons" sx={{ color: '#50b3f6', width: '30px', height: '30px' }} />
              </div>
            ) : (
              <div className="empty-block"></div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AccordionPopUp
