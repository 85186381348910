import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ShareIcon from '@mui/icons-material/Share'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Carousel from 'framer-motion-carousel'
import { coffeewebGetLocal, coffeewebSetLocal, coffeewebStorageKeys } from 'Utils/LocalStorage_Handler'
import Loader from 'Components/LayoutComponents/Loader'
import { ClockIcon, FilledHeartIcon, UnFilledHeartIcon } from 'Assets/Icons'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ShareNewsfeed from 'Screens/CoffeeNewsFeeds/Components/ShareNewsfeed'
import { addBlankTargetToAnchors } from 'Utils/HtmlUtils'
import { ROUTE_STRINGS } from 'Utils/Constants'
import ApiResponseHandler from 'Components/ApiResponseHandler'

const OpenNewsNotification = ({ setLoading, handleApiResponse }) => {
  const location = useLocation()
  const params = new URLSearchParams(location?.search)
  const notificationNewsId = params.get('newsId')
  const history = useHistory()
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const { i18n, t } = useTranslation()

  const [coffeeNewsData, setCoffeeNewsData] = useState({})
  const [images, setImages] = useState([])
  const [showShareNewsModal, setShowShareNewsModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const [isLiked, setIsLiked] = useState(false)

  useEffect(() => {
    getCoffeeNewsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationNewsId])

  const getCoffeeNewsData = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getNewsById({ userId: userDetails.id, newsId: notificationNewsId, isRead: true })
      const getNotificationNewsData = response.data.returnLst
      const { newsFavourite, nwsFeedMedia, newsHtml, newsLock, newsForLock, newsForSubscription, id: newsId } = getNotificationNewsData

      const subscribedNews = newsForSubscription.split(',')
      const lockedNews = newsForLock.split(',')

      const isSubscriptionValid = subscribedNews.includes(`${userDetails?.sub?.subType}`) && !lockedNews.includes(`${userDetails?.sub?.subType}`)

      if (isSubscriptionValid) {
        const modifiedNewsHtml = addBlankTargetToAnchors(newsHtml)
        const modifiedNotificationData = { ...getNotificationNewsData, newsHtml: modifiedNewsHtml }

        if (newsFavourite) {
          setIsLiked((previousState) => !previousState)
        }
        setCoffeeNewsData(modifiedNotificationData)
        const imageMatches = nwsFeedMedia.filter((item) => item.typeMedia === 'image')

        setImages(imageMatches)
      } else {
        history.replace(ROUTE_STRINGS.subscription)
        coffeewebSetLocal(coffeewebStorageKeys.subscriptionNewsId, newsId)
      }
    } catch (error) {
      handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_NEWS', onRetry: getCoffeeNewsData })
    } finally {
      setLoading(false)
    }
  }

  const addToFavorites = async (newsId) => {
    const postData = {
      id: 0,
      idUser: userDetails.id,
      idNews: newsId.id,
      isFavourite: true
    }

    try {
      const {
        data: { returnLst, statusCode }
      } = await apiAdapterCoffeeWeb.postNewsUsersFavorite(postData)

      if (statusCode === 200) {
        const NewsData = { ...coffeeNewsData, newsFavourite: returnLst }

        setCoffeeNewsData(NewsData)
      }
      setIsLiked(true)
      setLoading(false)
    } catch (error) {
      console.log('Error', error)
    }
  }

  const removeFromFavorites = async ({ newsFavourite }) => {
    try {
      const {
        data: { returnLst, statusCode }
      } = await apiAdapterCoffeeWeb.makeNewsUnfavorite(newsFavourite.id)

      if (statusCode === 200) {
        const NewsData = { ...coffeeNewsData, newsFavourite: returnLst }

        setCoffeeNewsData(NewsData)
      }
      setIsLiked(false)
    } catch (error) {
      console.log('Error', error)
    }
  }

  const handleImageModal = (ele) => {
    const imageIndexValue = images.map((e) => e.idNewsFeedMedia).indexOf(ele.idNewsFeedMedia)

    setIsOpen(true)
    setImageIndex(imageIndexValue)
  }

  const handleBackButton = () => {
    const { state } = history.location

    if (state?.pageRef === 'yes') {
      history.goBack()
    } else {
      history.replace(ROUTE_STRINGS.coffeeNewsFeeds)
    }
  }

  const handleShowShareButton = () => {
    setShowShareNewsModal(true)
  }

  const handleViewDocument = (item) => {
    window.open(item.filePath, '_blank')
  }

  return (
    <>
      {Object.keys(coffeeNewsData).length > 0 && (
        <div className="notification_news_container">
          <div className="notification_news">
            <div className="notification_news_image_container">
              <div className="sticky_buttons">
                <div className="back_button" onClick={handleBackButton}>
                  <div>
                    <ArrowBackIcon />
                  </div>
                  <div>{t('GO_BACK')}</div>
                </div>
                <div className="share_button" onClick={() => handleShowShareButton(coffeeNewsData)}>
                  <div>
                    <ShareIcon />
                  </div>
                  <div>{t('SHARE')}</div>
                </div>
              </div>
              {coffeeNewsData.nwsFeedMedia?.length > 0 && (
                <div className="images_div">
                  {coffeeNewsData.nwsFeedMedia?.map((ele, index) => (
                    <div key={index}>{ele.typeMedia === 'image' ? <img alt="notification_images" src={ele.pathOfMedia} className="notification_media_assets" onClick={() => handleImageModal(ele, index)} /> : <video src={ele.pathOfMedia} className="notification_media_assets" controls />}</div>
                  ))}
                </div>
              )}
            </div>
            <div className="notification_news_content_container">
              <div className="sticky_buttons_mobile_size">
                <div className="back_button" onClick={handleBackButton}>
                  <div>
                    <ArrowBackIcon />
                  </div>
                  <div>{t('GO_BACK')}</div>
                </div>
                <div className="share_button" onClick={() => handleShowShareButton(coffeeNewsData)}>
                  <div>
                    <ShareIcon />
                  </div>
                  <div>{t('SHARE')}</div>
                </div>
              </div>
              <div className="sticky_banner">
                <div className="banner_icons">
                  <div className="icon_style">
                    <CalendarMonthIcon className="social_feed_icons" />
                    {coffeeNewsData.customdate}
                  </div>
                  <div className="icon_style">
                    <ClockIcon className="social_feed_icons" />
                    {coffeeNewsData.customtime}
                  </div>
                </div>
                <div className="banner_image">{ReactHtmlParser(coffeeNewsData.subject)}</div>
                <div className="banner_icons">
                  <div className="icon_style">
                    <AccountCircleIcon className="social_feed_icons" />
                    {coffeeNewsData.author}
                  </div>
                  <div className="visibility_icon_and_like_button">
                    <div className="visibility_icon">
                      <VisibilityIcon className="social_feed_icons" />
                      {coffeeNewsData.views}
                    </div>
                    {isLiked ? (
                      <div className="button_pointer" onClick={() => removeFromFavorites(coffeeNewsData)}>
                        <FilledHeartIcon />
                      </div>
                    ) : (
                      <div className="button_pointer" onClick={() => addToFavorites(coffeeNewsData)}>
                        <UnFilledHeartIcon className="heart_logo_blob" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {coffeeNewsData?.nwsFeedMedia?.length === 1 ? (
                  <div className="image_carousel">
                    <div>
                      {coffeeNewsData?.nwsFeedMedia?.map((ele, index) => (
                        <div key={index}>{ele.typeMedia === 'image' ? <img alt="notification_images" src={ele.pathOfMedia} onClick={() => handleImageModal(ele, index)} /> : <video src={ele.pathOfMedia} controls />}</div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="image_carousel">
                    <Carousel interval={8000}>
                      {coffeeNewsData?.nwsFeedMedia?.map((ele, index) => (
                        <div key={index}>{ele.typeMedia === 'image' ? <img alt="notification_images" src={ele.pathOfMedia} onClick={() => handleImageModal(ele, index)} /> : <video src={ele.pathOfMedia} controls />}</div>
                      ))}
                    </Carousel>
                  </div>
                )}
              </div>
              {coffeeNewsData.fileName ? (
                <div className="pdf_view_document_button">
                  <button onClick={() => handleViewDocument(coffeeNewsData)}>View Document</button>
                </div>
              ) : (
                <div className="pdf_view_document_button"></div>
              )}
              <div>
                <div className="content_view">{ReactHtmlParser(coffeeNewsData.newsHtml)}</div>
                <div className="news_html_for_height"></div>
              </div>
            </div>
            {images.length === 1 ? <> {isOpen && <Lightbox mainSrc={images[imageIndex].pathOfMedia} onCloseRequest={() => setIsOpen(false)} onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)} onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)} />}</> : <> {isOpen && <Lightbox mainSrc={images[imageIndex].pathOfMedia} nextSrc={images[(imageIndex + 1) % images.length].pathOfMedia} prevSrc={images[(imageIndex + images.length - 1) % images.length].pathOfMedia} onCloseRequest={() => setIsOpen(false)} onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)} onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)} />}</>}

            <div>{showShareNewsModal && <ShareNewsfeed newsData={coffeeNewsData} closeModal={() => setShowShareNewsModal(false)} />}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default Loader(ApiResponseHandler(OpenNewsNotification))
