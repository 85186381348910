import { useState, useEffect } from 'react'
import { WEATHER_API_URL } from './Constants'

export const useResizeHandler = () => {
  const [isWebView, setIsWebView] = useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsWebView(false)
    } else {
      setIsWebView(true)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { isWebView }
}

export const commonMethods = {
  dateToLocal(args) {
    const date = new Date(args)

    return date.toLocaleDateString()
  },
  getGeoLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          const { latitude, longitude } = coords

          resolve({ latitude, longitude })
        },
        (err) => {
          reject(err)
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 10000
        }
      )
    })
  },
  /* eslint-disable no-useless-catch */

  async searchLocation() {
    try {
      const { latitude, longitude } = await this.getGeoLocation()
      const queryString = `${latitude},${longitude}`
      const response = await fetch(`${WEATHER_API_URL.apiUrl}?q=${queryString}&key=${process.env.REACT_APP_WEATHER_API_KEY}`)
      const [{ country, region, name }] = await response.json()

      return { country, region, name }
    } catch (error) {
      throw error
    }
  },
  updateDateTime() {
    const currentDate = new Date()

    const day = currentDate.getDate()
    const month = currentDate.toLocaleString('default', { month: 'short' })
    const year = currentDate.getFullYear()

    const hours = currentDate.getHours()
    const minutes = currentDate.getMinutes()

    const formattedDate = `${day}-${month}-${year}`
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`

    return { currentDate: formattedDate, currentTime: formattedTime }
  }
  // Left For Review
  // errorStateHandler(errorStateSetter, ...errorParameters) {
  //   const updatedErrorObject = errorStateSetter((previousState) => {
  //     const updatedErrorState = { ...previousState }

  //     errorParameters.forEach((parameterName) => {
  //       updatedErrorState[parameterName] = true
  //     })

  //     console.log(updatedErrorState)

  //     return updatedErrorState
  //   })

  //   console.log(updatedErrorObject)

  //   return updatedErrorObject
  // }
}
