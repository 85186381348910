import './style.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import Snackbar from '@mui/material/Snackbar'
import { useTranslation } from 'react-i18next'
import { coffeewebGetLocal, coffeewebStorageKeys, updateUserDetails } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import Loader from 'Components/LayoutComponents/Loader'
import CountrySelector from 'Components/CountrySelector'
import Header from 'Components/LayoutComponents/Header'
import FreeMenubar from 'Components/LayoutComponents/FreeUserMenubar'
import { BlueColorMainLogo, popupIcon } from 'Assets/Icons'
import AppLinks from 'Components/LayoutComponents/AppLinks'
import { ROUTE_STRINGS } from 'Utils/Constants'
import ApiResponseHandler from 'Components/ApiResponseHandler'
import { useResizeHandler } from 'Utils/commonMethods'
import AccordionPopUp from './Components/AccordionPopUp'
import AccordianForMobile from './Components/AccordianForMobile'

const GlobalDifferentials = ({ setLoading, handleApiResponse }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const isUserLoggedIn = coffeewebGetLocal(coffeewebStorageKeys.isLoggedIn)

  const [globalDifferentialData, setGlobalDifferentialData] = useState([])
  const [globalDifferentialHistoryData, setGlobalDifferentialHistoryData] = useState([])
  const [noDataFound, setNoDataFound] = useState(false)
  const [isDialogModalOpen, setIsDialogModalOpen] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [createAccountPopup, setCreateAccountPopup] = useState(false)
  const [authToken, setAuthToken] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [applyBlurBackground, setApplyBlurBackground] = useState(false)

  const { isWebView } = useResizeHandler()

  const [snackbarPosition, setSnackbarPosition] = React.useState({
    vertical: 'bottom',
    horizontal: 'right'
  })
  const { vertical, horizontal } = snackbarPosition

  useEffect(() => {
    if (!isUserLoggedIn) {
      generateAuthToken()
    } else {
      updateUser()
    }
  }, [])

  useEffect(() => {
    if (authToken && selectedCountry) {
      getGlobalDifferentialByCountry(selectedCountry)
    }
  }, [authToken, selectedCountry])

  const updateUser = async () => {
    const response = await updateUserDetails()

    if (response?.status === 401) {
      handleApiResponse({ status: response.status })
    }
  }

  useEffect(() => {
    if (applyBlurBackground) {
      const timeoutId = setTimeout(() => {
        setCreateAccountPopup(true)

        const element = document.getElementById('global_differentials_background')

        if (element) {
          const backgroundStyle = {
            filter: 'blur(3px)'
          }

          Object.assign(element.style, backgroundStyle)
        }
      }, 0)

      return () => clearTimeout(timeoutId)
    }
  }, [applyBlurBackground])

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY
      const element = document.getElementsByClassName('global_differentials_headers')

      if (currentPosition > 2) {
        element[0].style.borderBottom = '1px solid lightGray'
      } else {
        element[0].style.borderBottom = 'none'
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const getGlobalDifferentialByCountry = async (countryId) => {
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.getGlobalDifferentialByCountryId(countryId, authToken || null)

      if (data?.returnLst) {
        const { globalDifferentialCountryData } = data.returnLst

        setGlobalDifferentialData(globalDifferentialCountryData)
        setNoDataFound(false)

        if (!isUserLoggedIn) {
          setApplyBlurBackground(true)
        }
      } else {
        setNoDataFound(true)
        setGlobalDifferentialData([])
      }
    } catch (error) {
      setGlobalDifferentialData([])
      handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_GLOBAL_DIFFERENTIAL_DATA', onRetry: () => getGlobalDifferentialByCountry(countryId) })
    } finally {
      setLoading(false)
    }
  }

  const generateAuthToken = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.generateAuthToken()
      const { data, status } = response

      if (data?.token) {
        setAuthToken(data.token)
      } else {
        handleApiResponse({ status, message: 'FAILED_TO_GENERATE_AUTH_TOKEN', onRetry: generateAuthToken })
      }
    } catch (error) {
      handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_GENERATE_AUTH_TOKEN', onRetry: generateAuthToken })
    } finally {
      setLoading(false)
    }
  }

  const handleSelectCountry = ({ id }) => {
    if (isUserLoggedIn) {
      getGlobalDifferentialByCountry(id)
    }
    setSelectedCountry(id)
  }

  const handleShowAccordionPopup = ({ idCountry, qualityName, countryName }) => {
    if (!isUserLoggedIn) {
      setApplyBlurBackground(true)
    } else {
      getGlobalDifferentialHistoryDataByCountryId({ idCountry, qualityName, countryName })
    }
  }

  const getGlobalDifferentialHistoryDataByCountryId = async ({ idCountry, qualityName, countryName }) => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getGlobalDifferentialHistoryData(idCountry, qualityName, countryName, authToken || null)

      if (response?.data?.returnLst) {
        setGlobalDifferentialHistoryData(response?.data?.returnLst)
        setIsDialogModalOpen(true)
      } else {
        setGlobalDifferentialHistoryData([])
        setShowSnackbar(true)
        setTimeout(() => {
          setShowSnackbar(false)
        }, 4000)
      }
    } catch (error) {
      setGlobalDifferentialHistoryData([])
      handleApiResponse({ status: error?.response?.status, message: 'FAILED_TO_FETCH_GLOBAL_DIFFERENTIAL_HISTORY_DATA', onRetry: () => getGlobalDifferentialHistoryDataByCountryId({ idCountry, qualityName }) })
    } finally {
      setLoading(false)
    }
  }

  const navigateToLoginScreen = () => history.push(ROUTE_STRINGS.login)

  const currencyStyle = (value) => {
    if (value) {
      const convertedToNumber = parseFloat(value?.replace(/[$¢]/g, ''))

      if (convertedToNumber >= 0) {
        return { color: 'green' }
      }

      return { color: 'red' }
    }

    return {}
  }

  return (
    <div className="global_differentials_screens">
      {!isUserLoggedIn && (
        <>
          <Header />
          <FreeMenubar />
        </>
      )}
      <Snackbar open={showSnackbar} anchorOrigin={{ vertical, horizontal }} message={t('FAILED_TO_GET_DATA')} />
      <div className="global_differentials_headers">
        <div className="title">{t('GLOBAL_DIFFERENTIALS')}</div>
        <div className="dropdown">
          <span>{t('SELECT_ORIGIN')} :</span>
          <CountrySelector handleSelectCountry={handleSelectCountry} filterType={'isCoffeeProducingCountry'} />
        </div>
      </div>
      <div className="global_differentials_body" id="global_differentials_background">
        {globalDifferentialData?.map((GDItem, index) => (
          <div key={index}>
            <div className="differential_table">
              <div className="table_data">
                <div className="year-web-view">
                  <div className="flag_and_cropyear web-view">
                    <div className="crop_year_flag">
                      <img src={GDItem.flagUrl} alt="Flag" /> {GDItem?.country}
                      <div className="cropyear">
                        {t('CROP_YEAR')} : {GDItem?.croyear}
                      </div>
                    </div>
                    <div className="updated_on">
                      {t('UPDATED_ON')} : {GDItem?.upDated}
                    </div>
                    <div>
                      {GDItem?.forexType} : {GDItem?.forexValue}
                    </div>
                  </div>
                </div>
                <div className="year-mobile-view">
                  <div className="flag_and_cropyear">
                    <div className="crop_year_flag">
                      <img src={GDItem.flagUrl} alt="Flag" /> {GDItem?.country}
                    </div>
                    <span>
                      {GDItem?.forexType} : {GDItem?.forexValue}
                    </span>
                  </div>
                  <div className="year-date-wrapper">
                    <div className="cropyear">
                      {t('CROP_YEAR')} : {GDItem?.croyear}
                    </div>
                    <div className="updated_on">
                      {t('UPDATED_ON')} : {GDItem?.upDated}
                    </div>
                  </div>
                </div>
                {GDItem?.globalDifferentialAdminDTOs?.map((ele, index) => (
                  <React.Fragment key={index}>
                    {isWebView ? (
                      <table className="each_table" key={index}>
                        <thead className="differential_table_header">
                          <tr>
                            <th className="slNo blue-border">{index + 1}</th>
                            <th colSpan={1} className="quality-th-cell blue-border">
                              {t('QUALITY')}
                            </th>
                            <th className="screen-th-cell blue-border">{t('SCREEN')}</th>
                            <th className="blue-border" colSpan={2}>
                              {t('DIFFERENTIALS')} V/s
                            </th>
                            <th colSpan={2} className="blue-border">
                              {' '}
                              {ele?.terminal}
                            </th>
                            <th colSpan={2} className="blue-border">
                              {ele?.terminalLevel}
                            </th>
                            <th className="delivery_port blue-border">{t('DIFFERENTIALS_BASED_ON_FOB')}</th>
                          </tr>
                          <tr>
                            <th className="serial-column">{t('SL')}</th>
                            <th>{`${GDItem?.country?.toUpperCase()}  ${ele?.qualityGroupName}`}</th>
                            <th>{t('SIZE')}</th>
                            <th colSpan={6}>{t('BETWEEN')}</th>
                            <th>{t('FOB Port')}</th>
                          </tr>
                        </thead>
                        <tbody className="differential_table_body">
                          {ele?.globalDifferentialData?.map(({ idCountry, qualityName, qualityCode, rangeStart, rangeEnd, terminal, remark }, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td onClick={() => handleShowAccordionPopup({ idCountry, qualityName, countryName: GDItem?.country })} className="quality-group-name-td">
                                <div>
                                  {qualityName} <img className="popup-icon" src={popupIcon} alt="popup-icon" />
                                </div>
                              </td>
                              <td>{qualityCode}</td>
                              <td className="range-starts" style={currencyStyle(rangeStart)} colSpan={3}>
                                {rangeStart}
                              </td>
                              <td className="range-ends" style={currencyStyle(rangeEnd)} colSpan={3}>
                                {rangeEnd}
                              </td>
                              {index === 0 && (
                                <td className="fob-cell" rowSpan={ele?.globalDifferentialData.length}>
                                  {ele?.fobPort}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <AccordianForMobile data={[ele]} GDItem={GDItem} />
                    )}
                    {GDItem?.globalDifferentialAdminDTOs?.length > index + 1 && <div className="table-divider">{/* "This is for divider : It should be empty" */}</div>}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className="last-table-divider">{/* "This is for divider : It should be empty" */}</div>
        {noDataFound && (
          <div className="no_data_found">
            <FindInPageIcon sx={{ width: '220px', height: '220px', display: 'flex', color: '#50b3f678', margin: 'auto' }} />
            {t('NO_DATA_FOUND_FOR_THIS_COUNTRY')}
          </div>
        )}
      </div>
      <div>
        {createAccountPopup && (
          <div className="guest-global-differential-dailog">
            <div className="content-wrapper">
              <BlueColorMainLogo className="coffeeweb-logo" />
              <div className="info"> {t('TO_ACCESS_THIS_INFORMATION_FOR_GLOBAL_DIFFERENTIAL')}</div>
              <div className="auth-screen-button" onClick={() => navigateToLoginScreen()}>
                {t('LOGIN_AND_CREATE_ACCOUNT')}
              </div>
              <div>
                <AppLinks />
              </div>
            </div>
          </div>
        )}
        <AccordionPopUp isDialogModalOpen={isDialogModalOpen} setIsDialogModalOpen={setIsDialogModalOpen} globalDifferentialHistoryData={globalDifferentialHistoryData} />
      </div>
    </div>
  )
}

export default Loader(ApiResponseHandler(GlobalDifferentials))
