import './styles.scss'
import { useEffect, useState } from 'react'
import { FacebookShareButton, WhatsappShareButton, WhatsappIcon, FacebookIcon, EmailShareButton, EmailIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon, TelegramShareButton, TelegramIcon } from 'react-share'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Modal } from 'react-bootstrap'
import { Closebtn } from 'Assets/Icons'
import Loader from 'Components/LayoutComponents/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'
import ApiResponseHandler from 'Components/ApiResponseHandler'

const ShareNewsFeed = ({ newsData, closeModal, setLoading, generatedToken, handleApiResponse, setShowShareNewsModal }) => {
  const { t } = useTranslation()

  const shareUrl = `${process.env.REACT_APP_SHARE_URL_ENDPOINT}/share/News/${newsData.encryptedId}`
  const coffeeWebTitle = 'CoffeeWeb News!'
  const appStore = 'App Store :'
  const appStoreUrl = 'https://apps.apple.com/in/app/coffeeweb/id1618878403'
  const playStore = 'Play Store :'
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.coffeeweb.app&pli=1'
  const website = 'Website :'
  const websiteUrl = `${process.env.REACT_APP_SHARE_URL_ENDPOINT}`
  const newsTitle = `${coffeeWebTitle} ${newsData.subject.replace(/<\/?b>/g, '')}\n${shareUrl}\n`
  const newsTitleForWhatsApp = `*${coffeeWebTitle}*\n\n${newsData.subject.replace(/<\/?b>/g, '')}\n\n${shareUrl}`
  const shareLinksFowWhatsApp = `\n\n*${appStore}* ${appStoreUrl}\n\n*${playStore}* ${playStoreUrl}\n\n*${website}* ${websiteUrl}`

  const imgUrl = 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/CoffeeWeb_Logo_White_Background_Blue_Text.png'

  const [isCopied, setIsCopied] = useState(false)
  const [shortenUrl, setShortenUrl] = useState('')
  const [activeSocialMediaLists, setActiveSocialMediaLists] = useState('')
  const [modalResponse, setModalResponse] = useState(false)

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const init = async () => {
    setLoading(true)
    const getAllShareList = apiAdapterCoffeeWeb.getAllShareList(generatedToken)
    const getShortUrl = apiAdapterCoffeeWeb.shortenUrl(shareUrl, generatedToken)

    try {
      const [getAllShareListResponse, getShortUrlResponse] = await Promise.all([getAllShareList, getShortUrl])

      const activeSocialMediaListNames = getAllShareListResponse.data?.returnLst?.map((ele) => ele.shareName)

      setModalResponse(true)
      setActiveSocialMediaLists(activeSocialMediaListNames)
      setShortenUrl(getShortUrlResponse.data)
      setLoading(false)
    } catch (error) {
      handleApiResponse({ status: error?.response.status, message: 'FAILED_FETCHING_NEWS_SHARING_OPTIONS', onDismiss: () => setShowShareNewsModal(false), onRetry: init })
      setLoading(false)
    }
  }

  const handleCopyClick = () => {
    const copiedText = `${coffeeWebTitle}\n\n${newsData.subject.replace(/<\/?b>/g, '')}\n\n${shareUrl}\n` + '\n' + `${appStore} ${appStoreUrl}\n` + '\n' + `${playStore} ${playStoreUrl}\n` + '\n' + `${website} ${websiteUrl}`

    navigator.clipboard.writeText(copiedText)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  return (
    <Modal show={modalResponse && true} className="modal-share-news-read-more">
      <Modal.Header>
        <Modal.Title className="modal-share-news-title">
          <div className="modal-title-share-news-read-more">{t('SHARE')}</div>
        </Modal.Title>
        <Closebtn className="modal-close-btn-share-news-read-more" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-share-news-read-more">
          <div className="share-news-feed-container">
            <div className="share-news-feed-div">
              {activeSocialMediaLists.includes('Copy') && (
                <div className="social-media-div">
                  <Tooltip title={isCopied ? 'Copied!' : 'Copy'}>
                    <div>
                      <div className="copy-button" onClick={handleCopyClick}>
                        <ContentCopyIcon quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} value={shortenUrl} className="content-copy-icon" />
                      </div>
                      <p className="icons-text" onClick={handleCopyClick}>
                        {t('COPY')}
                      </p>
                    </div>
                  </Tooltip>
                </div>
              )}
              {activeSocialMediaLists.includes('WhatsApp') && (
                <div className="social-media-div">
                  <WhatsappShareButton url={shareLinksFowWhatsApp} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitleForWhatsApp} media={imgUrl}>
                    <WhatsappIcon size={40} round={true} />
                    <p className="icons-text">{t('WHATS_APP')}</p>
                  </WhatsappShareButton>
                </div>
              )}

              {activeSocialMediaLists.includes('Facebook') && (
                <div className="social-media-div">
                  <FacebookShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
                    <FacebookIcon size={40} round={true} />
                    <p className="icons-text">{t('FACEBOOK')}</p>
                  </FacebookShareButton>
                </div>
              )}

              {activeSocialMediaLists.includes('LinkedIn') && (
                <div className="social-media-div">
                  <LinkedinShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
                    <LinkedinIcon size={40} round={true} />
                    <p className="icons-text">{t('LINKED_IN')}</p>
                  </LinkedinShareButton>
                </div>
              )}

              {activeSocialMediaLists.includes('Twitter') && (
                <div className="social-media-div">
                  <TwitterShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
                    <TwitterIcon size={40} round={true} />
                    <p className="icons-text">{t('TWITTER')}</p>
                  </TwitterShareButton>
                </div>
              )}

              {activeSocialMediaLists.includes('Telegram') && (
                <div className="social-media-div">
                  <TelegramShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
                    <TelegramIcon size={40} round={true} />
                    <p className="icons-text">{t('TELEGRAM')}</p>
                  </TelegramShareButton>
                </div>
              )}

              {activeSocialMediaLists.includes('Email') && (
                <div className="social-media-div">
                  <EmailShareButton url={shortenUrl} quote={`"${newsTitle}" - Check out the full article at ${shortenUrl}`} hashtag={'#portfolio...'} title={newsTitle} media={imgUrl}>
                    <EmailIcon size={40} round={true} />
                    <p className="icons-text">{t('MAIL')}</p>
                  </EmailShareButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Loader(ApiResponseHandler(ShareNewsFeed))
