import { coffeewebStorageKeys, coffeewebGetLocal, updateUserDetails } from 'Utils/LocalStorage_Handler'
import apiAdapterCoffeeWeb from 'Services/apiAdapter_CoffeeWeb'

function razorpay({ gstInput, setLoading, handleApiResponse }) {
  const { userName, organistationName, organistationAddress, gstNumber, email } = gstInput
  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  const razorpayCreateOrder = async (ele) => {
    setLoading(true)
    try {
      const postData = {
        order: {
          userId: userDetails.id,
          billingAddress: 'NA',
          shippingAddress: 'NA',
          states: userDetails.states,
          city: userDetails.city,
          totalQuantity: 1,
          totalAmount: ele.basePrice,
          paymentDate: '2022-12-24T04:51:11.228Z',
          orderDate: '2022-12-24T04:51:11.228Z',
          payOrderId: 'NA',
          subscriptionId: ele.name,
          facilitatorAccessToken: 'NA',
          paymentId: ele.paymentId,
          paymentSource: ele.paymentName,
          paymentStatus: 'Pending',
          orderSatus: 1
        },
        orderDetails: [
          {
            idOrderDetail: 0,
            orderId: 0,
            planId: 'NA',
            serviceName: 'NA',
            subscriptionType: ele.paymentName,
            quantity: 0,
            rate: 0
          }
        ]
      }

      const response = await apiAdapterCoffeeWeb.createOrder(postData)

      if (response.data.isSuccess === true) {
        InitateRazorpayPayment(ele, response?.data?.returnLst)
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: 'SORRY_FAILED_TO_CREATE_PAYMENT', onRetry: () => razorpayCreateOrder(ele) })
    } finally {
      setLoading(false)
    }
  }

  const loadScript = (src) =>
    new Promise((resolve) => {
      const script = document.createElement('script')

      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })

  const InitateRazorpayPayment = async (ele, orderData) => {
    const amount = userDetails.countryId === 102 ? ele.csgstTaxableAmount : ele.basePrice
    const razorPayKey = process.env.REACT_APP_RAZORPAY_KEY
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('you are in offline filed to load Razorpay sdk')

      return
    }

    const options = {
      key: razorPayKey,
      currency: 'INR',
      amount: amount * 100,
      handler(response) {
        OrderUpdate(response, orderData, amount)
      },
      prefill: {
        name: `${userDetails.firstName}`,
        email: `${userDetails?.email}`,
        contact: `${userDetails.phone}`
      },
      theme: {
        color: '#50b3f6'
      }
    }

    const paymentObject = new window.Razorpay(options)

    paymentObject.open()
  }
  const OrderUpdate = async (razorPayPaymentId, oderData, amount) => {
    try {
      const getOrDefault = (value, defaultValue) => value || defaultValue

      const updateOrder = {
        updateOrderDTO: {
          idOrder: oderData.orderDetails[0]?.orderId,
          payOrderId: razorPayPaymentId.razorpay_payment_id,
          subscriptionId: oderData.order.subscriptionId,
          facilitatorAccessToken: 'string',
          paymentSource: oderData.order.paymentSource,
          paymentStatus: 'Success',
          orderSatus: 2,
          amount
        },
        userGSTDetails: {
          idUserGstdetails: 0,
          userId: 0,
          organistationName: getOrDefault(organistationName || 'NA'),
          organistationAddress: getOrDefault(organistationAddress || 'NA'),
          stateId: 0,
          gstno: getOrDefault(gstNumber || 'NA'),
          entryDate: '2023-05-25T12:19:02.839Z',
          lastUsed: false,
          lastUsedDtm: '2023-05-25T12:19:02.839Z',
          userName: getOrDefault(userName || 'NA'),
          email: getOrDefault(email || 'NA')
        }
      }

      setLoading(true)

      const response = await apiAdapterCoffeeWeb.UpdateOrder(updateOrder)

      if (response.data.statusCode === 200) {
        setTimeout(async () => {
          const userSubscriptionUpdated = await updateUserDetails()

          if (userSubscriptionUpdated) {
            window.location.pathname = 'dashboard/success'
          } else {
            setLoading(false)
          }
        }, 3000)
      }
    } catch (error) {
      const statusCode = error?.response?.status

      handleApiResponse({ status: statusCode, message: 'SORRY_FAILED_TO_UPDATE_PAYMENT', onRetry: () => OrderUpdate(razorPayPaymentId, oderData, amount) })
      setLoading(false)
    }
  }

  return {
    razorpayCreateOrder
  }
}

export default razorpay
