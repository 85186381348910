import { Route, Switch, useHistory } from 'react-router-dom'
import './App.scss'
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import CacheBuster from 'react-cache-buster'
import { doc, updateDoc, onSnapshot, serverTimestamp, Timestamp, arrayUnion, collection, getDocs, getDoc } from 'firebase/firestore'
import { Toaster, toast } from 'react-hot-toast'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { RouteStrings } from 'Utils/Routes/RouteStrings'
import { Logo } from 'Assets/Icons'
import { coffeewebStorageKeys, coffeewebGetLocal, coffeewebSetLocal } from 'Utils/LocalStorage_Handler'
import GuestNewsFeed from 'Screens/CoffeeNewsFeeds/Components/GuestNewsFeed'
import AboutUs from 'Screens/AboutUs'
import GlobalDifferentials from 'Screens/GlobalDifferentials'
import CoffeeQuotes from 'Screens/CoffeeQuotes'
import Routes from './Components/LayoutComponents/Routes'
import Footer from './Components/LayoutComponents/Footer'
import ProtectedRoute from './Components/LayoutComponents/Routes/ProtectedRoute'
import AuthScreens from './Screens/AuthScreens'
import SupportRequest from './Screens/SupportRequest'
import NewsFeedLink from './Screens/NewsFeedLink'
import versionDetail from '../package.json'
import { db, storage } from './firebase'
import AlreadyLoggedIn from './Components/LayoutComponents/AlreadyLoggedIn'
import MyErrorBoundary from './Components/MyErrorBoundary'

function App() {
  const [showLogoutPopup, setShowLogoutPopup] = useState(false)
  const tabID = window.sessionStorage.getItem('tabID')

  useEffect(() => {
    removeServiceWorkerRegistration()
  }, [])

  // useEffect(() => {
  //   let mounted = false;
  //   let userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails);
  //   console.log(userDetails)
  //   if (!userDetails) return;
  //   const { phone } = userDetails
  //   console.log(phone)

  //     const unSub = onSnapshot(doc(db, "CoffeeWeb", phone), (doc) => {
  //       if (mounted) {
  //       setTimeout(() => {
  //         const response = doc.data()
  //         console.log(userDetails)
  //         console.log(response)
  //         if (response?.DeviceId !== tabID && userDetails !== null) {
  //           coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, false);
  //           localStorage.clear();
  //           setShowLogoutPopup(true);
  //         }
  //       }, 1000)
  //       } else {
  //         mounted = true;
  //       }
  //     });
  //     return () => {
  //       unSub();
  //     };

  // }, []);

  const userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  // const unSub = onSnapshot(doc(db, "CoffeeWeb", phone), (doc) => {
  //   let userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails);
  //   setTimeout(() => {
  //     const response = doc.data()
  //     console.log(userDetails)
  //     console.log(response)
  //     if (response?.DeviceId !== tabID && userDetails !== null) {
  //       console.log("out")
  //       // coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, false);
  //       // localStorage.clear();
  //       // setShowLogoutPopup(true);
  //     }
  //   }, 1000)
  // });

  // useEffect(() => {
  //   let userDetails = coffeewebGetLocal(coffeewebStorageKeys.userDetails);
  //   let phone = userDetails !== null ? userDetails.phone : "8088117092"
  //   // if (phone !== "") {
  //     console.log(userDetails)
  //     const unSub = onSnapshot(doc(db, "CoffeeWeb", phone), (doc) => {
  //       console.log(userDetails)
  //       const response = doc.data()
  //       if(response?.DeviceId !== tabID){
  //         console.log("out")
  //       }
  //     });
  //     return () => {
  //       unSub();
  //     };
  //   // }
  // }, []);

  const history = useHistory()

  function logoutUser() {
    localStorage.clear()
    setShowLogoutPopup(false)
    history.push({
      pathname: ROUTE_STRINGS.login
    })
  }

  const showFireBasePopupInAppJs = () => {
    setShowLogoutPopup(true)
  }

  const uniqueKey = Date.now()

  const isProduction = process.env.NODE_ENV === 'production'

  const removeServiceWorkerRegistration = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister()
        })
      })
    }
  }

  return (
    <MyErrorBoundary>
      <div key={uniqueKey} suppressHydrationWarning={true}>
        <CacheBuster currentVersion={versionDetail.version} isEnabled={isProduction} isVerboseMode={false} metaFileDirectory={'.'}>
          <div>
            <AlreadyLoggedIn showLogoutPopup={showLogoutPopup} logoutUser={logoutUser} />
            <Switch>
              <Route exact path={ROUTE_STRINGS.guestNewsFeed} component={GuestNewsFeed} />
              <Route exact path={ROUTE_STRINGS.guestGlobalDifferentials} component={GlobalDifferentials} />
              <Route exact path={ROUTE_STRINGS.supportRequest} component={SupportRequest} />
              <Route exact path={ROUTE_STRINGS.aboutUs} component={AboutUs} />
              <Route exact path={ROUTE_STRINGS.guestCoffeeQuotes} component={CoffeeQuotes} />
              <Route exact path={ROUTE_STRINGS.aboutMobileView} component={AboutUs} />
              {/* <Route exact path="/changepassword/:id">
                <ChangePassword />
              </Route> */}
              <Route exact path="/share/News/:id">
                <NewsFeedLink />
              </Route>
              <Route exact path={ROUTE_STRINGS.login} component={AuthScreens} />
              <Route exact path={ROUTE_STRINGS.createAccount} component={AuthScreens} />
              <ProtectedRoute path={ROUTE_STRINGS.dashboard} component={Routes} />
            </Switch>
          </div>
        </CacheBuster>
        <Toaster
          // toast position
          position="bottom-right"
          // reverse position
          reverseOrder={false}
          // margin between two toasts
          gutter={8}
          // class name for styling
          containerClassName=""
          // add more custom styles here
          containerStyle={{}}
          // custom styling for all toasts
          toastOptions={{
            // Define default options
            className: '',
            duration: 7000,
            style: {
              background: 'rgb(255, 255, 255)',
              color: '#3498db',
              width: 'fit-content',
              padding: '10px',
              borderRadius: '10px',
              margin: '8px',
              boxShadow: '0px 0px 5px gray',
              gap: '10px'
            }

            // Default options for specific types
            // success: {
            //   duration: 3000,
            //   theme: {
            //     primary: 'blue',
            //     secondary: 'white',
            //   },
            // },
          }}
        />
      </div>
    </MyErrorBoundary>
  )
}

export default App
